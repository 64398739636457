import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Button from "components/CustomButtons/Button";
import { Box } from "@material-ui/core";
import CustomDate from "components/CustomDate";
const AgGridCustom = (props: any) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    if (props?.setExportData) {
      props?.setExportData(params?.api);
    }

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  // useEffect(()=>{
  //   if(props.rowData && gridApi){
  //     console.log("I am called refresh",gridApi)
  //     gridApi?.refreshRows();

  //   }

  // },[props.rowData])

  const {
    columnDefs,
    rowData,
    serverSideDatasource,
    minWidth,
    getRowNodeId,
    id,
    defaultExportParams,
  } = props;
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };
  function isFirstColumn(params: any) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#f7f7f7e8" };
    }
  };
  const frameworkComponents = {
    agDateInput: CustomDate,
  };

  return (
    <div className="example-wrapper">
      <div
        id="myGrid"
        style={{
          height: "80vh",
          width: "100%",
        }}
        className="ag-theme-alpine"
      >
        {/* <Box
          display="flex"
          justifyContent="flex-end"
          style={{ width: "100%" }}
        >

          <Button color="primary" className="custom-btn" onClick={() => onBtnExport()}>Export to Csv</Button>
        </Box> */}
        <div
          className="ag-grid-custom-table"
          style={{ height: "100%", width: "100%" }}
        >
          <AgGridReact
            getRowStyle={getRowStyle}
            defaultCsvExportParams={defaultExportParams}
            columnDefs={columnDefs}
            // animateRows={true}
            defaultColDef={{
              flex: 1,
              sortable: true,
              filter: true,
              resizable: true,
              wrapText: true,
              minWidth: 230,
              autoHeight: true,
              cellStyle: {
                textAlign: "left",
                alignItems: "left",
                "border-left-color": "#e2e2e2",
                minHeight: "100%",
              },
              headerCheckboxSelection: props.disabledCheckBox
                ? false
                : isFirstColumn,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: props.disabledCheckBox ? false : isFirstColumn,
            }}
            id={id}
            // animateRows={true}
            rowData={rowData}
            scroll={true}
            rowSelection="multiple"
            getRowNodeId={getRowNodeId}
            onGridReady={onGridReady}
            serverSideDatasource={serverSideDatasource}
            {...props}
            pagination={true}
            paginationPageSize={10}
            enableCellTextSelection={true}
            verticalScrollbar={true}
            frameworkComponents={frameworkComponents}
          />
        </div>
      </div>
    </div>
  );
};

export default AgGridCustom;

import axios from "axios";
import { toast } from "react-toastify";
import { getSession } from "./cognitouser";

const REACT_APP_API_URL: any = process.env.REACT_APP_API_URL;
const REACT_APP_BASE_PATH: any = process.env.REACT_APP_BASE_PATH;
const API_URL: any = REACT_APP_API_URL + REACT_APP_BASE_PATH;

const instance = axios.create({
  baseURL: API_URL,//process.env.API_URL,
  headers: { "Content-Type": "application/json" },
});
instance.interceptors.request.use(function (config) {
  var token = localStorage.getItem("token") ? "Bearer " + localStorage.getItem("token") : getSession() && getSession().getIdToken() ? "Bearer " + getSession().getIdToken().getJwtToken() : "";
  config.headers.Authorization = token;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(
  res => res,
  (err: any) => {

    let errorMessage: any = err?.response?.data?.result;
    if (typeof errorMessage === 'string' && errorMessage !== null) {
      toast.error(({ data }) => `Error ${data}`, {
        data: errorMessage
      })
    }else if (errorMessage?.[0]?.message) {
      toast.error(({ data }) => `Error ${data}`, {
        data: errorMessage?.[0]?.message?.replace(/\"/g, "")
      })
    }else if(errorMessage?.message){
      toast.error(({ data }) => `Error ${data}`, {
        data: errorMessage?.message
      })
    }else if (err?.response?.data?.message) {
      toast.error(({ data }) => `Error ${data}`, {
        data: err?.response?.data?.message
      })
    }
    if (err == undefined) {
      // toast.error(({ data }) => `Error ${data}`, {
      //   data: 'Network connection.'
      // })
    }
    if(JSON.parse(JSON.stringify(err)).name=='Error'){
      // toast.error(({ data }) => `Error ${data}`, {
      //   data: 'Network connection.'
      // })
      throw JSON.parse(JSON.stringify(err)).name;
    }
    console.log("err",)
    if (err !== undefined)
      throw new Error(err);
  }
)

export default instance;

import React, { ChangeEvent } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import * as XLSX from "xlsx";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import { InputAdornment } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  InputField,
  SwitchFieldDefault,
  DateField,
} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import { updatePromotionValidations } from "./Validations/promotionUpdateValidations";
import { CREATE_SUCCESS_MSG } from "json/constants";
import UploadFileIcon from "@material-ui/icons/FileCopy";

import { useHistory, useLocation } from "react-router-dom";
import { updateBulkPromotion } from "services/promotionServices";
import moment from "moment";

import "react-image-crop/dist/ReactCrop.css";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain",
  },
  media: {
    height: "100%",
    width: 300,
  },
  dot: {
    height: "70px",
    width: "70px",
    borderRadius: " 50%",
    display: " inline-block",
  },
};

const defaultFormValues: any = {
  name: "",
  discount_percentage: "",
  code: "",
  start_date: null,
  end_date: null,
  status: true,
  auto_apply: false,
  show_homepage: false,
};

const useStyles = makeStyles(styles);
declare var google: any;

export default function UpdatePromotion(props: any) {
  const classes = useStyles();
  const [editData, setEditData] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = React.useState<string>("Update Bulk");
  const [disabled, setDisabled] = useState<any>(false);
  const [disabledAutoapply, setDisabledAutoApply] = useState<any>(false);
  const [disabledCount, setDisabledCount] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [codeDisable, setCodeDisable] = useState<any>(false);
  const [endDate, setEndDate] = useState<any>(null);
  const { search } = useLocation();

  let uuid = new URLSearchParams(search).get("uuid");

  let history = useHistory();

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(updatePromotionValidations),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Offers ",
      link: "/admin/promotion",
    },
    {
      name: headerTitle,
      link: "",
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];

  const onSubmit = (data: any) => {
    let format = "YYYY-MM-DD";
    data.start_date = moment(data.start_date).format(format);
    data.end_date = moment(data.end_date).format(format);

    if (data?.user_limit == " ") {
      data.user_limit = null;
    }

    if (data?.code_limit == " ") {
      data.code_limit = null;
    }

    if (!data?.code && !data?.auto_apply) {
      toast.error("Please enter Promotion Code or Turn on AutoApply");
      return;
    }

    const codes = data?.code != "" ? data.code : null;

    let modifyData: any = {
      name: data?.name,
      user_limit: data?.user_limit != "" ? data.user_limit : null,
      code_limit: data?.code_limit != "" ? data.code_limit : null,
      polygon: data?.polygon,
      specific_location: data?.polygon ? true : null,
      count: data?.count != "" ? data.count : null,
      discount_percentage: data?.discount_percentage,
      start_date: data?.start_date,
      end_date: data?.end_date,
      status: data?.status,
      auto_apply: data?.auto_apply,
      show_homepage: data?.show_homepage,
      description: data?.description,
    };
    dispatch(spinLoaderShow(true));

    createBulkSubmit({
      ...modifyData,
      codes: codes.toLowerCase().split(", "),
    });
  };

  const createBulkSubmit = (data: any) => {
    updateBulkPromotion(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/promotion");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const onCountChange = (data: any) => {
    if (data.target.value) {
      setDisabledAutoApply(true);
      setValue("auto_apply", false);
      setValue("count", data.target.value);
    } else {
      if (!getValues("code")) {
        setDisabledAutoApply(false);
      }
      setValue("count", null);
    }
  };

  const onCodeChange = (data: any) => {
    if (data.target.value) {
      if (data.target.value.includes(",")) {
        setDisabledCount(true);
      }
      setDisabledAutoApply(true);
      setValue("auto_apply", false);
      setValue("code", data.target.value);
    } else {
      if (!getValues("count")) {
        setDisabledAutoApply(false);
      }
      setDisabledCount(false);
      setValue("code", null);
    }
  };

  const onAutoApplyChange = (data: any) => {
    if (data == true) {
      setValue("code", null);
      setValue("count", null);
      setDisabledCount(true);
      setCodeDisable(true);
    } else {
      setDisabledCount(false);
      setCodeDisable(false);
    }
  };
  const onStartChange = (data: any) => {
    setStartDate(data);
  };
  const onEndChange = (data: any) => {
    setEndDate(data);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target?.result;
        // Call a function to convert XLSX to array
        const dataArray = convertXLSXToArray(content);
        setDisabledAutoApply(true);
        setValue("auto_apply", false);
        setDisabledCount(true);
        setValue("code", dataArray.join(", "));
      };

      reader.readAsBinaryString(file);
    }
  };

  const convertXLSXToArray = (xlsxContent: any) => {
    const workbook = XLSX.read(xlsxContent, { type: "binary" });
    const sheetName = workbook.SheetNames[0]; // Assuming there is only one sheet
    const sheet = workbook.Sheets[sheetName];
    // Convert sheet data to array
    const dataArray = XLSX.utils.sheet_to_txt(sheet);
    return dataArray.split("\n");
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Add an Offer "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
                { text: "Back", link: "/admin/promotion" },
              ]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <h4 className={classes.cardTitleWhite}>
                    {" "}
                    {headerTitle} Offer
                  </h4>
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      iProps={{
                        disabled: disabled,
                      }}
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.service_config_id}
                      fieldName="service_config_id"
                      autoComplete="off"
                      label="* Select Service Config Id"
                      control={control}
                      setValue={setValue}
                      options={serviceconfig}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="level_type"
                      helperText={
                        errors?.service_config_id && errors?.service_config_id?.message
                      }
                      valueGot={editData?.service_config_id && serviceconfig?.find(({uuid}:any)=>{ return uuid == editData?.service_config_id})}
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.discount_percentage}
                      fieldName="discount_percentage"
                      type="text"
                      autoComplete="off"
                      label="* Discount Percentage"
                      control={control}
                      iProps={{
                        type: "number",
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.discount_percentage &&
                        errors?.discount_percentage?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.code}
                      fieldName="code"
                      type="text"
                      autoComplete="off"
                      label="Code"
                      control={control}
                      iProps={{
                        onChange: onCodeChange,
                        disabled: disabled || codeDisable,
                      }}
                      InputProps={{
                        endAdornment: uuid ? (
                          <></>
                        ) : (
                          <InputAdornment position="end">
                            <label
                              htmlFor="file-input"
                              className="file-upload-label"
                            >
                              <UploadFileIcon />
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              onChange={handleFileUpload}
                              style={{ display: "none" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.code && errors?.code?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.code_limit}
                      fieldName="code_limit"
                      type="text"
                      autoComplete="off"
                      label="How many user? (Code Limit)"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.code_limit && errors?.code_limit?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.user_limit}
                      fieldName="user_limit"
                      type="text"
                      autoComplete="off"
                      label="How many times for each user? (Usage Limit)"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.user_limit && errors?.user_limit?.message
                      }
                    />
                  </GridItem>
                  {!editData && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputField
                        errors={!!errors?.count}
                        fieldName="count"
                        type="text"
                        autoComplete="off"
                        label="Count"
                        control={control}
                        iProps={{
                          disabled: disabled || disabledCount,
                          onChange: onCountChange,
                        }}
                        valueGot={""}
                        setValue={setValue}
                        helperText={errors?.count && errors?.count?.message}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.description}
                      fieldName="description"
                      type="text"
                      autoComplete="off"
                      label="* Description"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.description && errors?.description?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.start_date}
                      fieldName="start_date"
                      autoComplete="off"
                      label="* Start Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true,
                        disabled: disabled,
                        format: "dd-MM-yyyy",
                        onChange: onStartChange,
                      }}
                      helperText={
                        errors?.start_date && errors?.start_date?.message
                      }
                      valueGot={startDate}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.end_date}
                      fieldName="end_date"
                      autoComplete="off"
                      label="* End Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true,
                        disabled: disabled,
                        format: "dd-MM-yyyy",
                        onChange: onEndChange,
                      }}
                      helperText={errors?.end_date && errors?.end_date?.message}
                      valueGot={endDate}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.auto_apply}
                      fieldName="auto_apply"
                      autoComplete="off"
                      label="Auto Apply"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled || disabledAutoapply}
                      helperText={
                        errors?.auto_apply && errors?.auto_apply?.message
                      }
                      iProps={{
                        onChange: onAutoApplyChange,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.show_homepage}
                      fieldName="show_homepage"
                      autoComplete="off"
                      label="Show on Home Screen"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.show_homepage && errors?.show_homepage?.message
                      }
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    disabled={disabled}
                    className={"custom-btn"}
                    type="submit"
                  >
                    Update Offer
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}


import httpservice from "./httpservice";

export const getBookingsReports = (body: any = null) => {
    return httpservice.get('/admin/report/booking', {
      params: body,
    })
  
  }

  export const getOldCustomerReports = (body: any = null) => {
    return httpservice.get('/admin/report/customer/old', {
      params: body,
    })
  
  }
  export const getNewCustomerReports = (body: any = null) => {
    return httpservice.get('/admin/report/customer/new', {
      params: body,
    })
  
  }

  export const getPackagesReports = (body: any = null) => {
    return httpservice.get('/admin/report/package', {
      params: body,
    })
    
  }

  export const getTransactionPackageReports = (body: any = null) => {
    return httpservice.get('/admin/user/package/report', {
      params: body,
    })
  }
  export const getTransactionServiceReports = (body: any = null) => {
    return httpservice.get('/admin/user/service/report', {
      params: body,
    })
  }
import * as yup from "yup";
export const EventValidation: any = yup.object().shape({
  //media validation
  name: yup.string().required("please enter Title").nullable(),
  event_description: yup
    .string()
    .required("please enter Description")
    .nullable(),
  event_name: yup.string().required("please enter Last Event Name").nullable(),
  event_location: yup
    .string()
    .required("please enter Location Text")
    .nullable(),
  upcoming_date: yup.date().required("please select Date").nullable(),
  event_Url: yup
    .string()
    .required("please enter Url")
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .nullable(),
});

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";


const styles: any = {
  dialog88: {
   zIndex:"88 !important"
  }
};

const useStyles = makeStyles(styles);

export const Popup = (props:any) =>{
  const defautlValues:any = {
    currencyname:null,
    currencycode:null
  }
  // const {control,handleSubmit,formState:{errors},setValue,reset}=useForm(defautlValues)
    const {handleClose,open,title}= props
    const classes = useStyles();

    return (
        <div>
        
        <Dialog className={classes.dialog88} disableBackdropClick  open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          {props.content}
        </Dialog>
        
        
        </div>
    )
}
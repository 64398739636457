import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import {CirclePicker} from 'react-color'; 
import PageHeader from "components/PageHeader/PageHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { useState } from "react";
import GoogleMap from "components/GoogleMap/GoogleMapMarker";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from "react-hook-form";
import {
  AutoCompleteField,
  InputField,
  LoadingButton,
  SwitchField,
  PhoneNoField,
  SwitchFieldDefault,
  CheckboxField,
  DateField,
} from "components/Forms/Formfield.component";
import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { useEffect } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import DrawMap from "components/GoogleMap/DrawMap";
import { Country, State, City } from "country-state-city";
import { getBooking, getCustomer,adminmarkBookingCompleted, markBookingCompleted,CanceledBooking,startBooking ,reachedBooking,onTheWayBooking,TransactionCommentApi,getTransactions} from "services/userservices";
import {
  getCity,
  getBranch,
  getZone,
  getLevels,
  getCountry,
  getScheduleDays,
} from "services/locationservices";
import {
  getDriver,
  getDriverSlot,
  changeDriver,
} from "services/driverServices";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useHistory, useLocation } from "react-router";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { UPDATE_SUCCESS_MSG } from "json/constants";
import { createBookingValidation } from "./validations/bookingValidation";
import { Link } from "react-router-dom";
import {getPackages} from 'services/packageservices'
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
  driverAccordion: {
    display: "flex",
    flex: "none",
  },
  dialog88: {
    zIndex:"88 !important"
   },
   dot: {
    height: "50px",
    width: "50px",
    borderRadius: " 50%",
    display: " inline-block"
  },
  color: {
    fontSize: "13px",
    font: "inherit",
    color: "#b4b4b4"
   
  }

};

const levelDependent: any = {
  Country: ["country"],
  City: ["country", "city"],
  Branch: ["country", "city", "branch"],
  Zone: ["country", "city", "branch", "zone"],
};

const defaultFormValues = {
  driver_id: "",
  day: "",
  status: true,
};

const useStyles = makeStyles(styles);

export default function AddBookingDetails(props: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open_comment, setOpenComment] = useState(false);
  const [editDataBooking, setEditDataBooking] = useState<any>(null);

  const [editData, setEditData] = useState<any>(null);
  const [file, setFile] = useState<any>([]);
  const [levels, setLevels] = useState<any>([]);
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [branch, setBranch] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [zone, setZone] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [driverAddress, setDriverAddress] = useState<any>(null);
  const [driverId, setDriverId] = useState<any>(null);
  const [drivers, setDrivers] = useState<any>([]);
  const [driver, setDriver] = useState<any>([]);
  const [driverTimeSlot, setDriverTimeSlot] = useState<any>([]);
  const [resetMap, setResetMap] = useState<any>(false);
  const [level, setLevel] = useState<any>({});
  const [dayId, setDayid] = useState<any>("");
  const [days, setDays] = useState<any>([]);
  const [checked, setChecked] = useState<any>(null);
  const [date, setDate] = useState<any>("");
  const [color, setColor] = useState<any>("")
  const [slotSelectionDate, setSlotSelectionDate] = useState<any>(null)
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const [disabledPremission, setDisabled] = useState<any>(false)

  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");
  let bookingId = new URLSearchParams(search).get("booking_id");

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createBookingValidation),
  });

  const routes: any = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Booking Details ",
    },
  ];

  const levelDependent: any = {
    Country: ["country"],
    City: ["country", "city"],
    Branch: ["country", "city", "branch"],
    Zone: ["country", "city", "branch", "zone"],
  };

  useEffect(() => {
    getDriver({ status: true }).then((e) => {
      setDrivers(e.data.result);
    });
    getLevels()
      .then((e) => {
        setLevels(e.data.result);
      })
      .catch((e) => {
      });
    getCountry({ status: true })
      .then((e) => {
        setCountries(e.data.result);
      })
      .catch((e) => {
      });
    getScheduleDays()
      .then((e: any) => {
        setDays(e?.data?.result);
      })
      .catch((err) => {
      });
  }, []);

  const getBookingData = () => {
    getBooking({ uuid: bookingId })
      .then((res: any) => {
        // console.log("data...",JSON.parse(JSON.stringify(res?.data?.result[0].booking_details).find((da:any) =>  x.uuid =) ))
        setEditDataBooking(res?.data?.result[0])

        getTransactions({order_id:res?.data?.result?.[0]?.id}).then(datax =>{
            // console.log("Tran", datax.data.result[0].additional_comment)
            setValue("additional_comment",datax?.data?.result?.[0]?.additional_comment)
        }).catch(e =>{
          console.log(e)
        })
        let data: any = JSON.parse(
          JSON.stringify(res?.data?.result[0].booking_details)
        ).find((x: any) => x.uuid == uuid); //SAAD BIGGEST MISTAKE HERE
        setValue("comment", res?.data?.result?.[0]?.comment ? res?.data?.result?.[0]?.comment : 'N/A');
        setValue("phone_number",res?.data?.result?.[0].customer?.phone_number ? res?.data?.result?.[0].customer?.phone_number : 'N/A')
        setValue("customer_email",res?.data?.result?.[0].customer?.email ? res?.data?.result?.[0].customer?.email : 'N/A')
        setValue("first_name", res?.data?.result?.[0].customer?.first_name ? res?.data?.result?.[0].customer?.first_name : 'N/A');
        setValue("last_name", res?.data?.result?.[0].customer?.last_name ? res?.data?.result?.[0].customer?.last_name : 'N/A');
        setValue("customer_address", data?.customer_address?.area ? data?.customer_address?.area : 'N/A');
        setValue("city", data?.customer_address?.city?.city_name ? data?.customer_address?.city?.city_name : 'N/A');
        setValue("country", data?.customer_address?.city?.country?.country_name ? data?.customer_address?.city?.country?.country_name : 'N/A');
        setValue("unitNumber", data?.customer_address?.jsonDump?.unitNumber ? data?.customer_address?.jsonDump?.unitNumber : 'N/A');
        setValue("unitType", data?.customer_address?.jsonDump?.unitType ? data?.customer_address?.jsonDump?.unitType : 'N/A');
        setValue("landmark", data?.customer_address?.landmark ? data?.customer_address?.landmark : 'N/A');
        setValue("customer_label", data?.customer_address?.label ? data?.customer_address?.label : 'N/A');
        setValue("booking_id", res?.data?.result?.[0].id)
        setValue("package_name", data?.package_config ? data?.package_config : 'N/A');
        setValue("start_time", data?.start_time ? data?.start_time : 'N/A');
        setValue("end_time", data?.end_time ? data?.end_time : 'N/A');
        setValue("vat",res?.data?.result[0]?.vat ? data?.price * ((res?.data?.result[0]?.vat)*0.01): 'N/A')  // To calculate
        console.log("Vat Value", +(res?.data?.result[0]?.vat))
        setValue("amount",data?.price ? data?.price : 'N/A') // Price in biookdetail
        setValue("payment_type", res?.data?.result[0]?.payment_type ? res?.data?.result[0]?.payment_type : 'N/A');
        setValue("service_name", data?.service_config?.service?.name ? data?.service_config?.service?.name : 'N/A');
        setValue("service_category", data?.service_config?.service?.category?.name ? data?.service_config?.service?.category?.name : 'N/A');
        setValue("processing_time", data?.service_config?.processing_time ? data?.service_config?.processing_time : 'N/A');
        setValue("price", data?.service_config?.price ? data?.service_config?.price : 'N/A');
        setValue("promotion_code", data?.promotion?.code ? data?.promotion?.code : 'N/A');
        setValue("promotion_name", data?.promotion?.name ? data?.promotion?.name : 'N/A');
        setValue("promotion_discount", data?.promotion?.discount_percentage ? data?.promotion?.discount_percentage : 'N/A');
        setValue("make", data?.customer_vehicle?.model?.brand?.name ? data?.customer_vehicle?.model?.brand?.name : 'N/A');
        setValue("model", data?.customer_vehicle?.model?.name ? data?.customer_vehicle?.model?.name : 'N/A');
        setValue("color", data?.customer_vehicle?.color ? data?.customer_vehicle?.color : 'N/A');
        setColor(data?.customer_vehicle?.color)
        setValue("year", data?.customer_vehicle?.year ? data?.customer_vehicle?.year : 'N/A');
        setValue("plate_no", data?.customer_vehicle?.plate_no ? data?.customer_vehicle?.plate_no : 'N/A');
        setValue("quantity", data?.customer_package_service?.quantity ? data?.customer_package_service?.quantity : 'N/A');
        setValue("consumed", data?.customer_package_service?.consumed ? data?.customer_package_service?.consumed : 'N/A');
        getPackageInformation(data?.customer_package_service?.customer_package?.packa)
        setValue("driver_name", data?.driver?.first_name ? data?.driver?.first_name : 'N/A');
        setValue("ease_of_booking", data?.review?.ease_of_booking ? data?.review?.ease_of_booking : 'N/A');
        setValue("service_quality", data?.review?.service_quality ? data?.review?.service_quality : 'N/A');
        setValue("jinie_performance", data?.review?.jinie_performance ? data?.review?.jinie_performance : 'N/A');
        setValue("speed_and_punctuality", data?.review?.speed_and_punctuality ? data?.review?.speed_and_punctuality : 'N/A');
        setValue("review_comment", data?.review?.comment ? data?.review?.comment : 'N/A');

        getReferralInformation(data?.referral_code)
        setDriverId(data?.driver_id);
        setLevel({
          name: data?.service_config?.level_type,
          uuid: data?.service_config?.level_id,
        });
        setLevelValidationShow(
          levelDependent[data?.service_config?.level_type]
        );
        // setDriver({uuid:data?.driver?.uuid , first_name: data?.driver?.first_name})
        setDate(data?.bookingAt);
        dispatch(spinLoaderShow(false));
        setEditData(data);
      })
      .catch((err: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  useEffect(() => {
    if (level) {
      if (level.name == "Country") {
        getCountry({ uuid: level.uuid })
          .then((res) => {
            let data: any = res?.data?.result[0];
            let country = {
              country_name: data?.country_name,
              uuid: data?.uuid,
            };
            setCountry(country);
          })
          .catch((err) => {
          });
      } else if (level.name == "City") {
        getCity({ uuid: level?.uuid })
          .then((res) => {
            let data: any = res?.data?.result[0];
            let country = {
              country_name: data?.country?.country_name,
              uuid: data?.country?.uuid,
            };
            let city = { city_name: data?.city_name, uuid: data?.uuid };
            setCountry(country);
            setCity(city);
          })
          .catch((err) => {
          });
      } else if (level.name == "Branch") {
        getBranch({ uuid: level?.uuid })
          .then((res) => {
            let data: any = res?.data?.result[0];
            let country = {
              country_name: data?.city?.country?.country_name,
              uuid: data?.city?.country?.uuid,
            };
            let city = {
              city_name: data?.city?.city_name,
              uuid: data?.city?.uuid,
            };
            let branch = { name: data?.name, uuid: data?.uuid };
            setCountry(country);
            setCity(city);
            setBranch(branch);
          })
          .catch((err) => {
          });
      } else if (level.name == "Zone") {
        getZone({ uuid: level?.uuid })
          .then((res) => {
            let data: any = res?.data?.result[0];
            let country = {
              country_name: data?.branch?.city?.country?.country_name,
              uuid: data?.branch?.city?.country?.uuid,
            };
            let city = {
              city_name: data?.branch?.city?.city_name,
              uuid: data?.branch?.city?.uuid,
            };
            let branch = { name: data?.branch?.name, uuid: data?.branch?.uuid };
            let zone = { name: data?.name, uuid: data?.uuid };
            setCountry(country);
            setCity(city);
            setBranch(branch);
            setZone(zone);
          })
          .catch((err) => {

          });
      }
    }
  }, [level]);

  useEffect(() => {
    getDriverLocation(driverId);
  }, [driverId]);

const getReferralInformation = (data:any) =>{
  if(data){
    getCustomer({referral_code:data}).then((res:any)=>{
      let temp =res?.data?.result[0]
       setValue("refer_by_first_name",temp?.first_name)
       setValue("refer_by_last_name",temp?.last_name)
       setValue("refer_by_email",temp?.email)

    }).catch((err :any)=>{
  
    })
  }
}
const getPackageInformation = (data:any) =>{
  if(data){
    getPackages({uuid:data}).then((res:any)=>{
      let temp =res?.data?.result[0]
       setValue("package_name",temp?.name)


    }).catch((err :any)=>{
  
    })
  }
}

  const getDriverLocation = (data: any) => {
    getDriver({ uuid: data })
      .then((res: any) => {
        let temp: any = res?.data?.result[0];

        setDriverAddress(temp);
        
        setValue("mobile_no", temp?.mobile_no);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getBookingData();
      let ispermission = !(verifyPermission(
        getPermission,
        'Booking',
        'Booking',
        'update'
      ))
      setDisabled(ispermission)
    }
  }, [uuid]);

  const refreshApiData = () =>{
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getBookingData();
      getDriver({ status: true }).then((e) => {
        setDrivers(e.data.result);
      });
      getLevels()
        .then((e) => {
          setLevels(e.data.result);
        })
        .catch((e) => {
        });
      getCountry({ status: true })
        .then((e) => {
          setCountries(e.data.result);
        })
        .catch((e) => {
        });
      getScheduleDays()
        .then((e: any) => {
          setDays(e?.data?.result);
        })
        .catch((err) => {
        });
    }
  }

  const viewDriver = () => {
    const win = window.open(
      "/admin/driver/edit?uuid=" + editData?.driver_id,
      "_blank"
    );
    win?.focus();
  };


  // const bookingCancel = () => {
  //   // dispatch(spinLoaderShow(true))
  //   if (uuid) {
  //     let data : any = { "uuid": uuid}
  //     console.log("booking cancel uuid",data)
  //     cancelBooking(data).then((e:any)=>{
  //       toast.success("Booking Cancelled Successfully");
  //       dispatch(spinLoaderShow(false))
  //       history.push("/admin/booking");

  //      }).catch((err)=>{
  //        dispatch(spinLoaderShow(false))
  //      })
  //   }

  // }
  const bookingCancel = ()=>{
     let temp = {
       uuid:uuid,
       cancel_reason:getValues('cancel_reason') 
     }
    if(temp){
      dispatch(spinLoaderShow(true))
      CanceledBooking(temp).then((e:any)=>{
        toast.success("success")
        refreshApiData()
        dispatch(spinLoaderShow(false))
        handleClose()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
        handleClose()
      })
    }
  }
  const bookingStart = () =>{
    dispatch(spinLoaderShow(true))
    startBooking({uuid:editData?.uuid}).then((e:any)=>{
      toast.success("success")
      refreshApiData()
      dispatch(spinLoaderShow(false))
     }).catch((err)=>{
      dispatch(spinLoaderShow(false))
     })
   
  }
  const bookingReached = () =>{
    dispatch(spinLoaderShow(true))
    reachedBooking({uuid:editData?.uuid}).then((e:any)=>{
      toast.success("success")
      refreshApiData()
      dispatch(spinLoaderShow(false))
     }).catch((err)=>{
      dispatch(spinLoaderShow(false))
     })
   
  }
  const bookingOntheWay =()=>{
    dispatch(spinLoaderShow(true))
    onTheWayBooking({uuid:editData?.uuid}).then((e:any)=>{
      toast.success("success")
      refreshApiData()
      dispatch(spinLoaderShow(false))
     }).catch((err)=>{
      dispatch(spinLoaderShow(false))
     })
  }

  const TransactionCommentAdd = ()=>{
    console.log("asdasd", editData)
    let temp = {
      order_id:editDataBooking.id,
      additional_comment:getValues('additional_comment') 
    }
   if(temp){
     dispatch(spinLoaderShow(true))
     TransactionCommentApi(temp).then((e:any)=>{
       toast.success("success")
       refreshApiData()
       dispatch(spinLoaderShow(false))
       handleCloseComment()
     }).catch((err)=>{
       dispatch(spinLoaderShow(false))
       handleCloseComment()
     })
   }
 }

  const onDriverChanged = async (data: any) => {
    if (data) {
      console.log("Asdasdas", date)
      dispatch(spinLoaderShow(true));
      let modifyData = {
        service_config_id: editData?.service_config_id,
        booking_at: moment(date).format("YYYY-MM-DD"),
        coordinates: {
          lat: editData?.customer_address?.latitude,
          lng: editData?.customer_address?.longitude,
          
          },
        day_id: dayId
          ? dayId
          : days.find((x: any) => x.name == moment(date).format("dddd")).uuid,
      };

      getDriverSlot(modifyData)
        .then((res: any) => {
          let responseApi = res?.data?.result;
          let slots = []
          if(responseApi.slots)
          {
            slots = responseApi.slots
            setSlotSelectionDate(responseApi.selectedTime)
          }else{
            slots = responseApi
          }
          let temp = slots
            ?.map((item: any, index: any) => {
              item.drivers = item?.drivers.filter((x: any) => {
                return x?.uuid == data?.uuid;
              });
              return item;
            })
            .filter((y: any) => y.drivers.length > 0);
            if(temp.length < 1){
              toast.error("No slot available for selected driver at selected date")
            }
          setDriverTimeSlot(temp);
          dispatch(spinLoaderShow(false));
        })
        .catch((err) => {
          dispatch(spinLoaderShow(false));
        });
    } else {
      setDriverTimeSlot([]);
    }
  };

  const handleDateChange = async (data: any) => {
    dispatch(spinLoaderShow(true));

    if (data) {
      let dayid = days.filter(
        (x: any) => x.name == moment(data).format("dddd")
      )[0].uuid;
      setDayid(dayid);
      setDate(data);
      setDriverTimeSlot([]);
      setValue("driver_id", null)
      setDriver([])
      dispatch(spinLoaderShow(false));
    }
  };


  const onCheckedChange = (e: any) => {
    setChecked(e.target.value);
  };

  const onSubmit = (data: any) => {
    if(!checked){
      toast.error("No Selection Made")
      return;
    }
    let modifyData :any= {
      uuid: uuid,
      driver_id: driverTimeSlot[checked - 1]?.drivers[0].uuid,
      start_time: driverTimeSlot[checked - 1]?.start_time,
      end_time: driverTimeSlot[checked - 1]?.end_original,
      bookingAt: moment(data.day).format("YYYY-MM-DD"),
      replacement_reason: data.replacement_reason,
    };
    if(slotSelectionDate)
    {
      modifyData["slot_selected_at"] = slotSelectionDate
    }
    if (modifyData) {
      dispatch(spinLoaderShow(true));
      changeDriver(modifyData)
        .then((res) => {
          dispatch(spinLoaderShow(false));
          toast.success("Driver assigned successfully");
          refreshApiData()
        })
        .catch((err) => {
          dispatch(spinLoaderShow(false));
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ToggleOpenComment = () => {
    setOpenComment(!open_comment);
  };
  const handleCloseComment = () => {
    setOpenComment(false);
  };
  // const onChangeColorSecond = (data: any) => {
  //   setColorSecond(data)
  // }

  const RenderDriverMap = () =>{
    return     editData?.driver_id && driverAddress?.latitude && <GoogleMap
      draggable={false}
      value={() => { }}
      setValue={{
        latLng: {
          lat: driverAddress?.latitude
            ? +driverAddress?.latitude
            : 25.276987,
          lng: driverAddress?.longitude
            ? +driverAddress?.longitude
            : 55.296249,
        },
      }}
      reset={resetMap}
      zoom={
        14
      }
      center={{
        lat: driverAddress?.latitude
          ? +driverAddress?.latitude
          : 25.276987,
        lng: driverAddress?.longitude
          ? +driverAddress?.longitude
          : 55.296249,
      }}
    >
 
    </GoogleMap>

  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        encType="multipart/form-data"
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Booking Details"
              routes={routes}
              newItem={[
                { text: "Back", link: `/admin/booking` },
              ]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
               
                <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                     <h4 className={classes.cardTitleWhite}>
                  Booking Status: {editData?.booking_status?.name}
                </h4>
                  {editData ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('DD-MM-YYYY hh:mm:ss a') }</h4> : null } 
                  {editData ? <h4 className={classes.cardTitleWhite}>Created At: { moment(editData?.createdAt).format('DD-MM-YYYY hh:mm:ss a') }</h4> : null } 
                  {editDataBooking && editDataBooking.booking_status_id == 14 ? <h4 className={classes.cardTitleWhite}>Reordered </h4> : null } 

                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  {editData?.customer_package_service?.customer_package &&
                    editData?.customer_package_service?.customer_package ? (
                    <Link
                      to={
                        "/admin/package/edit?uuid=" +
                        editData?.customer_package_service?.customer_package
                          ?.packa
                      }
                    >
                      <GridItem>
                        {" "}
                        <Typography variant="h5">
                          Included in Package
                        </Typography>
                      </GridItem>
                    </Link>
                  ) : (
                    <></>
                  )}

                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    style={{ width: "100%" }}
                  >
                    <GridItem xs={3} md={3} sm={6}>
                      <Typography>Booked For: {moment(editData?.bookingAt).format("YYYY-MM-DD")} </Typography>
                      <Typography> Timeslot: {editData?.start_time ? editData?.start_time : 'N/A' } - {editData?.end_time ? editData?.end_time : 'N/A'} </Typography>
 

                    </GridItem>
                  </Box>


                  {!disabledPremission && <Box
                    display="flex"
                    justifyContent="flex-end"
                    style={{ width: "100%" }}
                  >
                 <GridItem xs={3} md={3} sm={3}> 
                     
                     <Button color="primary"
                       disabled = {editData?.booking_status_id != 12}
                       type="button" onClick={()=> bookingOntheWay()} >On The Way Booking</Button>
                   
                 </GridItem>
                 <GridItem xs={3} md={3} sm={3}> 
                     
                     <Button color="primary"
                      disabled = {editData?.booking_status_id != 13}
                       type="button" onClick={()=>bookingReached()} >Reached Booking</Button>
                   
                 </GridItem>
                 <GridItem xs={3} md={3} sm={3}> 
                     
                     <Button color="primary"
                     disabled = {editData?.booking_status_id != 10}
                       type="button" onClick={()=> bookingStart()} >Start Booking</Button>
                   
                 </GridItem>
                  <GridItem xs={3} md={3} sm={3}> 
                      
                      <Button
                        // disabled={[1, 2, 3, 6, 8, 9].includes(
                        //   editData?.booking_status_id
                        // )}
                        disabled = {editData?.booking_status_id != 7}
                        color="primary"
                        type="button"
                        onClick={() => {
                          dispatch(spinLoaderShow(true));

                          adminmarkBookingCompleted({ uuid: editData.uuid })
                            .then((res) => {
                              toast.success("Success");
                              refreshApiData()
                              dispatch(spinLoaderShow(false));
                              history.push("/admin/booking");
                            })
                            .catch((e) => {
                              dispatch(spinLoaderShow(false));
                            });
                        }}
                      >
                        Mark Completed
                      </Button>
                    </GridItem>
                  <GridItem xs={3} md={3} sm={3}> 
                     
                        <Button color="primary"
                        disabled = {editData?.booking_status_id == 6 || editData?.booking_status_id == 8 || editData?.booking_status_id == 9}
                          type="button" onClick={() => setOpen(true)}>Cancel</Button>
                      
                    </GridItem>
                   
                    <GridItem xs={3} md={3} sm={3}> 
                     
                     <Button color="primary"
                   
                       type="button" onClick={() => ToggleOpenComment()}>Transaction Comment</Button>
                   
                 </GridItem>
                  </Box>}


                  <GridItem xs={12} md={12} sm={12}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Booking Information</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridContainer>
                        <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.booking_id}
                              fieldName="booking_id"
                              type="text"
                              autoComplete="false"
                              label="Booking Id"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.booking_id &&
                                errors?.booking_id?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.first_name}
                              fieldName="first_name"
                              type="text"
                              autoComplete="false"
                              label="First Name"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.first_name &&
                                errors?.first_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.last_name}
                              fieldName="last_name"
                              type="text"
                              autoComplete="false"
                              label="last Name"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.last_name && errors?.last_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.phone_number}
                              fieldName="phone_number"
                              type="text"
                              autoComplete="false"
                              label="Phone Number"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.phone_number && errors?.phone_number?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.customer_email}
                              fieldName="customer_email"
                              type="text"
                              autoComplete="false"
                              label="Email"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.customer_email && errors?.customer_email?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.customer_label}
                              fieldName="customer_label"
                              type="text"
                              autoComplete="false"
                              label=" Label"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.customer_label && errors?.customer_label?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.customer_address}
                              fieldName="customer_address"
                              type="text"
                              autoComplete="false"
                              label="Area"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              multiline={true}
                              rows={4}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.customer_address && errors?.customer_address?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.city}
                              fieldName="city"
                              type="text"
                              autoComplete="false"
                              label="City"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}  
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.city && errors?.city?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.country}
                              fieldName="country"
                              type="text"
                              autoComplete="false"
                              label="Country"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.country && errors?.country?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.unitNumber}
                              fieldName="unitNumber"
                              type="text"
                              autoComplete="false"
                              label="Unit Number"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.unitNumber && errors?.unitNumber?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.unitType}
                              fieldName="unitType"
                              type="text"
                              autoComplete="false"
                              label="Unit Type"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.unitType && errors?.unitType?.message
                              }
                            />
                          </GridItem>

                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.landmark}
                              fieldName="landmark"
                              type="text"
                              autoComplete="false"
                              label="Landmark"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.landmark && errors?.landmark?.message
                              }
                            />
                          </GridItem>

                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.make}
                              fieldName="make"
                              type="text"
                              autoComplete="false"
                              label="Make"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.make && errors?.make?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.model}
                              fieldName="model"
                              type="text"
                              autoComplete="false"
                              label="Model"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.model && errors?.model?.message
                              }
                            />
                          </GridItem>

                        

                          {/* <GridItem xs={3} md={3} sm={3}>
                          <span className={classes.dot} style={ {backgroundColor: color} }></span>
                            <InputField
                              errors={!!errors?.color}
                              fieldName="color"
                              type="text"
                              autoComplete="false"
                              label="Color"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.color && errors?.color?.message
                              }
                            />
                          </GridItem> */}
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.plate_no}
                              fieldName="plate_no"
                              type="text"
                              autoComplete="false"
                              label="Plate Number"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.plate_no && errors?.plate_no?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.year}
                              fieldName="year"
                              type="text"
                              autoComplete="false"
                              label="Year"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.year && errors?.year?.message
                              }
                            />
                          </GridItem>
                          
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.start_time}
                              fieldName="start_time"
                              type="text"
                              autoComplete="false"
                              iProps={{
                                disabled: true,
                              }}
                              label="Start Time"
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.start_time &&
                                errors?.start_time?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.end_time}
                              fieldName="end_time"
                              type="text"
                              autoComplete="false"
                              label="End Time"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.end_time && errors?.end_time?.message
                              }
                            />
                          </GridItem>
                         { editData?.referral_code ?  (<> <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.refer_by_first_name}
                              fieldName="refer_by_first_name"
                              type="text"
                              autoComplete="false"
                              label="Refer By First Name"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.refer_by_first_name && errors?.refer_by_first_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.refer_by_last_name}
                              fieldName="refer_by_last_name"
                              type="text"
                              autoComplete="false"
                              label="Refer By Last Name"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.refer_by_last_name && errors?.refer_by_last_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.refer_by_email}
                              fieldName="refer_by_email"
                              type="text"
                              autoComplete="false"
                              label="Refer By Email"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.refer_by_email && errors?.refer_by_email?.message
                              }
                            />
                          </GridItem></>) : null}
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.package_name}
                              fieldName="package_name"
                              type="text"
                              autoComplete="false"
                              label="Package"
                              control={control}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.package_name &&
                                errors?.package_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.amount}
                              fieldName="amount"
                              type="text"
                              autoComplete="false"
                              label="Amount"
                              control={control}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.amount &&
                                errors?.amount?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.vat}
                              fieldName="vat"
                              type="text"
                              autoComplete="false"
                              label="Vat"
                              control={control}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.vat &&
                                errors?.vat?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.payment_type}
                              fieldName="payment_type"
                              type="text"
                              autoComplete="false"
                              label="Payment Type "
                              control={control}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.payment_type &&
                                errors?.payment_type?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.payment_type}
                              fieldName="comment"
                              type="text"
                              autoComplete="false"
                              label="Comment"
                              control={control}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.payment_type &&
                                errors?.payment_type?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                          <Typography className={classes.color}>Vehicle Color</Typography>
                               <span className={classes.dot} style={ {backgroundColor: color} }></span>
                           </GridItem>
                          {/* <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.remarks}
                              fieldName="remarks"
                              type="text"
                              autoComplete="false"
                              label="Remarks "
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={errors?.remarks && errors?.remarks?.message}

                            />

                          </GridItem> */}
                          <GridItem xs={12} sm={12} md={12}>
                            <Typography variant={"subtitle1"}>
                              Address:
                              <span className="google-map-error-text">
                                {errors.polygon &&
                                  errors?.polygon?.latLng?.message}
                              </span>
                            </Typography>
                            {editData && (
                              <FormHelperText
                                error={errors.polygon && true}
                                className="google-map-error"
                              >
                               {editData?.customer_address?.latitude && <GoogleMap
                                  draggable={false}
                                  value={() => { }}
                                  setValue={{
                                    latLng: {
                                      lat: editData?.customer_address?.latitude
                                        ? +editData?.customer_address?.latitude
                                        : 25.276987,
                                      lng: editData?.customer_address?.longitude
                                        ? +editData?.customer_address?.longitude
                                        : 55.296249,
                                    },
                                  }}
                                  reset={resetMap}
                                  zoom={
                                    editData?.customer_address?.zoom
                                      ? +editData?.customer_address?.zoom
                                      : 14
                                  }
                                  center={{
                                    lat: editData?.customer_address?.latitude
                                      ? +editData?.customer_address?.latitude
                                      : 25.276987,
                                    lng: editData?.customer_address?.longitude
                                      ? +editData?.customer_address?.longitude
                                      : 55.296249,
                                  }}
                                >
                                  <DrawMap data={editData} />
                                </GoogleMap>}

                                {editData?.customer_address?.latitude  && <p> <a href={"https://www.google.com/maps/search/?api=1&query=" + editData?.customer_address?.latitude + ", " + editData?.customer_address?.longitude} target="_blank">Link</a></p>}
                            {editData?.customer_address?.latitude &&   <p> {"https://www.google.com/maps/search/?api=1&query=" +editData?.customer_address?.latitude + "," + editData?.customer_address?.longitude}</p>}
                              </FormHelperText>
                            )}
                          </GridItem>
                        </GridContainer>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>

                  <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Customer Reviews</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridContainer>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.ease_of_booking}
                              fieldName="ease_of_booking"
                              type="text"
                              autoComplete="false"
                              label="Ease Of Booking"
                              control={control}
                              isLoading={true}
                              iProps={{
                                disabled: true,
                              }}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.ease_of_booking &&
                                errors?.ease_of_booking?.message
                              }
                            />
                          </GridItem> 
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.service_quality}
                              fieldName="service_quality"
                              type="text"
                              autoComplete="false"
                              label="Service Quality"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.service_quality && errors?.service_quality?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.jinie_performance}
                              fieldName="jinie_performance"
                              type="text"
                              autoComplete="false"
                              label="Jino Performance"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.jinie_performance && errors?.jinie_performance?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.speed_and_punctuality}
                              fieldName="speed_and_punctuality"
                              type="text"
                              autoComplete="false"
                              label="Speed And Punctuality"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.speed_and_punctuality && errors?.speed_and_punctuality?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.review_comment}
                              fieldName="review_comment"
                              type="text"
                              autoComplete="false"
                              label="Comment"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.review_comment && errors?.review_comment?.message
                              }
                            />
                          </GridItem>

                        </GridContainer>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>


                  <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Service</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridContainer>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.service_name}
                              fieldName="service_name"
                              type="text"
                              autoComplete="false"
                              label="Service name"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.service_name &&
                                errors?.service_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.service_category}
                              fieldName="service_category"
                              type="text"
                              autoComplete="false"
                              label="Service Category"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.service_category &&
                                errors?.service_category?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.processing_time}
                              fieldName="processing_time"
                              type="text"
                              autoComplete="false"
                              label="Processing Time"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.processing_time &&
                                errors?.processing_time?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.price}
                              fieldName="price"
                              type="text"
                              autoComplete="false"
                              label="Price"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.price && errors?.price?.message
                              }
                            />
                          </GridItem>
                          {editData?.promotion?.code &&
                            editData?.promotion?.code != null ? (
                            <GridItem xs={3} md={3} sm={3}>
                              <InputField
                                errors={!!errors?.promotion_code}
                                fieldName="promotion_code"
                                type="text"
                                autoComplete="false"
                                label="Promotion Code"
                                iProps={{
                                  disabled: true,
                                }}
                                control={control}
                                isLoading={true}
                                valueGot={""}
                                setValue={setValue}
                                helperText={
                                  errors?.promotion_code && errors?.promotion_code?.message
                                }
                              />
                            </GridItem>
                          ) : null}
                          {editData?.promotion?.name &&
                            editData?.promotion?.name != null ? (
                            <GridItem xs={3} md={3} sm={3}>
                              <InputField
                                errors={!!errors?.promotion_name}
                                fieldName="promotion_name"
                                type="text"
                                autoComplete="false"
                                label="Promotion Name"
                                iProps={{
                                  disabled: true,
                                }}
                                control={control}
                                isLoading={true}
                                valueGot={""}
                                setValue={setValue}
                                helperText={
                                  errors?.promotion_name &&
                                  errors?.promotion_name?.message
                                }
                              />
                            </GridItem>
                          ) : null}
                          {editData?.promotion?.discount_percentage &&
                            editData?.promotion?.discount_percentage != null ? (
                            <GridItem xs={3} md={3} sm={3}>
                              <InputField
                                errors={!!errors?.promotion_discount}
                                fieldName="promotion_discount"
                                type="text"
                                autoComplete="false"
                                label="Discount in Percentage"
                                iProps={{
                                  disabled: true,
                                }}
                                control={control}
                                isLoading={true}
                                valueGot={""}
                                setValue={setValue}
                                helperText={
                                  errors?.promotion_discount &&
                                  errors?.promotion_discount?.message
                                }
                              />
                            </GridItem>
                          ) : null}
                         
                          {editData?.customer_package_service?.quantity &&
                            editData?.customer_package_service?.quantity !=
                            null ? (
                            <GridItem xs={3} md={3} sm={3}>
                              <InputField
                                errors={!!errors?.quantity}
                                fieldName="quantity"
                                type="text"
                                autoComplete="false"
                                label="Quantity"
                                iProps={{
                                  disabled: true,
                                }}
                                control={control}
                                isLoading={true}
                                valueGot={""}
                                setValue={setValue}
                                helperText={
                                  errors?.quantity && errors?.quantity?.message
                                }
                              />
                            </GridItem>
                          ) : null}
                          {editData?.customer_package_service?.consumed &&
                            editData?.customer_package_service?.consumed !=
                            null ? (
                            <GridItem xs={3} md={3} sm={3}>
                              <InputField
                                errors={!!errors?.consumed}
                                fieldName="consumed"
                                type="text"
                                autoComplete="false"
                                label="Consumed"
                                iProps={{
                                  disabled: true,
                                }}
                                control={control}
                                isLoading={true}
                                valueGot={""}
                                setValue={setValue}
                                helperText={
                                  errors?.consumed && errors?.consumed?.message
                                }
                              />
                            </GridItem>
                          ) : null}

                          <GridItem xs={12} sm={12} md={3}>
                            <AutoCompleteField
                              errors={!!errors?.level_id}
                              fieldName="level_id"
                              autoComplete="off"
                              label="Select Level"
                              control={control}
                              setValue={setValue}
                              options={levels}
                              optionKey="id"
                              returnObject={true}
                              iProps={{
                                disabled: true,
                              }}
                              isLoading={levels?.length > 0 ? true : false}
                              optionValue="name"
                              helperText={
                                errors?.level_id && errors?.level_id?.message
                              }
                              valueGot={level}
                            />
                          </GridItem>
                          {levelValidationShow?.includes("country") && (
                            <GridItem xs={12} sm={12} md={3}>
                              <AutoCompleteField
                                errors={!!errors?.country_id}
                                fieldName="country_id"
                                autoComplete="off"
                                label="Select Country"
                                control={control}
                                setValue={setValue}
                                options={countries}
                                isLoading={countries?.length > 0 ? true : false}
                                optionKey="uuid"
                                iProps={{
                                  disabled: true,
                                }}
                                optionValue="country_name"
                                helperText={
                                  errors?.country_id &&
                                  errors?.country_id?.message
                                }
                                valueGot={country}
                              />
                            </GridItem>
                          )}
                          {levelValidationShow?.includes("city") && (
                            <GridItem xs={12} sm={12} md={3}>
                              <AutoCompleteField
                                errors={!!errors?.city_id}
                                fieldName="city_id"
                                autoComplete="off"
                                label="Select City"
                                control={control}
                                setValue={setValue}
                                options={cities}
                                isLoading={true}
                                optionKey="uuid"
                                iProps={{
                                  disabled: true,
                                }}
                                optionValue="city_name"
                                helperText={
                                  errors?.city_id && errors?.city_id?.message
                                }
                                valueGot={city}
                              />
                            </GridItem>
                          )}
                          {levelValidationShow?.includes("branch") && (
                            <GridItem xs={12} sm={12} md={3}>
                              <AutoCompleteField
                                errors={!!errors?.branch_id}
                                fieldName="branch_id"
                                autoComplete="off"
                                label="Select Branch"
                                iProps={{
                                  disabled: true,
                                }}
                                control={control}
                                setValue={setValue}
                                options={branches}
                                optionKey="uuid"
                                optionValue="name"
                                helperText={
                                  errors?.branch_id &&
                                  errors?.branch_id?.message
                                }
                                valueGot={branch}
                                isLoading={true}
                              />
                            </GridItem>
                          )}
                          {levelValidationShow?.includes("zone") && (
                            <GridItem xs={12} sm={12} md={3}>
                              <AutoCompleteField
                                errors={!!errors?.zone_id}
                                fieldName="zone_id"
                                autoComplete="off"
                                label="Select Zone"
                                control={control}
                                setValue={setValue}
                                iProps={{
                                  disabled: true,
                                }}
                                options={zones}
                                optionKey="uuid"
                                optionValue="name"
                                helperText={
                                  errors?.zone_id && errors?.zone_id?.message
                                }
                                valueGot={zone}
                                isLoading={true}
                              />
                            </GridItem>
                          )}
                        </GridContainer>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Driver</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: "block" }}>
                        <GridContainer>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.driver_name}
                              fieldName="driver_name"
                              type="text"
                              autoComplete="false"
                              label="Name"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.driver_name &&
                                errors?.driver_name?.message
                              }
                            />
                          </GridItem>
                          <GridItem xs={3} md={3} sm={3}>
                            <InputField
                              errors={!!errors?.mobile_no}
                              fieldName="mobile_no"
                              type="text"
                              autoComplete="false"
                              label="Mobile Number"
                              iProps={{
                                disabled: true,
                              }}
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.mobile_no && errors?.mobile_no?.message
                              }
                            />
                          </GridItem>
                          <GridItem
                            xs={3}
                            md={3}
                            sm={3}
                            style={{ marginTop: "10px" }}
                          >
                            {/* <Button
                              color="primary"
                              onClick={() => viewDriver()}
                            >
                              View Driver
                            </Button> */}
                          </GridItem>

                          {editData?.driver_id &&
                            editData?.driver_id != null ? (
                            <GridItem xs={12} sm={12} md={12}>
                              <Typography variant={"subtitle1"}>
                                Address:
                                <span className="google-map-error-text">
                                  {errors.polygon &&
                                    errors?.polygon?.latLng?.message}
                                </span>
                              </Typography>
                              {editData && (
                                <FormHelperText
                                  error={errors.polygon && true}
                                  className="google-map-error"
                                >

                                {driverAddress && <RenderDriverMap />}
                            {/* {editData?.driver_id && driverAddress?.latitude && <GoogleMap
                                  draggable={false}
                                  value={() => { }}
                                  setValue={{
                                    latLng: {
                                      lat: driverAddress?.latitude
                                        ? +driverAddress?.latitude
                                        : 25.276987,
                                      lng: driverAddress?.longitude
                                        ? +driverAddress?.longitude
                                        : 55.296249,
                                    },
                                  }}
                                  reset={resetMap}
                                  zoom={
                                    14
                                  }
                                  center={{
                                    lat: driverAddress?.latitude
                                      ? +driverAddress?.latitude
                                      : 25.276987,
                                    lng: driverAddress?.longitude
                                      ? +driverAddress?.longitude
                                      : 55.296249,
                                  }}
                                >
                             
                                </GoogleMap>} */}



                                <br />


                                  {/* {driverAddress?.latitude && <GoogleMap
                                    draggable={false}
                                    value={() => { }}
                                    setValue={{
                                      latLng: {
                                        lat: driverAddress?.latitude
                                          ? +driverAddress?.latitude
                                          : 25.276987,
                                        lng: driverAddress?.longitude
                                          ? +driverAddress?.longitude
                                          : 55.296249,
                                      },
                                    }}
                                    reset={resetMap}
                                    zoom={
                                      editData?.polygon?.zoom
                                        ? editData?.polygon?.zoom
                                        : 14
                                    }
                                    center={{
                                      lat: driverAddress?.latitude
                                        ? +driverAddress?.latitude
                                        : 25.276987,
                                      lng: driverAddress?.longitude
                                        ? +driverAddress?.longitude
                                        : 55.296249,
                                    }}
                                  >
                                    <DrawMap data={editData} />
                                  </GoogleMap>} */}

                                  {driverAddress?.latitude  && <p> <a href={"https://www.google.com/maps/search/?api=1&query=" + driverAddress?.latitude + ", " + driverAddress?.longitude} target="_blank">Link</a></p>}
                            {driverAddress?.latitude &&   <p> {"https://www.google.com/maps/search/?api=1&query=" +driverAddress?.latitude + "," + driverAddress?.longitude}</p>}
                                </FormHelperText>
                              )}
                            </GridItem>
                          ) : null}
                        </GridContainer>
                      </AccordionDetails>
                    </Accordion>
                    {!disabledPremission &&
                    <GridItem xs={12} md={12} sm={12}>
                      <GridContainer>
                        <GridItem xs={3} sm={3} md={3}>
                          <DateField
                            errors={!!errors?.day}
                            fieldName="day"
                            autoComplete="off"
                            label="* Date"
                            setValue={setValue}
                            control={control}
                            inputVariant="outlined"
                            iProps={{
                              disablePast: true,
                              onChange: handleDateChange,
                              format: 'dd-MM-yyyy',
                              disabled: [1, 2, 3, 6, 7, 13, 10, 8, 9].includes(
                                editData?.booking_status_id
                              ),
                            }}
                            helperText={errors?.day && errors?.day?.message}
                            valueGot={date}
                          />
                        </GridItem>
                     
                        {editData?.booking_status_id == 11 || editData?.booking_status_id == 12 ?  (
                          <GridItem xs={3} md={3} sm={3}>
                            <AutoCompleteField
                              errors={!!errors?.driver_id}
                              fieldName="driver_id"
                              autoComplete="off"
                              label="* Select Driver "
                              control={control}
                              setValue={setValue}
                              options={drivers}
                              isLoading={true}
                              iProps={{
                                onChange: onDriverChanged,
                                disabled: [
                                  1, 2, 3, 6, 7, 13, 10, 8, 9,
                                ].includes(editData?.booking_status_id),
                              }}
                              optionKey="uuid"
                              optionValue="first_name"
                              helperText={
                                errors?.driver_id && errors?.driver_id?.message
                              }
                              valueGot={driver}
                            />
                          </GridItem>
                        ): null}
                        <GridItem xs={3} md={3} sm={3}>
                          <InputField
                            errors={!!errors?.replacement_reason}
                            fieldName="replacement_reason"
                            type="text"
                            autoComplete="false"
                            label="* Replacement Reason"
                            control={control}
                            isLoading={true}
                            iProps={{
                              disabled: [1, 2, 3, 6, 7, 13, 10, 8, 9].includes(
                                editData?.booking_status_id
                              ),
                            }}
                            valueGot={""}
                            setValue={setValue}
                            helperText={
                              errors?.replacement_reason &&
                              errors?.replacement_reason?.message
                            }
                          />
                        </GridItem>

                        <GridItem
                          xs={3}
                          md={3}
                          sm={3}
                          style={{ marginTop: "10px" }}
                        >
                          <Button
                            disabled={[1, 2, 3, 6, 7, 13, 10, 8, 9].includes(
                              editData?.booking_status_id
                            )}
                            color="primary"
                            type="submit"
                          >
                            Assign Driver
                          </Button>
                        </GridItem>

                   
                      </GridContainer>
                    </GridItem>}

                    {driverTimeSlot?.length > 0 && (
                      <GridItem className={"mt-15"}>
                        <GridContainer>
                          <GridItem xs={12} md={12} sm={12}>
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                aria-label="simple table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center"> Name</TableCell>
                                    <TableCell align="center">
                                      Start time
                                    </TableCell>
                                    <TableCell align="center">
                                      End time
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {driverTimeSlot?.map(
                                    (item: any, index: any) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell align="center">
                                            <CheckboxField
                                              errors={
                                                !!errors?.checked?.[index]
                                              }
                                              control={control}
                                              setValue={setValue}
                                              helperText={
                                                errors?.checked?.[index] &&
                                                errors?.checked?.[index]
                                                  ?.message
                                              }
                                              fieldName={`checked.${index}`}
                                              valueGot={index + 1}
                                              checked={checked == index + 1}
                                              iProps={{
                                                onChange: onCheckedChange,
                                              }}
                                            />
                                            {item?.drivers[0]?.name}
                                          </TableCell>
                                          <TableCell align="center">
                                            {item?.start_time}
                                          </TableCell>
                                          <TableCell align="center">
                                            {item?.end_original}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    )}
                  </GridItem>
                </GridContainer>
              
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} md={12} sm={12}>
          <Dialog open={open} onClose={handleClose} className={classes.dialog88} disableBackdropClick fullWidth={true}>
        <DialogTitle id="form-dialog-title">Reason</DialogTitle>
        <DialogContent>
          <DialogContentText>
           
          </DialogContentText>
          <GridContainer>
            <GridItem xs={12} md={12} sm={12}>
            <InputField
                      errors={!!errors?.cancel_reason}
                      fieldName="cancel_reason"
                      type="text"
                      autoComplete="off"
                      label="* Reason"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.cancel_reason && errors?.cancel_reason?.message
                      }
                    />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
           close
          </Button>
          <Button onClick={()=>bookingCancel()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open_comment} onClose={handleCloseComment} className={classes.dialog88} disableBackdropClick fullWidth={true}>
        <DialogTitle id="form-dialog-title">Transaction Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
           
          </DialogContentText>
          <GridContainer>
            <GridItem xs={12} md={12} sm={12}>
            <InputField
                      errors={!!errors?.additional_comment}
                      fieldName="additional_comment"
                      type="text"
                      autoComplete="off"
                      label="*Transaction Comment"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.additional_comment && errors?.additional_comment?.message
                      }
                    />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseComment} color="primary">
           close
          </Button>
          <Button onClick={()=>TransactionCommentAdd()} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

            </GridItem>
        </GridContainer>
        <GridContainer></GridContainer>
      </form>
    </div>
  );
}

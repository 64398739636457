import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Avatar,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
// import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const WhyUseJinoAccordion = (props: any) => {
  const classes = useStyles();
  const { collapse, apiData, control, errors, setValue, getValues } = props;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `why_use_jino_array`,
  });

  useEffect(() => {
    if (apiData) {
      setValue(
        "why_use_jino_array",
        apiData?.page_content?.how_to_use_jino?.why_use_jino_array
      );
    }
  }, [apiData]);

  useEffect(() => {
    [1, 2, 3, 4].forEach(() => {
      append({
        // video_link: "",
        title: "",
        short_description: "",
      });
    });
  }, []);
  const onSubmit = (data: any) => {
    console.log(data);
  };
  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Why Use Jino </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer spacing={3} style={{ flexGrow: 1 }}>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.why_use_title}
              fieldName="why_use_title"
              type="text"
              autoComplete="false"
              label="* Title "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.why_use_title && errors?.why_use_title?.message
              }
            />
          </GridItem>

          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.why_use_sub_title}
              fieldName="why_use_sub_title"
              type="text"
              autoComplete="false"
              label="* Sub Title "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.why_use_sub_title && errors?.why_use_sub_title?.message
              }
            />
          </GridItem>

          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.why_use_description}
              fieldName="why_use_description"
              type="text"
              autoComplete="false"
              label="* Description "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.why_use_description &&
                errors?.why_use_description?.message
              }
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.video_link}
              fieldName="video_link"
              type="text"
              autoComplete="false"
              label="* Video Link "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.video_link &&
                errors?.video_link?.message
              }
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.cta_text}
              fieldName="cta_text"
              type="text"
              autoComplete="false"
              label="* Learn More Text "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={errors?.cta_text && errors?.cta_text?.message}
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.cta_url}
              fieldName="cta_url"
              type="text"
              autoComplete="false"
              label="* Learn More Url "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={errors?.cta_url && errors?.cta_url?.message}
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            
          </GridItem>
        </GridContainer>
      </AccordionDetails>

      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index) => {
          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={4} sm={4} md={4}>
                <InputField
                  errors={!!errors?.why_use_jino_array?.[index]?.title}
                  fieldName={`why_use_jino_array[${index}].title`}
                  type="text"
                  label="* Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.why_use_jino_array?.[index]?.title &&
                    errors?.why_use_jino_array?.[index]?.title.message
                  }
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                <InputField
                  errors={
                    !!errors?.why_use_jino_array?.[index]?.short_description
                  }
                  fieldName={`why_use_jino_array[${index}].short_description`}
                  type="text"
                  label="* Short Description"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.why_use_jino_array?.[index]?.short_description &&
                    errors?.why_use_jino_array?.[index]?.short_description
                      .message
                  }
                />
              </GridItem>
              {/* <GridItem xs={4} sm={4} md={4}>
                <InputField
                  errors={!!errors?.why_use_jino_array?.[index]?.video_link}
                  fieldName={`why_use_jino_array[${index}].video_link`}
                  type="text"
                  label="* Video Link"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.why_use_jino_array?.[index]?.video_link &&
                    errors?.why_use_jino_array?.[index]?.video_link.message
                  }
                />
              </GridItem> */}
            </GridContainer>
          );
        })}
      </AccordionDetails>
      {/* <Button
        variant="contained"
        color="primary"
        className={"custom-btn"}
        onClick={() => {
          append({ video_link: "", title: "", short_description: "" });
        }}
      >
        Add more
      </Button> */}
      <AccordionActions>
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          {/* {permission === false ? (
              
            ) : (
              ""
            )} */}
          {/* <Button color="primary" className={"custom-btn"} type="submit">
                Submit
              </Button> */}
        </Box>
      </AccordionActions>
    </ControlledAccordion>
  );
};

import * as yup from "yup";

const FaqValidation:any = yup.object().shape({
  question: yup.string().required("Please enter  name.").nullable(),
  answer: yup.string().required("Please enter description").nullable(),

    
  });
  export{
    FaqValidation,
    
  }
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getFaq } from "services/faqServices";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { verifyPermission } from "helper/permission";
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getPartnership } from "services/website Services/partnershipServices";
import { getMediaDetail } from "services/website Services/media_detailServices";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const MediaListing = (props: any) => {
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [disabled, setDisabled] = useState<any>(false);
  const [exportData, setExportData] = useState<any>(null);
  const dispatch = useDispatch();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "News Details ",
      link: "",
    },
  ];

  useEffect(() => {
    let ispermission = !verifyPermission(
      getPermission,
      "Website",
      "Media",
      "update"
    );
    setDisabled(ispermission);
  }, [getPermission]);

  useEffect(() => {
    getMediaDetailData();
  }, []);

  const getMediaDetailData = () => {
    dispatch(spinLoaderShow(true));
    getMediaDetail()
      .then((res: any) => {
        // console.log(res.data.result,"res");
        let data = res?.data?.result.map((item: any) => {
          item.status = item.status ? "active" : "inactive";
          return item;
        });
        setRowData(data);
        // console.log("getMediaDetail",data);

        // setRowData(data?.filter((e: any) => e?.category?.type === "Media"));
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(spinLoaderShow(false));
      });
    // console.log("rowData", rowData);
  };

  const columnDefs: any = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
      headerName: "Name",
      field: "name",
    },
    // {
    //   headerName: "Title",
    //   field: "detail_content.title",
    // },
    // {
    //   headerName: "Description",
    //   field: "detail_content.description",
    // },

    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      },
    },
    {
      headerName: "Modified Date",
      field: "updatedAt",

      sort: "desc",
      //   valueGetter: (params: any) => {
      //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

      // }
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },

    {
      headerName: "Created Date",
      field: "createdAt",
      //   valueGetter: (params: any) => {
      //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

      // },
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Actions",
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return (
          <Link to={"media/edit?uuid=" + params?.data?.uuid}>
            <EditIcon />
          </Link>
        );
      },
    },
    // {
    //   headerName: "View",
    //   field: "action",
    //   sortable: false,
    //   filter: false,
    //   cellRendererFramework: (params: any) => {
    //     // put the value in bold
    //     return (
    //       <Link to={"faq/edit?uuid=" + params?.data?.uuid + "&type=view"}>
    //         <VisibilityIcon />
    //       </Link>
    //     );
    //   },
    // },
  ];
  const getSelectedRows = () => {
    let faqs = exportData.getSelectedRows().map((item: any) => {
      return item;
    });
    console.log("faqs selected", faqs);
  };
  const refreshData = () => {
    dispatch(spinLoaderShow(true));
    getMediaDetailData();
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="News Details "
            routes={routes}
            newItem={[{ text: "Add News Details", link: "/admin/media/add" }]}
            permission="Website.Media.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>News Detail List</h4>
            {/* <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button> */}
          </CardHeader>
          <CardBody>
            <Button
              variant="outlined"
              color="primary"
              className="custom-btn export-btn "
              onClick={(e: any) => refreshData()}
            >
              Refresh
            </Button>
            <AgGridCustom
              disabledCheckBox={true}
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={(data: any) => {
                return data.uuid;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

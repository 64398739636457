import * as yup from 'yup';

const createPackageValidations = yup.object().shape({
    name:yup.string().required("Please enter package name").nullable(),
    description:yup.string().required("Please enter description").nullable(),
    duration_days:yup.number().min(1, "Duration Days must be greater than 0").typeError("Please enter duration days").required("Please enter duration days").nullable(),

})

export{
    createPackageValidations
}
import * as yup from 'yup'


export const createSocialsValidation = yup.object().shape({
    facebook:yup.string().required("please enter facebook").nullable(),
    linkedin:yup.string().required("please enter linkedin ").nullable(), 
    twitter:yup.string().required("please enter twitter ").nullable(),  
    instagram:yup.string().required("please enter instagram ").nullable(),  


})
import React from "react";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import LanguageIcon from "@material-ui/icons/Language";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PlaceIcon from "@material-ui/icons/Place";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ScheduleIcon from "@material-ui/icons/Schedule";
import BusinessIcon from "@material-ui/icons/Business";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import TranslateIcon from "@material-ui/icons/Translate";
import CommuteIcon from "@material-ui/icons/Commute";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import AirlineSeatReclineExtraIcon from "@material-ui/icons/AirlineSeatReclineExtra";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import LocalCarWashIcon from "@material-ui/icons/LocalCarWash";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { InsertDriveFile } from "@material-ui/icons";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EventIcon from "@material-ui/icons/Event";
import EmailIcon from "@material-ui/icons/Email";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import PhoneIcon from "@material-ui/icons/Phone";
PhoneIcon;
export const itemsList: any = [
  {
    key: 11,
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    key: 28,
    path: "/vendor",
    name: "Vendor",
    rtlName: "إخطارات",
    icon: AirlineSeatReclineExtraIcon,
    layout: "/admin",
  },
  {
    key: 123123,
    path: "/users",
    name: "Admin",
    rtlName: "طباعة",
    icon: PeopleIcon,
    layout: "/admin",
  },
  {
    key: 123123,
    path: "/scheduler",
    name: "Schedular",
    rtlName: "طباعة",
    icon: ScheduleIcon,
    layout: "/admin",
  },
  {
    key: 1231236,
    name: "Location",
    icon: LanguageIcon,
    submenus: "554",
    children: [
      {
        submenus: "1",
        key: 31131,
        path: "/country",
        name: "Country",
        rtlName: "طباعة",
        icon: LanguageIcon,
        layout: "/admin",
      },
      {
        submenus: "2",
        key: 1541,
        path: "/city",
        name: "City",
        rtlName: "طباعة",
        icon: LocationCityIcon,
        layout: "/admin",
      },
      {
        submenus: "3",
        key: 711234,
        path: "/branch",
        name: "Branch",
        rtlName: "خرائط",
        icon: LocationOn,
        layout: "/admin",
      },
      {
        submenus: "4",
        key: 5123123,
        path: "/zone",
        name: "Zone",
        rtlName: "طباعة",
        icon: PlaceIcon,
        layout: "/admin",
      },
    ],
  },
  {
    key: 6,
    name: "Vehicles",
    icon: AirportShuttleIcon,
    submenus: "1",
    children: [
      {
        submenus: "1",
        key: 61,
        path: "/vehicletype",
        name: "Vehicle Type",
        rtlName: "طباعة",
        icon: CommuteIcon,
        layout: "/admin",
      },

      {
        submenus: "3",
        key: 64,
        path: "/vehiclemake",
        name: "Vehicle Make",
        rtlName: "طباعة",
        icon: DriveEtaIcon,
        layout: "/admin",
      },
      {
        submenus: "2",
        key: 61,
        path: "/vehiclemodel",
        name: "Vehicle Model",
        rtlName: "طباعة",
        icon: DriveEtaIcon,
        layout: "/admin",
      },
    ],
  },
  {
    key: 4455,
    name: "Services",
    icon: LocalCarWashIcon,
    submenus: "115",
    children: [
      {
        submenus: "116",
        key: 710,
        path: "/service-category",
        name: "Category",
        rtlName: "طباعة",
        icon: LocalCarWashIcon,
        layout: "/admin",
      },
      {
        submenus: "1116",
        key: 7110,
        path: "/service",
        name: "Service",
        rtlName: "طباعة",
        icon: LocalCarWashIcon,
        layout: "/admin",
      },
      {
        submenus: "113",
        key: 711,
        path: "/service-config",
        name: "Config",
        rtlName: "طباعة",
        icon: LocalCarWashIcon,
        layout: "/admin",
      },
    ],
  },
  {
    key: 4466,
    name: "Packages",
    icon: LocalMallIcon,
    submenus: "116",
    children: [
      {
        submenus: "118",
        key: 3332,
        path: "/package",
        name: "Package",
        rtlName: "طباعة",
        icon: LocalMallIcon,
        layout: "/admin",
      },
      // {
      //   submenus: '1118',
      //   key: 3331,
      //   path: "/package-config",
      //   name: "Config",
      //   rtlName: "طباعة",
      //   icon: LocalMallIcon,
      //   layout: "/admin",
      // },
    ],
  },
  // {
  //   key: 15,
  //   path: "/language",
  //   name: "Language",
  //   rtlName: "طباعة",
  //   icon: TranslateIcon,
  //   layout: "/admin",
  // },

  {
    key: 10,
    name: "Customer",
    icon: PeopleOutlineIcon,
    submenus: "2",
    children: [
      {
        submenus: "11223",
        key: 67,
        path: "/customer",
        name: "Customer",
        rtlName: "طباعة",
        icon: PeopleOutlineIcon,
        layout: "/admin",
      },
      {
        submenus: "1123123",
        key: 66,
        path: "/customer-vehicle",
        name: "Customer Vehicles",
        rtlName: "طباعة",
        icon: DriveEtaIcon,
        layout: "/admin",
      },
      {
        submenus: "45345345345",
        key: 68,
        path: "/customer-address",
        name: "Customer Address",
        rtlName: "طباعة",
        icon: LocationOn,
        layout: "/admin",
      },
      {
        submenus: "11223",
        key: 109,
        path: "/customer/packages",
        name: "Customer Packages",
        rtlName: "طباعة",
        icon: LocalMallIcon,
        layout: "/admin",
      },
    ],
  },
  {
    key: 10,
    name: "Notification",
    icon: Notifications,
    submenus: "3",
    children: [
      {
        submenus: "4",
        key: 69,
        path: "/push-notification",
        name: "Push Notification",
        rtlName: "طباعة",
        icon: Notifications,
        layout: "/admin",
      },
      {
        submenus: "5",
        key: 69,
        path: "/automated-notification",
        name: "Automated Notification",
        rtlName: "طباعة",
        icon: Notifications,
        layout: "/admin",
      },
      {
        submenus: "6",
        key: 69,
        path: "/alert",
        name: "Alerts",
        rtlName: "طباعة",
        icon: NotificationImportantIcon,
        layout: "/admin",
      },
    ],
  },
  {
    key: 112,
    name: "Driver",
    icon: PeopleOutlineIcon,
    submenus: "6",
    children: [
      {
        submenus: "6",
        key: 88,
        path: "/driver",
        name: "Driver",
        rtlName: "طباعة",
        icon: PeopleOutlineIcon,
        layout: "/admin",
      },
      {
        submenus: "6",
        key: 87,
        path: "/driver-config",
        name: "Driver Config",
        rtlName: "طباعة",
        icon: PeopleOutlineIcon,
        layout: "/admin",
      },
    ],
  },
  {
    key: 16,
    path: "/promotion",
    name: "Offers",
    rtlName: "إخطارات",
    icon: Notifications,
    layout: "/admin",
  },
  {
    key: 16,
    path: "/promotion/linked",
    name: "Offers Linked",
    rtlName: "إخطارات",
    icon: Notifications,
    layout: "/admin",
  },
  {
    key: 16,
    path: "/promotion/auto_applied",
    name: "Offers AutoApplied",
    rtlName: "إخطارات",
    icon: Notifications,
    layout: "/admin",
  },
  {
    key: 16,
    path: "/booking",
    name: "Booking",
    rtlName: "إخطارات",
    icon: EventIcon,
    layout: "/admin",
  },
  {
    key: 16,
    path: "/booking/package",
    name: "Booking Package",
    rtlName: "إخطارات",
    icon: EventIcon,
    layout: "/admin",
  },
  // {
  //   key: 10,
  //   name: "Content",
  //   icon: EditIcon,
  //   submenus: "4",
  //   children: [
  //     // {
  //     //   submenus: '5',
  //     //   key: 70,
  //     //   path: "/content",
  //     //   name: "Content Management",
  //     //   rtlName: "طباعة",
  //     //   icon: EditIcon,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       submenus: "6",
  //       key: 71,
  //       path: "/email-template",
  //       name: "Email Template",
  //       rtlName: "طباعة",
  //       icon: EmailIcon,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    key: 10,
    name: "Setting",
    icon: SettingsIcon,
    submenus: "5",
    children: [
      {
        submenus: "7",
        key: 70,
        path: "/application-setting",
        name: "Application Setting",
        rtlName: "طباعة",
        icon: SettingsApplicationsIcon,
        layout: "/admin",
      },
      {
        submenus: "7",
        key: 71,
        path: "/alert-setting",
        name: "Alert Setting",
        rtlName: "طباعة",
        icon: SettingsApplicationsIcon,
        layout: "/admin",
      },
    ],
  },

  // {
  //   key: 11,
  //   name: "Website",
  //   icon: SettingsIcon,
  //   submenus: "61",
  //   children: [
  //     {
  //       submenus: "71",
  //       key: 72,
  //       path: "/themesettings",
  //       name: "Theme Settings",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },

  //     {
  //       submenus: "85",
  //       key: 82,
  //       path: "/pages",
  //       name: "Pages",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },
  //     {
  //       submenus: "96",
  //       key: 89,
  //       path: "/webservices",
  //       name: "Website Services",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },
  //     {
  //       submenus: "88",
  //       key: 82,
  //       path: "/partnership",
  //       name: "Partnership",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },
  //     {
  //       submenus: "89",
  //       key: 89,
  //       path: "/business",
  //       name: "Business",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },
  //     // {
  //     //   submenus: "91",
  //     //   key: 85,
  //     //   path: "/pages/mediapage",
  //     //   name: "Media",
  //     //   rtlName: "طباعة",
  //     //   icon: SettingsApplicationsIcon,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       submenus: "95",
  //       key: 85,
  //       path: "/media",
  //       name: "News Details",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },
  //     {
  //       submenus: "96",
  //       key: 86,
  //       path: "/event",
  //       name: "Event Details",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },

  //     {
  //       submenus: "92",
  //       key: 87,
  //       path: "/category",
  //       name: "Media Category",
  //       rtlName: "طباعة",
  //       icon: SettingsApplicationsIcon,
  //       layout: "/admin",
  //     },

  //     // {
  //     //   submenus: '87',
  //     //   key: 82,
  //     //   path: "/partner",
  //     //   name: "partner",
  //     //   rtlName: "طباعة",
  //     //   icon: SettingsApplicationsIcon,
  //     //   layout: "/admin",
  //     // },
  //     // {
  //     //   submenus: '81',
  //     //   key: 82,
  //     //   path: "/homepage",
  //     //   name: "Home page",
  //     //   rtlName: "طباعة",
  //     //   icon: SettingsApplicationsIcon,
  //     //   layout: "/admin",
  //     // },
  //     // {
  //     //   submenus: '81',
  //     //   key: 82,
  //     //   path: "/aboutus",
  //     //   name: "AboutUs page",
  //     //   rtlName: "طباعة",
  //     //   icon: SettingsApplicationsIcon,
  //     //   layout: "/admin",
  //     // }
  //   ],
  // },

  {
    key: 38,
    path: "/transaction",
    name: "Transaction",
    rtlName: "إخطارات",
    icon: AccountBalanceIcon,
    layout: "/admin",
  },
  {
    key: 29,
    path: "/reports",
    name: "Reports",
    rtlName: "إخطارات",
    icon: InsertDriveFile,
    layout: "/admin",
  },
  {
    key: 31,
    path: "/contactus",
    name: "Contact Us",
    rtlName: "إخطارات",
    icon: PhoneIcon,
    layout: "/admin",
  },
  {
    key: 10,
    name: "FAQ",
    icon: LiveHelpIcon,
    submenus: "88",
    children: [
      {
        submenus: "93",
        key: 70,
        path: "/faq",
        name: "FAQ",
        rtlName: "طباعة",
        icon: LiveHelpIcon,
        layout: "/admin",
      },
      {
        submenus: "94",
        key: 70,
        path: "/faq/type",
        name: "FAQ Type",
        rtlName: "طباعة",
        icon: LiveHelpIcon,
        layout: "/admin",
      },
    ],
  },
];

import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import _ from 'lodash';
import { Box, FormHelperText, Typography } from "@material-ui/core";
import GoogleMap from "components/GoogleMap/GoogleMapMarker";
import DrawMap from "components/GoogleMap/DrawMap";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from "react-hook-form";
import { InputField, LoadingButton, SwitchFieldDefault, PhoneNoField, SelectField, AutoCompleteField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCustomerAddressValidation } from "./Validations/customerAddressValidation";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import { createCustomerAddress, getCustomers, getCustomersAddress, updateCustomer, updateCustomerAddress } from "services/customerServices";
import {getCity, getCountry, } from 'services/locationservices'
import { verifyPermission } from 'helper/permission'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link
} from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "components/CustomButtons/Button";
import {CREATE_SUCCESS_MSG} from 'json/constants'
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import moment from 'moment';

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  customer_id: '',
  first_name: '',
  last_name: '',
  label: '',
  area: '',
  landmark: '',
  street_one: '',
  unitNumber: '',
  unitType: '',
  country_id: '',
  city_id: '',
  status: true,
};
const useStyles = makeStyles(styles);
declare var google: any;

export default function AddCustomerAddress(props: any) {
  const classes = useStyles();
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [editData, setEditData] = useState<any>(null)
  const [country, setCountry] = useState<any>('');
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [city, setCity] = useState<any>('');
  const [customers, setCustomers] = useState<any>([]);
  const [customer, setCustomer] = useState<any>('');
  const [disabled, setDisabled] = useState<any>(false)
  const [resetMap, setResetMap] = useState<any>(false);
  const [mapValues, setMapValues] = useState<any>(null);
  const [currentZoom, setCurrentZoom] = useState(12);
  const [lat,setLat] = useState<any>(null)
  const [lng ,setLng]=useState<any>(null)
  const [unitType, setUnitType] = useState<any>('');

  // const [currentCenter, setcurrentCenter] = useState({
  //   lat: parseFloat(editData?.lat ? editData?.lat : "25.2048"),
  //   lng: parseFloat(editData?.long ? editData?.long : "55.2708"),
  // });

  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch()
  let history = useHistory();

  const { control, register, handleSubmit, reset, setError, formState: { errors }, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createCustomerAddressValidation)
  });

  const AddressUpdate = (data:any) =>{
    if(data){
      setValue("area",data)
    }
  }
  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Customer Address",
      'link': '/admin/customer-address'
    },
    {
      'name':headerTitle,
      'link': ''
    }
  ];

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getCustomersAddress({ uuid: uuid })
        .then((e: any) => {
          let data: any = e?.data?.result?.[0];

          setEditData(data);
          getCity({ country_id: data?.city?.country.uuid, status: true })
            .then((e) => {
              setCities(e?.data?.result)
            })
          setCity(data?.city)
          setCountry(data?.city?.country)
          setCustomer(data?.customer)
          setLng(data?.longitude)
          setLat(data?.latitude)
          setUnitType(data?.jsonDump?.unitType)
          setValue("unitNumber", data?.jsonDump?.unitNumber)
          // setValue("unitType", data?.jsonDump?.unitType)
          Object.keys(data).forEach(function (key) {
            setValue(key, data[key])
          })
          dispatch(spinLoaderShow(false))
        })
        
        let temp: any = new URLSearchParams(search).get("type");
        temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
        temp == 'view' ? setDisabled(true) : setDisabled(false)

    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData([])
    }

    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {
    })

    getCustomers({ status: true }).then((e) => {
      setCustomers(e.data.result)
    }).catch((e) => {
    })

  }, [uuid]);


  const unitTypeOptions = [
    { value: 'Indoor', label: 'Indoor'},
    { value: 'Villa', label: 'Villa' },
    { value: 'Outdoor', label: 'Outdoor'} 
  ];

  const onSubmit = async (data: any) => {
    // data.latitude = mapValues.lat
    // data.longitude = mapValues.lng
    // console.clear();

    data.latitude = mapValues?.autocomplete ? mapValues?.autocomplete.lat : mapValues?.lat;
    data.longitude = mapValues?.autocomplete ? mapValues?.autocomplete.lng : mapValues?.lng;
    data.jsonDump = {
      "unitNumber": data.unitNumber,
      "unitType": data.unitType
    }
    delete data?.unitNumber
    delete data?.unitType


    dispatch(spinLoaderShow(true));
    
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    createCustomerAddress(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/customer-address");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    updateCustomerAddress(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/customer-address");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const getCitiesByCountry = (data: any = null) => {
    setCities([])
    setCity({})
    setCountry(data)
    if (data?.uuid) {
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
        })
    }
  }


  useEffect(() => {
    console.log("map", mapValues)
    if(mapValues?.autocomplete ? mapValues?.autocomplete.lat : mapValues?.lat){
      
    setValue("lat",mapValues?.autocomplete ? mapValues?.autocomplete.lat : mapValues?.lat)
    setLng(mapValues?.autocomplete ? mapValues?.autocomplete?.lng : mapValues?.lng)
    setLat(mapValues?.autocomplete ? mapValues?.autocomplete?.lat : mapValues?.lat)
    }
  }, [mapValues])


  useEffect(()=>{
   console.log("mapValues",mapValues)
  },[mapValues])
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Customer Address"
              routes={routes}
              newItem={[{ text: 'Back', link: "/admin/customer-address" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" className="card-header-export-btn">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Customer Address</h4>
                  {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') } </h4> : null } 
                </Box>
                {
                  editData?.customer_id &&  <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => {
                    history.push('/admin/customer/edit?uuid=' + editData?.customer_id + "&type=view")
                  }}>View Customer</Button>
                }
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.customer_id}
                      fieldName="customer_id"
                      autoComplete='off'
                      label="* Select Customer"
                      control={control}
                      setValue={setValue}
                      options={customers}
                      iProps={{
                        disabled: disabled
                      }}
                      isLoading={customers?.length > 0 ? true : false}
                      optionKey="uuid"
                      optionValue="first_name"
                      helperText={errors?.customer_id && errors?.customer_id?.message}
                      valueGot={customer}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.first_name}
                      fieldName="first_name"
                      type="text"
                      autoComplete='false'
                      label="* First Name"
                      control={control}
                      iProps={{
                        disabled: disabled
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.first_name && errors?.first_name?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.last_name}
                      fieldName="last_name"
                      type="text"
                      autoComplete='false'
                      label="* Last Name"
                      control={control}
                      iProps={{
                        disabled: disabled
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.last_name && errors?.last_name?.message}

                    />
                  </GridItem> */}
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.label}
                      fieldName="label"
                      type="text"
                      autoComplete="false"
                      label="* Label"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.label && errors?.label?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.area}
                      fieldName="area"
                      type="text"
                      autoComplete="false"
                      label="* Area"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.area && errors?.area?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.landmark}
                      fieldName="landmark"
                      type="text"
                      autoComplete="false"
                      label="* Landmark"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.landmark && errors?.landmark?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.street_one}
                      fieldName="street_one"
                      type="text"
                      autoComplete="false"
                      label="* Address"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.street_one && errors?.street_one?.message
                      }
                    />
                  </GridItem>
                  
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.unitNumber}
                      fieldName="unitNumber"
                      type="text"
                      autoComplete="false"
                      label="* Unit Number"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.unitNumber && errors?.unitNumber?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} >
                    <AutoCompleteField
                             errors={!!errors?.unitType}
                             fieldName="unitType"
                             autoComplete='off'
                             label="* Select Unit Type"
                             control={control}
                             setValue={setValue}
                             options={unitTypeOptions }
                             isLoading={unitTypeOptions?.length > 0 ? true : false}
                             optionKey="value"
                             iProps={{
                               disabled:disabled
                             }}
                             optionValue="label"
                             helperText={errors?.unitType && errors?.unitType?.message}
                            //  valueGot={editData?.jsonDump?.unitType && unitTypeOptions?.find(({ x }: any) => { return x.label == editData?.jsonDump?.unitType })}
                              valueGot={editData?.jsonDump?.unitType ? unitTypeOptions.find(x => x.label == editData?.jsonDump?.unitType) : ''}
/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.country_id}
                      fieldName="country_id"
                      autoComplete='off'
                      label="* Select Country"
                      control={control}
                      setValue={setValue}
                      options={countries}
                      isLoading={countries?.length > 0 ? true : false}
                      optionKey="uuid"
                      iProps={{
                        onChange: getCitiesByCountry,
                        disabled:disabled
                      }}
                      optionValue="country_name"
                      helperText={errors?.country_id && errors?.country_id?.message}
                      valueGot={country}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.city_id}
                      fieldName="city_id"
                      autoComplete='off'
                      label="* Select City"
                      control={control}
                      setValue={setValue}
                      options={cities}
                      isLoading={true}
                      optionKey="uuid"
                      iProps={{
                        disabled: disabled
                      }}
                      optionValue="city_name"
                      helperText={errors?.city_id && errors?.city_id?.message}
                      valueGot={city}
                    />
                  </GridItem>
                  {/* <GridItem xs={4} sm={4} md={4}>
                    <SelectField
                      errors={!!errors?.gender}
                      fieldName="gender"
                      type="text"
                      autoComplete="false"
                      label="* Gender"
                      control={control}
                      valueGot={(editData && editData?.gender == 'Male') ? { 'id': 'male', 'name': 'Male' } : { 'id': 'female', 'name': 'Female' }}
                      selectOptions={
                        [{ 'id': 'male', 'name': 'Male' }, { 'id': 'female', 'name': 'Female' }]
                      }
                      setValue={setValue}
                      helperText={
                        errors?.gender && errors?.gender?.message
                      }
                    />
                  </GridItem> */}
                  {/* <GridItem xs={4} sm={4} md={4}>
                    <PhoneNoField
                      errors={!!errors?.mobile_no}
                      fieldName="mobile_no"
                      autoComplete="off"
                      label="* Mobile No"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.mobile_no && errors?.mobile_no?.message
                      }
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                            <Typography variant={"subtitle1"}>
                              Address:
                              <span className="google-map-error-text">
                                {errors.polygon &&
                                  errors?.polygon?.latLng?.message}
                              </span>
                            </Typography>
                            {editData && (
                              <FormHelperText 
                                error={errors.polygon && true}
                                className="google-map-error"
                              >
                                <GoogleMap
                                autocomplete
                                value={setMapValues}
                                setValueProp={AddressUpdate}
                                  draggable={disabled ? !disabled : true}
                                  setValue={{
                                    latLng: {
                                      lat: editData?.latitude
                                        ? +editData?.mapValues?.latitude
                                        : 25.276987,
                                      lng: editData?.longitude
                                        ? +editData?.longitude
                                        : 55.296249,
                                    },
                                  }}
                                  reset={resetMap}
                                  zoom={
                                    editData?.zoom
                                      ? +editData?.zoom
                                      : 14
                                  }
                                  center={{
                                    lat: editData?.latitude
                                      ? +editData?.latitude
                                      : 25.276987,
                                    lng: editData?.longitude
                                      ? +editData?.longitude
                                      : 55.296249,
                                  }}
                                  onCenterChanged={(val:any)=>{
                                    console.log("Changed",val)
                                  }}
                                >
                                  <DrawMap data={editData} />
                                </GoogleMap>
                              </FormHelperText>
                            )}
                            {editData && !mapValues?.lat  && <p> <a href={"https://www.google.com/maps/search/?api=1&query=" + lat + ", " +lng} target="_blank">Link</a></p>}
                            {editData  && !mapValues?.lat &&   <p> {"https://www.google.com/maps/search/?api=1&query=" +lat + "," +lng}</p>}

                            {mapValues?.lat  && <p> <a href={"https://www.google.com/maps/search/?api=1&query=" + mapValues?.lat + ", " + mapValues?.lng} target="_blank">Link</a></p>}
                            {mapValues?.lat &&   <p> {"https://www.google.com/maps/search/?api=1&query=" +mapValues?.lat + "," + mapValues?.lng}</p>}
                </GridItem>
                
                        
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} className={"custom-btn"} type="submit" >{uuid ? 'Update Customer' : "Create Customer"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}


import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";
import { ContactDetailValidation } from "./validations/contact_detail-validatiion";

export const ContactDetailAccordion = (props: any) => {
  const dispatch = useDispatch();
  const { apiData, permission, collapse } = props;
  // console.log("apiData",apiData?.footer_section);

  const defaultFormValues: any = {};

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(ContactDetailValidation),
  });

  useEffect(() => {
    if (apiData) {
      setValue(
        "operating_hours",
        apiData?.contact_detail_section?.operating_hours
      );
      setValue("email", apiData?.contact_detail_section?.email);
      setValue("description", apiData?.contact_detail_section?.description);
      setValue("phone_number", apiData?.contact_detail_section?.phone_number);
      setValue("whatsapp_number", apiData?.contact_detail_section?.whatsapp_number);
    }
  }, [apiData]);

  const onSubmit = (data: any) => {
    var payload = { contact_detail_section: data };
    updateOnSubmit(payload);
    console.log("payload", payload);
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateHeaderSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledAccordion collapse={collapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Contact Detail </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <GridContainer>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.phone_number}
                fieldName="phone_number"
                type="text"
                autoComplete="false"
                label="* Phone Number "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.phone_number && errors?.phone_number?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.whatsapp_number}
                fieldName="whatsapp_number"
                type="text"
                autoComplete="false"
                label="* Whatsapp Number "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.whatsapp_number && errors?.whatsapp_number?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.email}
                fieldName="email"
                type="text"
                autoComplete="false"
                label="* Email"
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.email && errors?.email?.message}
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.operating_hours}
                fieldName="operating_hours"
                type="text"
                autoComplete="false"
                label="* Operating Hours "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.operating_hours && errors?.operating_hours?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.description}
                fieldName="description"
                type="text"
                autoComplete="false"
                label="* Description "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.description && errors?.description?.message}
              />
            </GridItem>
          </GridContainer>
        </AccordionDetails>
        <AccordionActions>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <Button color="primary" className={"custom-btn"} type="submit">
              Update
            </Button>
          </Box>
        </AccordionActions>
      </ControlledAccordion>
    </form>
  );
};

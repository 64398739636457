import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Button, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box } from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import {
  InputField,
  LoadingButton,
  SwitchField,
} from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import zxcvbn from "zxcvbn";
import "../../../src/assets/css/PasswordStrengthMeter.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordValidation } from "./Validations/passwordValidation";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import { toast } from "react-toastify";
import { getUser} from "services/cognitouser";
import {Auth} from 'aws-amplify'
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  //   buttonStyle:{
  //     '&:hover': {
  //         background: "#f00",
  //   }
  // }
};

const defautlValues: any = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

const useStyles = makeStyles(styles);

export const ChangePassword = (props: any) => {
  const createPasswordLabel = (result: any) => {
    switch (result?.score) {
      case 0:
        return "";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Excellent";
      default:
        return "Weak";
    }
  };

  const classes = useStyles();
  const [password, setPassword] = useState<any>("");
  const [newPassword, setnewPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");
  const [showPasswordCurr, setShowPasswordCurr] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const passwordResult = zxcvbn(password);
  const newResult = zxcvbn(newPassword);
  const confirmResult = zxcvbn(confirmPassword);
  const dispatch = useDispatch()

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    setValue,
    reset,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defautlValues,
    resolver: yupResolver(passwordValidation),
  });

  //   const handlePasswordChange = (e: any) => {
  //     setPassword(e.target.value);
  //     setValue("current_password", e.target.value);
  //   };

  const handleNewChange = (e: any) => {
    setnewPassword(e.target.value);
    setValue("new_password", e.target.value);
  };
  const handleConfirmChange = (e: any) => {
    setConfirmPassword(e.target.value);
    setValue("confirm_password", e.target.value);
  };

  const onSubmit = (data: any) => {
     dispatch(spinLoaderShow(true))
    Auth.changePassword(getUser(),data?.current_password,data?.confirm_password).then((res)=>{
      console.log(res)
      toast.success("Password Changed succesfully.");
      dispatch(spinLoaderShow(false))
      reset(defautlValues);
      setPassword("");
      setnewPassword("");
      setConfirmPassword("");
    }).catch((err)=>{
      console.log(err)
      dispatch(spinLoaderShow(false))
      toast.error(err?.message)
    })
    
  };



  const handleClickShowPasswordCurr = () => {
    setShowPasswordCurr(!showPasswordCurr);
  };
  const handleClickShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  const handleClickShowPasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
 

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Change Password</h4>
            </CardHeader>
            <CardBody>
              <GridContainer spacing={3}>
                <GridItem xs={4} sm={4} md={4}>
                  <InputField
                    errors={!!errors?.current_password}
                    fieldName="current_password"
                    type={showPasswordCurr ? 'text':'password'}
                    autoComplete="false"
                    label="Current Password"
                    control={control}
                    isLoading={true}
                    setValue={setValue}
                    iProps={{
                          
                        InputProps:{endAdornment:(<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordCurr}
                        >
                          {showPasswordCurr ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>)}
                      }}
                    helperText={
                      errors?.current_password &&
                      errors?.current_password?.message
                    }
                  />
               
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputField
                    errors={!!errors?.new_password}
                    fieldName="new_password"
                    type={showPasswordNew? 'text':'password'}
                    autoComplete="false"
                    label="New Password"
                    control={control}
                    isLoading={true}
                    iProps={{
                        onChange: handleNewChange,
                        InputProps:{endAdornment:(<InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordNew}
                        >
                          {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                         
                        </IconButton>
                      </InputAdornment>)}
                      }}
                    setValue={setValue}
                    helperText={
                      errors?.new_password && errors?.new_password?.message
                    }
                  />
                  <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${createPasswordLabel(
                        newResult
                      )}`}
                      value={newResult?.score}
                      max="4"
                    />
                    <br />
                    <label className="password-strength-meter-label">
                      {newPassword && <>{createPasswordLabel(newResult)}</>}
                    </label>
                  </div>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InputField
                    errors={!!errors?.confirm_password}
                    fieldName="confirm_password"
                    type={showPasswordConfirm? 'text':'password'}
                    autoComplete="false"
                    label="Confirm Password"
                    control={control}
                    isLoading={true}
                    iProps={{
                      onChange: handleConfirmChange,
                      InputProps:{endAdornment:(<InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordConfirm}
                      >
                        {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                   
                      </IconButton>
                    </InputAdornment>)}
                    }}
                    
                    setValue={setValue}
                    helperText={
                      errors?.confirm_password &&
                      errors?.confirm_password?.message
                    }
                  />
                  <div className="password-strength-meter">
                    <progress
                      className={`password-strength-meter-progress strength-${createPasswordLabel(
                        confirmResult
                      )}`}
                      value={confirmResult?.score}
                      max="4"
                    />
                    <br />
                    <label className="password-strength-meter-label">
                      {confirmResult && (
                        <>{createPasswordLabel(confirmResult)}</>
                      )}
                    </label>
                  </div>
                </GridItem>
             
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%" }}
              >
                {/* <Button variant="contained"  color="primary" type="submit" className="button">Change password</Button>    */}
                <LoadingButton
                  className={"custom-btn"}
                  type="submit"
                  loading={false}
                  text="Change Password"
                />
              </Box>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export const sections: any = [
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/users",
    sectionName: "Users",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/users/add",
    sectionName: "Users",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/users/edit",
    sectionName: "Users",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Country",
    pathName: "/admin/country",
    sectionName: "Location",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Country",
    pathName: "/admin/country/add",
    sectionName: "Location",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Country",
    pathName: "/admin/country/edit",
    sectionName: "Location",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/users",
    sectionName: "Roles",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/role/add",
    sectionName: "Roles",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/role/edit",
    sectionName: "Roles",
  },
  {
    specific: "read",
    permission: true,
    subsection: "City",
    pathName: "/admin/city",
    sectionName: "Location",
  },
  {
    specific: "create",
    permission: true,
    subsection: "City",
    pathName: "/admin/city/add",
    sectionName: "Location",
  },
  {
    specific: "update",
    permission: true,
    subsection: "City",
    pathName: "/admin/city/edit",
    sectionName: "Location",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Branch",
    pathName: "/admin/branch",
    sectionName: "Location",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Branch",
    pathName: "/admin/branch/add",
    sectionName: "Location",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Branch",
    pathName: "/admin/branch/edit",
    sectionName: "Location",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Zone",
    pathName: "/admin/zone",
    sectionName: "Location",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Zone",
    pathName: "/admin/zone/add",
    sectionName: "Location",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Zone",
    pathName: "/admin/zone/edit",
    sectionName: "Location",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/scheduler",
    sectionName: "Schedule",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Type",
    pathName: "/admin/vehicletype",
    sectionName: "Vehicles",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Type",
    pathName: "/admin/vehicletype/add",
    sectionName: "Vehicles",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Type",
    pathName: "/admin/vehicletype/edit",
    sectionName: "Vehicles",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Make",
    pathName: "/admin/vehiclemake",
    sectionName: "Vehicles",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Make",
    pathName: "/admin/vehiclemake/add",
    sectionName: "Vehicles",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Make",
    pathName: "/admin/vehiclemake/edit",
    sectionName: "Vehicles",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Model",
    pathName: "/admin/vehiclemodel",
    sectionName: "Vehicles",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Model",
    pathName: "/admin/vehiclemodel/add",
    sectionName: "Vehicles",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Model",
    pathName: "/admin/vehiclemodel/edit",
    sectionName: "Vehicles",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/scheduler/add",
    sectionName: "Schedule",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/scheduler/edit",
    sectionName: "Schedule",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Admin Users",
    pathName: "/admins/user/edit",
    sectionName: "Administrator",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Service",
    pathName: "/admin/service",
    sectionName: "Services",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Config",
    pathName: "/admin/service-config",
    sectionName: "Services",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Config",
    pathName: "/admin/service-config/edit",
    sectionName: "Services",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Category",
    pathName: "/admin/service-category",
    sectionName: "Services",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Category",
    pathName: "/admin/service-category/add",
    sectionName: "Services",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Category",
    pathName: "/admin/service-category/edit",
    sectionName: "Services",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Package",
    pathName: "/admin/package",
    sectionName: "Packages",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Package",
    pathName: "/admin/package/add",
    sectionName: "Packages",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Package",
    pathName: "/admin/package/edit",
    sectionName: "Packages",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer",
    sectionName: "Customer",
  },
  // {
  //   specific: "create",
  //   permission: true,
  //   subsection: "Manage",
  //   pathName: "/admin/customeradd",
  //   sectionName: "Customer",
  // },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer/edit",
    sectionName: "Customer",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Customer Vehicles",
    pathName: "/admin/customer-vehicle",
    sectionName: "Customer",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Customer Vehicles",
    pathName: "/admin/customer-vehicle/add",
    sectionName: "Customer",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Customer Vehicles",
    pathName: "/admin/customer-vehicle/edit",
    sectionName: "Customer",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Push Notification",
    pathName: "/adminpush-notification",
    sectionName: "Notification",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Alerts",
    pathName: "/admin/alert",
    sectionName: "Notification",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/automated-notification",
    sectionName: "Notification",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/push-notification",
    sectionName: "Notification",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/push-notification",
    sectionName: "Notification",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Driver",
    pathName: "/admin/driver",
    sectionName: "Driver",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Driver",
    pathName: "/admin/driver/add",
    sectionName: "Driver",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Driver",
    pathName: "/admin/driver/edit",
    sectionName: "Driver",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Customer Detail",
    sectionName: "Customer",
    pathName: "/customer",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion",
    sectionName: "Promotion",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/linked",
    sectionName: "Promotion",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/linked/all",
    sectionName: "Promotion",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/auto_applied",
    sectionName: "Promotion",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/add",
    sectionName: "Promotion",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/promotion/edit",
    sectionName: "Promotion",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/application-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/application-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/application-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Alert Setting",
    pathName: "/admin/alert-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Alert Setting",
    pathName: "/admin/alert-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/automated-notification",
    sectionName: "Push Notification",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Alert Setting",
    pathName: "/admin/alert-setting",
    sectionName: "Application Setting",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Push Notification",
    pathName: "/admin/automated-notification",
    sectionName: "Push Notification",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/vendor",
    sectionName: "Vendor",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/vendor/add",
    sectionName: "Vendor",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/vendor/edit",
    sectionName: "Vendor",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/content",
    sectionName: "Content",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/content/add",
    sectionName: "Content",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/content/edit",
    sectionName: "Content",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Email",
    pathName: "/admin/email-template",
    sectionName: "Content",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Email",
    pathName: "/admin/cemail-template/add",
    sectionName: "Content",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Email",
    pathName: "/admin/email-template/edit",
    sectionName: "Content",
  },
  {
    specific: "read",
    permission: true,
    sectionName: "Reports",
    pathName: "/admin/reports",
    subsection: "Manage",
  },

  {
    specific: "read",
    permission: true,
    subsection: "Product Detail",
    sectionName: "Products",
    pathName: "/products",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Product Category Detail",
    sectionName: "Product Category",
    pathName: "/productCategory",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Order Detail",
    sectionName: "Orders",
    pathName: "/order",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Config",
    pathName: "/admin/package-config",
    sectionName: "Packages",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Vehicles Manager",
    sectionName: "Vehicles Manager",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/faq",
    sectionName: "FAQ",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/faq/add",
    sectionName: "FAQ",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/faq/edit",
    sectionName: "FAQ",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Type",
    pathName: "/admin/faq/type",
    sectionName: "FAQ",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Type",
    pathName: "/admin/faq/type/add",
    sectionName: "FAQ",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Type",
    pathName: "/admin/faq/type/edit",
    sectionName: "FAQ",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/contactus",
    sectionName: "Contact Us",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Booking",
    pathName: "/admin/booking",
    sectionName: "Booking",
  },
  {
    specific: "read",
    permission: true,
    subsection: "View",
    pathName: "/admin/booking/package",
    sectionName: "Booking",
  },
  {
    specific: "read",
    permission: true,
    subsection: "View",
    pathName: "/admin/booking/view",
    sectionName: "Booking",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Config",
    pathName: "/admin/driver-config",
    sectionName: "Driver",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Config",
    pathName: "/admin/driver-config/add",
    sectionName: "Driver",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Config",
    pathName: "/admin/driver-config/edit",
    sectionName: "Driver",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer",
    sectionName: "Customer",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer/add",
    sectionName: "Customer",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    pathName: "/admin/customer/edit",
    sectionName: "Customer",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Customer Address",
    pathName: "/admin/customer-address",
    sectionName: "Customer",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Customer Address",
    pathName: "/admin/customer-address/add",
    sectionName: "Customer",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Customer Address",
    pathName: "/admin/customer-address/edit",
    sectionName: "Customer",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Transaction",
    pathName: "/admin/transaction",
    sectionName: "Transaction",
  },
  // {
  //   specific: "read",
  //   permission: true,
  //   subsection: "View",
  //   pathName: "/admin/transaction/view",
  //   sectionName: "Transaction",
  // },
  {
    specific: "read",
    permission: true,
    subsection: "Contact Us",
    pathName: "/admin/contactus",
    sectionName: "Contact Us",
  },
  {
    specific: "read",
    permission: true,
    subsection: "View",
    pathName: "/admin/contactus/view",
    sectionName: "Contact Us",
  },
  // {
  //   specific: "read",
  //   permission: true,
  //   subsection: "Packages",
  //   pathName: "/admin/customer/packages/view",
  //   sectionName: "Customer",
  // },
  {
    specific: "read",
    permission: true,
    subsection: "Packages",
    pathName: "/admin/customer/packages",
    sectionName: "Customer",
  },

  // {
  //   specific: "read",
  //   permission: true,
  //   subsection: "HomePage",
  //   pathName: "/admin/home",
  //   sectionName: "HomePage",
  // },

  // {
  //   specific: "update",
  //   permission: true,
  //   subsection: "HomePage",
  //   pathName: "/admin/home/edit",
  //   sectionName: "HomePage",
  // },
  // {
  //   specific: "read",
  //   permission: true,
  //   subsection: "ThemeSettings",
  //   pathName: "/admin/theme",
  //   sectionName: "ThemeSettings",
  // },

  // {
  //   specific: "update",
  //   permission: true,
  //   subsection: "ThemeSettings",
  //   pathName: "/admin/theme/edit",
  //   sectionName: "ThemeSettings",
  // },

  // website serices===============>

  {
    specific: "read",
    permission: true,
    subsection: "Webservices",
    pathName: "/admin/webservices",
    sectionName: "Website",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Webservices",
    pathName: "/admin//webservices/add",
    sectionName: "Website",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Webservices",
    pathName: "/admin/webservices/edit",
    sectionName: "Website",
  },
  //partnership===============>
  {
    specific: "read",
    permission: true,
    subsection: "Partnership",
    pathName: "/admin/partnership",
    sectionName: "Website",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Partnership",
    pathName: "/admin/partnership/add",
    sectionName: "Website",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Partnership",
    pathName: "/admin/partnership/edit",
    sectionName: "Website",
  },
  //Media==================>
  {
    specific: "read",
    permission: true,
    subsection: "Media",
    pathName: "/admin/media",
    sectionName: "Website",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Media",
    pathName: "/admin/media/add",
    sectionName: "Website",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Media",
    pathName: "/admin/media/edit",
    sectionName: "Website",
  },
  //Events==================>
  {
    specific: "read",
    permission: true,
    subsection: "Events",
    pathName: "/admin/event",
    sectionName: "Website",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Events",
    pathName: "/admin/event/add",
    sectionName: "Website",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Events",
    pathName: "/admin/event/edit",
    sectionName: "Website",
  },
  //Media Category==================>
  {
    specific: "read",
    permission: true,
    subsection: "Media Category",
    pathName: "/admin/category",
    sectionName: "Website",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Media Category",
    pathName: "/admin/event/add",
    sectionName: "Website",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Media Category",
    pathName: "/admin/event/edit",
    sectionName: "Website",
  },
  // website Business
  {
    specific: "read",
    permission: true,
    subsection: "Website Business",
    pathName: "/admin/business",
    sectionName: "Website",
  },
  // website pages 
  {
    specific: "read",
    permission: true,
    subsection: "Website Pages",
    pathName: "/admin/pages",
    sectionName: "Website",
  },
  // Themesettings
  {
    specific: "read",
    permission: true,
    subsection: "Theme Settings",
    pathName: "/admin/themesettings",
    sectionName: "Website",
  },
];

import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getBooking, getViewBookingPackage } from "services/userservices";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import Button from "components/CustomButtons/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory, useLocation } from "react-router";
import NotificationsIcon from '@material-ui/icons/Notifications';

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);



export const BookingListPackage = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()
  const { search } = useLocation()
  let driverId = new URLSearchParams(search).get("driverId");
  let bookingType = new URLSearchParams(search).get("type");


  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Booking",
      'link': ''
    }
  ];

  useEffect(() => {
    dispatch(spinLoaderShow(true));
    if (driverId) {
      getApiData({ driver_id: driverId })
    }
    else if (bookingType) {
      if (bookingType == 'completed') {
        getApiData({ booking_status_id: 6 })
      } else if (bookingType == 'upcoming') {
        getApiData({ booking_status_id: 12 })
      } else if (bookingType == 'ongoing') {
        getApiData({ booking_status_id: 7 })
      } else if (bookingType == 'cancelledByUser') {
        getApiData({ booking_status_id: 8 })
      } else if (bookingType == 'cancelledByAdmin') {
        getApiData({ booking_status_id: 9 })
      } else if (bookingType == "onTheWay"){
        getApiData({ booking_status_id: 13 })
      }
      else if (bookingType == "driverReached"){
        getApiData({ booking_status_id: 10 })
      }
    }
    else {
      getApiData()
    }


  }, [])



  useEffect(() => {
    let ispermission = !(verifyPermission(
      getPermission,
      'Booking',
      'View',
      'read'
    ))
    setDisabled(ispermission)
  }, [getPermission])
  const getApiData = (type: any = null) => {
    if (type) {
      getViewBookingPackage(type).then((e: any) => {
        let data = e?.data?.result.map((item: any) => {
          item.status = item.status ? 'active' : 'inactive'
          return item
        })
        setRowData(data)
        dispatch(spinLoaderShow(false));
      }).catch((err: any) => {
        dispatch(spinLoaderShow(false));
      })


    }
    else {
      getViewBookingPackage().then((e) => {
        let data = e?.data?.result.map((item: any) => {
          item.status = item.status ? 'active' : 'inactive'
          return item;
        })
        setRowData(data)
        dispatch(spinLoaderShow(false));
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
      })

    }
  }

  const columnDefsOld: any = [
    {
      headerName: "Id",
      field: "orderId",
      maxWidth: 80
    },
    {
      headerName: "Customer First Name",
      field: "customer_FirstName",

    },
    {
      headerName: "Customer Last Name",
      field: "customer_LastName",

    },
    {
      headerName: "Customer Mobile",
      field: "customer_PhoneNumber",
    },
    {
      headerName: "Payment Type",
      field: "transaction_type",
    },
    {
      headerName: 'Booking Status',
      field: "bookingStatus",
    },
    {
      headerName: "Customer Address",
      field: "customer_adress",
    },
    {
      headerName: "City",
      field: "city",
    },
    {
      headerName: "Country",
      field: "country",
    },
    {
      headerName: "Service Name",
      field: "service_name",
    },
    {
      headerName: "Package Name",
      field: "package_name",
    },
    {
      headerName: "Vehicle Brand",
      field: "vehicle_brand",
    },

    {
      headerName: "Vehicle Model",
      field: "vehicle_model",
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
    },
    {
      headerName: "Driver First Name",
      field: "driver_FirstName"
    },
    {
      headerName: "Driver Last Name",
      field: "driver_LastName"
    },
    {
      headerName: "Amount",
      field: "price",
    },
    {
      headerName: "Vat",
      field: "vat",
      valueGetter: (params: any) => {
        return ((params?.data?.vat * 0.01) * params?.data?.price).toFixed(2);

        // return params?.data?.date_of_birth;
      },
    },
    {
      headerName: "Booking Date",
      field: "bookingAt",
      filter: 'agDateColumnFilter',
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
      // valueGetter: (params: any) => {
      //   return moment(params?.data?.bookingAt).format('DD-MM-YYYY');

      //   // return params?.data?.date_of_birth;
      // },
      // cellRendererFramework: (params: any) => {
      //   return moment(params.value).format('DD-MM-YYYY');
      // },
    },
    {
      headerName: "Start Time",
      field: "start_time",
    },
    {
      headerName: "End Time",
      field: "end_time",
    },
    {
      headerName: "Order At Date",
      field: "order_at",
      sort:'desc',
      // valueGetter: (params: any) => {
      //   return moment(params?.data?.order_at).format('DD-MM-YYYY HH:MM:ss');

      //   // return params?.data?.date_of_birth;
      // },
      // // cellRendererFramework: (params: any) => {
      // //   return moment(params.value).format('DD-MM-YYYY');
      // // },
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },

    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <>

          <Link to={`/admin/booking/details/add?booking_id=${params?.data?.booking_id}&uuid=${params?.data?.booking_detail_id}`}><VisibilityIcon /></Link>

          {params?.data?.booking_status_id == 1 &&
            <Link to={`/admin/push-notification/add?customer_id=${params?.data?.customer_id}`}><NotificationsIcon /></Link>
          }
        </>
      }
    },

  ]

  const columnDefs: any = [
    {
      headerName: "Id",
      field: "orderId",
      maxWidth: 80
    },
    {
      headerName: "Customer First Name",
      field: "customer_FirstName",

    },
   
    {
      headerName: "Customer Mobile",
      field: "customer_PhoneNumber",
    },
    {
      headerName: 'Booking Status',
      field: "bookingStatus",
    },

    {
      headerName: "Driver First Name",
      field: "driver_FirstName"
    },
    {
      headerName: "Order At Date",
      field: "order_at",
      filter: 'agDateColumnFilter',
      sort:'desc',
      // valueGetter: (params: any) => {
      //   return moment(params?.data?.order_at).format('DD-MM-YYYY HH:MM:ss');

      //   // return params?.data?.date_of_birth;
      // },
      // // cellRendererFramework: (params: any) => {
      // //   return moment(params.value).format('DD-MM-YYYY');
      // // },
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },

    {
      headerName: "Booking Date",
      field: "bookingAt",
      filter: 'agDateColumnFilter',
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
      // valueGetter: (params: any) => {
      //   return moment(params?.data?.bookingAt).format('DD-MM-YYYY');

      //   // return params?.data?.date_of_birth;
      // },
      // cellRendererFramework: (params: any) => {
      //   return moment(params.value).format('DD-MM-YYYY');
      // },
    },
    {
      headerName: "Start Time",
      field: "start_time",
    },
    {
      headerName: "End Time",
      field: "end_time",
    },
   
    {
      headerName: "Payment Type",
      field: "transaction_type",
      valueGetter: (params: any) => {
        if(params?.data?.transaction_type == "card" || params?.data?.transaction_type == "Card")
        {
          return "Bank"
        }else{
          return params?.data?.transaction_type
        }

        // return params?.data?.date_of_birth;
      },
    },
 
    {
      headerName: "Amount",
      field: "price",
    },
    {
      headerName: "Vat",
      field: "vat",
      valueGetter: (params: any) => {
        return ((params?.data?.vat * 0.01) * params?.data?.price).toFixed(2);

        // return params?.data?.date_of_birth;
      },
    },
    {
      headerName: "Service Name",
      field: "service_name",
    },
    {
      headerName: "Branch",
      field: "branch",
    },
    
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <>

          <Link to={`/admin/booking/details/add?booking_id=${params?.data?.booking_id}&uuid=${params?.data?.booking_detail_id}`}><VisibilityIcon /></Link>

          {params?.data?.booking_status_id == 1 &&
            <Link to={`/admin/push-notification/add?customer_id=${params?.data?.customer_id}`}><NotificationsIcon /></Link>
          }
        </>
      }
    },
    {
      headerName: "Reordered?",
      field: "reordered",
    },
    {
      headerName: "Reordered Info",
      field: "reordered_info",
    },
    {
      headerName: "Customer Last Name",
      field: "customer_LastName",

    },
   
    {
      headerName: "Customer Address",
      field: "customer_adress",
    },
    {
      headerName: "City",
      field: "city",
    },
    {
      headerName: "Country",
      field: "country",
    },
   
    {
      headerName: "Package Name",
      field: "package_name",
    },
    {
      headerName: "Promotion Name",
      field: "promotion_name",
    },
    {
      headerName: "Promotion Code",
      field: "promotion_code",
    },
    {
      headerName: "Vehicle Brand",
      field: "vehicle_brand",
    },

    {
      headerName: "Vehicle Model",
      field: "vehicle_model",
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
    },
 
    {
      headerName: "Driver Last Name",
      field: "driver_LastName"
    },
 
   
   
 
   

  ]
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()

  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Booking"
            routes={routes}
          // newItem={[{ text: 'Add New Booking', link: "booking/add" }]}
          // permission="Booking.Booking.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Booking List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>


          </CardHeader>
          <CardBody>
            <Button variant="outlined" color="primary" className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              disabledCheckBox={true}
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
``
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useFieldArray, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
// import { HeaderValidation } from "./validations/header-validation";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const RepeaterSectionAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `repeater_array`,
  });
  //   useEffect(() => {
  //     [1, 2, 3].forEach(() => {
  //       append({
  //         sub_title: "",
  //       });
  //     });
  //   }, []);

  const defaultFormValues: any = {};

  useEffect(() => {
    if (apiData) {
      setValue("repeater_array", apiData[0]?.content_section_2?.repeater_array);
      // console.log(
      //   "repeater_array",
      //   apiData[0]?.content_section_2.repeater_array
      // );
    }
  }, [apiData]);

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Repeater Section</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index) => {
          //   console.log("item", item);
          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={4} md={4} sm={4} style={{ marginTop: "07px" }}>
                <Box
                  style={{ display: "flex", alignItems: "center" }}
                  alignItems="center"
                >
                  <FileUploadFieldArray
                    fieldName={`repeater_array[${index}].logo`}
                    keyss={`repeater_array[${index}.logo]`}
                    open={dropOpen}
                    index={index}
                    setOpen={setDropOpen}
                    lable={"Image"}
                    setValue={props?.setValue}
                    getValuesX={getValues}
                    control={props?.control}
                    disabled={disabled}
                    message={
                      "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                    }
                  />
                </Box>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.repeater_array?.[index]?.title}
                  fieldName={`repeater_array[${index}].title`}
                  type="text"
                  label="* Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.repeater_array?.[index]?.title &&
                    errors?.repeater_array?.[index]?.title.message
                  }
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.repeater_array?.[index]?.description}
                  fieldName={`repeater_array[${index}].description`}
                  type="text"
                  label="* Description"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.repeater_array?.[index]?.description &&
                    errors?.repeater_array?.[index]?.description.message
                  }
                />
              </GridItem>
            </GridContainer>
          );
        })}
      </AccordionDetails>
     
      <Button
            style={{ marginLeft: "15px" }}
            variant="contained"
            color="primary"
            className={"custom-btn"}
            onClick={() =>
              append({ title: "", description: "", logo: "", call_to_action: "" })
            }
          >
            Add more
          </Button>
      <AccordionActions></AccordionActions>
    </ControlledAccordion>
  );
};

export const verifyPermission:any = (permissionArray:any, section:any, subSection:any, specific:any) => {
   if(permissionArray){
    let result =  permissionArray.find((item:any )=> {
        return item.sectionName == section &&  item.subsection == subSection && item.specific == specific} )
   
    if(result){
        return result.permission;
    }else{
        return false;
    }
   }
   else{
       return false;
   }
}
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Avatar,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
// import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const TestimonialsAccordion = (props: any) => {
  const classes = useStyles();
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [disabled, setDisabled] = useState<any>(false);

  const { collapse, apiData, control, errors, setValue, getValues } = props;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `testimonials_array`,
  });

  useEffect(() => {
    if (apiData) {
      setValue(
        "testimonials_array",
        apiData?.page_content?.testimonials?.testimonials_array
      );
    }
  }, [apiData]);

  useEffect(() => {
    [1, 2, 3, 4].forEach(() => {
      append({
        logo: "",
        no_of_stars: "",
        name: "",
        description: "",
      });
    });
  }, []);

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Testimonials</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer spacing={3} style={{ flexGrow: 1 }}>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.testimonials_title}
              fieldName="testimonials_title"
              type="text"
              autoComplete="false"
              label="* Title "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.testimonials_title &&
                errors?.testimonials_title?.message
              }
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.testimonials_sub_title}
              fieldName="testimonials_sub_title"
              type="text"
              autoComplete="false"
              label="* Sub Title "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.testimonials_sub_title &&
                errors?.testimonials_sub_title?.message
              }
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails>

      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index) => {
          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={3} md={3} sm={3} style={{ marginTop: "07px" }}>
                {/* <input {...register("logo")} type="hidden" /> */}
                <Box
                  style={{ display: "flex", alignItems: "center" }}
                  alignItems="center"
                >
                  <FileUploadFieldArray
                    fieldName={`testimonials_array[${index}].logo`}
                    keyss={`testimonials_array[${index}.logo]`}
                    open={dropOpen}
                    index={index}
                    lable={"Image"}
                    setOpen={setDropOpen}
                    setValue={props?.setValue}
                    getValuesX={getValues}
                    control={props?.control}
                    disabled={disabled}
                    message={
                      "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                    }
                  />
                </Box>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} style={{marginTop:"30px"}}>
                <InputField
                  errors={!!errors?.testimonials_array?.[index]?.name}
                  fieldName={`testimonials_array[${index}].name`}
                  type="text"
                  label="* Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.testimonials_array?.[index]?.name &&
                    errors?.testimonials_array?.[index]?.name.message
                  }
                />
              </GridItem>
              <GridItem xs={3} sm={3} md={3} style={{marginTop:"30px"}}>
                <InputField
                  errors={!!errors?.testimonials_array?.[index]?.description}
                  fieldName={`testimonials_array[${index}].description`}
                  type="text"
                  label="* Short Description"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.testimonials_array?.[index]?.description &&
                    errors?.testimonials_array?.[index]?.description.message
                  }
                />
              </GridItem>
              <GridItem xs={3} sm={3} md={3} style={{marginTop:"30px"}}>
                <InputField
                  errors={!!errors?.testimonials_array?.[index]?.no_of_stars}
                  fieldName={`testimonials_array[${index}].no_of_stars`}
                  type="text"
                  label="* number of Stars"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.testimonials_array?.[index]?.no_of_stars &&
                    errors?.testimonials_array?.[index]?.no_of_stars.message
                  }
                />
              </GridItem>
            </GridContainer>
          );
        })}
      </AccordionDetails>
      {/* <Button
        variant="contained"
        color="primary"
        className={"custom-btn"}
        onClick={() => {
          append({ logo: "", no_of_stars: "", name: "", description: "" });
        }}
      >
        Add more
      </Button> */}
      <AccordionActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ width: "100%" }}
        ></Box>
      </AccordionActions>
    </ControlledAccordion>
  );
};

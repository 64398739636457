import React, { useState, useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import { getAlertSetting, updateApplicationSetting } from "services/applicationSettingServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { toast } from "react-toastify";
import { verifyPermission } from 'helper/permission'
import { Box } from "@material-ui/core"
import Button from "components/CustomButtons/Button";
import { AutoCompleteField, InputField } from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { getServices } from 'services/servicesServices'
import { Typography } from "@material-ui/core";
import { AlertForm } from './alertChild.component'






const styles: any = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {

            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

const useStyles = makeStyles(styles);



export const AlertSetting = (props: any) => {
    const classes = useStyles();
    const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
    const [disabled, setDisabled] = useState<any>(false)
    const [collapse, setCollapse] = useState<any>(false)
    const [services, setServices] = useState<any>([])
    const [apiData, setApiData] = useState<any>([])
    const dispatch = useDispatch()
    const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });




    const routes: any = [
        {
            'name': "Admin",
            'link': '/admin'
        },
        {
            'name': "Alert Setting",
            'link': ''
        }
    ];

    const getApiData = () => {
        getServices().then((e) => {
            setServices(e.data.result)
            dispatch(spinLoaderShow(false))
        }).catch((e) => {
            dispatch(spinLoaderShow(false))
        })
        getAlertSetting().then((res) => {
            let data = res?.data?.result
            dispatch(spinLoaderShow(false))
            setApiData(data)

        }).catch((err) => {
            dispatch(spinLoaderShow(false))
        })
    }


    useEffect(() => {
        dispatch(spinLoaderShow(true))
        getApiData()
        let ispermission = !(verifyPermission(
            getPermission,
            'Application Setting',
            'Alert Setting',
            'update'
        ))
        setDisabled(ispermission)
    }, [])


    const toggleAll = () => setCollapse(!collapse);


    const onSubmit = (data: any, key: any) => {
        console.log("dataa", data)

    }


    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={12}>
                    <PageHeader
                        title="Alert Setting"
                        routes={routes}

                    />
                </GridItem>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader color="primary" className="card-header-export-btn">
                        <h4 className={classes.cardTitleWhite}>Alert Setting</h4>
                        {/* <Button variant="outlined" className="custom-btn export-btn" onClick={toggleAll}>{collapse ? "Collapse All" : "Expand All"}</Button> */}

                    </CardHeader>
                    <CardBody>


                        <GridContainer >
                            {apiData && apiData?.sort(function (a:any, b:any) {
                                if (a.alert_name < b.alert_name) { return -1; }
                                if (a.alert_name > b.alert_name) { return 1; }
                                return 0;
                            }).map((item: any, key: any) => {
                                return (<GridItem xs={12} md={12} sm={12}>
                                    <AlertForm apidata={item} services={services} permission={disabled} refresh={getApiData} />

                                </GridItem>)
                            })}
                        </GridContainer>
                    </CardBody>
                    {/* <CardFooter>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            style={{ width: "100%" }}
                        >
                            <Button color="primary" className="custom-btn" disabled={disabled} type="submit" >Update</Button>
                        </Box>
                    </CardFooter> */}
                </Card>
            </GridItem>
        </GridContainer>

    );
}


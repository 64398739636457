import * as yup from 'yup'


const levelDependent: any = {
    'Country': ['country'],
    'City': ['country', 'city'],
    'Branch': ['country', 'city', 'branch'],
    'Zone': ['country', 'city', 'branch', 'zone'],
};

const createServiceConfigValidation: any = yup.object().shape({
    category_id: yup.string().required("Please select service category.").nullable(),
    service_id: yup.string().required("Please select service").nullable(),
    price: yup.number().required("Please enter price ").nullable().typeError('Please enter valid price.'),
    processing_time: yup.number().min(1).required("Please enter processing time ").nullable().typeError('Please enter valid processing time.'),
    // buffer_period: yup.number().min(1).required("Please enter buffer time ").nullable().typeError('Please enter valid buffer time.'),

    vehicle_type_id: yup.string().required("Please select vehicle type").nullable(),
    level_id: yup.object().required("Please select service").nullable(),
    country_id: yup.string().when("level_id", {
        is: function (data: any) {

            if (data !== null && data.name) {
                console.log(data)
                return levelDependent[data.name].includes('country');
            }
            return false;
        },
        then:  yup.string().required('Please select Country').nullable().nullable()
    }).nullable(),

    city_id: yup.mixed().when("level_id", {
        is: function (data: any) {
            if (data !== null && data.name) {
                return levelDependent[data.name].includes('city');
            }
            return false;
        },
        then: yup.mixed().when("uuid",{
            is: "", // alternatively: (val) => val == true
            then:   yup.mixed().when("level_id", {
                is: function (data: any) {
        
                    if (data !== null && data.name) {
                        console.log("asdasdasdas",data.name)
                        return data.name == "City"
                    }
                    return false;
                },
                then:  yup.array().min(1,"Alteast Select One City").required('Please select City').nullable(),
                otherwise: yup.string().required('Please select City').nullable(),
            }),
            otherwise: yup.string().required('Please select City').nullable(),
           
        })
    }).nullable(),
    branch_id: yup.mixed().when("level_id", {
        is: function (data: any) {
            if (data !== null && data.name) {
                return levelDependent[data.name].includes('branch');
            }
            return false;
        },
        then: yup.mixed().when("uuid",{
            is: "", // alternatively: (val) => val == true
            then:   yup.mixed().when("level_id", {
                is: function (data: any) {
        
                    if (data !== null && data.name) {
                    
                        return data.name == "Branch"
                    }
                    return false;
                },
                then:  yup.array().min(1,"Alteast Select One Branch").required('Please select Branch').nullable(),
                otherwise: yup.string().required('Please select Branch').nullable(),
            }),
            otherwise: yup.string().required('Please select Branch').nullable(),

           
        })
    })
    .nullable(),
    zone_id: yup.mixed().when("level_id", {
        is: function (data: any) {
            if (data !== null && data.name) {
                return levelDependent[data.name].includes('zone');
            }
            return false;
        },
        then: yup.mixed().when("uuid",{
            is: "", // alternatively: (val) => val == true
            then:   yup.mixed().when("level_id", {
                is: function (data: any) {
        
                    if (data !== null && data.name) {
                    
                        return data.name == "Zone"
                    }
                    return false;
                },
                then:  yup.array().min(1,"Alteast Select One Branch").required('Please select Branch').nullable(),
                otherwise: yup.string().required('Please select Branch').nullable(),
            }),
            otherwise: yup.string().required('Please select Branch').nullable(),

           
        })
    })
    .nullable(),
    // zone_id: yup.mixed().when("level_id", {
    //     is: function (data: any) {
    //         if (data !== null && data.name) {
    //             return levelDependent[data.name].includes('zone');
    //         }
    //         return false;
    //     },
    //     then: yup.mixed().when("uuid",{
    //         is: "", // alternatively: (val) => val == true
    //         then:   yup.mixed().when("level_id", {
    //             is: function (data: any) {
        
    //                 if (data !== null && data.name) {
                    
    //                     return data.name == "Zone"
    //                 }
    //                 return false;
    //             },
    //             then:  yup.array().min(1,"Alteast Select One Zone").required('Please select Zone').nullable(),
    //             otherwise: yup.string().required('Please select Zone').nullable(),
    //         }),
    //         otherwise: yup.string().required('Please select Zone').nullable(),

           
    //     })
    // }).nullable(),
});

export {
    createServiceConfigValidation
}
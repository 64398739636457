import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { AccordionActions, Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, SwitchFieldDefault, AutoCompleteMultipleField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createServiceValidation } from "./Validations/serviceValidations";
import { getServiceCategory, getServiceTypes, createService, updateService, getServices, serviceFileUpload } from "services/servicesServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';



import {
  BrowserRouter as Router,
  useHistory,
  useLocation
} from "react-router-dom";
import { TextEditorField } from "components/Forms/Formfield.component";
import Typography from "@material-ui/core/Typography";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { FileUploadArea } from 'components/FileUpload/fileuploadArea.component';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';






const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  editor: {
    border: '1px solid gray',
    minHeight: '6em'
  },
  uploadButton: {
    backgroundColor: '#043cae',
    color: '#fff',
    marginLeft: "1em"
  },
  avatar: {
    height: "60px",
    width: "60px"
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain"
  },
  swiperContainer: {
    ".swiper-slide": {
      width: '150px'
    }
  },
  button: {
    width: '100%'
  }
};

const defaultFormValues: any= {
  name: '',
  description: '',
  service_category_id: '',
  // service_type_id: true,
  priority: '',
  status: true,
  is_most_selling:false,
  is_quote:false
}


const useStyles = makeStyles(styles);
declare var google: any;


export default function AddService(props: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState<string>('Add');
  const [serviceCategories, setServiceCategories] = useState<any>([])
  const [editData, setEditData] = useState<any>(null)
  const [services, setServices] = useState<any>([])
  const [servicesUnselected, setServicesUnselected] = useState<any>([])
  const [servicesSelected, setServicesSelected] = useState<any>([])
  const [service, setService] = useState<any>({})
  const [serviceTypes, setServiceTypes] = useState<any>([])
  const [serviceType, setServiceType] = useState<any>(false)
  const [serviceCategoriesLoading, setServiceCategoriesLoading] = useState<any>(false)
  const [pairsDataSelected, setPairsDataSelected] = useState<any>([]);
  const [pairsDataUnselected, setPairsDataUnselected] = useState<any>([]);
  const [imageSlider, setImageSlider] = useState<any>([])
  const [videoSlider, setVideoSlider] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  let history = useHistory();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch()
  const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues, watch } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createServiceValidation)
  });


  const routes: any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Service ",
      'link': '/admin/service'
    },
    {
      'name': headerTitle,
      'link': ''
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];
  const getServiceData = () => {
    getServices({ 'uuid': uuid }).then((e: any) => {
      let data: any = e?.data?.result?.[0];
      setValue('name', data.name)
      setValue('description', data.description)
      setValue('status', data.status)
      setValue('short_description', data.short_description)

      setEditData(data)
      setImageSlider(data?.images ? data?.images : [])
      setVideoSlider(data?.video ? data?.video : [])
      setValue("priority",data?.priority) 
      setValue("is_quote",data?.is_quote)
      // setValue("is_most_selling",data?.is_most_selling)
      // handleServiceCategory({ uuid: data.service_category_id })

      if (data?.pairsData?.length > 0) {
        setServiceType(true)
      }
      let temp: any = new URLSearchParams(search).get("type");
          temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
          temp == 'view' ? setDisabled(true) : setDisabled(false)
      dispatch(spinLoaderShow(false))

    }).catch((err) => {
      dispatch(spinLoaderShow(false))
    })
  }

  useEffect(() => {
    getServiceTypes().then((e: any) => {
      setServiceTypes(e.data.result)
    }).catch((e: any) => {
    })

    getServices({ status: true }).then((e: any) => {
      setServices(e?.data?.result)
      setServicesUnselected(e?.data?.result)
      setServicesSelected(e?.data?.result)
    }).catch((e: any) => {
    })
    getServiceCategory({ status: true }).then((e: any) => {
      setServiceCategories(e.data.result)
    }).catch((e: any) => {
    })


  }, []);

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getServiceData();
    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData(null)
    }
  }, [uuid])


  // save image in s3 and returns its url and save in state
  const handleSaveImage = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append("images", files[i].file);
      }
      const { data } = await serviceFileUpload(formData)
      let temp: any = [];
      data?.result?.map((item: any, index: any) => {
        temp.push(item);
      });
      const mergeTemp = [...imageSlider, ...temp];
      setImageSlider(mergeTemp)
      dispatch(spinLoaderShow(false))
    }

  }

  // save video in s3 and returns its url and save in state
  const handleSaveVideo = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append("images", files[i].file);
      }
      const { data } = await serviceFileUpload(formData)
      let temp: any = [];
      data?.result?.map((item: any, index: any) => {
        temp.push(item);
      });
      const mergeTemp = [...videoSlider, ...temp];
      setVideoSlider(mergeTemp)
      dispatch(spinLoaderShow(false))
    }
  }


  const onSubmit = async (data: any) => {

    let temp: any = [];
    if(data?.pairs &&  data?.pairs?.length > 0 ){
      data?.pairs?.map((item: any) => {
        temp.push({
          pair_service_id: item.uuid,
          type: 'selected',
          status: true,
        })
      })
    }  
    if(data?.unselect_pairs &&  data?.unselect_pairs?.length > 0 ){
      data?.unselect_pairs?.map((item: any) => {
        temp.push({
          pair_service_id: item.uuid,
          type: 'deselected',
          status: true,
        })
      })
    }
   

    if (editData) {


      editData?.pairsData?.length > 0 && editData.pairsData.filter((item: any) => item.type == 'deselected' && item.pair_status).filter((service: any) => {
        return !data?.unselect_pairs.find((item: any) => service.uuid == item.uuid);
      }).map((item: any) => {
        temp.push({
          pair_service_id: item.uuid,
          type: 'deselected',
          pair_status: false,
          status: false
        })
        return item;
      })
      editData?.pairsData?.length > 0 && editData.pairsData.filter((item: any) => item.type == 'selected' && item.pair_status).filter((service: any) => {
        return !data?.pairs.find((item: any) => service.uuid == item.uuid);
      }).map((item: any) => {
        temp.push({
          pair_service_id: item.uuid,
          type: 'selected',
          pair_status: false,
          status: false
        })
        return item;
      })

      console.log("TEEM", temp)
      const duplicateIds = temp
      .map((v:any) => v.pair_service_id)
      .filter((v:any, i:any, vIds:any) => vIds.indexOf(v) !== i)
      let arr = []
   
      console.log("TEEM2", duplicateIds)
      duplicateIds.map((item:any) =>{
       let indexToRemove = temp.findIndex((x:any) =>  x.pair_service_id == item && x.status == false);
       console.log("Remove1", indexToRemove,item)

       if (indexToRemove > -1) {
         console.log("Remove", indexToRemove)
        temp.splice(indexToRemove, 1); // 2nd parameter means remove one item only
      }



      })

     
      // console.log("TEEM3", temp)


      // return;
    }



    // data.un_pairs = unSelectTemp;
    delete data.unselect_pairs

    let modifyData: any = {
      name: data?.name,
      description: data?.description,
      status: data?.status,
      service_category_id: data?.service_category_id,
      pairs: temp,
      images: imageSlider,
      video: videoSlider,
      priority:data?.priority,
      short_description:data?.short_description,
      is_quote:data?.is_quote
      // is_most_selling:data?.is_most_selling
    }

    dispatch(spinLoaderShow(true));
    if (uuid) {
      modifyData.uuid = uuid;
      updateOnSubmit(modifyData);
    } else {
      createOnSubmit(modifyData);
    }

  };

  const createOnSubmit = (data: any) => {
    createService(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        history.push("/admin/service");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {

    updateService(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/service");

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };

  // const handleTypeOnChange = (data: any) => {

  //   if (data) {
  //     console.log("abcd..", data)
  //     setServiceType(data?.validation == 'pair');
  //   } else {
  //     setServiceType(false);
  //   }

  // }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setService({})
    setOpen(false);
  };

  const handelServiceChange = (data: any) => {
    setService(data)
  }

  useEffect(() => {
    if (editData) {
      //handleServiceSelectOnChange(editData?.pairsData?.filter((item: any) => item.type == 'selected' && item.pair_status))
      setPairsDataSelected(editData?.pairsData?.filter((item: any) => item.type == 'selected' && item.pair_status))

      // handleServiceUnSelectOnChange(editData?.pairsData?.filter((item: any) => item.type == 'deselected' && item.pair_status))
      setPairsDataUnselected(editData?.pairsData?.filter((item: any) => item.type == 'deselected' && item.pair_status))
    }

  }, [editData])

  // const handleServiceCategory = (data: any) => {

  //   setServicesSelected([])
  //   setServicesUnselected([])
  //   setPairsDataSelected([])
  //   setPairsDataUnselected([])
  //   if (data?.uuid) {
  //     setServiceCategoriesLoading(false)
  //     getServices({ status: true, service_category_id: data.uuid }).then((e: any) => {
  //       setServices(e.data.result)
  //       setServicesUnselected(e.data.result)
  //       setServicesSelected(e.data.result)
  //       setServiceCategoriesLoading(true)

  //     }).catch((e: any) => {
  //       console.log(e)
  //     })
  //   } else {
  //     setServices([])
  //     setServicesSelected([])
  //     setServicesUnselected([])
  //     setPairsDataSelected([])
  //     setPairsDataUnselected([])
  //   }


  // }

  const handleServiceSelectOnChange = (data: any) => {

    if (data && data.length > 0) {
      const selected_ = services.length > 0 && services?.filter((service: any) => {
        return !data.find((item: any) => service.uuid === item.uuid);
      })
      setServicesUnselected(selected_)
    } else {
      setServicesUnselected(services)
    }

  }

  const handleServiceUnSelectOnChange = (data: any) => {

    if (data && data.length > 0) {
      const selected_ = services.length > 0 && services.filter((service: any) => {
        return !data.find((item: any) => service.uuid === item.uuid);
      })

      setServicesSelected(selected_)
    } else {
      setServicesSelected(services)
    }

  }


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Service "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city-manager/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/service" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Service</h4>
                  {editData ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null } 
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer spacing={3}>
                      <GridItem xs={12} sm={12} md={4}>
                        <InputField
                          errors={!!errors?.name}
                          fieldName="name"
                          type="text"
                          autoComplete='off'
                          label="* Name"
                          control={control}
                          iProps={{
                            disabled: disabled
                          }}
                          valueGot={""}
                          setValue={setValue}
                          helperText={errors?.name && errors?.name?.message}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <AutoCompleteField
                          errors={!!errors?.service_category_id}
                          fieldName="service_category_id"
                          autoComplete='off'
                          label="* Select Category"
                          control={control}
                          setValue={setValue}
                          options={serviceCategories}
                          optionKey="uuid"
                          iProps={{
                            disabled: disabled
                          }}
                          isLoading={serviceCategories?.length > 0 ? true : false}
                          optionValue="name"
                          helperText={errors?.service_category_id && errors?.service_category_id?.message}
                          valueGot={editData?.category && editData?.category}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={4}>
                        <AutoCompleteField
                          errors={!!errors?.service_type_id}
                          fieldName="service_type_id"
                          autoComplete='off'
                          label="* Select Type"
                          control={control}
                          setValue={setValue}
                          options={serviceTypes}
                          optionKey="uuid"
                          iProps={{
                            onChange: handleTypeOnChange,
                          }}
                          returnObject={true}
                          isLoading={serviceTypes?.length > 0 ? true : false}
                          optionValue="name"
                          helperText={errors?.service_type_id && errors?.service_type_id?.message}
                          valueGot={editData?.type && editData?.type}
                        />

                      </GridItem> */}
                      {/* {serviceType && */}
                      <>
                        <GridItem xs={12} sm={12} md={4}>
                          <AutoCompleteMultipleField
                            errors={!!errors?.pairs}
                            fieldName="pairs"
                            autoComplete='off'
                            label="Auto Selected Services"
                            control={control}
                            setValue={setValue}
                            options={servicesSelected?.filter((item: any) => item.uuid !== uuid)}
                            optionKey="uuid"
                            iProps={{
                              onChange: handleServiceSelectOnChange,
                              disabled: disabled
                            }}
                            isLoading={true}
                            optionValue="name"
                            helperText={errors?.pairs && errors?.pairs?.message}
                            valueGot={pairsDataSelected}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <AutoCompleteMultipleField
                            errors={!!errors?.unselect_pairs}
                            fieldName="unselect_pairs"
                            autoComplete='off'
                            label="UnSelected Service"
                            control={control}
                            setValue={setValue}
                            options={servicesUnselected.filter((item: any) => item.uuid !== uuid)}
                            optionKey="uuid"
                            iProps={{
                              onChange: handleServiceUnSelectOnChange,
                              disabled: disabled
                            }}
                            isLoading={true}
                            optionValue="name"
                            helperText={errors?.unselect_pairs && errors?.unselect_pairs?.message}
                            valueGot={pairsDataUnselected}
                          />
                        </GridItem>
                      </>
                      {/* } */}
                      {/* <GridItem xs={4} md={4} sm={4} style={{ marginTop: "06px" }}>
                <input {...register("images")} type="hidden" />

                  <Box style={{display:"flex",alignItems:"center"}}>
                  <Avatar alt="Remy Sharp" src={imageUrl} className={classes.avatar} />
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.uploadButton}
                      onClick={() => setImageOpen(true)}
                    >
                   Image
                    </Button>
                    <FileUploadArea  open={imageOpen} setOpen={setImageOpen} handleSave={handleSaveImage}/>
                    </Box>     
                  </GridItem> */}
                   <GridItem xs={12} sm={12} md={4}>
                   <InputField
                      errors={!!errors?.priority}
                      fieldName="priority"
                      type="text"
                      autoComplete='off'
                      label="* Priority"
                      control={control}
                      iProps={
                        {
                          type: "number",
                          disabled: disabled
                        }
                      }
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.priority && errors?.priority?.message}

                    />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <InputField
                          errors={!!errors?.short_description}
                          fieldName="short_description"
                          type="text"
                          autoComplete='off'
                          label="Short Description"
                          control={control}
                          iProps={{
                            disabled: disabled
                          }}
                          valueGot={""}
                          setValue={setValue}
                          helperText={errors?.short_description && errors?.short_description?.message}
                        />
                      </GridItem>

                      <GridItem xs={1} sm={1} md={1} style={{ marginTop: "15px" }}>
                        <SwitchFieldDefault
                          errors={!!errors?.status}
                          fieldName="status"
                          autoComplete="off"
                          label="Status"
                          control={control}
                          isLoading={true}
                          disabled={disabled}
                          setValue={setValue}
                          helperText={errors?.status && errors?.status?.message}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3} style={{ marginTop: "15px" }}>
                        <SwitchFieldDefault
                          errors={!!errors?.is_quote}
                          fieldName="is_quote"
                          autoComplete="off"
                          label="Is Quote"
                          control={control}
                          isLoading={true}
                          // disabled={disabled}
                          setValue={setValue}
                          helperText={errors?.is_quote && errors?.is_quote?.message}
                        />
                      </GridItem>
                      {/* <GridItem xs={12} sm={12} md={2} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.is_most_selling}
                      fieldName="is_most_selling"
                      autoComplete="off"
                      label="Is Most Selling"
                      control={control}
                      isLoading={true}
                      disabled={disabled}
                      setValue={setValue}
                      helperText={errors?.is_most_selling && errors?.is_most_selling?.message}
                    />
                  </GridItem> */}
                    </GridContainer>
                  </GridItem>
                  
                  <GridItem xs={12} className={"mt-15"} >
                    <Typography> Service Description: </Typography>
                    <TextEditorField
                      className={"mt-15"}
                      errors={!!errors?.description}
                      textareaName="description"
                      fieldName="description"
                      type="text"
                      autoComplete="false"
                      label="Description"
                      control={control}
                      isLoading={true}
                       disabled={disabled}
                      valueGot={editData?.description}
                      setValue={setValue}
                      helperText={errors?.description && errors?.description?.message}
                    />
                  </GridItem>

                  {/* <GridItem xs={4} md={4} sm={4} style={{ marginTop: "06px" }}>
                <input {...register("service_video")} type="hidden" />
       
                  <Box style={{display:"flex",alignItems:"center"}}>
    
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.uploadButton}
                      onClick={() => setVideoOpen(true)}
                    >
                   Video
                    </Button>
                    <FileUploadArea  open={videoOpen} setOpen={setVideoOpen} handleSave={handleSaveVideo}/>
                    </Box>     
                  </GridItem> */}
                  <GridItem>

                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Add Images</Typography>
                      </AccordionSummary>
                      <AccordionDetails>

                        <GridItem xs={12} md={12} sm={12} className="swiperContainer">
                          {/* Swiper */}
                          <Swiper

                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView='auto'
                            navigation
                          >
                            {
                              // swiper map
                              imageSlider?.map((item: any, index: any) => {
                                return <SwiperSlide>
                                  <Box>
                                    <img src={item} className={classes.img} />
                                    <Button
                                      variant="outlined"
                                      className={classes.button}
                                      startIcon={<DeleteIcon />}
                                      disabled={disabled}
                                      onClick={
                                        () => {
                                          const deletImg = imageSlider.filter((fileObjects: any, i: any) => index !== i);
                                          setImageSlider(deletImg)}
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </SwiperSlide>
                              })
                            }

                          </Swiper>
                        </GridItem>


                      </AccordionDetails>
                      <AccordionActions>
                        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>

                  {disabled == false ? <FileUploadArea limit={80000000} handleSave={handleSaveImage} /> : null }        
                        </Box>
                      </AccordionActions>
                    </Accordion>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Add Video</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} md={12} sm={12} className="swiperContainer">
                          {/* Swiper */}
                          <Swiper

                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView='auto'
                            navigation
                          >
                            {
                              // swiper map
                              videoSlider?.map((item: any, index: any) => {
                                return <SwiperSlide>
                                  <Box>
                                    < CardMedia
                                      component="video"
                                      autoPlay
                                      controls
                                      src={item}
                                      className={classes.img}
                                    />
                                    <Button
                                      variant="outlined"
                                      className={classes.button}
                                      startIcon={<DeleteIcon />}
                                      disabled={disabled}
                                      onClick={
                                        () => {
                                          const deletImg = videoSlider?.filter((fileObjects: any, i: any) => index !== i);
                                          setVideoSlider(deletImg)
                                        }
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </SwiperSlide>
                              })
                            }

                          </Swiper>
                        </GridItem>
                      </AccordionDetails>
                      <AccordionActions>
                        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                     {disabled == false ? <FileUploadArea video={['video/*']} message={"Only mp4 is allowed.The maximum size is 10mb"} handleSave={handleSaveVideo} /> : null }     
                        </Box>
                      </AccordionActions>
                    </Accordion>
                  </GridItem>

                </GridContainer>

                {/* {
                  serviceType?.validation == 'pair' &&
                  <>
                    <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                      Open form dialog
                    </Button>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Service Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pairs.map((row: any) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                } */}
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} type="submit" >{uuid ? 'Update Service' : "Create Service"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>

      {
        serviceType?.validation == 'pair' &&
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Select Service</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <AutoCompleteField
                errors={!!errors?.services}
                fieldName="services"
                autoComplete='off'
                label="* Select Service"
                control={control}
                setValue={setValue}
                options={serviceCategories}
                optionKey="uuid"
                isLoading={services?.length > 0 ? true : false}
                optionValue="name"
                iProps={{
                  onChange: handelServiceChange
                }}
                helperText={errors?.services && errors?.services?.message}
                valueGot={Object.keys(service).length > 0 && service}
              />
            </DialogContentText>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleClose} color="primary">
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { PermIdentity, MailOutline, LocationSearching, CalendarToday, PhoneAndroid, Message } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import CardHeader from "components/Card/CardHeader";
import CardContent from '@material-ui/core/CardContent';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PageHeader from "components/PageHeader/PageHeader";
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router';
import { spinLoaderShow } from 'redux/action/spinLoader';
import {getContactus} from "services/userservices";
import moment from 'moment'
import { Box } from "@material-ui/core";



const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const routes = [
  {
    'name': "Admin",
    'link': '/admin'
  },
  {
    'name': "Contact Us ",
    'link': ''
  }
];

export default function ContactView() {
  const dispatch = useDispatch()

  const [contactData, setContactData] = useState<any>({})

  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");


    const getApiData = () =>{
        getContactus({uuid:uuid}).then((res:any)=>{
       let data:any =   res?.data?.result[0]
       setContactData(data)
       dispatch(spinLoaderShow(false))
        }).catch((err)=>{
        })
    }


  useEffect(() => {
    dispatch(spinLoaderShow(true));
    getApiData()
  }, [])
  return (
    <>
      <PageHeader
        title={`Contact Us`}
        routes={routes}
        newItem={[{ text: 'Back', link: "/admin/contactus" }]}
      />
      <Card>
        <CardContent>


          <List>


            <ListItem button>
              <ListItemIcon>
                <PermIdentity />
              </ListItemIcon>
              <ListItemText primary={contactData.first_name + " " + contactData.last_name} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <MailOutline />
              </ListItemIcon>
              <ListItemText primary={contactData.email} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <PhoneAndroid />
              </ListItemIcon>
              <ListItemText primary={contactData.phone} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText primary={contactData.message} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText primary={contactData.reason_message} />
            </ListItem>

            <ListItem button>
              <ListItemIcon>
                <CalendarToday />
              </ListItemIcon>
              <ListItemText primary={moment(contactData.updatedAt).format('llll')} />
            </ListItem>
          </List>
        </CardContent>
      </Card></>

  )
}



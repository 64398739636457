import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { Box, Divider } from "@material-ui/core";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, SwitchField, SwitchFieldDefault, AutoCompleteMultipleField,DateField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createServiceConfigValidation } from "./Validations/serviceConfigValidations";
import { getServiceCategory, getServiceTypes, createService, updateService, getServices, createServiceConfig, updateServiceConfig, getServiceConfig } from "services/servicesServices";
import { getCountry, getCity, getBranch, getZone,getLevels} from "services/locationservices"
import {getVehicleType} from 'services/vehiclesServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import {CREATE_SUCCESS_MSG} from 'json/constants'
import {UPDATE_SUCCESS_MSG} from 'json/constants'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import moment from "moment";


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const levelDependent: any = {
  'Country': ['country'],
  'City': ['country', 'city'],
  'Branch': ['country', 'city', 'branch'],
  'Zone': ['country', 'city', 'branch', 'zone'],
};

const defaultFormValues: any = {
  service_id: '',
  price: '',
  level_id: '',
  level_type: '',
  vehicle_type_id: '',
  currency_code: "",
  processing_time: '',
  status: true,
  is_most_selling:false
}

const useStyles = makeStyles(styles);

export default function AddServiceConfig(props: any) {
  const classes = useStyles();
  const [headerTitle, setHeaderTitle] = useState<string>('Add');
  const [editData, setEditData] = useState<any>(null)
  const [isEdit, setIsEdit] = useState<any>(false)
  const [levels, setLevels] = useState<any>([])
  const [services, setServices] = useState<any>([])
  const [vehicleTypes, setVehicleTypes] = useState<any>([])
  const [serviceType, setServiceType] = useState<any>({})
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [branch, setBranch] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [zone, setZone] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);
  const [servicesLoader, setServicesLoader] = useState<any>(true);
  const [service, setService] = useState<any>({});
  const [level, setLevel] = useState<any>({});
  const [view ,setView] =useState<any>(false);
  const [levelType,setLevelType] = useState<any>(null);
  let history = useHistory();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch()
  const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues, watch} = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createServiceConfigValidation)
  });
  const routes: any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Service Config",
      'link': '/admin/service-config'
    },
    {
      'name': headerTitle,
      'link': ''
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];
  const processngTimeVal = watch("processing_time")
  const getServiceConfigData = () => {
    getServiceConfig({ 'uuid': uuid }).then((e) => {
      let data: any = e?.data?.result?.[0];
      setEditData(data)
      setValue('price', data.price)
      setValue('status', data.status)
      setValue('processing_time', data.processing_time)
      // setValue('buffer_period', data.buffer_period)
      setValue("uuid",data.uuid)
      setLevelValidationShow(levelDependent[data.level_type])
      setService(data.service)
      setLevel({ name: data?.level_type, uuid: data?.level_id })
      setValue("is_most_selling",data?.is_most_selling)
      let temp: any = new URLSearchParams(search).get("type");
          temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
          temp == 'view' ? setView(true) : setView(false)
      setIsEdit(true)
      dispatch(spinLoaderShow(false))

    })
  }

  useEffect(() => {
    getServiceCategory({ status: true }).then((e) => {
      setCategories(e.data.result)
    }).catch((e) => {
    })
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {
    })
    getLevels().then((e) => {
      setLevels(e.data.result.filter((x:any)=> x.name !="Zone"))
    }).catch((e) => {
    })
    getVehicleType().then((e) => {
      setVehicleTypes(e.data.result)
    }).catch((e) => {
    })

  }, []);

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getServiceConfigData();
    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData(null)
    }
  }, [uuid])

  useEffect(() => {


    if (editData) {

      getServicesByCategory({ uuid: editData?.service?.service_category_id })
      setService(editData.service)

      let related_value = editData?.related_value?.[0];
      let level_type = editData?.level_type;

      if (level_type == 'Zone') {


        let country_id = related_value?.branch?.city?.country_id
        getCitiesByCountry({ uuid: country_id })
        setCity(related_value?.branch?.city)

        let city_id = related_value?.branch?.city_id
        getBranchesByCity({ uuid: city_id })
        setBranch(related_value?.branch)

        let branch_id = related_value?.branch_id
        getZonesByBranch({ uuid: branch_id })
        setZone(related_value)

        setValue('currency_code', related_value?.branch?.city?.country?.currency)
        setCountry(related_value?.branch?.city?.country)


      } else if (level_type == 'Branch') {


        let country_id = related_value?.city?.country_id
        getCitiesByCountry({ uuid: country_id })
        setCity(related_value?.city)

        let city_id = related_value?.city_id
        getBranchesByCity({ uuid: city_id })
        setBranch(related_value)

        setValue('currency_code', related_value?.city?.country?.currency)
        setCountry(related_value?.city?.country)


      } else if (level_type == 'City') {

        let country_id = related_value?.country_id
        getCitiesByCountry({ uuid: country_id })
        setCity(related_value)
        setValue('currency_code', related_value?.country?.currency)
        setCountry(related_value?.country)

      } else if (level_type == 'Country') {

        setCountry(related_value)
        setValue('currency_code', related_value?.currency)
      }

    }

  }, [editData])

  const onSubmit = async (data: any) => {
    console.log("data", data)

    let dataModify: any = {}
    dataModify.service_id = data.service_id
    dataModify.price = parseFloat(data.price).toFixed(2)
    if (data.level_id.name == 'Country') {
      dataModify.level_id = data.country_id
    } else if (data.level_id.name == 'City') {
      dataModify.level_id = uuid  ? data.city_id :   data.city_id.map((item:any) => item.uuid)
    } else if (data.level_id.name == 'Branch') {
      dataModify.level_id =  uuid  ? data.branch_id :data.branch_id.map((item:any) => item.uuid)
    } else if (data.level_id.name == 'Zone') {
      dataModify.level_id =  uuid  ? data.zone_id : data.zone_id.map((item:any) => item.uuid)
    }
    
    if(+(data.processing_time) % 5 != 0){
      data.processing_time = Math.ceil(+(data.processing_time)/5)*5;
      setValue("processing_time",data.processing_time)
      toast.info("Processing Time Rounded Up to next 5th which is " + data.processing_time)
    }
    // if(+(data.buffer_period) % 5 != 0){
    //   data.buffer_period = Math.ceil(+(data.buffer_period)/5)*5;
    //   setValue("buffer_period",data.buffer_period)
    //   toast.info("Buffer Time Rounded Up to next 5th which is " + data.buffer_period)
    // }
    // return;
    dataModify.level_type = data.level_id.name
    dataModify.service_id = data.service_id
    dataModify.vehicle_type_id = data.vehicle_type_id
    dataModify.status = data.status
    dataModify.processing_time = data.processing_time
    dataModify.is_most_selling = data?.is_most_selling
    // dataModify.buffer_period = +(data.buffer_period)

    dispatch(spinLoaderShow(true));
    if (uuid) {
      dataModify.uuid = uuid;
      updateOnSubmit(dataModify);
    } else {
      createOnSubmit(dataModify);
    }
  };

  const createOnSubmit = (data: any) => {

    createServiceConfig(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        history.push("/admin/service-config");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {

    updateServiceConfig(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/service-config");

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };

  const handleTypeOnChange = (data: any) => {
    setServiceType(data)
  }

  const getCitiesByCountry = (data: any = null) => {
    setCities([])
    setBranches([])
    setZones([])
    setCity([])
    setBranch([])
    setZone([])
    
    if (data?.uuid) {
          setCountry(data)
          
      setValue('currency_code', data?.currency)
      dispatch(spinLoaderShow(true))
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
          setIsEdit(false)
          dispatch(spinLoaderShow(false))
        }).catch((err)=>{
          console.log(err)
          dispatch(spinLoaderShow(false))
        })
    }
  }

  const getBranchesByCity = (data: any = null) => {
    setBranches([])
    setZones([])
    setBranch([])
    setZone([])

    if (data?.uuid) {
      dispatch(spinLoaderShow(true))
      getBranch({ city_id: data?.uuid, status: true }).then((e) => {
        setBranches(e.data.result)
        dispatch(spinLoaderShow(false))
      }).catch((e) => {
        dispatch(spinLoaderShow(false))

      })
    }

  }

  const getZonesByBranch = (data: any = null) => {
    setZones([])
    setZone([])
    if (data?.uuid) {
      dispatch(spinLoaderShow(true))

      getZone({ branch_id: data?.uuid, status: true }).then((e) => {
        setZones(e.data.result)
        dispatch(spinLoaderShow(false))

      }).catch((e) => {
        dispatch(spinLoaderShow(false))

      })
    }

  }
  const changeZone = (data:any) =>{
    if(data){
      setZone(data)

    }else{
      setZone(null)
    }

  }

  const handleConfigLevel = (data: any) => {
    setValue('currency_code', '')
    setCities([])
    setBranches([])
    setZones([])
    setCity([])
    setBranch([])
    setZone([])
    setCountry({})
    setLevel(data)
    setValue('price', '')
    setLevelValidationShow(levelDependent[data?.name])
    setLevelType(data.id)
  }

  const getServicesByCategory = (data: any) => {
    setServices([])
    setService({})
    
    if (data) {
      dispatch(spinLoaderShow(true))
      let service_category_id = { service_category_id: data?.uuid , status:true}
      getServices(service_category_id).then((e) => {
        setServices(e.data.result)
        setServicesLoader(true)
        dispatch(spinLoaderShow(false))
      }).catch((e) => {
        dispatch(spinLoaderShow(false))
      })
    }
    

  }
 
  
  // useEffect(()=>{
  //   if(processngTimeVal){
  //     // console.log("Value GET",processngTimeVal, ( Math.ceil(+(processngTimeVal)/5)*5) )
  //     setValue( "processing_time",Math.ceil(+(processngTimeVal)/5)*5)
  //   }
  // },[processngTimeVal])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Service Config"
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city-manager/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/service-config" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Service Config</h4>
                  {editData ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null } 
                </Box>
              </CardHeader>
              <CardBody>
                <input type="hidden"  value={editData?.uuid ? editData.uuid : ""} {...register("uuid")} />
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={3}>
                    <AutoCompleteField
                      errors={!!errors?.category_id}
                      fieldName="category_id"
                      autoComplete='off'
                      label="* Select Category"
                      control={control}
                      setValue={setValue}
                      options={categories}
                      iProps={{
                        onChange: getServicesByCategory,
                        disabled: !!uuid
                      }}
                      optionKey="uuid"
                      isLoading={categories?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.category_id && errors?.category_id?.message}
                      valueGot={editData?.service?.service_category_id && categories.find(({ uuid }: any) => uuid == editData?.service?.service_category_id)}
                    />

                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <AutoCompleteField
                      errors={!!errors?.service_id}
                      fieldName="service_id"
                      autoComplete='off'
                      label="* Select Service"
                      control={control}
                      setValue={setValue}
                      iProps={{
                        onChange: handleTypeOnChange,
                        disabled: !!uuid
                      }}
                      options={services}
                      optionKey="uuid"
                      isLoading={servicesLoader}
                      optionValue="name"
                      helperText={errors?.service_id && errors?.service_id?.message}
                      valueGot={service}
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <AutoCompleteField
                      errors={!!errors?.vehicle_type_id}
                      fieldName="vehicle_type_id"
                      autoComplete='off'
                      label="* Select Vehicle Type"
                      control={control}
                      setValue={setValue}
                      options={vehicleTypes}
                      optionKey="uuid"
                      iProps={{
                        onChange: handleTypeOnChange,
                        disabled: !!uuid
                      }}
                      isLoading={vehicleTypes?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.vehicle_type_id && errors?.vehicle_type_id?.message}
                      valueGot={editData?.vehicle_type && editData?.vehicle_type}
                    />

                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.processing_time}
                      fieldName="processing_time"
                      type="text"
                      autoComplete='off'
                      label="* Processing Time (Min)"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{
                        disabled: view
                      }}
                      helperText={errors?.processing_time && errors?.processing_time?.message}
                    />
                  </GridItem> 
{/* 
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.buffer_period}
                      fieldName="buffer_period"
                      type="text"
                      autoComplete='off'
                      label="* Buffer Time (Min)"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{
                        disabled: view
                      }}
                      helperText={errors?.buffer_period && errors?.buffer_period?.message}
                    />
                  </GridItem>  */}

                  <GridItem xs={12} sm={12} md={3}>
                    <AutoCompleteField
                      errors={!!errors?.level_id}
                      fieldName="level_id"
                      autoComplete='off'
                      label="* Select Level"
                      control={control}
                      setValue={setValue}
                      options={levels}
                      optionKey="id"
                      returnObject={true}
                      iProps={{
                        onChange: handleConfigLevel,
                        disabled: !!uuid
                      }}
                      isLoading={levels?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.level_id && errors?.level_id?.message}
                      valueGot={level}
                    />
                  </GridItem>

                  {levelValidationShow?.includes('country') &&
                    <GridItem xs={12} sm={12} md={3} >
                      <AutoCompleteField
                        errors={!!errors?.country_id}
                        fieldName="country_id"
                        autoComplete='off'
                        label="* Select Country"
                        control={control}
                        setValue={setValue}
                        options={countries}
                        isLoading={countries?.length > 0 ? true : false}
                        optionKey="uuid"
                        iProps={{
                          onChange: getCitiesByCountry,
                          disabled: view || !!uuid
                        }}
                        optionValue="country_name"
                        helperText={errors?.country_id && errors?.country_id?.message}
                        valueGot={country}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('city') &&
                    <GridItem xs={12} sm={12} md={3} >

                      {!editData && levelType == 3 && <AutoCompleteMultipleField
                              errors={!!errors?.city_id}
                              fieldName="city_id"
                              autoComplete='off'
                              label="* Select City"
                              control={control}
                              setValue={setValue}
                              options={cities}
                              isLoading={true}
                              optionKey="uuid"
                              iProps={{
                                onChange: getBranchesByCity,
                                disabled: !!uuid
                              }}
                              optionValue="city_name"
                              helperText={errors?.city_id && errors?.city_id?.message}
                              valueGot={city}
                        />}


                     {(editData || levelType != 3) && <AutoCompleteField
                        errors={!!errors?.city_id}
                        fieldName="city_id"
                        autoComplete='off'
                        label="* Select City"
                        control={control}
                        setValue={setValue}
                        options={cities}
                        isLoading={true}
                        optionKey="uuid"
                        iProps={{
                          onChange: getBranchesByCity,
                          disabled: !!uuid
                        }}
                        optionValue="city_name"
                        helperText={errors?.city_id && errors?.city_id?.message}
                        valueGot={city}
                      />}
                    </GridItem>
                  }
                  {levelValidationShow?.includes('branch') &&
                    <GridItem xs={12} sm={12} md={3} >
                      {!editData && levelType == 1 && <AutoCompleteMultipleField
                        errors={!!errors?.branch_id}
                        fieldName="branch_id"
                        autoComplete='off'
                        label="* Select Branch"
                        
                        iProps={{
                          onChange: getZonesByBranch,
                          disabled: !!uuid
                        }}
                        control={control}
                        setValue={setValue}
                        options={branches}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.branch_id && errors?.branch_id?.message}
                        valueGot={branch}
                        isLoading={true}
                      />}
                      {(editData || levelType != 1) && <AutoCompleteField
                            errors={!!errors?.branch_id}
                            fieldName="branch_id"
                            autoComplete='off'
                            label="* Select Branch"
                            
                            iProps={{
                              onChange: getZonesByBranch,
                              disabled: !!uuid
                            }}
                            control={control}
                            setValue={setValue}
                            options={branches}
                            optionKey="uuid"
                            optionValue="name"
                            helperText={errors?.branch_id && errors?.branch_id?.message}
                            valueGot={branch}
                            isLoading={true}
                      />}


                    </GridItem>
                  }
                  {levelValidationShow?.includes('zone') &&
                    <GridItem xs={12} sm={12} md={3} >
                       {!editData && levelType == 4 && <AutoCompleteMultipleField
                        errors={!!errors?.zone_id}
                        fieldName="zone_id"
                        autoComplete='off'
                        label="* Select Zone"
                        
                        iProps={{
                          onChange: changeZone,
                          disabled: !!uuid
                        }}
                        control={control}
                        setValue={setValue}
                        options={zones}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.zone_id && errors?.zone_id?.message}
                        valueGot={zone}
                        isLoading={true}
                      />}

                        {/* {!editData && levelType == 4 && <AutoCompleteMultipleField
                        errors={!!errors?.zone_id}
                        fieldName="zone_id"
                        autoComplete='off'
                        label="* Select Zone"
                        control={control}
                        setValue={setValue}
                        options={zones}
                        iProps={{
                          disabled: !!uuid
                        }}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.zone_id && errors?.zone_id?.message}
                        valueGot={zone}
                        isLoading={true}
                      />} */}

                      {(editData || levelType != 4) && <AutoCompleteField
                        errors={!!errors?.zone_id}
                        fieldName="zone_id"
                        autoComplete='off'
                        label="* Select Zone"
                        control={control}
                        setValue={setValue}
                        options={zones}
                        iProps={{
                          disabled: !!uuid
                        }}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.zone_id && errors?.zone_id?.message}
                        valueGot={zone}
                        isLoading={true}
                      />}
                    </GridItem>
                  }
                   <GridItem xs={12} sm={12} md={2} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      disabled={view}
                      setValue={setValue}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.is_most_selling}
                      fieldName="is_most_selling"
                      autoComplete="off"
                      label="Is Most Selling"
                      control={control}
                      isLoading={true}
                      disabled={view}
                      setValue={setValue}
                      helperText={errors?.is_most_selling && errors?.is_most_selling?.message}
                    />
                  </GridItem>

                </GridContainer>

                {(country.country_name && levelValidationShow?.includes('country')) && <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <InputField
                      errors={!!errors?.currency_code}
                      fieldName="currency_code"
                      type="text"
                      autoComplete='off'
                      //label="Currency Code"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: true
                      }}
                      setValue={setValue}
                      helperText={errors?.currency_code && errors?.currency_code?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <InputField
                      errors={!!errors?.price}
                      fieldName="price"
                      type="text"
                      autoComplete='off'
                      label="* Price"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: view
                      }}
                      setValue={setValue}
                      helperText={errors?.price && errors?.price?.message}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={2}>
                  <DateField
                      errors={!!errors?.price_start_date}
                      fieldName="price_start_date"
                      autoComplete='off'
                      label="* Price Start Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true
                      }}
                      helperText={errors?.price_start_date && errors?.price_start_date?.message}
                      valueGot={editData?.price_start_date && moment(editData?.price_start_date)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                  <DateField
                      errors={!!errors?.price_end_date}
                      fieldName="price_end_date"
                      autoComplete='off'
                      label="* Price End Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true
                      }}
                      helperText={errors?.price_end_date && errors?.price_end_date?.message}
                      valueGot={editData?.price_end_date && moment(editData?.price_end_date)}
                    />
                  </GridItem> */}
                </GridContainer>}
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={view}  type="submit" >{uuid ? 'Update Service Config' : "Create Service Config"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

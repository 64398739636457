import React,{useImperativeHandle,forwardRef} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AutoCompleteField, SwitchFieldDefault, CheckboxField, InputField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPackageConfigValidation } from "./Validations/packageConfigValidations";
import {  createPackageConfig, updatePackageConfig, getPackages, getPackageConfigs,  } from "services/packageservices";
import {getCountry, getCity, getBranch, getZone,getLevels} from "services/locationservices"
import {getServiceCategory,getServiceConfig} from 'services/servicesServices'
import {getVehicleType} from 'services/vehiclesServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import {
  BrowserRouter as Router,
  useHistory,
  useLocation
} from "react-router-dom";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { UniqueDirectiveNamesRule } from "graphql";


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const levelDependent: any = {
  'Country': ['country'],
  'City': ['country', 'city'],
  'Branch': ['country', 'city', 'branch'],
  'Zone': ['country', 'city', 'branch', 'zone'],
};

const defaultFormValues: any = {
  service_id: '',
  price: '',
  level_id: '',
  level_type: '',
  vehicle_type_id: '',
  currency_code: "",
  status: true,
  discount_type: false,
  configs: [{
    count: 0,
    checked: true
  }]
}

const useStyles = makeStyles(styles);

const AddPackageConfig = forwardRef((props:any, ref:any) => {

  const classes = useStyles();
  var {packageConfig}=props
  const [headerTitle, setHeaderTitle] = useState<string>('Add');
  const [editData, setEditData] = useState<any>(null)
  const [levels, setLevels] = useState<any>([])
  const [serviceConfigs, setServiceConfigs] = useState<any>([])
  const [packages, setPackages] = useState<any>([]);
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [branch, setBranch] = useState<any>('');
  const [city, setCity] = useState<any>('');
  const [zone, setZone] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [selectedServiceConfigs, setSelectedServiceConfigs] = useState<any>([])
  const [discountType, setDiscountType] = useState<any>(false)
  const [subTotalPackageConfig, setSubTotalPackageConfig] = useState<any>(0)
  const [totalPackageConfig, setTotalPackageConfig] = useState<any>(0)
  const [allResults, setAllResults] = useState<any>([])
  const [serviceCategories, setServiceCategories] = useState<any>([])
  const [tempState, setTempState] = useState<any>([])
  const [vehicleTypes, setVehicleTypes] = useState<any>([])
  const [vehicleType, setVehicleType] = useState(null)
  const [selectedCategoryId, setselectedCategoryId] = useState<any>(null)
  const [LevelId, setLevelId] = useState<any>(null)




  let history = useHistory();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch()
  const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues, trigger } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createPackageConfigValidation)
  });


  const routes: any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Package Config",
      'link': '/admin/package-config'
    },
    {
      'name': headerTitle,
      'link': ''
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];
  useEffect(() => {
    getPackages({ status: true }).then((e) => {
      setPackages(e.data.result)
    }).catch((e) => {
    })
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {
    })
    getLevels().then((e) => {
      setLevels(e.data.result)
    }).catch((e) => {
    })
    getServiceCategory({ status: true }).then((e: any) => {
      setServiceCategories(e.data.result)
    }).catch((e: any) => {
    })
    getVehicleType().then((e) => {
      setVehicleTypes(e.data.result)
    }).catch((e) => {
    })


  }, []);

  useEffect(() => {
    if (uuid) {
      // setText("Loading")
      dispatch(spinLoaderShow(true))
      getApiData();
    }
    else {
      setHeaderTitle('Add')
      // setText("No Service Available")
      reset(defaultFormValues)
      setEditData(null)
    }
  }, [uuid])




  useEffect(() => {


    if (editData) {
      let related_value = editData?.serviceConfig?.related_value?.[0];
      let level_type = editData?.serviceConfig?.level_type;
      let level_id = editData?.serviceConfig?.level_id;
      let vehicleTypeId = editData?.serviceConfig?.vehicle_type_id;
      let categoryId = editData?.serviceConfig?.service.service_category_id;

      if (level_type == 'Zone') {

        setCountry(related_value?.branch?.city?.country)

        let country_id = related_value?.branch?.city?.country?.uuid
        getCitiesByCountry({ uuid: country_id })
        setCity(related_value?.branch?.city)

        let city_id = related_value?.branch?.city?.uuid
        getBranchesByCity({ uuid: city_id })
        setBranch(related_value?.branch)

        let branch_id = related_value?.branch_id
        getZonesByBranch({ uuid: branch_id })
        setZone(related_value)

        setValue('currency_code', related_value?.branch?.city?.country?.currency)


      } else if (level_type == 'Branch') {

        setCountry(related_value?.city?.country)

        let country_id = related_value?.city?.country?.uuid
        getCitiesByCountry({ uuid: country_id })
        setCity(related_value?.city)

        let city_id = related_value?.city?.uuid
        getBranchesByCity({ uuid: city_id })
        setBranch(related_value)

        setValue('currency_code', related_value?.city?.country?.currency)


      } else if (level_type == 'City') {

        setCountry(related_value?.country)

        let country_id = related_value?.country_id
        getCitiesByCountry({ uuid: country_id })
        setCity(related_value)

        setValue('currency_code', related_value?.country?.currency)

      } else if (level_type == 'Country') {

        setCountry(related_value)
        setValue('currency_code', related_value?.currency)
      }
      setLevelId(level_id);
      // setselectedCategoryId(categoryId);
      setVehicleType(vehicleTypeId);


      // getServiceConfigData({ uuid: level_id })

    }

  }, [editData])

  useEffect(() => {
    console.log("Change dected");
    getServiceConfigData()
  }, [LevelId, vehicleType])

  useEffect(() => {
    setPackagePrice();
  }, [selectedServiceConfigs])

  useImperativeHandle(ref, () => ({
    // trigger child component onsubmit from parent 
    getAlert() {
      
     return    onSubmit(getValues())
    }

  }));


  const onSubmit =  (data: any) : any=> {
    console.log("submit data", data)
  data = JSON.parse(JSON.stringify(data))
    var dataModify: any = {
      "packageData": {
        // "uuid": data?.package_id,
        "discount_type": (data?.discount_type == false || data?.discount_type == 'false') ? "Fixed" : 'Percentage',
        "price": data?.price
      }
    }
    console.log("modify data", dataModify)

    if (uuid) {
      let configData:any[] = [];
      data?.configs?.length > 0 && data?.configs.map((item: any) => {
        if (item.checked == 'false') {
          item.status = false;
          item.count = 0;
        }
    
       delete item.checked
       delete item.package_id
        // item.service_config_id
       if(allResults.find((x:any) => x.service_config_id == item.service_config_id || item.new ))
       {
        configData.push(item)
       }
     
      });
      dataModify.configs = configData;


      return dataModify;
    } else {
      dataModify.configs = data?.configs?.filter((item: any) => item.checked == 'true').map((item: any) => {
       delete item.checked
       delete item.package_id
  
    
        return item;
      });
      if( dataModify?.configs?.length < 1){
        toast.error("Please Select Services");
        dispatch(spinLoaderShow(false));

        return;
      }else{

return dataModify
      }
    }
  };

  const createOnSubmit = (data: any) => {
    console.log("create data", data)
    createPackageConfig(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        // history.push("/admin/package-config");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    console.log("update on submit", data)
    updatePackageConfig(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        // history.push("/admin/package-config");

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };


  const getCitiesByCountry = (data: any = null) => {
    setCities([])
    setBranches([])
    setZones([])
    setCity({})
    setBranch({})
    setZone({})
    setServiceConfigs([])
    setTempState([])
    setValue('currency_code', data?.currency)
    if (data?.uuid) {
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)

        })
    }
    if (data && levelValidationShow.at(-1) == 'country') {
      setServiceConfigs([])
      setTempState([])
      setLevelId(data.uuid)

      // getServiceConfigData(data);
    }
  }

  const getBranchesByCity = (data: any = null) => {
    setBranches([])
    setZones([])
    setBranch({})
    setZone({})
    setServiceConfigs([])
    setTempState([])
    if (data?.uuid) {
      getBranch({ city_id: data?.uuid, status: true }).then((e) => {
        setBranches(e.data.result)
      }).catch((e) => {
      })
    }


    if (data && levelValidationShow.at(-1) == 'city') {
      setServiceConfigs([])
      setTempState([])
      setLevelId(data.uuid)

      // getServiceConfigData(data);
    }

  }

  const getZonesByBranch = (data: any = null) => {
    setZones([])
    setZone({})
    setServiceConfigs([])
    setTempState([])
    if (data?.uuid) {
      getZone({ branch_id: data?.uuid, status: true }).then((e) => {
        setZones(e.data.result)
      }).catch((e) => {
      })
    }

    if (data && levelValidationShow.at(-1) == 'branch') {
      setServiceConfigs([])
      setTempState([])
      setLevelId(data.uuid)

      // getServiceConfigData(data);
    }

  }

  const handleZoneChange = (data: any) => {

    setServiceConfigs([])
    setTempState([])
    if (data && levelValidationShow.at(-1) == 'zone') {

      // getServiceConfigData(data);
      setLevelId(data.uuid)
    }
  }




  const handleConfigLevel = (data: any) => {
    setValue('currency_code', '')
    setServiceConfigs([]);
    setTempState([])
    setLevelValidationShow(levelDependent[data?.name])
  }

  const handleQuantityChange = (e: any) => {

    if (e.target.checked) {
      setSelectedServiceConfigs([...selectedServiceConfigs, ...[e.target.value]])
    } else {
      setSelectedServiceConfigs(selectedServiceConfigs.filter((item: any) => item !== e.target.value))
    }
    setValue(e.target.name.replace("service_config_id", "count"), '')

    setPackagePrice();
  }

  const setPackagePrice = () => {
    let data: any = getValues('configs');
    let discount_type: any = getValues('discount_type');
    let price: any = getValues('price');
    let subTotal: any = data?.filter((item: any) => item.checked == 'true')?.map((item: any) => item?.price * item?.count)?.reduce((a: any, b: any) => a + b, 0);
    if (discount_type == true || discount_type == 'true') {
      setTotalPackageConfig(subTotal - (subTotal / 100) * price)
    } else {
      setTotalPackageConfig(+(parseFloat(price).toFixed(2)))
    }

    setSubTotalPackageConfig(+(parseFloat(subTotal).toFixed(2)))

  }

  const handleQuantityPrice = (e: any) => {
    if(uuid){
      let fieldIndex = (e.target.name).split(".")[1]

      console.log("Iam hitted",e.target.name,fieldIndex , getValues("configs")[fieldIndex])
      let newConfigAdded =  getValues("configs")[fieldIndex].service_config_id;
      let dataX = allResults.find((x:any) => x.service_config_id == newConfigAdded )
      if(!dataX){
        setValue("configs."+fieldIndex+".new", e.target.value)
      }
     
    }
    setValue(e.target.name, e.target.value)
    // if(e.target.value == 0){
    //   toast.error("Quantity Cannot be Zero")
    //   setValue(e.target.name, 1)

    // }else{
    //   setValue(e.target.name, e.target.value)

    // }
    setPackagePrice();

  }

  const handleCategoryChange = (data: any) => {
    if (!editData) {
      // setSelectedServiceConfigs([])
      setValue("configs",null)
      setSelectedServiceConfigs([])

    }
  //  setselectedCategoryId(data?.uuid)

  }

  

  const handleVehicleType = (data: any) => {
    if (!editData) {
      // setSelectedServiceConfigs([])
      setValue("configs",null)
      setSelectedServiceConfigs([])

    }
    data && data.uuid && setVehicleType(data.uuid)
  }

  // FInd Config by filters
  const getServiceConfigData = (data?: any) => {
    dispatch(spinLoaderShow(true))
    if (!LevelId || !vehicleType) {
      dispatch(spinLoaderShow(false))
      return;
    }
    let filterBy: any = { level_id: LevelId, status: true }
    if (vehicleType) {
      filterBy.vehicle_type_id = vehicleType;
      
    }

    // if (selectedCategoryId) {
    //   filterBy.service_category_id = selectedCategoryId;
    // }
    getServiceConfig(filterBy).then((e: any) => {

      setServiceConfigs(e?.data?.result)
      setTempState(e?.data?.result)
    
      if (editData) {
        allResults.map((item: any, index: any) => {
         
          if (item?.status == true) {
            console.log("Index",getValues("configs"))
            let findIndex = e?.data?.result.findIndex((x:any) => x.uuid == item?.service_config_id);

            setValue(`configs.${findIndex}.count`, item?.count)
            setValue(`configs.${findIndex}.service_config_id`, item?.service_config_id)
          }
          dispatch(spinLoaderShow(false))
          return item;
        })
        dispatch(spinLoaderShow(false))
        setSelectedServiceConfigs(allResults.filter((item: any) => item.status == true).map((item: any) => item.service_config_id))

      }
      dispatch(spinLoaderShow(false))
    });
  }

  //On Edit Screen
  const getApiData = () => {

    getPackageConfigs({ 'package_id': uuid }).then((e) => {
      let data: any = e?.data?.result?.[0];
      let results: any = e?.data?.result
      setEditData(data)
      setAllResults(results)
      setValue('price', data?.package?.price)
      setValue('discount_type', data?.package?.discount_type == 'Percentage' ? true : false)
      setDiscountType(data?.package?.discount_type == 'Percentage' ? true : false)
      setValue('status', data?.package?.status)
      setLevelValidationShow(levelDependent[data?.serviceConfig?.level_type])
      setHeaderTitle('Edit')
      dispatch(spinLoaderShow(false))
    })
  }





  return (
    <div >
    <form onSubmit={handleSubmit(onSubmit)}>
          <GridItem xs={12} sm={12} md={12}>
         
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              
              <CardBody>
                <GridContainer spacing={3}>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.package_id}
                      fieldName="package_id"
                      autoComplete='off'
                      label="* Select Package"
                      control={control}
                      setValue={setValue}
                      options={packages}
                      optionKey="uuid"
                      isLoading={packages?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.package_id && errors?.package_id?.message}
                      valueGot={editData?.package && editData?.package}
                    />

                  </GridItem> */}
                    {/* <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.service_category_id}
                      fieldName="service_category_id"
                      autoComplete='off'
                      label="* Select Category"
                      control={control}
                      setValue={setValue}
                      options={serviceCategories}
                      optionKey="uuid"
                      iProps={{
                        onChange: handleCategoryChange,
                        disabled: !!uuid
                      }}
                      isLoading={true}
                      optionValue="name"
                      returnObject={true}
                      helperText={errors?.service_category_id && errors?.service_category_id?.message}
                      valueGot={editData && selectedCategoryId && serviceCategories.find((x: any) => x.uuid = selectedCategoryId)}
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.vehicle_type_id}
                      fieldName="vehicle_type_id"
                      autoComplete='off'
                      label="* Select Vehicle Type"
                      control={control}
                      setValue={setValue}
                      options={vehicleTypes}
                      optionKey="uuid"
                      iProps={{
                        onChange: handleVehicleType,
                        disabled: !!uuid
                      }}
                      isLoading={vehicleTypes?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.vehicle_type_id && errors?.vehicle_type_id?.message}
                      valueGot={editData && vehicleType && vehicleTypes.find(({ uuid }: any) => uuid == editData?.serviceConfig?.vehicle_type_id)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.level_id}
                      fieldName="level_id"
                      autoComplete='off'
                      label="* Select Level"
                      control={control}
                      setValue={setValue}
                      options={levels}
                      optionKey="id"
                      returnObject={true}
                      iProps={{
                        onChange: handleConfigLevel,
                        disabled: !!uuid
                      }}
                      isLoading={levels?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.level_id && errors?.level_id?.message}
                      valueGot={editData?.serviceConfig && { name: editData?.serviceConfig?.level_type, uuid: editData?.serviceConfig?.level_id }}
                    />
                  </GridItem>

                  {levelValidationShow?.includes('country') &&
                    <GridItem xs={12} sm={12} md={4} >
                      <AutoCompleteField
                        errors={!!errors?.country_id}
                        fieldName="country_id"
                        autoComplete='off'
                        label="* Select Country"
                        control={control}
                        setValue={setValue}
                        options={countries}
                        isLoading={countries?.length > 0 ? true : false}
                        optionKey="uuid"
                        iProps={{
                          onChange: getCitiesByCountry,
                          disabled: !!uuid
                        }}
                        optionValue="country_name"
                        helperText={errors?.country_id && errors?.country_id?.message}
                        valueGot={country}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('city') &&
                    <GridItem xs={12} sm={12} md={4} >
                      <AutoCompleteField
                        errors={!!errors?.city_id}
                        fieldName="city_id"
                        autoComplete='off'
                        label="* Select City"
                        control={control}
                        setValue={setValue}
                        options={cities}
                        isLoading={true}
                        optionKey="uuid"
                        iProps={{
                          onChange: getBranchesByCity,
                          disabled: !!uuid
                        }}
                        optionValue="city_name"
                        helperText={errors?.city_id && errors?.city_id?.message}
                        valueGot={city}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('branch') &&
                    <GridItem xs={12} sm={12} md={4} >
                      <AutoCompleteField
                        errors={!!errors?.branch_id}
                        fieldName="branch_id"
                        autoComplete='off'
                        label="* Select Branch"
                        iProps={{
                          onChange: getZonesByBranch,
                          disabled: !!uuid
                        }}
                        control={control}
                        setValue={setValue}
                        options={branches}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.branch_id && errors?.branch_id?.message}
                        valueGot={branch}
                        isLoading={true}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('zone') &&
                    <GridItem xs={12} sm={12} md={4} >
                      <AutoCompleteField
                        errors={!!errors?.zone_id}
                        fieldName="zone_id"
                        autoComplete='off'
                        label="* Select Zone"
                        control={control}
                        setValue={setValue}
                        iProps={{
                          onChange: handleZoneChange,
                          disabled: !!uuid
                        }}
                        options={zones}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.zone_id && errors?.zone_id?.message}
                        valueGot={zone}
                        isLoading={true}
                      />
                    </GridItem>
                  }
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={props?.disabled}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>
                </GridContainer>
              
                {serviceConfigs.length > 0 && <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Service Name</TableCell>
                            <TableCell align="left">Category</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {serviceConfigs.map((row: any, index: any) => (
                            <TableRow key={row.name}>
                              <InputField
                                fieldName={`configs.${index}.price`}
                                type="hidden"
                                control={control}
                                valueGot={row?.price}
                                isLoading={true}
                                setValue={setValue}
                              />
                              <InputField
                                fieldName={`configs.${index}.package_id`}
                                type="hidden"
                                control={control}
                                valueGot={getValues('package_id')}
                                isLoading={true}
                                setValue={setValue}
                              />
                              <InputField
                                fieldName={`configs.${index}.status`}
                                type="hidden"
                                control={control}
                                valueGot={true}
                                isLoading={true}
                                setValue={setValue}
                              />
                              <InputField
                                fieldName={`configs.${index}.checked`}
                                type="hidden"
                                control={control}
                                valueGot={selectedServiceConfigs.includes(row?.uuid).toString()}
                                isLoading={true}
                                setValue={setValue}
                              />
                              <TableCell align="left">
                                <CheckboxField
                                  errors={!!errors?.configs?.[index]?.service_config_id}
                                  control={control}
                                  setValue={setValue}
                                  helperText={errors?.configs?.[index]?.service_config_id && errors?.configs?.[index]?.service_config_id?.message}
                                  iProps={{
                                    onChange: handleQuantityChange,
                                    disabled:props?.disabled
                                  }}
                                  fieldName={`configs.${index}.service_config_id`}
                                  valueGot={row?.uuid}
                                  checked={selectedServiceConfigs.includes(row?.uuid)}
                                />
                                {row?.service?.name}
                              </TableCell>
                              <TableCell align="left">{serviceCategories?.find((x: any) => x.uuid == row?.service?.service_category_id)?.name}</TableCell>
                              <TableCell align="center">
                                {row?.price}

                              </TableCell>
                              <TableCell align="center">
                                <InputField
                                  errors={!!errors?.configs?.[index]?.count}
                                  fieldName={`configs.${index}.count`}
                                  type="number"
                                  autoComplete='off'
                                  label="* Quantity"
                                  control={control}
                                  isLoading={true}
                                  valueGot={""}
                                  iProps={{
                                    InputProps:{
                                      inputProps: { min: 1 }
                                    },
                                    fullWidth: false,
                                    onChange: handleQuantityPrice,
                                    disabled: !selectedServiceConfigs.includes(row?.uuid) || props?.disabled
                                  }}
                                  setValue={setValue}
                                  helperText={errors?.configs?.[index]?.count && errors?.configs?.[index]?.count?.message}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <Typography component="span" style={{ marginRight: '20px' }}>Fixed</Typography> <SwitchFieldDefault
                      errors={!!errors?.discount_type}
                      fieldName="discount_type"
                      autoComplete="off"
                      label=""
                      control={control}
                      isLoading={true}
                      disabled={props?.disabled}
                      iProps={{
                        onChange: (e: any) => {
                          setDiscountType(e.target.checked)
                          setPackagePrice();
                          trigger();
                        }
                      }}
                      setValue={setValue}
                      helperText={errors?.discount_type && errors?.discount_type?.message}
                    // valueGot={editData?.package && editData?.package.discount_type == 'Percentage' ? true : false}
                    /> <Typography component="span">Percentage</Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.price}
                      fieldName={`price`}
                      type="text"
                      autoComplete='off'
                      label={(discountType == true || discountType == 'true') ? 'Percentage' : 'Fixed'}
                      control={control}
                      isLoading={true}
                      valueGot={""}
                      iProps={{
                        fullWidth: false,
                        disabled:props?.disabled,
                        onChange: (e: any) => {
                          setValue(e.target.name, e.target.value)
                          setPackagePrice();
                          
                        }
                      }}
                      setValue={setValue}
                      helperText={errors?.price && errors?.price?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Typography variant="h5">Sub Total: {subTotalPackageConfig}</Typography>
                    <Typography variant="h5">Total: {totalPackageConfig ? totalPackageConfig.toFixed(2) : 0}</Typography>
                  </GridItem>
                </GridContainer>
                }

                {serviceConfigs.length == 0 &&
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant="h5" style={{ textAlign: 'center' }}>No Service Available</Typography>
                    </GridItem>
                  </GridContainer>
                }

              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  {/* <Button color="primary" type="button"  onClick={onSubmit}>{uuid ? 'Update Service' : "Create Service"}</Button> */}
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
          </form>

    </div >
  );
});

export default AddPackageConfig
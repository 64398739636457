import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import Box from '@material-ui/core/Box';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from "moment";
import StatusComponent from "components/Mix/Status";
import { getAdmin, getRoles } from "services/userservices";
import {bulkEnable,bulkDisable} from 'services/bulkEnableServices'
import EditIcon from '@material-ui/icons/Edit';
import { Link, useLocation } from "react-router-dom";
import queryString from 'querystring'
import Button from "components/CustomButtons/Button";
import { useDispatch,useSelector } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { verifyPermission } from 'helper/permission'
const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const AdminList = (props: any) => {
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [val, setVal] = useState(0)
  const [adminRow, setAdminRow] = useState<any>([])
  const [rolesRow, setRolesRow] = useState<any>([])
  const [exportData, setExportData] = useState<any>(null)
  const [exportData1, setExportData1] = useState<any>(null)
  const [headerTitle,setHeaderTitle]=useState<any>('Admin')
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const [disabledAdmin, setDisabledAdmin] = useState<any>(false)
  const [disabledRoles, setDisabledRoles] = useState<any>(false)
  const { search } = useLocation()
  const queryValues = queryString.parse(search)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin/users'
    },
    {
      'name': "Users",
      'link': ''
    }
  ];

 
  useEffect(() => {
    getAdminData()
    getRolesData()

   

    let temp: any = new URLSearchParams(search).get("list");
    if (temp == "role") {
      setVal(1)
      setHeaderTitle("Roles")
    }

  }, [])
  useEffect(()=>{
    let ispermissionAdmin = !(verifyPermission(
      getPermission,
      'Users',
      'Manage',
      'update'
    ))
    let ispermissionRoles = !(verifyPermission(
      getPermission,
      'Roles',
      'Manage',
      'update'
    ))

    setDisabledAdmin(ispermissionAdmin)
    setDisabledRoles(ispermissionRoles)
    
  },[getPermission])

  const getAdminData = () =>{
    dispatch(spinLoaderShow(true));
    getAdmin().then((e: any) => {
      let data =  e?.data?.result.map((item:any)=>{
        item.status=item.status? 'active' : 'inactive' 
        item.type = item?.vendor_id != null ? 'Vendor' : 'Admin'
         return item;
       })
       dispatch(spinLoaderShow(false));
      setAdminRow(data)
    }).catch((err)=>{
      dispatch(spinLoaderShow(false))
    })
    
  }
  const getRolesData = () =>{
    dispatch(spinLoaderShow(true));
    getRoles().then((e: any) => {
      dispatch(spinLoaderShow(false));
      let data =  e?.data?.result.map((item:any)=>{
        item.status=item.status? 'active' : 'inactive' 
         return item;
       }).filter((x:any)=> {return  !x.super && x.type != "vendor"})
     
       setRolesRow(data)
    })
  }

  const columnDefs: any = [
    {
      headerName: "Name",
      field: "first_name",
      filter: "agTextColumnFilter",
      valueGetter: (params: any) => {
        return  params?.data?.first_name + params?.data?.last_name;
    },
    },
    {
      headerName: "Type",
      field: "type",
    },
    {
      headerName: "Role",
      field: "role.name",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "City",
      field: "city",
    },
    {
      headerName: "Country",
      field: "country",

    },
    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: (params: any) => {
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
     
      sort: "desc",
      filter: 'agDateColumnFilter',

      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
  },
    {
      headerName: "Created Date",
      field: "createdAt",
      filter: 'agDateColumnFilter',
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide:disabledAdmin,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <Link to={'users/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;

      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <Link to={'users/edit?uuid=' + params?.data?.uuid + "&type=view"}><VisibilityIcon /></Link>;

      }
    },
  ];

  const columnDefsRoles: any = [
    {
      headerName: "Role Name",
      field: "name",
      filter: "agTextColumnFilter",
    },

    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: (params: any) => {
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
     
      sort: "desc",
      filter: 'agDateColumnFilter',
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
  },
    {
      headerName: "Created Date",
      field: "createdAt",
      minWidth: 150,
      filter: 'agDateColumnFilter',
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide:disabledRoles,
      cellRendererFramework: (params: any) => {
        return <Link to={'role/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        return <Link to={'role/edit?uuid=' + params?.data?.uuid + "&type=view"}><VisibilityIcon /></Link>;
      }
    }
  ];


  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  

  const valhandleChange = (event: any, newValue: any) => {
    setVal(newValue);
  };
  useEffect(()=>{
    if(val == 0){
      setHeaderTitle("Admin")
    }
    else if( val==1){
      setHeaderTitle("Roles")
    } 
  },[val])

  const enableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid}  

    })
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true))
      bulkEnable(temp).then((res:any)=>{
          dispatch(spinLoaderShow(false))
          toast.success("Record Updated successfully")
          getAdminData()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
      })
      getAdminData()
    }
    else {
      toast.error("Please select a row")
    }
  
  }
  const disableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid}  

    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
      bulkDisable(temp).then((res:any)=>{
          dispatch(spinLoaderShow(false))
          toast.success("Record Updated successfully")
          getAdminData()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
        getAdminData()
      })
    }
    else {
      toast.error("Please select a row")
    } 
  }
  const refreshAdminData = () => {
    dispatch(spinLoaderShow(true))
    getAdminData()
  
  }
  const refreshRoleData = () => {
    dispatch(spinLoaderShow(true))
    getRolesData()
  
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <PageHeader
          title={headerTitle}
          routes={routes}
          newItem={[
            { text: 'Add New Admin', link: "users/add",permission:"Users.Manage.create" },
            { text: 'Add New Role', link: "role/add",permission:"Roles.Manage.create" }
          ]}
            permission="Users.Manage.create"
            permission2="Roles.Manage.create"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>{headerTitle} Listing</h4>

            <Button variant="outlined" className="custom-btn export-btn" onClick={(e:any)=> exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>

            <Tabs
              value={val}
              onChange={valhandleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Administrators" />
              <Tab label="Roles & Permissions" />
            </Tabs>
           
            {val == 0 ? (
               <> <Button disabled={disabledAdmin} variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>enableSelectedRows() }>Enable</Button>
               <Button disabled={disabledAdmin} variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>disableSelectedRows() }>Disable</Button>
               <Button disabled={disabledAdmin} variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshAdminData()}>Refresh</Button>
              <AgGridCustom
                columnDefs={columnDefs}
                rowData={adminRow}
                getRowNodeId={(data: any) => {
                  return data.uuid;
                }}
                setExportData={setExportData}
              />
             </>

            ) : (
              <>
                <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshRoleData()}>Refresh</Button>
              <AgGridCustom
                disabledCheckBox={true}
                columnDefs={columnDefsRoles}
                rowData={rolesRow}
                getRowNodeId={(data: any) => {
                  return data.uuid;
                }}
              
              />
              </>
            )


            }



          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


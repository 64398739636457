import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const defautlFormValues = {
  message: null,
}

const useStyles = makeStyles(styles);

export const ContentList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)


  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Email Template Manager",
      'link': ''
    }
  ];

  useEffect(()=>{
    let ispermission = !(verifyPermission(
      getPermission,
      'Content',
      'Manage',
      'update'
    ))
    setDisabled(ispermission)
  },[])

  const columnDefs: any = [
    {
      headerName: "Country Name",
      field: "country_name",
    },
    {
      headerName: "Name",
      field: "name",
      resizable: true
    }
    ,
    {
      headerName: "Contents",
      field: "contents",
      resizable: true
    }
    ,

    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: "Modified Date",
      field: "created_at",
      filter: "agDateColumnFilter",
    },
    {
      headerName: 'Actions',
      field: "action",
sortable: false,
filter:false,
      hide:disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'branch/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },

  ]
  const rowData = [
    { id: '1', country_name: 'Qatar', name: 'Refund Policy', contents: 'Qatar Refund Policy JINO Parking Car Wash is committed to protect visitors’ and members’ privacy. The following Privacy policy outlines the information JINO Parking Car Wash may collect and how we may use that information to better serve visitors and members while using either JINO website...', status: 'active', created_at: 'Aug 08, 2021' },
    { id: '2', country_name: 'Qatar', name: 'Refund Policy', contents: 'Qatar Refund Policy JINO Parking Car Wash is committed to protect visitors’ and members’ privacy. The following Privacy policy outlines the information JINO Parking Car Wash may collect and how we may use that information to better serve visitors and members while using either JINO website...', status: 'active', created_at: 'Aug 08, 2021' },
    { id: '3', country_name: 'Qatar', name: 'Refund Policy', contents: 'Qatar Refund Policy JINO Parking Car Wash is committed to protect visitors’ and members’ privacy. The following Privacy policy outlines the information JINO Parking Car Wash may collect and how we may use that information to better serve visitors and members while using either JINO website...', status: 'active', created_at: 'Aug 08, 2021' },
    { id: '4', country_name: 'Qatar', name: 'Refund Policy', contents: 'Qatar Refund Policy JINO Parking Car Wash is committed to protect visitors’ and members’ privacy. The following Privacy policy outlines the information JINO Parking Car Wash may collect and how we may use that information to better serve visitors and members while using either JINO website...', status: 'active', created_at: 'Aug 08, 2021' },
    { id: '5', country_name: 'Qatar', name: 'Refund Policy', contents: 'Qatar Refund Policy JINO Parking Car Wash is committed to protect visitors’ and members’ privacy. The following Privacy policy outlines the information JINO Parking Car Wash may collect and how we may use that information to better serve visitors and members while using either JINO website...', status: 'active', created_at: 'Aug 08, 2021' },
  ]



  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Content "
            routes={routes}
            newItem={[{ text: 'Edit', link: "/admin/content/add" }]}
            // permission="Content.Manage.create"

          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary"  className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Content List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>


          </CardHeader>
          <CardBody>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


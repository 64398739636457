import httpservice from "./httpservice";

export const getCustomers = (body: any = null) => {
    return httpservice.get('/customer/', {
        params: body,
    })
} 
export const getCustomerCount = (body: any = null) => {
    return httpservice.get('/customer/count', {
        params: body,
    })
}
export const createCustomer = (body: any) => {
    return httpservice.post("/customer/", body)
}
export const updateCustomer = (body: any) => {
    return httpservice.put("/customer/", body)
}
export const getCustomersAddress = (body: any = null) => {
    return httpservice.get('/customer/address', {
        params: body,
    })
}
export const getCustomerDetail = (body: any = null) => {
    return httpservice.get('/customer/detail', {
      params: body,
    })
  
  }
export const createCustomerVehicle = (body: any) => {
    return httpservice.post("/customer/vehicle", body)
}
export const updateCustomerVehicle = (body: any) => {
    return httpservice.put("/customer/vehicle", body)
}
export const getCustomersVehicle = (body: any = null) => {
    return httpservice.get('/customer/vehicle', {
        params: body,
    })
}
export const createCustomerAddress = (body: any) => {
    return httpservice.post("/customer/address", body)
}
export const updateCustomerAddress = (body: any) => {
    return httpservice.put("/customer/address", body)
}

export const getCustomerPackage = async (body: any = null) => {
    return httpservice.get("/customer/packages", { params: body })
}
import httpservice from "./httpservice";

export function getPromotionNormal(body: any = null) {
  return httpservice.get("/promotions/view/normal/odata?" + body);
}

export function getCustomerOdata(body: any = null) {
  return httpservice.get("/customer/odata?" + body);
}

export function getNotificationOdata(body: any = null) {
  return httpservice.get("/notifications/view?" + body);
}
export function getAlertOdata(body: any = null) {
  return httpservice.get("/notifications/alert", {
    params: body,
  });
}
export function transactionOData(body: any = null) {
  return httpservice.get("/admin/user/transactions/view/odata?" + body);
}
export function bookingOData(body: any = null) {
  return httpservice.get("/booking/view/odata?" + body);
}
export function bookingODataDriver(body: any = null) {
  return httpservice.get("/booking/view/driver?" + body);
}
export function customerVehiclesOData(body: any = null) {
  return httpservice.get("/customer/vehicle/odata?" + body);
}
export function customerAddressOData(body: any = null) {
  return httpservice.get("/customer/address/view?" + body);
}

import React, { useState, useEffect } from 'react';
import { Accordion } from '@material-ui/core';

export default function Collapseable(props:any) {

    const [isCollapse, setIsCollapse] = useState(props.collapse);

    const toggle = () => {
        setIsCollapse(!isCollapse);
    };

    useEffect(() => {
        setIsCollapse(props.collapse);
    }, [props.collapse]);

    return (
        <Accordion expanded={isCollapse} onChange={() => toggle()}>
            {props.children}
        </Accordion>
    )
}
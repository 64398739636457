import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { InputField, SwitchField, AutoCompleteField, SwitchFieldDefault } from 'components/Forms/Formfield.component'
import { useForm } from 'react-hook-form';
import { Label } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { createServiceCategoryValidation } from './Validations/serviceCategoryValidation';
import { createServiceCategory, updateServiceCategory, serviceCategoryLogo } from 'services/servicesServices'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';
import { spinLoaderShow } from "redux/action/spinLoader";
import { CREATE_SUCCESS_MSG } from 'json/constants';
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { FileUploadDialog } from 'components/FileUpload/fileuploadDialog.component';
import Avatar from '@material-ui/core/Avatar';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core/'
import moment from 'moment'

const styles: any = {
  uploadButton: {
    backgroundColor: '#043cae',
    color: '#fff',
    marginLeft: "1em"
  },
  avatar: {
    height: "60px",
    width: "60px"
  }
}
const useStyles = makeStyles(styles);

export const ServiceCategoryPopup = (props: any) => {
  const classes = useStyles()
  const [dropOpen, setDropOpen] = useState<any>(false)
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [url, setUrl] = useState<any>("")
  const [disabled, setDisabled] = useState<any>(false)

  const defaultFormValues: any = {
    name: null,
    priority: '',
    status: true,
  }
  const { control, handleSubmit, register, formState: { errors }, setValue, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createServiceCategoryValidation)
  })
  const { handleClose, open, title, editData, setOpen } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setValue("name", editData.name);
      setValue("status", editData.status);
      setValue("category_logo", editData?.category_logo)
      setValue("automate_notifications", editData?.automate_notifications || false)
      setValue("notification_title", editData?.notification_title)
      setValue("notification_body", editData?.notification_body)
      setShowNotification(editData?.automate_notifications || false)
      setValue("priority", editData?.priority)
      setUrl(editData?.category_logo)
      if (editData?.view) {
        setDisabled(true)
      }
    } else {
      reset(defaultFormValues);
    }
  }, [editData]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true))
    setDropOpen(false)
    const formData = new FormData()
    formData.append('images', files[0])
    const { data } = await serviceCategoryLogo(formData)
    setValue("category_logo", data.result[0])
    setUrl(data.result[0])
    dispatch(spinLoaderShow(false))
  }

  const onSubmit = async (data: any) => {
    dispatch(spinLoaderShow(true))
    let uuid: any = editData?.uuid;
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    createServiceCategory(data)
      .then((e: any) => {
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false))
      });
  };

  const updateOnSubmit = (data: any) => {
    updateServiceCategory(data)
      .then((e: any) => {
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false))
      });
  };

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} md={12} sm={12}>
            {editData != null ? <Typography>Modified At:  {moment(editData?.updatedAt).format('llll')} </Typography> : null}
          </GridItem>
          <GridItem xs={6} md={6} sm={6} style={{ marginTop: "06px" }}>
            <input {...register("category_logo")} type="hidden" />

            <Box style={{ display: "flex", alignItems: "center" }}>
              <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />
              <Button
                variant="outlined"
                color="primary"
                disabled={disabled}
                className={classes.uploadButton}
                onClick={() => setDropOpen(true)}
              >
                Category Logo
              </Button>
              <FileUploadDialog open={dropOpen} setOpen={setDropOpen} handleSave={handleSave} />
            </Box>
          </GridItem>
          <GridItem xs={6} sm={6} md={6} >
            <InputField
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete="false"
              label="Name"
              iProps={{
                disabled: disabled,
              }}
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.name && errors?.name?.message}

            />

          </GridItem>
          <GridItem xs={6} sm={6} md={6} >
            <InputField
              errors={!!errors?.priority}
              fieldName="priority"
              type="text"
              autoComplete='off'
              label="* Priority"
              control={control}
              iProps={
                {
                  type: "number",
                  disabled: disabled
                }
              }
              valueGot={""}
              setValue={setValue}
              helperText={errors?.priority && errors?.priority?.message}

            />
          </GridItem>

          <GridItem xs={4} sm={4} md={4} className={"mt-15"}>
            <SwitchFieldDefault
              errors={!!errors?.status}
              fieldName="status"
              autoComplete='off'
              label="status"
              defaultChecked="true"
              control={control}
              valueGot={''}
              disabled={disabled}
              setValue={setValue}
              helperText={errors?.status && errors?.status?.message}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
            <SwitchFieldDefault
              errors={!!errors?.automate_notifications}
              fieldName="automate_notifications"
              autoComplete='off'
              label="Automated Notifications"
              defaultChecked="false"
              control={control}
              valueGot={''}
              iProps={{
                onChange: (data: boolean) => setShowNotification(data),
              }}
              disabled={disabled}
              setValue={setValue}
              helperText={errors?.status && errors?.status?.message}
            />
          </GridItem>
          {showNotification && <><GridItem xs={12} sm={12} md={12} >
            <InputField
              errors={!!errors?.notification_title}
              fieldName="notification_title"
              type="text"
              autoComplete="false"
              label="* Notification Title"
              iProps={{
                disabled: disabled,
              }}
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.notification_title && errors?.notification_title?.message}

            />

          </GridItem>
            <GridItem xs={12} sm={12} md={12} >
              <InputField
                errors={!!errors?.notification_body}
                fieldName="notification_body"
                multiline={true}
                rows={7}
                type="text"
                autoComplete="false"
                label="* Notification Body"
                iProps={{
                  disabled: disabled,
                }}
                control={control}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.notification_body && errors?.notification_body?.message}

              />

            </GridItem></>}

        </GridContainer>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => { reset(defaultFormValues); handleClose() }} color="primary">
          Cancel
        </Button>
        <Button type="submit" disabled={disabled} color="primary">
          submit
        </Button>
      </DialogActions>
    </form>
  )
}
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { Box, FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import _ from 'lodash';
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, SwitchField, SwitchFieldDefault } from "components/Forms/Formfield.component";
import GoogleMap from 'components/GoogleMap/GoogleMap';
import { createZoneValidation } from "./Validations/zoneValidations";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { createZone, getBranch, getCity, getCountry, getSchedules, getZone, updateZone } from "services/locationservices";
import { spinLoaderShow } from "redux/action/spinLoader";
import DrawMap from 'components/GoogleMap/DrawMap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import {CREATE_SUCCESS_MSG} from 'json/constants'
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import moment from "moment"


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  name: '',
  branch_id: '',
  status: true,
  polygon: {},
  schedule_id: null,
  country_id: '',
  city_id: ''
}


const useStyles = makeStyles(styles);
declare var google: any;
const options: any = [{ id: 1, name: "Financial Center" }, { id: 2, name: "222 Al Khaleej Rd" }];

export default function AddZone(props: any) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [branches, setBranches] = useState<any>([]);
  const [schedule, setSchedule] = useState<any>([])
  const [resetMap, setResetMap] = useState<any>(false)
  const [searchLocation, setSearchLocation] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [editData, setEditData] = useState<any>(null);
  const [mapValues, setMapValues] = useState<any>(null);
  const [countries, setCountries] = useState<any>([]);
  const [branch, setBranch] = useState<any>({});
  const [city, setCity] = useState<any>({});
  const [cities, setCities] = useState<any>([]);
  const [disabled, setDisabled] = useState<any>(false)
  const { search } = useLocation()

  let  uuid  =new URLSearchParams(search ).get("uuid");
  let history=useHistory()
  const { control, handleSubmit, formState: { errors }, setValue, reset, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createZoneValidation)
  });

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Zone",
      'link': '/admin/zone'
    },
    {
      'name': headerTitle,
      'link': ''
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }

  ];

  useEffect(() => {
    if (!!mapValues?.latLng && Object.keys(mapValues?.latLng)?.length > 0) {
      setValue("polygon", mapValues?.latLng);
    } else {
      setValue("polygon", {});
    }
    if (!!mapValues?.autocomplete) {
      setSearchLocation(mapValues?.autocomplete);
    }
  }, [mapValues]);


  useEffect(() => {

    getSchedules({ status: true,type:"Location" }).then((e) => {
      setSchedule(e.data.result)
    }).catch((e) => {
    })
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((err) => {
    })
  }, [])

  const getCitiesByCountry = (data: any) => {
    setCities([])
    setCity({})
    setBranch({})
    if (data?.uuid) {
      dispatch(spinLoaderShow(true))
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
          dispatch(spinLoaderShow(false))
        }).catch((err)=>{
          dispatch(spinLoaderShow(false))
        })
    }
  }

  const getBranchesByCity = (data: any) => {
    setBranch({})
    if(data?.uuid){
      dispatch(spinLoaderShow(true))
      getBranch({ city_id: data?.uuid, status: true }).then((e) => {
        setBranches(e.data.result)
        dispatch(spinLoaderShow(false))
      }).catch((e) => {
        dispatch(spinLoaderShow(false))
      })
    }
   
  }

  const getZoneData = () => {
    getZone({ uuid: uuid })
      .then((e) => {
        let data: any = e?.data?.result?.[0];
        getCitiesByCountry({ uuid: data?.branch?.city?.country_id })
        getBranchesByCity({ uuid: data?.branch?.city_id })
        setValue("name", data?.name);
        // setValue("internal_name", data.internal_name);
        setValue("branch_id", data.branch_id);
        setValue("status", data.status);
        setValue("schedule_id", data.schedule_id);
        setMapValues(data.polygon)
        setCity(data?.branch?.city)
        setBranch(data?.branch)
        setEditData(data);
        let temp: any = new URLSearchParams(search).get("type");
          temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
          temp == 'view' ? setDisabled(true) : setDisabled(false)
        dispatch(spinLoaderShow(false))
      })
  }

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getZoneData()
    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData([])
    }

  }, [uuid]);


  const onSubmit = async (data: any) => {
    dispatch(spinLoaderShow(true));
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    createZone(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        setResetMap(true);
        history.push("/admin/zone");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };
  const updateOnSubmit = (data: any) => {
    updateZone(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        setResetMap(true);
        history.push("/admin/zone");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Zone"
              routes={routes}
              newItem={[
                //{ text: 'Add', link: "/admin/zone/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/zone" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Zone</h4>
             {uuid ?<h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null }     
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete='off'
                      label="* Zone Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}

                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.internal_name}
                      fieldName="internal_name"
                      type="text"
                      autoComplete='off'
                      label="* Internal Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.internal_name && errors?.internal_name?.message}

                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.schedule_id}
                      fieldName="schedule_id"
                      autoComplete='off'
                      label="Select Schedule"
                      control={control}
                      setValue={setValue}
                      options={schedule}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={errors?.schedule_id && errors?.schedule_id?.message}
                      valueGot={editData?.schedule && editData?.schedule}
                      isLoading={schedule?.length > 0 ? true : false}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.country_id}
                      fieldName="country_id"
                      autoComplete='off'
                      label="* Select Country"
                      control={control}
                      setValue={setValue}
                      options={countries}
                      isLoading={countries?.length > 0 ? true : false}
                      optionKey="uuid"
                      iProps={{
                        onChange: getCitiesByCountry,
                        disabled: disabled
                      }}
                      optionValue="country_name"
                      helperText={errors?.country_id && errors?.country_id?.message}
                      valueGot={editData?.branch?.city?.country && editData?.branch?.city?.country}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.city_id}
                      fieldName="city_id"
                      autoComplete='off'
                      label="* Select City"
                      control={control}
                      setValue={setValue}
                      options={cities}
                      isLoading={true}
                      optionKey="uuid"
                      iProps={{
                        onChange: getBranchesByCity,
                        disabled: disabled
                      }}
                      optionValue="city_name"
                      helperText={errors?.city_id && errors?.city_id?.message}
                      valueGot={city}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.branch_id}
                      fieldName="branch_id"
                      autoComplete='off'
                      label="* Select Branch"
                      control={control}
                      setValue={setValue}
                      options={branches}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={errors?.branch_id && errors?.branch_id?.message}
                      valueGot={branch}
                      isLoading={true}
                    />
                  </GridItem>
                  <GridItem style={{ marginTop: "15px" }}>

                  <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />

                  </GridItem>
                </GridContainer>
                <Box mt={2}>
                  <GridContainer mt={2}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant={"subtitle1"}>
                        Draw area: <span className="google-map-error-text">{errors.polygon && errors?.polygon?.latLng?.message}</span>
                      </Typography>
                      {editData &&
                        <FormHelperText error={errors.polygon && true} className="google-map-error">
                          <GoogleMap
                            value={setMapValues}
                            setValue={{
                              latLng: editData?.polygon
                            }}
                            autocomplete
                            polygon={disabled ? false : true}
                            reset={resetMap}
                            zoom={editData?.polygon?.zoom ? editData?.polygon?.zoom : 14}
                            center={editData?.polygon?.center ? editData?.polygon?.center : {
                              lat: 25.276987,
                              lng: 55.296249
                            }}
                          >
                            <DrawMap data={editData} />
                          </GoogleMap>
                        </FormHelperText>
                      }
                      {
                        // <FormHelperText error className="google-map-error-text">
                        //   {errors.polygon && errors?.polygon?.message}
                        // </FormHelperText>
                      }
                    </GridItem>
                  </GridContainer>
                </Box>
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} className={"custom-btn"} type="submit" >{uuid ? 'Update Zone' : "Create Zone"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}


import * as yup from 'yup'


const levelDependent: any = {
    'Country': ['country'],
    'City': ['country', 'city'],
    'Branch': ['country', 'city', 'branch'],
    'Zone': ['country', 'city', 'branch', 'zone'],
};

const createPackageConfigValidation: any = yup.object().shape({
    package_id: yup.string().required("Please select package").nullable(),
    level_id: yup.object().required("Please select service").nullable(),
    price: yup.number().when("discount_type", {
        is: true,
        then: yup.number().min(0).max(100).required("Please enter percentage  ").typeError('Please enter valid percentage.')
    }).when("discount_type", {
        is: false,
        then: yup.number().min(0).required("Please enter price").typeError('Please enter valid price. ')
    }),
    // price: yup.number().required("Please enter price percantage ").typeError('Please enter valid price. percantage'),

    configs: yup.array().of(yup.object().shape({
        count: yup.string().when("checked", {
            is: function (data: any) {
                return data == 'true';
            },
            then: yup.string().required("Please enter quantity ").typeError('Please enter valid quantity.')
        }).nullable(),
    })),

    country_id: yup.string().when("level_id", {
        is: function (data: any) {
            if (data !== null) {
                return levelDependent[data.name].includes('country');
            }
            return false;
        },
        then: yup.string().required('Please select country').nullable()
    }).nullable(),
    city_id: yup.string().when("level_id", {
        is: function (data: any) {
            if (data !== null) {
                return levelDependent[data.name].includes('city');
            }
            return false;
        },
        then: yup.string().required('Please select city').nullable()
    }).nullable(),
    branch_id: yup.string().when("level_id", {
        is: function (data: any) {
            if (data !== null) {
                return levelDependent[data.name].includes('branch');
            }
            return false;
        },
        then: yup.string().required('Please select branch').nullable()
    }).nullable(),
    zone_id: yup.string().when("level_id", {
        is: function (data: any) {
            if (data !== null) {
                return levelDependent[data.name].includes('zone');
            }
            return false;
        },
        then: yup.string().required('Please select zone').nullable()
    }).nullable(),
});

export {
    createPackageConfigValidation
}
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useState } from "react";
import GoogleMap from 'components/GoogleMap/GoogleMapMarker';
import { useForm } from "react-hook-form";
import { AutoCompleteField, InputField, LoadingButton, SwitchField, PhoneNoField, SwitchFieldDefault, CheckboxField ,DateField} from "components/Forms/Formfield.component";
import { Box, CardMedia, FormHelperText, Grid, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import { createDriverValidation } from "./Validations/driverValidations"
import DrawMap from 'components/GoogleMap/DrawMap';
import { Country, State, City } from 'country-state-city';
import {getSchedules} from 'services/locationservices'
import {getVendor } from "services/vendorServices";
import { createDriver, getDriver, updateDriver, driverlogo } from 'services/driverServices'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useHistory, useLocation } from "react-router";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { FileUploadDialog } from 'components/FileUpload/fileuploadDialog.component';
import Avatar from '@material-ui/core/Avatar';
import { FileUploadArea } from "components/FileUpload/fileuploadArea.component";
import moment from "moment";
import { getAdmin } from "services/userservices";


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  uploadButton: {
    backgroundColor: '#043cae',
    color: '#fff',
    marginLeft: "1em"
  },
  avatar: {
    height: "60px",
    width: "60px"
  }
};

const levelDependent: any = {
  'Country': ['country'],
  'City': ['country', 'city'],
  'Branch': ['country', 'city', 'branch'],
  'Zone': ['country', 'city', 'branch', 'zone'],
};


const defaultFormValues = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_no: '',
  vendor_id: '',
  schedule_id: '',
  status: true,
  license_expiry:null,
  license_number:'',
  country:'',
  city:'',
  active:true
  // pin: '',
  // level_id: '',
  // level_type: '',
  // branch_id: '',
  // city_id: '',
  // configs: [{
  //   package_id: null,
  //   service_config_id: null,
  //   checked: null,
  //   price: null
  // }],
  // country_id: '',
  // currency_code: '',
  // zone_id: '',
  // package_id: null,
  // service_config_id: null,
  // checked: null,
  // price: null
}


const useStyles = makeStyles(styles);


export default function AddDriver(props: any) {
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [editData, setEditData] = useState<any>(null);
  const [vendor, setVendor] = useState<any>([])
  const [schedule, setSchedule] = useState<any>([])
  const [headerTitle, setHeaderTitle] = useState<any>("Add")
  const [driverLicenseFront, setDriverlicensefront]=useState<any>("")
  const [driverLicenseBack, setDriverlicenseback]=useState<any>("")
  const [disabled, setDisabled] = useState<any>(false)
  const [image, setImage] = useState<any>("")
  const [resetMap, setResetMap] = useState<any>(false);
  const [licenseExpiry , setLicenseExpiry] = useState<any>(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");



  const { control, register, handleSubmit, clearErrors, setError, formState: { errors }, setValue, reset, getValues } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createDriverValidation)

  });

  const routes: any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Driver ",
      'link': '/admin/driver'
    },
    {
      'name': headerTitle,
      'link': ''
    }
  ];

  useEffect(() => {
    getApi();
   

  }, [])

  const getApi = async () =>{
    var adminUser:any = await getAdmin({uuid:localStorage.getItem("userId")});
    adminUser = adminUser?.data?.result?.[0];
    if(adminUser.vendor_id){
    getVendor({uuid:adminUser.vendor_id}).then((e: any) => {
      
      setVendor(e.data.result)

    })
  }else{
    getVendor().then((e: any) => {
      
      setVendor(e.data.result.filter((x:any) => x.status))

    })
  }
  let payload:any = { type: "Driver",status:true }
  if(adminUser.vendor_id){
    payload.vendor_id = adminUser.vendor_id;
  }
    getSchedules(payload).then((e: any) => {
      setSchedule(e.data.result)

    })
  }


  const getDriverData = () => {
    getDriver({ 'uuid': uuid }).then((e: any) => {
      let data: any = e?.data?.result?.[0];
      setValue("first_name", data?.first_name)
      setValue("last_name", data?.last_name)
      setValue("email", data?.email)
      setValue("mobile_no", data?.mobile_no)
      setValue("vendor_id", data?.vendor_id)
      setValue("schedule_id", data?.schedule_id)
      setValue("status", data?.status)
      setValue("latitide", data?.latitide)
      setValue("longitude", data?.longitude)
      setValue("rating",data?.rating)
      setImage(data?.image)
      setDriverlicensefront(data?.license_image_front)
      setDriverlicenseback(data?.license_image_back)
      setValue("license_image_front",data?.license_image_front)
      setValue("license_image_back",data?.license_image_back)
      setValue("image",data?.image)
      setValue("license_expiry",data?.license_expiry)
      setLicenseExpiry(data?.license_expiry ? data?.license_expiry : null)
      setValue("license_number",data?.license_number)
      setValue("country",data?.country)
      setValue("city",data?.city)
      setValue("active",data?.active)
      setEditData(data)
      let temp: any = new URLSearchParams(search).get("type");
      temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
      temp == 'view' ? setDisabled(true) : setDisabled(false)
      dispatch(spinLoaderShow(false))
    })
  }

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getDriverData()
    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
    }
  }, [uuid])



  const handleSaveProfile = async (files: any) => {
    dispatch(spinLoaderShow(true))
    setOpen(false)
    const formData = new FormData()
    formData.append('images', files[0])
    const { data } = await driverlogo(formData)
    setValue("image", data.result[0])
    setImage(data.result[0])
    dispatch(spinLoaderShow(false))


  }

  // dropzone function====================>

  // save image in s3 and returns its url and save in state
  const handleSaveLicenseFront = async (files: any) => {
    dispatch(spinLoaderShow(true))
    setOpen(false)
    const formData = new FormData()
    formData.append('images', files[0].file)
    const { data } = await driverlogo(formData)
    setValue("license_image_front", data.result[0])
    setDriverlicensefront(data.result[0])
    dispatch(spinLoaderShow(false))

    

  }
  const handleSaveLicenseBack = async (files: any) => {
    dispatch(spinLoaderShow(true))

    const formData = new FormData()
    formData.append('images', files[0].file)
    const { data } = await driverlogo(formData)
    setValue("license_image_back", data.result[0])
    setDriverlicenseback(data.result[0])
    dispatch(spinLoaderShow(false))

    

  }
  // dropzone function====================>

  const onSubmit = (data: any) => {
    let format = 'YYYY-MM-DD'
    data.license_expiry = moment(data?.license_expiry).format(format)
    data.rating == "" ? data.rating = null : data.rating  = (+data.rating).toFixed(2);
    dispatch(spinLoaderShow(true))
    if (uuid) {
      data.uuid = uuid
      updateOnSubmit(data)
    }
    
    else {
      createOnSubmit(data)
    }
  }

  const createOnSubmit = async (data: any) => {
    createDriver(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues)
        history.push("/admin/driver");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };

  const updateOnSubmit = (data: any) => {
    updateDriver(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        setEditData(null)
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues)
        history.push("/admin/driver");

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const onlicenseExpiryChange = (data:any) =>{
    setLicenseExpiry(data)
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Driver"
              routes={routes}
              newItem={[

                { text: 'Back', link: "/admin/driver" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Driver</h4>
                  {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null } 
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={4} md={4} sm={4} style={{ marginTop: "06px" }}>
                    <input {...register("image")} type="hidden" />

                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Avatar alt="Remy Sharp" src={image} className={classes.avatar} />
                      <Button
                        className={classes.uploadButton}
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                        onClick={() => setOpen(true)}
                      >
                        Image
                      </Button>
                      <FileUploadDialog open={open} setOpen={setOpen} handleSave={handleSaveProfile} />
                    </Box>
                  </GridItem>


                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.first_name}
                      fieldName="first_name"
                      type="text"
                      autoComplete="false"
                      label="* First Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.first_name && errors?.first_name?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.last_name}
                      fieldName="last_name"
                      type="text"
                      autoComplete="false"
                      label="* Last Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.last_name && errors?.last_name?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.email}
                      fieldName="email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.email && errors?.email?.message
                      }

                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} >
                    <PhoneNoField
                      errors={!!errors?.mobile_no}
                      fieldName="mobile_no"
                      type="text"
                      autoComplete="false"
                      label="* Mobile Number"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: editData || disabled,
                        disableDropdown:disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.mobile_no && errors?.mobile_no?.message}

                    />
                  </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.rating}
                      fieldName="rating"
                      type="text"
                      autoComplete="false"
                      label="Rating"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.rating && errors?.rating?.message}

                    />
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.license_number}
                      fieldName="license_number"
                      type="text"
                      autoComplete="false"
                      label="* License Number"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.license_number && errors?.license_number?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                  <DateField
                      errors={!!errors?.license_expiry}
                      fieldName="license_expiry"
                      autoComplete='off'
                      label="* License Expiry"
                      setValue={setValue}
                      control={control}
                      iProps={{
                        disabled: disabled,
                        disablePast: true,
                        format: 'dd-MM-yyyy',
                        onChange:onlicenseExpiryChange
                        
                      
                      }}
                      inputVariant="outlined"
                      helperText={errors?.license_expiry && errors?.license_expiry?.message}
                      valueGot={licenseExpiry}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.vendor_id}
                      fieldName="vendor_id"
                      autoComplete="off"
                      label="* Select Vendor Id"
                      control={control}
                      setValue={setValue}
                      options={vendor}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="first_name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.vendor_id && errors?.vendor_id?.message
                      }
                      valueGot={editData?.vendor_id && vendor?.find(({ uuid }: any) => { return uuid == editData?.vendor_id })}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.country}
                      fieldName="country"
                      type="text"
                      autoComplete="false"
                      label="* Country"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.country && errors?.country?.message}

                    />
                  </GridItem> 
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.city}
                      fieldName="city"
                      type="text"
                      autoComplete="false"
                      label="* City"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.city && errors?.city?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.schedule_id}
                      fieldName="schedule_id"
                      autoComplete="off"
                      label="* Select Schedule Id"
                      control={control}
                      setValue={setValue}
                      options={schedule}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.schedule_id && errors?.schedule_id?.message
                      }
                      valueGot={editData?.schedule_id && schedule?.find(({ uuid }: any) => { return uuid == editData?.schedule_id })}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.active}
                      fieldName="active"
                      autoComplete="off"
                      label="Active"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.active &&
                        errors?.active?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={9} style={{ marginTop: '30px' }}>
                  <input {...register("license_image_front")} type="hidden" />
                    <Box display="flex" justifyContent="center" style={{ width: '100%', borderRadius: '20px' }}>
                      <img src={driverLicenseFront ? driverLicenseFront : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"} style={{ width: '250px', marginRight: '50px' }} />

                   {disabled == false ? <FileUploadArea handleSave={handleSaveLicenseFront}  message={"Drag or drop Driver License Front "} /> : <div></div>}   
                    </Box>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={9} style={{ marginTop: '30px' }}>
                  <input {...register("license_image_back")} type="hidden" />
                    <Box display="flex" justifyContent="center" style={{ width: '100%', borderRadius: '20px' }}>
                      <img src={driverLicenseBack ? driverLicenseBack : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"} style={{ width: '250px', marginRight: '50px' }} />

                {disabled == false  ?  <FileUploadArea handleSave={handleSaveLicenseBack}  message={"Drag or drop Driver License Back "}  /> : <div></div>}     
                    </Box>
                  </GridItem>
                </GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                            
                            {editData && disabled ?  (
                              <>
                              <Typography variant={"subtitle1"}>
                              Location:
                              <span className="google-map-error-text">
                                {errors.polygon &&
                                  errors?.polygon?.latLng?.message}
                              </span>
                            </Typography>
                              <FormHelperText 
                              error={errors.polygon && true}
                              className="google-map-error"
                            >
                             <GoogleMap
                                draggable={false}
                                value={() => { }}
                                setValue={{
                                  latLng: {
                                    lat: editData?.latitude
                                      ? +editData?.latitude
                                      : 25.276987,
                                    lng: editData?.longitude
                                      ? +editData?.longitude
                                      : 55.296249,
                                  },
                                }}
                                reset={resetMap}
                                zoom={
                                  editData?.driver?.zoom
                                    ? +editData?.zoom
                                    : 14
                                }
                                center={{
                                  lat: editData?.latitude
                                    ? +editData?.latitude
                                    : 25.276987,
                                  lng: editData?.longitude
                                    ? +editData?.longitude
                                    : 55.296249,
                                }}
                              >
                                <DrawMap data={editData} />
                              </GoogleMap>
                            </FormHelperText>
                            </>
                            ) :null}
                          </GridItem>
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} type="submit" >{uuid ? 'Update Driver' : "Create Driver"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}


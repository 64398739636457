import { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CancelIcon from "@material-ui/icons/Cancel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Danger from "components/Typography/Danger";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import GoogleMap from "components/GoogleMap/GoogleMap";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle";
import { Marker } from "@react-google-maps/api";
import {
  getViewBooking,
  getHeatMapAPI,
  getViewBookingPackage,
  getBookingCount,
  getBookingByDate,
  getBookingByService,
} from "services/userservices";
import { getCustomers, getCustomerCount } from "services/customerServices";
import { getDriver } from "services/driverServices";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { InfoWindow } from "@react-google-maps/api";
import { Typography, Box } from "@material-ui/core";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import {
  DateField,
  AutoCompleteField,
} from "components/Forms/Formfield.component";
import moment from "moment";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import Button from "components/CustomButtons/Button";
import Skeleton from "@material-ui/lab/Skeleton";

declare var google: any;

const useStyles = makeStyles(styles);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BookingStatuses = [
  { uuid: 1, name: "In Cart" },
  { uuid: 2, name: "CC Payment Pending" },
  { uuid: 3, name: "CC Payment Failed" },
  { uuid: 4, name: "CC Payment Processed" },
  { uuid: 5, name: "COD" },
  { uuid: 6, name: "Completed" },
  { uuid: 7, name: "In Progress" },
  { uuid: 8, name: "Cancelled By User" },
  { uuid: 9, name: "Cancelled By Admin" },
];

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      // position: 'top' as const,
    },
    title: {
      display: false,
      text: "Customer Chart",
    },
  },
};

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Dashboard() {
  const classes = useStyles();
  const [defaultMarkers, setDefaultMarkers] = useState<any>([]);
  const [customers, setCustomers] = useState<any>([]);
  const [drivers, setDrivers] = useState<any>([]);
  // const [bookings, setBookings] = useState<any>([]);
  const [completedJobs, setCompletedJobs] = useState<any>([]);
  const [inCarts, setInCarts] = useState<any>([]);
  const [upcomingJobs, setUpcomingJobs] = useState<any>([]);
  const [ongoingJobs, setOngoingJobs] = useState<any>([]);
  const [bookingOnTheWay, setBookingOnTheWay] = useState<any>([]);
  const [driverReached, setDriverReached] = useState<any>([]);
  const [cancelledByUsers, setCancelledByUsers] = useState<any>([]);
  const [cancelledByAdmin, setCancelledByAdmin] = useState<any>([]);
  const [driverLocation, setDriverLocation] = useState<any>([]);
  const [driverLocationCenter, setDriverLocationCenter] = useState<any>(null);

  const [upcomingJobsPackage, setUpcomingJobsPackage] = useState<any>([]);
  const [bookingOnTheWayPackage, setbookingOnTheWayPackage] = useState<any>([]);
  const [driverReachedPackage, setdriverReachedPackage] = useState<any>([]);
  const [cancelledByUsersPackage, setcancelledByUsersPackage] = useState<any>(
    []
  );
  const [cancelledByAdminPackage, setcancelledByAdminPackage] = useState<any>(
    []
  );
  const [onGoingPackage, setonGoingPackage] = useState<any>([]);
  const [onCompletePackage, setonCompletePackagePackage] = useState<any>([]);
  const [inCartPackage, setinCartPackage] = useState<any>([]);
  const [customerCount, setCustomerCount] = useState<any>("");

  const [upcomingJobLoaderPackage, setUpcomingJobLoaderPackage] =
    useState<any>(true);

  const [completedJobLoaderPackage, setCompletedJobLoaderPackage] =
    useState<any>(true);
  const [incartJobLoaderPackage, setinCartLoaderPackage] = useState<any>(true);
  const [ongoingJobLoaderPackage, setOngoingJobLoaderPackage] =
    useState<any>(true);
  const [cancelledByUserLoaderPackage, setCancelledByUserLoaderPackage] =
    useState<any>(true);
  const [cancelledByAdminLoaderPackage, setCancelledByAdminLoaderPackage] =
    useState<any>(true);
  const [bookingOnTheWayLoaderPackage, setBookingOnTheWayLoaderPackage] =
    useState<any>(true);
  const [driverReachedLoaderPackage, setDriverReachedLoaderPackage] =
    useState<any>(true);

  const [orderheatmap, setOrderheatmap] = useState<any>([]);
  const [orderheatmapCenter, setOrderheatmapCenter] = useState<any>(null);

  const [activeMarker, setActiveMarker] = useState(null);
  const [chartValues, setChartValues] = useState<any>(null);
  const [bookingChart, setBookingChart] = useState<any>(null);
  const [pieChart, setPieChart] = useState<any>(null);
  const [customercardLoader, setCustomercardLoader] = useState<any>(true);
  const [DrivercardLoader, setDrivercardLoader] = useState<any>(true);
  const [completedJobLoader, setCompletedJobLoader] = useState<any>(true);
  const [upcomingJobLoader, setUpcomingJobLoader] = useState<any>(true);
  const [inCartJobLoader, setIncartJobLoader] = useState<any>(true);
  const [ongoingJobLoader, setOngoingJobLoader] = useState<any>(true);
  const [cancelledByUserLoader, setCancelledByUserLoader] = useState<any>(true);
  const [cancelledByAdminLoader, setCancelledByAdminLoader] =
    useState<any>(true);
  const [bookingOnTheWayLoader, setBookingOnTheWayLoader] = useState<any>(true);
  const [driverReachedLoader, setDriverReachedLoader] = useState<any>(true);
  const [orderMapLoader, setOrderMapLoader] = useState<any>(true);
  const [driverMapLoader, setDriverMapLoader] = useState<any>(true);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [bStartDate, setBStartDate] = useState<any>(null);
  const [bEndDate, setBEndDate] = useState<any>(null);
  const [sStartDate, setSStartDate] = useState<any>(null);
  const [sEndDate, setSEndDate] = useState<any>(null);
  const [driver, setDriver] = useState<any>(null);
  const filterOptions = [
    { uuid: "1", name: "Today" },
    { uuid: "2", name: "Yesterday" },
    { uuid: "3", name: "Last Week" },
    { uuid: "4", name: "Last Month" },
    { uuid: "5", name: "This Month" },
  ];
  const [isVendor, setVendor] = useState<any>(
    localStorage.getItem("vendorId") != null ? true : false
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const dispatch = useDispatch();

  var locations: any = [
    [-33.890542, 151.274856, 4],
    [-33.923036, 151.259052, 5],
    [-34.028249, 151.157507, 3],
    [-33.80010128657071, 151.28747820854187, 2],
    [-33.950198, 151.259302, 1],
  ];

  const onLoad = (marker: any) => {
    console.log("marker: ", marker);
  };
  const position: any = {
    lat: 37.772,
    lng: -122.214,
  };

  useEffect(() => {
    if (drivers) {
      let temp: any = [];
      drivers?.map((item: any) => {
        temp.push({
          first_name: item?.first_name,
          last_name: item?.last_name,
          name: item?.first_name + " " + item?.last_name,
          uuid: item?.uuid,
          longitude: item?.longitude,
          latitude: item?.latitude,
          email: item?.email,
          mobile_no: item?.mobile_no,
          status: item?.status,
        });
        return item;
      });
      setDriverLocation(temp);
      setDriverLocationCenter(temp.find((x: any) => x.latitude));
      setDriverMapLoader(false);
    }
  }, [drivers]);

  // useEffect(() => {

  //   // let completed = 0
  //   // let upcoming = 0
  //   // let ongoing = 0
  //   // let cancelledByUsers = 0
  //   // let cancelledByAdmin = 0

  //   // Promise.all(bookings.map((x: any) => {
  //   //   x.booking_details.map((item: any) => {
  //   //     // console.log("status", item.booking_status)

  //   //     if (item.booking_status_id == 6) {
  //   //       completed++

  //   //     } else if (item.booking_status_id == 12) {
  //   //       upcoming++

  //   //     } else if (item.booking_status_id == 7 || item.booking_status_id == 13 || item.booking_status_id == 10) {
  //   //       ongoing++

  //   //     } else if (item.booking_status_id == 8) {
  //   //       cancelledByUsers++

  //   //     } else if(item?.booking_status_id == 9){
  //   //       cancelledByAdmin++
  //   //     }
  //   //   })
  //   // })
  //   // ).then(x => {
  //   //   setCompletedJobs(completed)
  //   //   setUpcomingJobs(upcoming)
  //   //   setOngoingJobs(ongoing)
  //   //   console.log("onoging", ongoing)
  //   //   setCancelledByUsers(cancelledByUsers)
  //   //   setCancelledByAdmin(cancelledByAdmin)
  //   // })

  //   let temp: any = []
  //   bookings?.map((item: any) => {
  //     item?.booking_details?.map((x: any) => {
  //       temp.push({
  //         first_name: x?.customer_address?.first_name,
  //         last_name: x?.customer_address?.last_name,
  //         latitude: x?.customer_address?.latitude,
  //         longitude: x?.customer_address?.longitude
  //       })
  //       return x;
  //     })
  //     return item;
  //   })
  //   setOrderheatmap(temp)
  //   setOrderheatmapCenter(temp.find((x:any) => x.latitude))
  //   setOrderMapLoader(false)
  // }, [bookings])

  const HeatMapAPI = async (data: any) => {
    let heatMapData: any = await getHeatMapAPI({});
    heatMapData = heatMapData.data.result;
    setOrderheatmap(heatMapData);
    setOrderheatmapCenter(heatMapData.find((x: any) => x.latitude));
    setOrderMapLoader(false);
  };

  useEffect(() => {
    // dispatch(spinLoaderShow(true))
    getCustomerCountData();
    let temp = [];

    for (var i = 0; i < locations.length; i++) {
      temp.push({
        lat: locations[1][0],
        lng: locations[1][1],
      });
    }
    setDefaultMarkers(temp);
    getCustomerData();
    getDriverData();
    // getBookings()
    getCompletedJobs();
    getUpcomingJobs();
    getUpcomingJobsPackage();
    getInCartJobs();
    getOngoingJobs();
    getDriverOnTheWay();
    getDriverReached();
    getCancelledByUserJobs();
    getCancelledByAdminJobs();
    HeatMapAPI({});
    getCompletePackage();
    getOntheWayPackage();
    getdriverReachedPackage();
    getcancelledbyUserPackage();
    getcancelledbyAdminPackage();
    getonGoingAdminPackage();
    getInCartJobsPackage();
    getBookingsByDate();
    getBookingsByService();
  }, []);

  const getBookingsByDate = async (start?: Date, end?: Date) => {
    let body: any = {};
    const start_date = start ? start : getValues("b_start_date");
    const end_date = end ? end : getValues("b_end_date");
    const status = getValues("booking_status_id");
    const driver = getValues("driverId");
    if (start_date && end_date) {
      body.start_date = start_date;
      body.end_date = end_date;
    }
    if (status) {
      body.booking_status_id = status.uuid;
    }
    if (driver?.uuid) {
      body.driver_id = driver.uuid;
    }
    getBookingByDate(body).then((res) => {
      setBookingChart({
        labels: res.data.result.map((data: any) =>
          moment(new Date(data.date)).format("MMMM D, YYYY")
        ),
        datasets: [
          {
            label: "Bookings",
            data: res.data.result.map((data: any) => data.bookings),
            borderColor: "#043cae",
            backgroundColor: "#043cae",
          },
        ],
      });
    });
  };
  const getBookingsByService = async (start?: Date, end?: Date) => {
    let body: any = {};
    const start_date = start ? start : getValues("s_start_date");
    const end_date = end ? end : getValues("s_end_date");
    if (start_date && end_date) {
      body.start_date = start_date;
      body.end_date = end_date;
    }
    getBookingByService(body).then((res) => {
      setPieChart({
        labels: res.data.result.map((data: any) => data.service_name),
        datasets: [
          {
            label: "Services",
            data: res.data.result.map((data: any) => data.bookings),
            backgroundColor: [
              "#AB876E",
              "#3F48F1",
              "#004D6D",
              "#416CF8",
              "#2B7FB5",
              "#FCA3D1",
              "#2782FA",
              "#FDF3C8",
              "#484E3F",
              "#0A5B6D",
              "#49DB66",
              "#AFADEC",
              "#FC235A",
              "#9A9BB1",
              "#0425C0",
              "#7DEB9A",
              "#C85F41",
              "#842971",
              "#7E6332",
              "#CFDC90",
            ],
          },
        ],
      });
    });
  };

  const onHeatmapLayerLoad = (heatmapLayer: any) => {
    console.log("HeatmapLayer onLoad heatmapLayer: ", heatmapLayer);
  };

  const onUnmount = (heatmapLayer: any) => {
    console.log("HeatmapLayer onUnmount heatmapLayer: ", heatmapLayer);
  };

  const makeCustomerChartData = (
    data: any,
    start: any = null,
    end: any = null,
    filterBy: any = null
  ) => {
    if (start && end) {
      data = data.filter((y: any) => {
        return (
          moment(y?.createdAt, "YYYY-MM-DD").isSameOrAfter(start, "day") &&
          moment(y?.createdAt, "YYYY-MM-DD").isSameOrBefore(end, "day")
        );
      });
    }

    if (filterBy == "Today") {
      data = data?.filter((y: any) => {
        return moment(y?.createdAt, "YYYY-MM-DD").isSame(moment(), "days");
      });
    } else if (filterBy == "Yesterday") {
      data = data?.filter((y: any) => {
        return moment(y?.createdAt).isSame(
          moment().subtract(1, "days").format("YYYY-MM-DD"),
          "days"
        );
      });
    } else if (filterBy == "Last Week") {
      let dateTo = moment().format("YYYY-MM-DD");
      let dateFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
      data = data?.filter((y: any) => {
        return (
          moment(y?.createdAt, "YYYY-MM-DD").isAfter(dateFrom, "day") &&
          moment(y?.createdAt, "YYYY-MM-DD").isBefore(dateTo, "day")
        );
      });
    } else if (filterBy == "Last Month") {
      let dateTo = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
      let dateFrom = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      data = data?.filter((y: any) => {
        return (
          moment(y?.createdAt, "YYYY-MM-DD").isAfter(dateFrom, "day") &&
          moment(y?.createdAt, "YYYY-MM-DD").isSameOrBefore(dateTo, "day")
        );
      });
    } else if (filterBy == "This Month") {
      let dateTo = moment().endOf("month").format("YYYY-MM-DD");
      let dateFrom = moment().startOf("month").format("YYYY-MM-DD");
      data = data?.filter((y: any) => {
        return (
          moment(y?.createdAt, "YYYY-MM-DD").isAfter(dateFrom, "day") &&
          moment(y?.createdAt, "YYYY-MM-DD").isBefore(dateTo, "day")
        );
      });
    }

    data = data.sort(function (a: any, b: any) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      var c: any = new Date(a.createdAt);
      var d: any = new Date(b.createdAt);
      return c - d;
    });
    var dateRange = {
      fromDate: data[0]?.createdAt,
      toDate: data[data.length - 1]?.createdAt,
    };

    var weeks = moment(dateRange.toDate).diff(
      dateRange.fromDate,
      "weeks",
      false
    );
    var months = moment(dateRange.toDate).diff(
      dateRange.fromDate,
      "months",
      true
    );

    let temp: any = "";
    if (weeks <= 3) {
      temp = "D";
    } else if (weeks > 3 && weeks <= 12) {
      temp = "W";
    } else if (weeks > 12 && months <= 8) {
      temp = "MMM";
    } else if (months > 8 && months <= 12) {
      temp = "Q";
    } else if (months > 12) {
      temp = "Y";
    }
    let dateArr = data?.map((item: any) => {
      item.createdAt = moment(item?.createdAt).format("YYYY-MM-DD");
      return { date: item?.createdAt };
    });

    const groupBy = (data: any, token: any) => {
      let splitBy = "";
      if (token == "W") {
        splitBy = "Week";
      } else if (token == "MMM") {
        splitBy = "Month";
      } else if (token == "Q") {
        splitBy = "Quarter";
      } else if (token == "Y") {
        splitBy = "Year";
      } else if (token == "D") {
        splitBy = "Day";
      }

      data = data.reduce((acc: any, date: any) => {
        const yearWeek = moment(date["date"]).format("LL");

        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }
        acc[yearWeek].push(date);
        return acc;
      }, {});
      return data;
    };
    let groups: any = [];
    let tempo: any = {};
    let keys: any = {};
    let values: any = {};
    if (temp == "W") {
      groups = groupBy(dateArr, "W");
      Object.entries(groups).map(([key, value]: any) => {
        tempo[key] = value.length;
      });
      keys = Object.keys(tempo);
      values = Object.values(tempo);
    } else if (temp == "MMM") {
      groups = groupBy(dateArr, "MMM");
      Object.entries(groups).map(([key, value]: any) => {
        tempo[key] = value.length;
      });
      keys = Object.keys(tempo);
      values = Object.values(tempo);
    } else if (temp == "Q") {
      groups = groupBy(dateArr, "Q");
      Object.entries(groups).map(([key, value]: any) => {
        tempo[key] = value.length;
      });
      keys = Object.keys(tempo);
      values = Object.values(tempo);
    } else if (temp == "Y") {
      groups = groupBy(dateArr, "Y");
      Object.entries(groups).map(([key, value]: any) => {
        tempo[key] = value.length;
      });
      keys = Object.keys(tempo);
      values = Object.values(tempo);
    } else if (temp == "D") {
      groups = groupBy(dateArr, "D");
      Object.entries(groups).map(([key, value]: any) => {
        tempo[key] = value.length;
      });
      keys = Object.keys(tempo);
      values = Object.values(tempo);
    }

    // const result = data.reduce((r: any, item: any) => {
    //   let dateObj = new Date(item.createdAt);
    //   let monthyear = dateObj.toLocaleString("en-us", { month: "long", year: 'numeric' });
    //   if (!r[monthyear]) r[monthyear] = { monthyear, entries: 1 }
    //   else r[monthyear].entries++;
    //   return r;
    // }, {})

    // console.log(Object.values(result));
    // var groupData = Object.values(result);
    // console.log("group data", groupData)
    var chartData = {
      labels: keys,
      datasets: [
        {
          label: "Registered User",
          data: values,
          borderColor: "#043cae",
          backgroundColor: "#043cae",
        },
      ],
    };
    console.log(JSON.stringify(chartData), "chartdata");
    setChartValues(chartData);
  };

  const filterDate = () => {
    let startDate = moment(getValues("start_date")).format("YYYY-MM-DD");
    let endDate = moment(getValues("end_date")).format("YYYY-MM-DD");
    if (startDate && endDate) {
      makeCustomerChartData(customers, startDate, endDate);
    }
  };

  const getCustomerData = () => {
    if (!isVendor) {
      getCustomers({ status: true })
        .then((res: any) => {
          makeCustomerChartData(res?.data?.result);
          let data = res?.data?.result.sort((a: any, b: any) => {
            var c: any = new Date(a.createdAt);
            var d: any = new Date(b.createdAt);
            return c - d;
          });
          setStartDate(data[0]?.createdAt);
          setEndDate(data[data?.length - 1]?.createdAt);
          setCustomers(data);
          setCustomercardLoader(false);
        })
        .catch((err) => {
          setCustomercardLoader(false);
        });
    }
  };
  const getCustomerCountData = () => {
    if (!isVendor) {
      getCustomerCount({ status: true })
        .then((res: any) => {
          setCustomerCount(res?.data?.result);
          setCustomercardLoader(false);
        })
        .catch((err) => {
          setCustomercardLoader(false);
        });
    }
  };
  const getDriverData = () => {
    getDriver()
      .then((res: any) => {
        setDrivers(res?.data?.result);
        setDrivercardLoader(false);
      })
      .catch((err) => {
        setDrivercardLoader(false);
      });
  };
  // const getBookings = () => {
  //   getBooking({ booking_status_id: 6 }).then((res: any) => {
  //     setBookings(res?.data?.result)

  //   }).catch((err) => {

  //   })
  // }
  const getCompletedJobs = async () => {
    let completedBooking = await getBookingCount({ booking_status_id: 6 });
    setCompletedJobs(completedBooking?.data?.result);
    setCompletedJobLoader(false);
    return;
    getViewBooking({ booking_status_id: 6 })
      .then((res: any) => {
        setCompletedJobs(res?.data?.result);
        setCompletedJobLoader(false);
      })
      .catch((err) => {
        setCompletedJobLoader(false);
      });
  };
  const getUpcomingJobs = async () => {
    let upcommingBooking = await getBookingCount({ booking_status_id: 12 });
    setUpcomingJobs(upcommingBooking?.data?.result);
    setUpcomingJobLoader(false);

    return;
    getViewBooking({ booking_status_id: 12 })
      .then((res: any) => {
        setUpcomingJobs(res?.data?.result);
        setUpcomingJobLoader(false);
      })
      .catch((err) => {
        setUpcomingJobLoader(false);
      });
  };
  const getInCartJobs = async () => {
    const inCartJobs = await getBookingCount({ booking_status_id: 1 });
    setInCarts(inCartJobs?.data?.result);
    setIncartJobLoader(false);
    return;

    getViewBooking({ booking_status_id: 1 })
      .then((res: any) => {
        setInCarts(res?.data?.result);
        setIncartJobLoader(false);
      })
      .catch((err) => {
        setUpcomingJobLoader(false);
      });
  };

  const getInCartJobsPackage = () => {
    getViewBookingPackage({ booking_status_id: 1 })
      .then((res: any) => {
        setinCartPackage(res?.data?.result);
        setinCartLoaderPackage(false);
      })
      .catch((err) => {
        setinCartLoaderPackage(false);
      });
  };
  const getUpcomingJobsPackage = () => {
    getViewBookingPackage({ booking_status_id: 12 })
      .then((res: any) => {
        setUpcomingJobsPackage(res?.data?.result);
        setUpcomingJobLoaderPackage(false);
      })
      .catch((err) => {
        setUpcomingJobLoaderPackage(false);
      });
  };

  const getCompletePackage = () => {
    getViewBookingPackage({ booking_status_id: 6 })
      .then((res: any) => {
        setonCompletePackagePackage(res?.data?.result);
        setCompletedJobLoaderPackage(false);
      })
      .catch((err) => {
        setCompletedJobLoaderPackage(false);
      });
  };
  const getOntheWayPackage = () => {
    getViewBookingPackage({ booking_status_id: 13 })
      .then((res: any) => {
        setbookingOnTheWayPackage(res?.data?.result);
        setBookingOnTheWayLoaderPackage(false);
      })
      .catch((err) => {
        setBookingOnTheWayLoaderPackage(false);
      });
  };
  const getdriverReachedPackage = () => {
    getViewBookingPackage({ booking_status_id: 10 })
      .then((res: any) => {
        setdriverReachedPackage(res?.data?.result);
        setDriverReachedLoaderPackage(false);
      })
      .catch((err) => {
        setDriverReachedLoaderPackage(false);
      });
  };
  const getcancelledbyUserPackage = () => {
    getViewBookingPackage({ booking_status_id: 8 })
      .then((res: any) => {
        setcancelledByUsersPackage(res?.data?.result);
        setCancelledByUserLoaderPackage(false);
      })
      .catch((err) => {
        setCancelledByUserLoaderPackage(false);
      });
  };
  const getcancelledbyAdminPackage = () => {
    getViewBookingPackage({ booking_status_id: 9 })
      .then((res: any) => {
        setcancelledByAdminPackage(res?.data?.result);
        setCancelledByAdminLoaderPackage(false);
      })
      .catch((err) => {
        setCancelledByAdminLoaderPackage(false);
      });
  };

  const getonGoingAdminPackage = () => {
    getViewBookingPackage({ booking_status_id: 7 })
      .then((res: any) => {
        setonGoingPackage(res?.data?.result);
        setOngoingJobLoaderPackage(false);
      })
      .catch((err) => {
        setOngoingJobLoaderPackage(false);
      });
  };
  const getOngoingJobs = () => {
    getViewBooking({ booking_status_id: 7 })
      .then((res: any) => {
        setOngoingJobs(res?.data?.result);
        setOngoingJobLoader(false);
      })
      .catch((err) => {
        setOngoingJobLoader(false);
      });
  };
  const getCancelledByUserJobs = async () => {
    let cancelled = await getBookingCount({ booking_status_id: 8 });
    setCancelledByUsers(cancelled?.data?.result);
    setCancelledByUserLoader(false);
    return;
    getViewBooking({ booking_status_id: 8 })
      .then((res: any) => {
        setCancelledByUsers(res?.data?.result);
        setCancelledByUserLoader(false);
      })
      .catch((err) => {
        setCancelledByUserLoader(false);
      });
  };
  const getCancelledByAdminJobs = async () => {
    let cancelled = await getBookingCount({ booking_status_id: 9 });
    setCancelledByAdmin(cancelled?.data?.result);
    setCancelledByAdminLoader(false);
    return;
    getViewBooking({ booking_status_id: 9 })
      .then((res: any) => {
        setCancelledByAdmin(res?.data?.result);
        setCancelledByAdminLoader(false);
      })
      .catch((err) => {
        setCancelledByAdminLoader(false);
      });
  };

  const getDriverOnTheWay = async () => {
    const bookingonTheWay = await getBookingCount({ booking_status_id: 13 });
    setBookingOnTheWay(bookingonTheWay?.data?.result);
    setBookingOnTheWayLoader(false);
    return;
    getViewBooking({ booking_status_id: 13 })
      .then((res: any) => {
        setBookingOnTheWay(res?.data?.result);
        setBookingOnTheWayLoader(false);
      })
      .catch((err) => {
        setBookingOnTheWayLoader(false);
      });
  };
  const getDriverReached = () => {
    getViewBooking({ booking_status_id: 10 })
      .then((res: any) => {
        setDriverReached(res?.data?.result);
        setDriverReachedLoader(false);
      })
      .catch((err) => {
        setDriverReachedLoader(false);
      });
  };

  // useEffect(() => {
  //   let dates = [
  //     {
  //       "date": "2022-02-08"
  //     },
  //     {
  //       "date": "2022-02-08"
  //     },
  //     {
  //       "date": "2022-02-08"
  //     },
  //     {
  //       "date": "2022-02-09"
  //     },
  //     {
  //       "date": "2022-02-10"
  //     },
  //     {
  //       "date": "2022-02-10"
  //     },
  //     {
  //       "date": "2022-02-10"
  //     },
  //     {
  //       "date": "2022-02-10"
  //     }
  //   ]
  // }, [])

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const refreshData = () => {
    setCustomercardLoader(true);
    setDrivercardLoader(true);
    setCompletedJobLoader(true);
    setUpcomingJobLoader(true);
    setOngoingJobLoader(true);
    setCancelledByUserLoader(true);
    setCancelledByAdminLoader(true);
    setBookingOnTheWayLoader(true);
    setDriverReachedLoader(true);
    getCustomerCountData();
    getInCartJobs();
    getCustomerData();
    getDriverData();
    // getBookings()
    getCustomerData();
    getDriverData();
    getCompletedJobs();
    getUpcomingJobs();
    getOngoingJobs();
    getDriverOnTheWay();
    getDriverReached();
    getCancelledByUserJobs();
    getCancelledByAdminJobs();
    getCompletePackage();
    getOntheWayPackage();
    getdriverReachedPackage();
    getcancelledbyUserPackage();
    getcancelledbyAdminPackage();
    getonGoingAdminPackage();
    getInCartJobsPackage();
  };

  const handleBookingFilter = (filter: any) => {
    const dates = handleFilterToDate(filter?.name);
    getBookingsByDate(new Date(dates.startDate), new Date(dates.endDate));
    setBStartDate(dates.startDate);
    setBEndDate(dates.endDate);
  };

  const handleServiceFilter = (filter: any) => {
    const dates = handleFilterToDate(filter?.name);
    getBookingsByService(new Date(dates.startDate), new Date(dates.endDate));
    setSStartDate(dates.startDate);
    setSEndDate(dates.endDate);
  };

  const handleFilterToDate = (filter: string) => {
    let startDate, endDate;

    switch (filter) {
      case "Today":
        startDate = moment().startOf("day").toISOString();
        endDate = moment().endOf("day").toISOString();
        break;

      case "Yesterday":
        startDate = moment().subtract(1, "days").startOf("day").toISOString();
        endDate = moment().subtract(1, "days").endOf("day").toISOString();
        break;

      case "Last Week":
        startDate = moment().subtract(1, "weeks").startOf("week").toISOString();
        endDate = moment().subtract(1, "weeks").endOf("week").toISOString();
        break;

      case "Last Month":
        startDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .toISOString();
        endDate = moment().subtract(1, "months").endOf("month").toISOString();
        break;

      case "This Month":
        startDate = moment().startOf("month").toISOString();
        endDate = moment().endOf("month").toISOString();
        break;

      default:
        throw new Error(`Invalid date range name: ${name}`);
    }

    return { startDate, endDate };
  };

  const filterHandleChange = (data: any) => {
    let startDate = moment(getValues("start_date")).format("YYYY-MM-DD");
    if (data) {
      let filterBy = data?.name;
      makeCustomerChartData(customers, startDate, null, filterBy);
    } else {
      makeCustomerChartData(customers);
    }
  };
  const refreshDriver = () => {
    setDriverMapLoader(true);
    getDriverData();
  };
  const refreshOrderLoader = () => {
    setOrderMapLoader(true);
    // getBookings()
  };

  const onStartChange = (data: any) => {
    setStartDate(data);
  };
  const onEndChange = (data: any) => {
    setEndDate(data);
  };
  return (
    <div>
      {/* ---Card Start--- */}
      <GridContainer>
        {/* Refresj Button*/}
        <GridItem xs={12} md={12} sm={12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <Button
              color="primary"
              className="custom-btn"
              onClick={() => {
                refreshData();
              }}
            >
              Refresh
            </Button>
          </Box>
        </GridItem>

        {/* Customer */}
        {!isVendor && (
          <GridItem xs={12} sm={6} md={3}>
            <Link to={"customer"}>
              <Card>
                {customercardLoader == true ? (
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={"auto"}
                    height={115}
                  />
                ) : (
                  <>
                    {" "}
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        <Icon>
                          <PeopleOutlineIcon />
                        </Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Customers</p>
                      <h3 className={classes.cardTitle}>{customerCount}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Danger></Danger>
                      </div>
                    </CardFooter>
                  </>
                )}
              </Card>
            </Link>
          </GridItem>
        )}
        {/* Driver */}
        <GridItem xs={12} sm={6} md={3}>
          <Link to="driver">
            <Card>
              {DrivercardLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="success" stats icon>
                    <CardIcon color="success">
                      <DriveEtaIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Drivers</p>
                    <h3 className={classes.cardTitle}>{drivers?.length}</h3>
                  </CardHeader>
                  <CardFooter stats></CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* InCart Jobs*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=incart"}>
            <Card>
              {inCartJobLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <CheckCircleOutlineIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>InCart Jobs</p>
                    <h3 className={classes.cardTitle}>{inCarts}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* InCart Jobs Package */}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=incart"}>
            <Card>
              {incartJobLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <CheckCircleOutlineIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>InCart Jobs Package</p>
                    <h3 className={classes.cardTitle}>
                      {inCartPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Complete Jobs */}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=completed"}>
            <Card>
              {completedJobLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <CheckCircleOutlineIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Completed Jobs</p>
                    <h3 className={classes.cardTitle}>{completedJobs}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Complete Jobs  Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=completed"}>
            <Card>
              {completedJobLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="info" stats icon>
                    <CardIcon color="info">
                      <CheckCircleOutlineIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Completed Jobs Package
                    </p>
                    <h3 className={classes.cardTitle}>
                      {onCompletePackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>

        {/* <---ExtraGrid */}
        <GridItem xs={12} sm={12} md={12}></GridItem>
        {/* ExtraGrid---> */}

        {/* Upcoming Jobs*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=upcoming"}>
            <Card>
              {upcomingJobLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon>
                        <ScheduleIcon />
                      </Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>Upcoming Jobs</p>
                    <h3 className={classes.cardTitle}>{upcomingJobs}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* On the way*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=onTheWay"}>
            <Card>
              {bookingOnTheWayLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <AirportShuttleIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>On The Way</p>
                    <h3 className={classes.cardTitle}>{bookingOnTheWay}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Driver Reached*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=driverReached"}>
            <Card>
              {driverReachedLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <LocationOnIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Driver Reached</p>
                    <h3 className={classes.cardTitle}>
                      {driverReached?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Outgoing Jobs*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=ongoing"}>
            <Card>
              {ongoingJobLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <HourglassEmptyIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Ongoing Jobs</p>
                    <h3 className={classes.cardTitle}>{ongoingJobs?.length}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Upcoming Jobs Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=upcoming"}>
            <Card>
              {upcomingJobLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <Icon>
                        <ScheduleIcon />
                      </Icon>
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Upcoming Jobs Package
                    </p>
                    <h3 className={classes.cardTitle}>
                      {upcomingJobsPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* On the way Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=onTheWay"}>
            <Card>
              {bookingOnTheWayLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <AirportShuttleIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>On The Way Package</p>
                    <h3 className={classes.cardTitle}>
                      {bookingOnTheWayPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Driver Reached Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=driverReached"}>
            <Card>
              {driverReachedLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <LocationOnIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Driver Reached Package
                    </p>
                    <h3 className={classes.cardTitle}>
                      {driverReachedPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Outgoing Jobs Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=ongoing"}>
            <Card>
              {ongoingJobLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <HourglassEmptyIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Ongoing Jobs Package</p>
                    <h3 className={classes.cardTitle}>
                      {onGoingPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Cancelled By Users*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=cancelledByUser"}>
            <Card>
              {cancelledByUserLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <CancelIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Cancelled By Users</p>
                    <h3 className={classes.cardTitle}>{cancelledByUsers}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Cancelled by Users Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=cancelledByUser"}>
            <Card>
              {cancelledByUserLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <CancelIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Cancelled By Users Package
                    </p>
                    <h3 className={classes.cardTitle}>
                      {cancelledByUsersPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Cancelled by Admin*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking?type=cancelledByAdmin"}>
            <Card>
              {cancelledByAdminLoader == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <CancelIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>Cancelled By Admin </p>
                    <h3 className={classes.cardTitle}>{cancelledByAdmin}</h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
        {/* Cancelled by Admin Package*/}
        <GridItem xs={12} sm={6} md={3}>
          <Link to={"booking/package?type=cancelledByAdmin"}>
            <Card>
              {cancelledByAdminLoaderPackage == true ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  width={"auto"}
                  height={115}
                />
              ) : (
                <>
                  <CardHeader color="danger" stats icon>
                    <CardIcon color="danger">
                      <CancelIcon />
                    </CardIcon>
                    <p className={classes.cardCategory}>
                      Cancelled By Admin Package
                    </p>
                    <h3 className={classes.cardTitle}>
                      {cancelledByAdminPackage?.length}
                    </h3>
                  </CardHeader>
                  <CardFooter stats>
                    <div className={classes.stats}></div>
                  </CardFooter>
                </>
              )}
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      {/* ---Card End--- */}

      {!isVendor && (
        <>
          <GridItem xs={12} sm={12} md={12}>
            {/* <div class="chart-container" style="position: relative; height:40vh; width:80vw"> */}

            <Card chart>
              <CardHeader>
                <GridContainer spacing={1}>
                  <GridItem xs={12} sm={12} md={12}>
                    {" "}
                    <h3 className={classes.cardTitle}>Bookings</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <AutoCompleteField
                      errors={!!errors?.booking_status_id}
                      fieldName="booking_status_id"
                      autoComplete="off"
                      label="Booking Status"
                      control={control}
                      setValue={setValue}
                      options={BookingStatuses}
                      isLoading={true}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.type && errors?.type?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="driverId"
                      autoComplete="off"
                      label="Driver"
                      control={control}
                      setValue={setValue}
                      options={driverLocation.filter(
                        (driver: any) => driver.status === true
                      )}
                      isLoading={true}
                      iProps={{
                        onChange: (value: any) => setDriver(value),
                      }}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.type && errors?.type?.message}
                      valueGot={driver}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="stype"
                      autoComplete="off"
                      label="* Filter By"
                      control={control}
                      setValue={setValue}
                      options={filterOptions}
                      isLoading={true}
                      iProps={{
                        onChange: handleBookingFilter,
                      }}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.type && errors?.type?.message}
                      valueGot={""}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <DateField
                      errors={!!errors?.start_date}
                      fieldName="b_start_date"
                      autoComplete="off"
                      label="Start Date"
                      iProps={{
                        format: "dd-MM-yyyy",
                        onChange: (date: any) => setBStartDate(date),
                      }}
                      clearable={true}
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      helperText={
                        errors?.start_date && errors?.start_date?.message
                      }
                      valueGot={bStartDate}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <DateField
                      errors={!!errors?.end_date}
                      fieldName="b_end_date"
                      autoComplete="off"
                      label="End Date"
                      setValue={setValue}
                      iProps={{
                        format: "dd-MM-yyyy",
                        onChange: (date: any) => setBEndDate(date),
                      }}
                      control={control}
                      clearable={true}
                      inputVariant="outlined"
                      helperText={errors?.end_date && errors?.end_date?.message}
                      valueGot={bEndDate}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <Button
                      color="primary"
                      className={"mt-15"}
                      onClick={(e: any) => {
                        getBookingsByDate();
                      }}
                    >
                      Filter
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {bookingChart && (
                  <div className="chart-container" style={{ height: "40vh" }}>
                    <Line options={options} data={bookingChart} />
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {/* <div class="chart-container" style="position: relative; height:40vh; width:80vw"> */}

            <Card chart>
              <CardHeader>
                <GridContainer spacing={1}>
                  <GridItem xs={8} sm={8} md={4}>
                    {" "}
                    <h3 className={classes.cardTitle}>Services</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="stype"
                      autoComplete="off"
                      label="* Filter By"
                      control={control}
                      setValue={setValue}
                      options={filterOptions}
                      isLoading={true}
                      iProps={{
                        onChange: handleServiceFilter,
                      }}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.type && errors?.type?.message}
                      valueGot={""}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <DateField
                      errors={!!errors?.start_date}
                      fieldName="s_start_date"
                      autoComplete="off"
                      label="Start Date"
                      iProps={{
                        format: "dd-MM-yyyy",
                        onChange: (date: any) => setSStartDate(date),
                      }}
                      clearable={true}
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      helperText={
                        errors?.start_date && errors?.start_date?.message
                      }
                      valueGot={sStartDate}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <DateField
                      errors={!!errors?.end_date}
                      fieldName="s_end_date"
                      autoComplete="off"
                      label="End Date"
                      setValue={setValue}
                      iProps={{
                        format: "dd-MM-yyyy",
                        onChange: (date: any) => setSEndDate(date),
                      }}
                      control={control}
                      inputVariant="outlined"
                      helperText={errors?.end_date && errors?.end_date?.message}
                      valueGot={sEndDate}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <Button
                      color="primary"
                      className={"mt-15"}
                      onClick={(e: any) => {
                        getBookingsByService();
                      }}
                    >
                      Filter
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {pieChart && (
                  <div className="chart-container" style={{ height: "40vh" }}>
                    <Pie options={options} data={pieChart} />
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {/* <div class="chart-container" style="position: relative; height:40vh; width:80vw"> */}

            <Card chart>
              <CardHeader>
                <GridContainer spacing={1}>
                  <GridItem xs={8} sm={8} md={4}>
                    {" "}
                    <h3 className={classes.cardTitle}>Customers</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="type"
                      autoComplete="off"
                      label="* Filter By"
                      control={control}
                      setValue={setValue}
                      options={filterOptions}
                      isLoading={true}
                      iProps={{
                        onChange: filterHandleChange,
                      }}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.type && errors?.type?.message}
                      valueGot={""}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <DateField
                      errors={!!errors?.start_date}
                      fieldName="start_date"
                      autoComplete="off"
                      label="* Start Date"
                      iProps={{
                        format: "dd-MM-yyyy",
                        onChange: onStartChange,
                      }}
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      helperText={
                        errors?.start_date && errors?.start_date?.message
                      }
                      valueGot={startDate}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <DateField
                      errors={!!errors?.end_date}
                      fieldName="end_date"
                      autoComplete="off"
                      label="* End Date"
                      setValue={setValue}
                      iProps={{
                        format: "dd-MM-yyyy",
                        onChange: onEndChange,
                      }}
                      control={control}
                      inputVariant="outlined"
                      helperText={errors?.end_date && errors?.end_date?.message}
                      valueGot={endDate}
                    />
                  </GridItem>
                  <GridItem xs={2} md={2} sm={2}>
                    <Button
                      color="primary"
                      className={"mt-15"}
                      onClick={(e: any) => {
                        filterDate();
                      }}
                    >
                      Filter
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>
                {chartValues && (
                  <div className="chart-container" style={{ height: "40vh" }}>
                    <Line options={options} data={chartValues} />
                  </div>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </>
      )}

      {/* ---Charts/Map Start--- */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader>
              <GridContainer spacing1={1}>
                <GridItem xs={8} sm={8} md={4}>
                  {" "}
                  <h3 className={classes.cardTitle}>Drivers</h3>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    style={{ width: "100%" }}
                  >
                    <Button
                      color="primary"
                      className="custom-btn"
                      onClick={() => {
                        refreshDriver();
                      }}
                    >
                      Refresh
                    </Button>
                  </Box>
                </GridItem>
              </GridContainer>{" "}
            </CardHeader>

            {driverMapLoader == true ? (
              <Skeleton
                variant="rect"
                animation="wave"
                width={"auto"}
                height={500}
              />
            ) : (
              <CardBody>
                {driverLocation.length > 0 && (
                  <GoogleMap
                    autocomplete
                    zoom={12}
                    center={{
                      lat: driverLocationCenter?.latitude
                        ? Number(driverLocationCenter?.latitude)
                        : 25.0981,
                      lng: driverLocationCenter?.longitude
                        ? Number(driverLocationCenter?.longitude)
                        : 55.24118,
                    }}
                  >
                    {driverLocation?.map((item: any, index: any) => {
                      return (
                        <Marker
                          key={item?.uuid}
                          position={{
                            lat: Number(item?.latitude),
                            lng: Number(item?.longitude),
                          }}
                          onClick={() => {
                            handleActiveMarker(index);
                          }}
                        >
                          {activeMarker === index ? (
                            <InfoWindow
                              onCloseClick={() => setActiveMarker(null)}
                            >
                              <div>
                                <Typography variant="subtitle2">
                                  {item?.first_name + " " + item?.last_name}
                                </Typography>
                                <Typography variant="subtitle2">
                                  {item?.mobile_no}
                                </Typography>
                              </div>
                            </InfoWindow>
                          ) : null}
                        </Marker>
                      );
                    })}
                  </GoogleMap>
                )}
              </CardBody>
            )}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader>
              <GridContainer spacing1={1}>
                <GridItem xs={8} sm={8} md={4}>
                  {" "}
                  <h3 className={classes.cardTitle}>Orders</h3>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    style={{ width: "100%" }}
                  >
                    <Button
                      color="primary"
                      className="custom-btn"
                      onClick={() => {
                        refreshOrderLoader();
                      }}
                    >
                      Refresh
                    </Button>
                  </Box>
                </GridItem>
              </GridContainer>{" "}
            </CardHeader>

            {orderMapLoader == true ? (
              <Skeleton
                variant="rect"
                animation="wave"
                width={"auto"}
                height={500}
              />
            ) : (
              <CardBody>
                <GoogleMap
                  autocomplete
                  zoom={12}
                  center={{
                    lat: orderheatmapCenter?.latitude
                      ? Number(orderheatmapCenter?.latitude)
                      : 25.0981,
                    lng: orderheatmapCenter?.longitude
                      ? Number(orderheatmapCenter?.longitude)
                      : 55.24118,
                  }}
                  heatmapLayer={orderheatmap}
                />
              </CardBody>
            )}
          </Card>
        </GridItem>
      </GridContainer>
      {/* ---Charts/Map End--- */}
    </div>
  );
}

import * as yup from "yup";
export const AboutValidation: any = yup.object().shape({
  //about banner validation
  name: yup.string().required("please enter Banner Text").nullable(),

  //who we are validation
  who_we_are_title: yup.string().required("please enter Title").nullable(),
  who_we_are_description: yup
    .string()
    .required("please enter Description")
    .nullable(),

  //array validation factcolumn
  fact_columns: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      description: yup.string().required("Please enter Description").nullable(),
    })
  ),

  //fieldArray validation accomplishments
  accomplishments: yup.array().of(
    yup.object().shape({
      year: yup
        .string()
        .required("Please enter Year")
        .matches(/^\d+$/, "Please enter numaric value only")
        .nullable(),
      description: yup.string().required("Please enter Description").nullable(),
    })
  ),

  //Award Winning validation

  award_winning: yup.object().shape({
    title: yup.string().required("Please enter Title").nullable(),
    description: yup.string().required("Please enter Description").nullable(),

    award_cards: yup.array().of(
      yup.object().shape({
        title: yup.string().required("Please enter title").nullable(),
        description: yup
          .string()
          .required("Please enter Description")
          .nullable(),
        date: yup.string().required("Please enter date/Text").nullable(),
      })
    ),
  }),
});

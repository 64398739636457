export const permissionsx = [
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    sectionName: "Roles",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    sectionName: "Roles",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Roles",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Users",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    sectionName: "Users",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    sectionName: "Users",
  },
  {
    sectionName: "Location",
    subsection: "Country",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Country",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Country",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "City",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "City",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "City",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Branch",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Branch",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Branch",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Zone",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Zone",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Location",
    subsection: "Zone",
    specific: "update",
    permission: true,
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Schedule",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    sectionName: "Schedule",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    sectionName: "Schedule",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Service",
    sectionName: "Services",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Service",
    sectionName: "Services",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Service",
    sectionName: "Services",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Config",
    sectionName: "Services",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Config",
    sectionName: "Services",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Config",
    sectionName: "Services",
  },
  {
    sectionName: "Vehicles",
    subsection: "Type",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Type",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Type",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Model",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Model",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Model",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Make",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Make",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Vehicles",
    subsection: "Make",
    specific: "create",
    permission: true,
  },
  {
    specific: "read",
    permission: true,
    subsection: "Manage",
    sectionName: "Promotion",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Manage",
    sectionName: "Promotion",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Manage",
    sectionName: "Promotion",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Push Notification",
    sectionName: "Notification",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Alerts",
    sectionName: "Notification",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Push Notification",
    sectionName: "Notification",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Push Notification",
    sectionName: "Notification",
  },
  {
    specific: "read",
    permission: true,
    subsection: "Package",
    sectionName: "Packages",
  },
  {
    specific: "create",
    permission: true,
    subsection: "Package",
    sectionName: "Packages",
  },
  {
    specific: "update",
    permission: true,
    subsection: "Package",
    sectionName: "Packages",
  },
  {
    sectionName: "Services",
    subsection: "Category",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Services",
    subsection: "Category",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Services",
    subsection: "Category",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Application Setting",
    subsection: "Manage",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Application Setting",
    subsection: "Manage",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Application Setting",
    subsection: "Manage",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Application Setting",
    subsection: "Alert Setting",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Application Setting",
    subsection: "Alert Setting",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Application Setting",
    subsection: "Alert Setting",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Content",
    subsection: "Manage",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Content",
    subsection: "Manage",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Content",
    subsection: "Manage",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Content",
    subsection: "Email",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Content",
    subsection: "Email",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Content",
    subsection: "Email",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Driver",
    subsection: "Driver",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Driver",
    subsection: "Driver",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Driver",
    subsection: "Driver",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Vendor",
    subsection: "Manage",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Vendor",
    subsection: "Manage",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Vendor",
    subsection: "Manage",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Manage",
    specific: "read",
    permission: true,
  },
  //   {
  //       "sectionName": "Customer",
  //       "subsection": "Manage",
  //       "specific": "create",
  //       "permission": true
  //   },
  {
    sectionName: "Customer",
    subsection: "Manage",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Customer Vehicles",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Customer Vehicles",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Customer Vehicles",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Customer Address",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Customer Address",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Customer Address",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Customer",
    subsection: "Packages",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Reports",
    subsection: "Manage",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Booking",
    subsection: "Booking",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Booking",
    subsection: "View",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Booking",
    subsection: "Booking",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Transaction",
    subsection: "Transaction",
    specific: "read",
    permission: true,
  },
  // {"sectionName":"Transaction",
  // "subsection":"View",
  // "specific":"read",
  // "permission":true
  // },
  {
    sectionName: "Contact Us",
    subsection: "Contact Us",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Contact Us",
    subsection: "View",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "FAQ",
    subsection: "Manage",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "FAQ",
    subsection: "Manage",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "FAQ",
    subsection: "Manage",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "FAQ",
    subsection: "Type",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "FAQ",
    subsection: "Type",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "FAQ",
    subsection: "Type",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Driver",
    subsection: "Config",
    specific: "read",
    permission: true,
  },
  {
    sectionName: "Driver",
    subsection: "Config",
    specific: "create",
    permission: true,
  },

  // {
  //     "sectionName": "HomePage",
  //     "subsection": "HomePage",
  //     "specific": "read",
  //     "permission": true
  // },

  // {
  //     "sectionName": "HomePage",
  //     "subsection": "HomePage",
  //     "specific": "update",
  //     "permission": true
  // },
  // {
  //     "sectionName": "ThemeSettings",
  //     "subsection": "ThemeSettings",
  //     "specific": "read",
  //     "permission": true
  // },

  // {
  //     "sectionName": "ThemeSettings",
  //     "subsection": "ThemeSettings",
  //     "specific": "update",
  //     "permission": true
  // },
  //webService=============>
  {
    sectionName: "Website",
    subsection: "Webservices",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Webservices",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Webservices",
    specific: "read",
    permission: true,
  },
  //partnership===========>
  {
    sectionName: "Website",
    subsection: "Partnership",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Partnership",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Partnership",
    specific: "read",
    permission: true,
  },
  //Media===========>
  {
    sectionName: "Website",
    subsection: "Media",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Media",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Media",
    specific: "read",
    permission: true,
  },
  //Events===========>
  {
    sectionName: "Website",
    subsection: "Events",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Events",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Events",
    specific: "read",
    permission: true,
  },
  //business===========>
  {
    sectionName: "Website",
    subsection: "Website Business",
    specific: "read",
    permission: true,
  },
  //webiste pages===========>
  {
    sectionName: "Website",
    subsection: "Website Pages",
    specific: "read",
    permission: true,
  },

  //webiste pages===========>
  {
    sectionName: "Website",
    subsection: "Theme Settings",
    specific: "read",
    permission: true,
  },

  // Media Category===========>.

  {
    sectionName: "Website",
    subsection: "Media Category",
    specific: "create",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Media Category",
    specific: "update",
    permission: true,
  },
  {
    sectionName: "Website",
    subsection: "Media Category",
    specific: "read",
    permission: true,
  },
];

import httpservice from "./httpservice";


export const getNotification = async (body: any) => {
  return httpservice.post("/admin/user/notification/user", body)
}
export const createNotification = async (body: any) => {
  console.log("body", body)
  return httpservice.post("/notifications/send", body, { headers: { "internal-header-verify": "internal-request" } })
}

export function getAutomatedNotificationOdata(body: any = null) {
  return httpservice.get("/notifications/automated/odata?" + body)
}

export function getAutomatedNotification(body: any = null) {
  return httpservice.get("/notifications/automated/", { params: body })
}

export function createAutomatedNotification(body: any) {
  return httpservice.post("/notifications/automated", body)
}

export function updateAutomatedNotification(body: any) {
  return httpservice.put("/notifications/automated", body)
}

export const createNotificationBulk = async (body: any) => {
  return httpservice.post("/notifications/send/bulk", body)
}

export const createNotificationToAll = async (body: any) => {
  console.log("body", body)
  return httpservice.post("/notifications/send/all", body)
}

export const getNotificationList = async (body: any) => {
  return httpservice.get("/notifications/", { params: body })
}
import * as yup from "yup";

const createBranchValidation:any = yup.object().shape({
    name: yup.string().required("Please type branch name.").nullable(),
    // mobile_no: yup.string().required("Please enter mobile no").nullable(),
    city_id: yup.string().required("Please select city").nullable(),
    country_id: yup.string().required("Please select country").nullable(),
    // schedule_id:yup.string().nullable(),
    polygon: yup.object({
        latLng: yup.array().required("Please draw map."),
      }).required("Please draw map.").nullable(),
    // email: yup.string().required("Please enter email").email().nullable(),
  });

  

  

  export{
      createBranchValidation,
  }
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getCustomerPackage} from "services/customerServices";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import { Link,useLocation } from "react-router-dom";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import Button from "components/CustomButtons/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { FormHelperText, Typography } from "@material-ui/core";

import GoogleMap from "components/GoogleMap/GoogleMapMarker";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);



export const CustomerView = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([])
  const [mapValues, setMapValues] = useState<any>(null);

  const [editData, setEditData] = useState<any>(null)
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const AddressUpdate = (data:any) =>{
    if(data){
      setMapValues(data)
    }
  }

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Customer",
      'link': ''
    },

  ];

  useEffect(() => {
    dispatch(spinLoaderShow(true));

    getApiData()

  }, [])
  useEffect(() => {
    let ispermission = !(verifyPermission(
      getPermission,
      'Packages',
      'Package',
      'update'
    ))
    setDisabled(ispermission)
  }, [getPermission])

  const columnDefs: any = [
       
    {
      headerName: "Service",
      field: "service_configs.service.name",
    },
    {
      headerName: "Price",
      field: "price",
    },
    {
      headerName: "Quantity",
      field: "quantity",
    },
    {
      headerName: "Consumed",
      field: "consumed",
    },
    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },

    {
      headerName: 'Created Date',
      field: "createdAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
      filter: "agDateColumnFilter",
      sort: "desc",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format('llll');
      },
    }
  ]

  const getApiData = () =>{
    getCustomerPackage({uuid :uuid}).then((e: any) => {
      let data =  e?.data?.result[0]?.customer_package_services.map((item:any)=>{
        item.status=item.status? 'active' : 'inactive' 
         return item; 
      })
      setEditData(e?.data?.result[0]?.jsonDump?.location)
      console.log("Saad Confi",e?.data?.result[0]?.jsonDump?.location)
      setRowData(data)
      dispatch(spinLoaderShow(false));

    }).catch((err: any) => {
      dispatch(spinLoaderShow(false))
    })

  }
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()
  
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Customer Packages"
            routes={routes}
            // newItem={[{ text: 'Add New Package', link: "package/add" }]}
            permission="Packages.Package.create"
            newItem={[{ text: 'Back', link: "/admin/customer/packages" }]}
            backIcon
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Customer Package Details</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>


          </CardHeader>
          <CardBody>
          {editData && <Card>
        <GridItem xs={12} sm={12} md={12}>
                            <Typography variant={"subtitle1"}>
                              Package Buying Location:
                             
                            </Typography>
                            {editData  && (
                              <FormHelperText 
                            
                                className="google-map-error"
                              >
                                <GoogleMap
                                autocomplete
                                // value={setMapValues}
                                // setValueProp={AddressUpdate}
                                  draggable={disabled ? !disabled : true}
                                  setValue={{
                                    latLng: {
                                      lat: editData?.latitude
                                        ? +editData?.mapValues?.latitude
                                        : 25.276987,
                                      lng: editData?.longitude
                                        ? +editData?.longitude
                                        : 55.296249,
                                    },
                                  }}
                            
                                  zoom={
                                    editData?.zoom
                                      ? +editData?.zoom
                                      : 14
                                  }
                                  center={{
                                    lat: editData?.latitude
                                      ? +editData?.latitude
                                      : 25.276987,
                                    lng: editData?.longitude
                                      ? +editData?.longitude
                                      : 55.296249,
                                  }}
                                  onCenterChanged={(val:any)=>{
                                    console.log("Changed",val)
                                  }}
                                >
                                
                                </GoogleMap>
                              </FormHelperText>
                            )}
                         
                </GridItem>
        </Card>}

          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>

      
      </GridItem>
    </GridContainer>
  );
}


import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import _ from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useForm } from "react-hook-form";
import {
  InputField,
  LoadingButton,
  SwitchFieldDefault,
  PhoneNoField,
  SelectField,
} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCustomerValidation } from "./Validations/customerValidation";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { updateCustomer, getCustomerDetail } from "services/customerServices";
import { verifyPermission } from "helper/permission";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "components/CustomButtons/Button";
import { UPDATE_SUCCESS_MSG } from "json/constants";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};

const defaultFormValues: any = {
  name: "",
  mobile_no: "",
  email: "",
  city_id: "",
  schedule_id: null,
  country_id: "",
  status: true,
};
const useStyles = makeStyles(styles);
declare var google: any;

export default function AddCustomer(props: any) {
  const classes = useStyles();
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");
  const [editData, setEditData] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false);
  const [rowData, setRowData] = useState([]);
  const [profileImg, setProfileImg] = useState<any>("");

  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch();
  let history = useHistory();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createCustomerValidation),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Customer",
      link: "/admin/customer",
    },
    {
      name: headerTitle,
      link: "",
    },
  ];

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getCustomerDetail({ uuid: uuid }).then((e: any) => {
        let data: any = e?.data?.result?.[0];

        console.log("data", data);

        console.log("Referred Users", data.referredUsers);

        setValue("first_name", data?.customerInfo?.first_name);
        setValue("last_name", data?.customerInfo?.last_name);
        setValue("phone_number", data?.customerInfo?.phone_number);
        setValue("status", data?.customerInfo?.status);
        setValue("email", data?.customerInfo?.email);
        setValue("referral_code", data?.customerInfo?.referral_code);
        setValue(
          "registration_referral_code",
          data?.customerInfo?.registration_referral_code
        );
        setValue(
          "registration_referral_used",
          data?.customerInfo?.registration_referral_used
        );
        setValue("redeem_referral", data?.customerInfo?.redeem_referral);
        setValue(
          "redeem_referral_count",
          data?.customerInfo?.redeem_referral_count
        );
        setValue(
          "redeem_referral_expriy",
          data?.customerInfo?.redeem_referral_expriy
        );
        setValue("device_type", data?.customerInfo?.device_type);
        setValue(
          "referredUsers_first_name",
          data?.referredUsers?.customer?.first_name
        );
        setValue(
          "referredUsers_last_name",
          data?.referredUsers?.customer?.last_name
        );
        setValue(
          "referredUsers_phone_number",
          data?.referredUsers?.customer?.phone_number
        );
        setValue("referredUsers_email", data?.referredUsers?.customer?.email);
        setValue(
          "referredUsers_referral_code",
          data?.referredUsers?.customer?.referral_code
        );
        setValue(
          "referredUsers_registration_referral_code",
          data?.referredUsers?.customer?.registration_referral_code
        );
        setValue(
          "referredUsers_createdAt",
          data?.referredUsers?.customer?.createdAt
        );
        setValue(
          "referredUsers_updatedAt",
          data?.referredUsers?.customer?.updatedAt
        );
        setProfileImg(data?.customerInfo?.profile_image);
        setEditData(data);
        console.log("opfkdkf", data);

        let temp: any = new URLSearchParams(search).get("type");
        temp ? setHeaderTitle("View") : setHeaderTitle("Edit");
        temp == "view" ? setDisabled(true) : setDisabled(false);
        setRowData(data.referredUsers);
        dispatch(spinLoaderShow(false));
      });
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
    }
  }, [uuid]);

  const columnDefs: any = [
    {
      headerName: "First Name",
      field: "customer.first_name",
    },
    {
      headerName: "Last Name",
      field: "customer.last_name",
    },
    {
      headerName: "Email",
      field: "customer.email",
    },
    {
      headerName: "Phone Number",
      field: "customer.phone_number",
    },
    {
      headerName: "Referral Code",
      field: "customer.referral_code",
    },
    {
      headerName: "Registration Referral Code",
      field: "customer.registration_referral_code",
    },
    {
      headerName: "Booking",
      field: "booking",
    },
    {
      headerName: "By Code",
      field: "byCode",
    },
    {
      headerName: "Created Date",
      field: "customer.createdAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format("llll");
      },
    },
    {
      headerName: "Updated Date",
      field: "customer.createdAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format("llll");
      },
    },
  ];

  const onSubmit = async (data: any) => {
    let _data = {
      uuid: uuid,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      status: data.status,
    };
    dispatch(spinLoaderShow(true));
    updateCustomer(_data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/customer");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Customer"
              routes={routes}
              newItem={[{ text: "Back", link: "/admin/customer" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <h4 className={classes.cardTitleWhite}>
                    {" "}
                    {headerTitle} Customer
                  </h4>
                  {uuid ? (
                    <h4 className={classes.cardTitleWhite}>
                      Modified At: {moment(editData?.updatedAt).format("llll")}
                    </h4>
                  ) : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={1}
                    style={{ marginTop: "06px" }}
                  >
                    <Avatar
                      className={classes.avatar}
                      alt="Remy Sharp"
                      src={
                        profileImg ? profileImg : "/static/images/avatar/1.jpg"
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.first_name}
                      fieldName="first_name"
                      type="text"
                      autoComplete="false"
                      label="* First Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.first_name && errors?.first_name?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.last_name}
                      fieldName="last_name"
                      type="text"
                      autoComplete="false"
                      label="* Last Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.last_name && errors?.last_name?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.email}
                      fieldName="email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                      }}
                      setValue={setValue}
                      helperText={errors?.email && errors?.email?.message}
                    />
                  </GridItem>
                  {/* <GridItem xs={4} sm={4} md={4}>
                    <SelectField
                      errors={!!errors?.gender}
                      fieldName="gender"
                      type="text"
                      autoComplete="false"
                      label="* Gender"
                      control={control}
                      valueGot={(editData && editData?.gender == 'Male') ? { 'id': 'male', 'name': 'Male' } : { 'id': 'female', 'name': 'Female' }}
                      selectOptions={
                        [{ 'id': 'male', 'name': 'Male' }, { 'id': 'female', 'name': 'Female' }]
                      }
                      setValue={setValue}
                      helperText={
                        errors?.gender && errors?.gender?.message
                      }
                    />
                  </GridItem> */}
                  <GridItem xs={4} sm={4} md={3}>
                    <PhoneNoField
                      errors={!!errors?.phone_number}
                      fieldName="phone_number"
                      autoComplete="off"
                      label="* Phone Number"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                        disableDropdown: disabled,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.phone_number && errors?.phone_number?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.device_type}
                      fieldName="device_type"
                      autoComplete="off"
                      label="* Device Type"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.device_type && errors?.device_type?.message
                      }
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.redeem_referral}
                      fieldName="redeem_referral"
                      type="text"
                      autoComplete="false"
                      label="Redeem Referral"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: true,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.redeem_referral &&
                        errors?.redeem_referral?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.redeem_referral_count}
                      fieldName="redeem_referral_count"
                      type="text"
                      autoComplete="false"
                      label="Redeem Referral Count"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: true,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.redeem_referral_count &&
                        errors?.redeem_referral_count?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.referral_code}
                      fieldName="referral_code"
                      type="text"
                      autoComplete="false"
                      label="Referral Code"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: true,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.referral_code && errors?.referral_code?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.registration_referral_code}
                      fieldName="registration_referral_code"
                      type="text"
                      autoComplete="false"
                      label="Registratio Referral Code"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: true,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.registration_referral_code &&
                        errors?.registration_referral_code?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={3}>
                    <InputField
                      errors={!!errors?.registration_referral_used}
                      fieldName="registration_referral_used"
                      type="text"
                      autoComplete="false"
                      label="Registration Referral Used"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: true,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.registration_referral_used &&
                        errors?.registration_referral_used?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Referred Bookings</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                          <AgGridCustom
                            columnDefs={columnDefs}
                            rowData={rowData}
                            disabledCheckBox={true}
                            getRowNodeId={function (data: any) {
                              return data.uuid;
                            }}
                          />
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    disabled={disabled}
                    className={"custom-btn"}
                    type="submit"
                  >
                    {uuid ? "Update Customer" : "Create Customer"}
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

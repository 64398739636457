import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputField } from 'components/Forms/Formfield.component';
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import { createSocialsValidation } from './Validations/socials-validations'
import ControlledAccordion from "components/common/controlledAccordion.component";



export const SocialsAccordion = (props: any) => {
    const { sendToParent, data,permission,collapse } = props
    const defaultFormValues: any = {
        facebook:'',
        linkedin:'',
        twitter:'',
        instagram:'',
    }
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultFormValues,
        resolver: yupResolver(createSocialsValidation)

    });
    useEffect(() => {
        if (data) {
            setValue("facebook",String(data?.socials?.find((x:any)=>x.name == 'Facebook').value))
            setValue("linkedin",String(data?.socials?.find((x:any)=>x.name == 'Linkedin').value))
            setValue("twitter",String(data?.socials?.find((x:any)=>x.name == 'Twitter').value))
            setValue("instagram",String(data?.socials?.find((x:any)=>x.name == 'Instagram').value))


        }
    }, [data])
    const onSubmit = (data: any) => {
        let modify_data = [
            {name:"Facebook",
             value:data?.facebook},
             {
                 name:"Linkedin",
                 value:data?.linkedin
             },
             {
                name:"Twitter",
                value:data?.twitter
            },
            {
                name:"Instagram",
                value:data?.instagram
            }

        ]
        data.socials= modify_data
        delete data?.twitter
        delete data?.facebook
        delete data?.linkedin
        delete data?.instagram
     
         sendToParent(data)
        
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ControlledAccordion collapse={collapse}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Socials</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <GridItem xs={4} md={4} sm={4}>
                    <InputField
                errors={!!errors?.facebook}
                fieldName="facebook"
                type="text"
                autoComplete="false"
                label="* Facebook"
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.facebook && errors?.facebook?.message}
                
              />

                    </GridItem>
                    <GridItem xs={4} md={4} sm={4}>
                        <InputField
                            errors={!!errors?.linkedin}
                            fieldName="linkedin"
                            type="text"
                            autoComplete="false"
                            label="* Linkedin"
                            control={control}
                            valueGot={""}                           
                            setValue={setValue}
                            helperText={errors?.linkedin && errors?.linkedin?.message}

                        />

                    </GridItem>
                    <GridItem xs={4} md={4} sm={4}>
                        <InputField
                            errors={!!errors?.twitter}
                            fieldName="twitter"
                            type="text"
                            autoComplete="false"
                            label="* Twitter"
                            control={control}
                            valueGot={""}                           
                            setValue={setValue}
                            helperText={errors?.twitter && errors?.twitter?.message}

                        />

                    </GridItem>
                    <GridItem xs={4} md={4} sm={4}>
                        <InputField
                            errors={!!errors?.instagram}
                            fieldName="instagram"
                            type="text"
                            autoComplete="false"
                            label="* Instagram"
                            control={control}
                            valueGot={""}                           
                            setValue={setValue}
                            helperText={errors?.instagram && errors?.instagram?.message}

                        />

                    </GridItem>


                </AccordionDetails>
                <AccordionActions>
                    <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                    {permission === false ?<Button color="primary" className={"custom-btn"} type="submit" >Submit</Button> : ''}
                    </Box>

                </AccordionActions>
            </ControlledAccordion>

        </form>
    )
}
import httpservice from "./httpservice";



export const getFaq = async (body: any = null) => {
    return httpservice.get("/faq", { params: body })
}
export const createFaq = async (body: any) => {
    return httpservice.post("/faq", body)
}
export const updateFaq = (body: any) => {
    return httpservice.put("/faq", body)
}
export const getFaqType = async (body: any = null) => {
    return httpservice.get("/faq-types", { params: body })
}
export const createFaqType = async (body: any) => {
    return httpservice.post("/faq-types", body)
}
export const updateFaqType = (body: any) => {
    return httpservice.put("/faq-types", body)
}
import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

 
export function getWebSection(body: any = null) {
    return httpservice.get('/webservice', { params: body })
  }
  export function createHeaderSection(body: any) {
    return httpservice.post('/themesetting', body)
  }
  export function updateWebSection(body: any) {
    return httpservice.post('/webservice', body)
  }
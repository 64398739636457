import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getTransactions } from "services/userservices";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import Button from "components/CustomButtons/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AgGridEnterprise from "components/AgGridCustom/AgGridEnterprise"; 
import {transactionOData} from 'services/oDataServices'

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);



export const TransactionList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Transaction",
      'link': ''
    }
  ];

  useEffect(() => {
    // dispatch(spinLoaderShow(true));
    // getApiData()
   
    let ispermission = !(verifyPermission(
      getPermission,
      'Transaction',
      'View',
      'read'
    ))
    console.log(ispermission)
    setDisabled(ispermission)

  }, [])
  
  const getApiData = () =>{
    getTransactions().then((e) => {
      let data = e?.data?.result.map((item: any) => {
        item.status = item.status ? 'active' : 'inactive'
        item.promotion_auto_applied = item.promotion_auto_applied ? 'Yes' : 'No'
        return item;
      })
      setRowData(data)
      dispatch(spinLoaderShow(false));

    }).catch((err) => {
      console.log(err)
      dispatch(spinLoaderShow(false))
    })
  }


  const columnDefs: any = [
    {
      headerName: "Booking ID	",
      field: "order_id",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: [ 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }

    },
    {
      headerName: "Customer First Name",
      field: "customer_firstname"  ,
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }  
    },
    {
      headerName: "Customer Last Name",
      field: "customer_lastname"  ,
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }  
    },
    {
      headerName: "Package Name",
      field: "package_name",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Service Name",
      field: "service_name",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Payment Type",
      field: "payment_type",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Transaction Reference Number",
      field: "transaction_ref",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Amount",
      field: "amount",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },

    {
      headerName: "Vat",
      field: "vat",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Discount Value",
      field: "discount_value",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Total Paid",
      field: "total_paid",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Promotion Name",
      field: "promotion_name",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Promo Auto Applied",
      field: "promotion_auto_applied",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Payment Status",
      field: "payment_status",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Transaction Comment",
      field: "additional_comment",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: 'Transaction Date',
      field: "createdAt",
      sort: "desc",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

    // },
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: { 
      newRowsAction: 'keep', // Preserve the existing filters when adding new ones
      filterOptions: ['equals', 'lessThan', 'greaterThan'], 
      suppressAndOrCondition: true, // Remove the "AND" and "OR" checkbox
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
    },
  ]
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()
  
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Transaction"
            routes={routes}
            // newItem={[{ text: 'Add New Package', link: "package/add" }]}
            // permission="Packages.Package.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Transaction List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>


          </CardHeader>
          <CardBody>
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => {exportData && exportData?.refreshServerSideStore({ purge: true })}}>Refresh</Button>
          <AgGridEnterprise 
              dataSourceUrl={transactionOData}
              columnDefs={columnDefs} 
              disabledCheckBox={true}
              getRowNodeId={(data: any) => {
                return data.uuid;
              }}
              setExportData={setExportData}
              componentType="normal"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

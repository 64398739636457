import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import GoogleMap from "components/GoogleMap/GoogleMap";
import _, { rest } from "lodash";
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  InputField,
  AutoCompleteField,
  SwitchField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCountryValidation } from "./Validations/countryValidations";
import {
  createCountry,
  getCountry,
  updateCountry,
  getSchedules,
} from "services/locationservices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import DrawMap from 'components/GoogleMap/DrawMap';
import { Country, State, City } from 'country-state-city';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useParams, useLocation
} from "react-router-dom";
import { FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import moment from 'moment';

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  country_code: "",
  country_name: "",
  currency: "",
  status: true,
  polygon: {},
  schedule_id: null,
};

const useStyles = makeStyles(styles);

export default function AddCountry(props: any) {
  const classes = useStyles();
  const [searchLocation, setSearchLocation] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [mapValues, setMapValues] = useState<any>(null);
  const [schedule, setSchedule] = useState<any>([]);
  const [resetMap, setResetMap] = useState<any>(false);
  const [editData, setEditData] = useState<any>(null);
  const [countries, setCountries] = useState<any>([])
  const [disabled,setDisabled] = useState<any>(false)
  const { search } = useLocation()

  let uuid = new URLSearchParams(search).get("uuid");
  let history = useHistory();

  const dispatch = useDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createCountryValidation),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Country ",
      link: "/admin/country",
    },
    {
      'name': headerTitle,
      'link': ''
    },
  ];

  useEffect(() => {
    getSchedules({ status: true ,type:"Location" }).then((e) => {
      setSchedule(e.data.result);
    });
    setCountries(Country.getAllCountries())
    let temp: any = new URLSearchParams(search).get("type");
    if (temp == 'view' ){
      setDisabled(true);
    }
  }, []);

  

  const getCountryData = () => {
    getCountry({ uuid: uuid })
      .then((e) => {
        let data: any = e?.data?.result?.[0];
        setValue("country_code", data?.country_code);
        setValue("country_name", data.country_name);
        setValue("currency", data.currency);
        setValue("status", data.status);
        setValue("schedule_id", data.schedule_id);
        setMapValues(data.polygon)
        setEditData(data);
        let temp: any = new URLSearchParams(search).get("type");
       temp ? setHeaderTitle('View'): setHeaderTitle("Edit")
        dispatch(spinLoaderShow(false))
      })
  }

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getCountryData()
    }
    else {
      reset(defaultFormValues)
      setEditData({})
    }
  }, [uuid]);

  useEffect(() => {
    if (!!mapValues?.latLng && Object.keys(mapValues?.latLng)?.length > 0) {
      setValue("polygon", mapValues?.latLng);
    } else {
      setValue("polygon", {});
    }
    if (!!mapValues?.autocomplete) {
      setSearchLocation(mapValues?.autocomplete);
    }
  }, [mapValues]);

  const onSubmit = async (data: any) => {
    dispatch(spinLoaderShow(true));
    if (data?.schedule_id == "") {
      data['schedule_id'] = null
    }
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {

    createCountry(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/country");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    updateCountry(data)
      .then((e: any) => {
        setEditData(null)
        getCountryData()
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/country");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const handleCountryChange = (data: any) => {
    setValue('currency', data?.currency);
    setValue('country_code', data?.isoCode);
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Country "
              routes={routes}
              newItem={[
                { text: 'Back', link: "/admin/country" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Country</h4>
                {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null }  
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.country_name}
                      fieldName="country_name"
                      autoComplete="off"
                      label="* Select Country"
                      control={control}
                      setValue={setValue}
                      options={countries}
                      isLoading={countries.length > 0 ? true : false}
                      optionKey="name"
                      optionValue="name"
                      iProps={{
                        onChange: handleCountryChange,
                        disabled:disabled
                      }}
                      helperText={
                        errors?.country_name && errors?.country_name?.message
                      }
                      valueGot={(countries.length > 0) ? (countries?.find((e: any) => e.name == editData?.country_name)) : {}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.country_code}
                      fieldName="country_code"
                      type="text"
                      autoComplete="off"
                      label="* Country Code"
                      control={control}
                      valueGot={""}
                      iProps={
                        {
                          disabled: true
                        }
                      }
                      setValue={setValue}
                      helperText={
                        errors?.country_code && errors?.country_code?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      disabled={disabled}
                      setValue={setValue}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.currency}
                      fieldName="currency"
                      type="text"
                      autoComplete="off"
                      label="* Currency"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{ 
                        disabled:disabled
                      }}
                      helperText={errors?.currency && errors?.currency?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.schedule_id}
                      fieldName="schedule_id"
                      autoComplete="off"
                      label="Select Schedule"
                      control={control}
                      setValue={setValue}
                      options={schedule}
                      isLoading={schedule.length > 0 ? true : false}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={
                        errors?.schedule_id && errors?.schedule_id?.message
                      }
                      iProps={{ 
                        disabled:disabled
                      }}
                      valueGot={editData?.schedule && editData?.schedule}
                    />
                  </GridItem>
                </GridContainer>
                <Box mt={2}>
                  <GridContainer mt={2}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant={"subtitle1"}>
                        Draw area:
                        <span className="google-map-error-text">
                          {errors.polygon && errors?.polygon?.latLng?.message}
                        </span>
                      </Typography>
                      {editData &&
                        <FormHelperText error={errors.polygon && true} className="google-map-error">
                          <GoogleMap
                            value={setMapValues}
                            setValue={{
                              latLng: editData?.polygon
                            }}
                            autocomplete
                            polygon={disabled ? false : true}
                            reset={resetMap}
                            zoom={editData?.polygon?.zoom ? editData?.polygon?.zoom : 6}
                            center={editData?.polygon?.center ? editData?.polygon?.center : {
                              lat: 25.276987,
                              lng: 55.296249
                            }}
                          >
                            <DrawMap data={editData} />
                          </GoogleMap>
                        </FormHelperText>
                      }
                    </GridItem>
                  </GridContainer>
                </Box>
              </CardBody>

              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button color="primary" className="custom-btn" disabled={disabled} type="submit" >{uuid ? 'Update Country' : "Create Country"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "./httpservice";

export
function getVehicleBrand(body: any = null) {
    return httpservice.get('vehicle/brand', {params:body})
  }
 export function createVehicleBrand(body: any) {
    return httpservice.post('/vehicle/brand', body)
  }
  
 export function updateVehicleBrand(body: any) {
    return httpservice.put('/vehicle/brand', body)
  }
 export function getVehicleType(body: any = null) {
    return httpservice.get('/vehicle/type/', {params:body})
  }
 export function getVehicleModel(body: any = null) {
    return httpservice.get('/vehicle/model', body)
  }
 export function createVehicleModel(body: any) {
    return httpservice.post('/vehicle/model', body)
  }
 export function updateVehicleModel(body: any) {
    return httpservice.put('/vehicle/model', body)
  }
 export function createVehicleType(body: any) {
    return httpservice.post('/vehicle/type', body)
  }
 export function updateVehicleType(body: any) {
    return httpservice.put('/vehicle/type', body)
  }

  export const  vehicleBrandLogo= (files:any)=> {
    return httpservice.post('/upload/vehicle/brand', files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  export const  vehicleModelImage= (files:any)=> {
    return httpservice.post('/upload/vehicle/model', files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
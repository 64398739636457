import { InputField } from "components/Forms/Formfield.component";
import GridItem from "components/Grid/GridItem";
import ControlledAccordion from "components/common/controlledAccordion.component";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";

import React from "react";
import { useFieldArray } from "react-hook-form";
import GridContainer from "components/Grid/GridContainer";

export const BusinessCard = (props: any) => {
  const { apiData, setValue, getValues, errors, collapse, control, nestIndex } =
    props;

  const { fields, remove, append } = useFieldArray({
    control,
    name: `for_business_array[${nestIndex}].business_card`,
  });

  return (
    <GridItem
      xs={12}
      md={12}
      sm={12}
      style={{ marginTop: "15px", display: "block" }}
    >
      <Typography variant="h6" style={{ marginTop: "15px", padding: "10px" }}>
        THE JINO BUSINESS ADVANTAGE
      </Typography>
      <Divider style={{ marginBottom: "10px" }} />
      {fields.map((item, index) => {
        return (
          <GridContainer key={item.id} spacing={3}>
            <GridItem xs={6} sm={6} md={6}>
              <InputField
                // errors={!!errors?.for_business_array?.[nestIndex]?.sub_title}
                errors={!!errors?.for_business_array?.[nestIndex]?.sub_title}
                fieldName={`for_business_array[${nestIndex}].business_card[${index}].sub_title`}
                type="text"
                label="* Sub Title"
                autoComplete="false"
                control={control}
                setValue={setValue}
                valueGot={""}
                helperText={
                  errors?.for_business_array?.[nestIndex]?.sub_title &&
                  errors?.for_business_array?.[nestIndex]?.sub_title.message
                }
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                color="primary"
                className={"custom-btn"}
                type="button"
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </GridItem>
          </GridContainer>
        );
      })}

      <GridItem
        xs={6}
        sm={6}
        md={6}
        style={{ marginTop: "10px", marginBottom: "50px" }}
      >
        <Button
          style={{ marginLeft: "15px" }}
          variant="contained"
          color="primary"
          className={"custom-btn"}
          onClick={() => {
            append({ sub_title: "" });
          }}
        >
          Add
        </Button>
      </GridItem>
    </GridItem>
  );
};

import React, { useEffect } from 'react'
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputField } from 'components/Forms/Formfield.component';
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import { createPrivacyValidation } from './Validations/privacy-policy.validation'
import ControlledAccordion from "components/common/controlledAccordion.component";



const style: any = {
    accordian: {
        display: "block"
    }

}

const useStyles = makeStyles(style);
let renderCount = 0;
export const PrivacyPoliciesAccordion = (props: any) => {
    const { sendToParent, data,permission,collapse } = props
    const classes = useStyles();


    const defaultFormValues: any = {
        privacy_policy: []
    }
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,

    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultFormValues,
        resolver: yupResolver(createPrivacyValidation)

    });

    const { fields, remove, append } = useFieldArray({
        control,
        name: `privacy_policy`
    });
    useEffect(() => {
        if (data) {
            setValue("privacy_policy", data.privacy_policy)
        }
    }, [data])
    const onSubmit = (data: any) => {
        sendToParent(data)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ControlledAccordion collapse={collapse}> 
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography>Privacy Policy</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordian}>

                    {fields.map((item: any, index) => {
                        return (
                            <GridContainer key={item.id}>

                                <GridItem xs={4} sm={4} md={4}>

                                    <InputField
                                        errors={!!errors?.privacy_policy?.[index]?.title}
                                        fieldName={`privacy_policy[${index}].title`}
                                        type="text"
                                        label="* Title"
                                        autoComplete='false'
                                        control={control}
                                        setValue={setValue}
                                        valueGot={''}
                                        helperText={errors?.privacy_policy?.[index]?.title && errors?.privacy_policy?.[index]?.title.message}
                                    />
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4}>

                                    <InputField
                                        errors={!!errors?.privacy_policy?.[index]?.description}
                                        fieldName={`privacy_policy[${index}].description`}
                                        type="text"
                                        label="* Description"
                                        autoComplete='false'
                                        multiline={true}
                                        rows={6}
                                        control={control}
                                        setValue={setValue}
                                        valueGot={''}
                                        helperText={errors?.privacy_policy?.[index]?.description && errors?.privacy_policy?.[index]?.description.message}
                                    />
                                </GridItem>
                                <GridItem xs={4} sm={4} md={4} className={"mt-15"}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={"custom-btn"}
                                        onClick={() => {
                                            remove(index)
                                        }} >
                                        Remove

                                    </Button>
                                </GridItem>

                            </GridContainer>
                        )
                    })}
                    <GridContainer spacing={3} justify="end" alignItems="center" className={"textAlignEnd"}>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={"custom-btn"}
                                onClick={() => {
                                    append({ title: "", description: "" });
                                }} >
                                Add more

                            </Button>
                        </GridItem>

                    </GridContainer>

                </AccordionDetails>
                <AccordionActions>
                    <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                    {permission === false ?<Button color="primary" className={"custom-btn"} type="submit" >Submit</Button> : ''}
                    </Box>

                </AccordionActions>
            </ControlledAccordion>

        </form>
    )
}
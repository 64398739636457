import * as yup from 'yup'

const createServiceCategoryValidation: any = yup.object().shape({
  name: yup.string().required("Please enter name").nullable(),
  priority: yup.number().required("Please enter priority").nullable(),
  notification_title: yup
    .string()
    .when("automate_notifications", {
      is: true,
      then: yup.string().required("Please enter notification title"),
    })
    .nullable(),
  notification_body: yup
    .string()
    .when("automate_notifications", {
      is: true,
      then: yup.string().required("Please enter notification body"),
    })
    .nullable(),
});


export {
  createServiceCategoryValidation
}
import { min } from "lodash";
import * as yup from "yup";

export const FaqValidation = yup.object().shape({
  Faq: yup.array().of(
    yup.object().shape({
      question: yup.string().required("Please enter question").nullable(),
      answer: yup.string().required("Please enter answer").nullable(),
     
    })
  ),
});

import * as yup from "yup";

const createAutomatedValidation: any = yup.object().shape({
  name: yup.string().required("Please enter name").nullable(),
  // type: yup.string().required("Please select notification type").nullable(),
  days: yup
    .number()
    .required("Please enter days limit")
    .typeError("Please enter days limit")
    .min(0, "Days can't be less than 0")
    .nullable(),
  title: yup.string().required("Please enter notification title"),
  body: yup.string().required("Please enter notification body"),
});

export { createAutomatedValidation };

import * as yup from 'yup'

const createAdminValidation: any = yup.object().shape({
    first_name: yup.string().required("Please enter first name").nullable(),
    last_name: yup.string().required("Please enter last name").nullable(),
    email: yup.string().required("Please enter email").email().nullable(),
    role_id: yup.string().when("type", {
        is: 'admin',
        then: yup.string().required('Please select role').nullable()
    }),
    vendor_id: yup.string().when("type", {
        is: 'vendor',
        then: yup.string().required('Please select vendor').nullable()
    }),
    type: yup.string().required("Please select type").nullable()
})

const createRoleValidation: any = yup.object().shape({
    name: yup.string().required("Please enter role name").nullable(),
})

export {
    createAdminValidation,
    createRoleValidation
}
import { min } from "lodash";
import * as yup from "yup";

export const ContactDetailValidation = yup.object().shape({
  email: yup.string().required("Please enter Video url").nullable(),
  operating_hours: yup.string().required("Please enter Video url").nullable(),
  description: yup.string().required("Please enter Video url").nullable(),
  phone_number: yup
    .string()
    .required("please enter Phone number")
    .matches(
      /^(\s*|\d+)$/,
      "please enter number without using space & alphabets"
    )
    .min(7, "minimum number limit is 7")
    .max(11, "maximun number limit is 11")
    .nullable(),
  whatsapp_number: yup
    .string()
    .matches(
      /^(\s*|\d+)$/,
      "please enter number without using space & alphabets"
    )
    .required("please enter  Whatsapp number")
    .min(7, "minimum number limit is 7")
    .max(11, "maximun number limit is 11")
    .nullable(),
});

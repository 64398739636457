import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import PageHeader from "components/PageHeader/PageHeader";
import { Popup } from 'components/Popup/Popup.component'
import { ServiceCategoryPopup } from "containers/ServiceCategory/servicecategory.popup.component"
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getServiceCategory } from "services/servicesServices";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment-timezone";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import Button from "components/CustomButtons/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  icon: {
    color: "#043cae",
    '&:hover': {
      color: "#4287f5",
    }
  }
};

const useStyles = makeStyles(styles);

const ServiceCategoryList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null)
  const [gridColumnApi, setGridColumnApi] = useState<any>(null)
  const [rowData, setRowData] = useState<any>([])
  const [editData, setEditData] = useState<any>(null)
  const [headerTitle, setHeaderTitle] = useState<any>("ADD")
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)

  }

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Service Category",
      link: "",
    },
  ];

  const addNew = () => {

    setOpen(true);
    setHeaderTitle("Add Category")
  }


  const handleClose = () => {
    getServiceCountries();
    setOpen(false);
    setEditData(null)
  };

  const getServiceCountries = () => {
    dispatch(spinLoaderShow(true));

    getServiceCategory().then((e: any) => {
      let data = e?.data?.result.map((item: any) => {
        item.status = item.status ? 'active' : 'inactive'
        return item;
      })
      setRowData(data)
      dispatch(spinLoaderShow(false));

    }).catch((err: any) => {
      dispatch(spinLoaderShow(false))
    })
  }

  useEffect(() => {

    getServiceCountries();

  }, [])

  useEffect(() => {
    let ispermission = !(verifyPermission(
      getPermission,
      'Services',
      'Category',
      'update'
    ))
    setDisabled(ispermission)

   
  }, [getPermission])
 
 

  const columnDefs: any = [
    {
      headerName: 'Priority',
      field:'priority'
    },{
      headerName: "Name",
      field: "name",
    },

    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt", 
      sort: "desc",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

    // }
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
  },
    {
      headerName: 'Created Date',
      field: "createdAt",
      
      //   valueGetter: (params: any) => {
      //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

      // },
      filter: 'agDateColumnFilter',
      valueFormatter: function (data:any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)
  
          console.log('cellValue ', cellValue)
  
          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')
  
          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        return <EditIcon className={classes.icon} color={"inherit"} onClick={() => {
          setOpen(true)
          params.data.status = params.data.status == 'active' ? true : false
          setEditData(params.data)
          setHeaderTitle("Edit Category")
        }} />;
      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        return <VisibilityIcon className={classes.icon} color={"inherit"} onClick={() => {
          setOpen(true)
          params.data.status = params.data.status == 'active' ? true : false
          params.data.view= 'view'
          setEditData(params.data)
          setHeaderTitle("View Category")
        }} />;
      }
    }
  ]
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getServiceCountries()
  
  }
  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Service Category"
            routes={routes}
            modalButton={{ text: "Add New", onClick: addNew }}
            permission="Services.Category.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Category List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>
          </CardHeader>
          <CardBody>
          {/* <Button variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>getSelectedRows() }>Enable</Button>
          <Button variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>getSelectedRows() }>Disable</Button> */}
             <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
            disabledCheckBox={true}
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.uuid;
              }}
              setExportData={setExportData}
            />

          </CardBody>
        </Card>
      </GridItem>
      <Popup handleClose={handleClose} open={open} title={headerTitle} content={<ServiceCategoryPopup handleClose={handleClose} editData={editData} setEditData={setEditData} />} />
    </GridContainer>
  );
}

export default ServiceCategoryList;

import * as yup from 'yup'

const createCountryValidation: any = yup.object().shape({
    country_code: yup.string().required("Please enter country code").nullable(),
    country_name: yup.string().required("Please enter country name").nullable(),
    currency: yup.string().required("Please enter currency").nullable(),
    polygon: yup.object({
        latLng: yup.array().required("Please draw map."),
    }).required("Please draw map.").nullable()
});

export {
    createCountryValidation
}
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { FormControlLabel } from "@material-ui/core";
import {
  InputField,
  SwitchField,
  AutoCompleteField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { any } from "prop-types";
import { Box } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { createVehicleMakeValidation } from "./Validations/vehicleMakeValidation";
import {
  createVehicleBrand,
  updateVehicleBrand,
  vehicleBrandLogo
} from "services/vehiclesServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import {CREATE_SUCCESS_MSG} from 'json/constants'
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import {FileUploadDialog} from 'components/FileUpload/fileuploadDialog.component';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import Typography from "@material-ui/core/Typography";

const styles:any={
  uploadButton:{
    backgroundColor:'#043cae',
    color:'#fff',
    marginLeft:"1em"
  },
  avatar:{
    height:"60px",
    width:"60px"
  }

}

const useStyles = makeStyles(styles);
export const MakePopup = (props: any) => {
 const classes = useStyles()
  const dispatch = useDispatch();
  const [dropOpen,setDropOpen]=useState<any>(false)
  const [url,setUrl]=useState<any>("")
  const [disabled , setDisabled]=useState<any>(false)
  const defaultFormValues: any = {
    name: "",
    status: true,
  };
  // const makeOptions:any = [{id:1,name:"Honda"},{id:2,name:"Toyota"},{id:2,name:"Suzuki"}];
  // const typeOptions:any = [{id:1,name:"Suv"},{id:2,name:"Sedan"},{id:2,name:"Crossover"}];
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createVehicleMakeValidation),
  });
  const { handleClose, open, title, editData } = props;
  useEffect(() => {

    if (editData) {
      setValue("name", editData.name);
      setValue("status", editData.status);
      setUrl(editData?.image)
      if (editData?.view ){
        setDisabled(true)
     }

    } else {
      reset(defaultFormValues);
    }
  }, [editData]);

  const handleSave= async(files:any)=>{
    dispatch(spinLoaderShow(true))
    setDropOpen(false)
    const formData = new FormData()
    formData.append('images', files[0])
    const { data } = await vehicleBrandLogo(formData)
    setValue("image",data.result[0])
    setUrl(data.result[0])
    dispatch(spinLoaderShow(false))
  }

  const onSubmit = async (data: any) => {
    dispatch(spinLoaderShow(true));
    let uuid: any = editData?.uuid;
    data.image =url
    data.status = (data?.status == null || data?.status == true) ? true : false
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);

    } else {
      createOnSubmit(data);

    }
  };

  const createOnSubmit = (data: any) => {
    createVehicleBrand(data)
      .then((e: any) => {
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()
      })
      .catch((e: any) => {
      });
  };

  const updateOnSubmit = (data: any) => {
    updateVehicleBrand(data)
      .then((e: any) => {
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()

      })
      .catch((e: any) => {
      });
  };
  return (
    <form action="" onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
      <DialogContent style={{ marginBottom: "15px" }}>
        <GridContainer>
        <GridItem  xs={12} md={12} sm={12}>
          {editData != null ?  <Typography>Modified At:  { moment(editData?.updatedAt).format('llll')} </Typography> : null}
          </GridItem>
        <GridItem xs={5} md={5} sm={5} style={{ marginTop: "06px" }}>
                <input {...register("image")} type="hidden" />

                  <Box style={{display:"flex",alignItems:"center"}}>
                  <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={disabled}
                      className={classes.uploadButton}
                      onClick={() => setDropOpen(true)}
                    >
                    Brand Logo
                    </Button>
                    <FileUploadDialog  open={dropOpen} setOpen={setDropOpen} handleSave={handleSave}/>
                    </Box>     
                  </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete="false"
              label="Make"
              control={control}
              iProps={{
                disabled:disabled
              }}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.name && errors?.name?.message}
            />
          </GridItem>
          
          <GridItem xs={6} sm={6} md={6} style={{ marginTop: "15px" }}>
            <SwitchFieldDefault
              errors={!!errors?.status}
              fieldName="status"
              autoComplete="off"
              label="Status"
              defaultChecked="true"
              control={control}
              isLoading={true}
              disabled={disabled}
              setValue={setValue}
              helperText={ 
                errors?.status &&
                errors?.status?.message
              }
            />
          </GridItem>
        
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            reset(defaultFormValues);
            handleClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button type="submit" disabled={disabled} color="primary">
          submit
        </Button>
      </DialogActions>
    </form>
  );
};

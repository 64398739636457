import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {
  InputField,
  SwitchField,
  AutoCompleteField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { Label } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryValidation } from "./Validations/CategoryValidation";
import {
  createServiceCategory,
  updateServiceCategory,
  serviceCategoryLogo,
} from "services/servicesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { UPDATE_SUCCESS_MSG } from "json/constants";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import Avatar from "@material-ui/core/Avatar";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core/";
import moment from "moment";
import {
  createCategory,
  updateCategory,
} from "services/website Services/categoryServices";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const CategoryPopup = (props: any) => {
  const classes = useStyles();
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const [types, setType] = useState<any>(false);
  const typeOptions = [
    { uuid: "1", name: "News" },
    { uuid: "2", name: "Events" },
  ];

  const defaultFormValues: any = {
    name: null,
    status: true,
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(CategoryValidation),
  });
  const { handleClose, open, title, editData, setOpen } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setValue("name", editData.name);
      setValue("status", editData.status);
      // setValue("type", editData.type);
      setType(editData);
      if (editData?.view) {
        setDisabled(true);
      }
    } else {
      reset(defaultFormValues);
    }
  }, [editData]);
  // console.log("editData", editData?.type);
  // console.log("typeOptions", typeOptions);
  // console.log("types", types);

  const typeHandleChange = (data: any) => {
    // if (data !== null) {
    //   if (data.uuid == 1) {
    //     setType(true);
    //   } else {
    //     setType(false);
    //   }
    // }
  };

  const onSubmit = async (data: any) => {
    console.log("data", data);
    data.type = data?.type?.name;
    dispatch(spinLoaderShow(true));
    let uuid: any = editData?.uuid;

    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    createCategory(data)
      .then((e: any) => {
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false));
        handleClose();
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
    console.log(data);
    
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateCategory(data)
      .then((e: any) => {
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false));
        handleClose();
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} md={12} sm={12}>
            {editData != null ? (
              <Typography>
                Modified At: {moment(editData?.updatedAt).format("llll")}{" "}
              </Typography>
            ) : null}
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={"mt-5"}
            style={{ marginBottom: "20px" }}
          >
            <SwitchFieldDefault
              errors={!!errors?.status}
              fieldName="status"
              autoComplete="off"
              label="status"
              defaultChecked="true"
              control={control}
              valueGot={""}
              disabled={disabled}
              setValue={setValue}
              helperText={errors?.status && errors?.status?.message}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6} style={{ marginBottom: "30px" }}>
            <InputField
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete="false"
              label="* Name"
              iProps={{
                disabled: disabled,
              }}
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.name && errors?.name?.message}
            />
          </GridItem>

          <GridItem xs={6} sm={6} md={6} style={{ marginBottom: "30px" }}>
            <AutoCompleteField
              errors={!!errors?.type}
              fieldName="type"
              autoComplete="off"
              label="* Category."
              control={control}
              setValue={setValue}
              options={typeOptions}
              isLoading={true}
              iProps={{
                onChange: typeHandleChange,
                // disabled: disabled || disabledType,
              }}
              returnObject={true}
              optionKey="uuid"
              optionValue="name"
              helperText={errors?.type && errors?.type?.message}
              valueGot={
                editData?.type &&
                typeOptions?.find(({ name }: any) => {
                  return name == editData?.type;
                })
              }
            />
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            reset(defaultFormValues);
            handleClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button type="submit" disabled={disabled} color="primary">
          submit
        </Button>
      </DialogActions>
    </form>
  );
};

import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

export function getPartnership(body: any = null) {
  return httpservice.get("/partnership", {
    params: body,
  });
}
export function createPartnership(body: any) {
  return httpservice.post("/partnership", body);
}
export function updatePartnership(body: any) {
  return httpservice.put("/partnership", body);
}

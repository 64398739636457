import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

 
export function getHeaderSection(body: any = null) {
    return httpservice.get('/themesetting', { params: body })
  }
//   export function createHeaderSection(body: any) {
//     return httpservice.post('/themesetting', body)
//   }
  export function updateHeaderSection(body: any) {
    return httpservice.put('/themesetting', body)
  }
import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import {
  InputField,
  SwitchField,
  SwitchFieldDefault,
  AutoCompleteField
} from "components/Forms/Formfield.component";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CalendarScheduler from "components/CalendarScheduler/CalendarScheduler";
import CalendarSchedulerCustom from "components/CalendarScheduler/CalendarSchedulerCustom";
import TimeScheduledModal from "components/TimeScheduled/TimeScheduledModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { createBranchTimeScheduleValidation } from "./Validations/schedularValidations";
import {
  createSchedule,
  getScheduleDays,
  getSchedules,
  updateSchedule,
} from "services/locationservices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { Box, FormControlLabel } from "@material-ui/core";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import moment from "moment";
import { getAdmin } from "services/userservices";
import {getVendor } from "services/vendorServices";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

function getSteps() {
  return ["Calendar Scheduler", "Time Scheduler"];
}

const defaultFormValues = {
  schedule: {
    name: "",
    status: true,
    off_days: [],
  },
  schedule_details: [],
};

const Scheduler = () => {
  const classes = useStyles();
  const steps = getSteps();
  const dispatch = useDispatch();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  let history = useHistory();

  const [scheduleData, setSchedulteData] = useState<any>({
    data: [],
    confirmationVisible: false,
    editingFormVisible: false,
    deletedAppointmentId: undefined,
    editingAppointment: undefined,
    previousAppointment: undefined,
    addedAppointment: {},
    isNewAppointment: false,
  });
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");
  const [editData, setEditData] = useState<any>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [scheduleDays, setScheduleDays] = useState<any>([]);
  const [scheduleType, setScheduleType] = useState<any>(false)
  const [disabled, setDisabled] = useState<any>(false)
  const [disabledType, setDisabledType] = useState<any>(false)
  const [vendor, setVendor] = useState<any>([])


  const [modifiedDate, setModifedDate] = useState<any>("")
  const [adminUser, setAdminUser] = useState<any>(null)
  const [dateDisabled, setDateDisabled] = useState<any>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  });
  const typeOptions = [{ uuid: '1', name: 'Driver' }, { uuid: '2', name: 'Location' }]
  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Schedular",
      link: "/admin/scheduler",
    },
    {
      name: headerTitle,
      link: "",
    },
  ];

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    getValues,
    trigger,
    register,
    formState: { errors },
    watch,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createBranchTimeScheduleValidation),
  });

  const getScheduled = () => {
    getSchedules({ uuid: uuid })
      .then((e) => {
        let data: any = e?.data?.result?.[0];
        setValue("schedule.name", data?.name);
        setValue("schedule.status", data?.status);
        setValue("schedule.vendor_id", data?.vendor_id)

        setSchedulteData(data?.off_days);
        setEditData(data);
        setModifedDate(data?.updatedAt)
        setScheduleType(data?.type == "Driver" ? true : false)
        let temp: any = new URLSearchParams(search).get("type");
        temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
        temp == 'view' ? setDisabled(true) : setDisabled(false)
        // setDisabled(true)
        setDisabledType(true)
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));

      });
  };

  const handleNext = async () => {
    const result = await trigger("schedule_details");
    if (result) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onSubmit = async (data: any) => {
    console.log("99999999999999999999999",data)
    if(!data.schedule?.vendor_id){
      data.schedule.vendor_id = null
    }
    let modifyData: any = data?.schedule_details.map(
      (item: any, index: any) => {
        let tempObject: any = {};
        let _dayData: any = data?.schedule_details?.[index]?.[item.key];
        tempObject = {
          day_id: _dayData.day_id,
          status: _dayData.status,
          start_time: _dayData?.status ? null : moment(_dayData?.start_time).format('HH:mm'),
          end_time: _dayData?.status ? null : moment(_dayData?.end_time).format('HH:mm'),
          break_start_time: _dayData?.status ? null : moment(_dayData?.break_start_time).isValid() ?  moment(_dayData?.break_start_time).format('HH:mm'):null,
          break_end_time: _dayData?.status ? null : moment(_dayData?.break_end_time).isValid()  ?  moment(_dayData?.break_end_time).format('HH:mm') : null,
        };
        return tempObject;
      }
    );
    data.schedule.off_days = scheduleData;
    delete data.schedule_details["undefined"];
    data.schedule_details = modifyData;
    data.schedule.type = data?.type?.name

    console.log("dndopgn",data)
    dispatch(spinLoaderShow(true));
    if (uuid) {
      data.schedule.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    createSchedule(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/scheduler");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    updateSchedule(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/scheduler");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  useEffect(() => {
 getAdmin({uuid:localStorage.getItem("userId")}).then(res =>{
      setAdminUser(res?.data?.result?.[0])
    });

    getVendor().then((e: any) => {
      
      setVendor(e.data.result.filter((x:any) => x.status))

    })


    getScheduleDays()
      .then((res) => setScheduleDays(res?.data?.result));
    register("schedule.off_days");
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getScheduled();
      setHeaderTitle("Edit");
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
      setActiveStep(0);
      setSchedulteData([]);
      setEditData([]);
      setDateDisabled({
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true,
      });
    }
  }, [uuid]);

  const typeHandleChange = (data: any) => {
    if (data !== null) {
      if (data.uuid == 1) {
        setScheduleType(true)
      }
      else {
        setScheduleType(false)
      }
    }
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Schedular"
              routes={routes}
              newItem={[
                { text: "Back", link: "/admin/scheduler" },
              ]}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                  <h4 className={classes.cardTitleWhite}> {headerTitle} Schedule</h4>
                  {modifiedDate ? <h4 className={classes.cardTitleWhite}>Modified At: {moment(modifiedDate).format('llll')}</h4> : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="type"
                      autoComplete="off"
                      label="* Select Type"
                      control={control}
                      setValue={setValue}
                      options={typeOptions}
                      isLoading={true}
                      iProps={{
                        onChange: typeHandleChange,
                        disabled: disabled || disabledType
                      }}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={
                        errors?.type && errors?.type?.message
                      }
                      valueGot={editData?.type && typeOptions?.find(({ name }: any) => { return name == editData?.type })}
                    />
                  </GridItem>
                  
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.schedule?.name}
                      fieldName="schedule.name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.schedule?.name &&
                        errors?.schedule?.name?.message
                      }
                    />
                  </GridItem>
                 
                 {adminUser && !adminUser?.vendor_id && scheduleType && <GridItem xs={12} sm={12} md={4}>
                 <AutoCompleteField
                      errors={!!errors?.vendor_id}
                      fieldName="schedule.vendor_id"
                      autoComplete="off"
                      label="* Select Vendor Id"
                      control={control}
                      setValue={setValue}
                      options={vendor}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="first_name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.vendor_id && errors?.vendor_id?.message
                      }
                      valueGot={editData?.vendor_id && vendor?.find(({ uuid }: any) => { return uuid == editData?.vendor_id })}
                    />
                  </GridItem>}
                  <GridItem xs={3} sm={3} md={3} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.schedule?.status}
                      fieldName="schedule.status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.schedule?.status &&
                        errors?.schedule?.status?.message
                      }
                    />
                  </GridItem>
                </GridContainer>
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step key={1}>
                    <StepLabel>Weekly Timing</StepLabel>
                    <StepContent>
                      <Typography>
                        {
                          <TimeScheduledModal
                            getValues={getValues}
                            setDateDisabled={setDateDisabled}
                            editData={editData}
                            control={control}
                            scheduleDays={scheduleDays}
                            setValue={setValue}
                            dateDisabled={dateDisabled}
                            errors={errors}
                            disabled={disabled}
                            scheduleType={scheduleType}
                          />
                        }
                      </Typography>
                      {scheduleType === false ? (
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          style={{ width: "100%" }}
                        >

                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={"custom-btn"}
                            onClick={handleNext}
                          >
                            Next
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          style={{ width: "100%" }}
                        >
                          <Button color="primary" type="submit" disabled={disabled} variant="contained">Submit</Button>
                        </Box>
                      )}
                    </StepContent>
                  </Step>
                  <Step key={2}>
                    <StepLabel>Off Days</StepLabel>
                    <StepContent>
                      <Typography>
                        <CalendarSchedulerCustom
                          disabled={disabled}
                          setSchedulteData={setSchedulteData}
                          scheduleData={scheduleData}
                        />
                      </Typography>

                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        style={{ width: "100%" }}
                      >
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type={"submit"}
                          disabled={disabled}
                        >
                          Save
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>
                </Stepper>
                {activeStep === steps.length && (
                  <Paper
                    square
                    elevation={0}
                    className={classes.resetContainer}
                  >
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </Paper>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </form>
  );
};
export default Scheduler;

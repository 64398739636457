import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useFieldArray, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
// import { HeaderValidation } from "./validations/header-validation";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const OurAccomplishmentAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `accomplishments`,
  });

  useEffect(() => {
    if (apiData) {
      // console.log(apiData?.page_content?.accomplishments);
      setValue("accomplishments", apiData?.page_content?.accomplishments);
    }
  }, [apiData]);

  // useEffect(() => {
  //   [1, 2, 3, 4, 5].forEach(() => {
  //     append({
  //       year: "",
  //       description: "",
  //     });
  //   });
  // }, []);
  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Our Accomplishments</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index: any) => {
          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.accomplishments?.[index]?.year}
                  fieldName={`accomplishments[${index}].year`}
                  type="text"
                  label="* Year"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.accomplishments?.[index]?.year &&
                    errors?.accomplishments?.[index]?.year.message
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.accomplishments?.[index]?.description}
                  fieldName={`accomplishments[${index}].description`}
                  type="text"
                  label="* Description"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.accomplishments?.[index]?.description &&
                    errors?.accomplishments?.[index]?.description.message
                  }
                />
              </GridItem>
              <GridItem
                xs={6}
                sm={6}
                md={6}
                style={{ marginBottom: "25px", marginLeft: "5px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={"custom-btn"}
                  onClick={() => remove(index)}
                >
                  Delete
                </Button>
              </GridItem>
            </GridContainer>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          disabled={disabled || fields.length > 4}
          className={"custom-btn"}
          onClick={() => append({ year: "", description: "" })}
        >
          add more
        </Button>
      </AccordionDetails>
    </ControlledAccordion>
  );
};

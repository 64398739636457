export function createMenu(title:any, click:any, actionSlug:any, href:any) {
  if (click != null) {
    return { title: title, click: click, actionSlug: actionSlug };
  } else {
    return { title: title, href: href, actionSlug: actionSlug };
  }
}

export function checkPermission(permission:any, section:any, subsection:any, specific:any) {

  if (permission && section && subsection) {
    const y:any = !specific ? permission.find((x:any) => x.sectionName === section && x.subsection === subsection) : permission.find((x:any) => x.sectionName === section && x.subsection === subsection && x.specific === specific)
    if(y)
    {
      return y.permission;

    }else
    {
      return false;
    }
  }
  return false
}


import React,{useEffect} from "react";
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputField, SwitchFieldDefault } from 'components/Forms/Formfield.component';
import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";

export const DistanceMatrixAccordian = (props:any) =>{
    const { sendToParent, data,permission ,collapse} = props
    const defaultFormValues: any = {
        estimate_time_to_reach:'',
        estimate_km_to_reach:'',
        distance_matrix_api:false
    }

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultFormValues,
        // resolver: yupResolver(createPhoneNumberValidation)

    });

    useEffect(()=>{
        setValue("estimate_time_to_reach",String(data?.estimate_time_to_reach))
        setValue("estimate_km_to_reach",String(data?.estimate_km_to_reach))
        setValue("distance_matrix_api",data?.distance_matrix_api)

        },[data])

    const onSubmit = (data: any) => {
        console.log(data)
         sendToParent(data)
        
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledAccordion collapse={collapse}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>Distance Matrix </Typography>
            </AccordionSummary>
            <AccordionDetails>

            <GridItem xs={2} md={2} sm={2}>
            <SwitchFieldDefault
                errors={!!errors?.distance_matrix_api}
                fieldName="distance_matrix_api"
                autoComplete="off"
                label="Distance Matrix"
                control={control}
                isLoading={true}
                setValue={setValue}
                helperText={
                  errors?.distance_matrix_api &&
                  errors?.distance_matrix_api?.message
                }
              />        
           </GridItem>
                <GridItem xs={4} md={4} sm={4}>
                <InputField
            errors={!!errors?.estimate_time_to_reach}
            fieldName="estimate_time_to_reach"
            type="text"
            autoComplete="false"
            label="* Estimated Time (Mins) To Reach "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={errors?.estimate_time_to_reach && errors?.estimate_time_to_reach?.message}
          />          
           </GridItem>
           <GridItem xs={4} md={4} sm={4}>
                <InputField
            errors={!!errors?.estimate_km_to_reach}
            fieldName="estimate_km_to_reach"
            type="text"
            autoComplete="false"
            label="* Estimated Meters To Reach "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={errors?.estimate_km_to_reach && errors?.estimate_km_to_reach?.message}
          />          
           </GridItem>
            


            </AccordionDetails>
            <AccordionActions>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                {permission === false ?<Button color="primary" className={"custom-btn"} type="submit" >Submit</Button> : ''}
                </Box>

            </AccordionActions>
        </ControlledAccordion>

    </form>
    )
}
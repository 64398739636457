import * as yup from "yup";

const createZoneValidation: any = yup.object().shape({
  name: yup.string().required("Please enter zone name").nullable(),
  // internal_name: yup.string().required("Please enter internal name").nullable(),
  branch_id: yup.string().required("Please select branch").nullable(),
  country_id: yup.string().required("Please select country").nullable(),
  city_id: yup.string().required("Please select city").nullable(),
  polygon: yup.object({
    latLng: yup.array().required("Please draw map."),
  }).required("Please draw map.").nullable(),
  //schedule_id: yup.string().required("Please select Schedule").nullable(),
  //schedule_id: yup.object().required("Please select branch.").nullable(),

})


export {
  createZoneValidation
}
import  * as yup from 'yup'

const createContentManagementValidation:any = yup.object().shape({
    country_id: yup.object().required("Please enter Country").nullable(),
    name: yup.string().required("Please enter name").nullable(),
    content: yup.string().required("Please enter content").nullable(),
  })


  export {
      createContentManagementValidation
  }
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TextEditorField } from "components/Forms/Formfield.component";
import {
  AutoCompleteField,
  InputField,
  LoadingButton,
  PhoneNoField,
  SwitchField,
  SwitchFieldDefault
} from "components/Forms/Formfield.component";
import { useEffect } from "react";
import Button from "components/CustomButtons/Button";
import { FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaqValidation } from "./Validations/faqValidations";
import { Box } from "@material-ui/core";
import { createFaq ,getFaq,getFaqType,updateFaq } from "services/faqServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import {CREATE_SUCCESS_MSG} from 'json/constants'
import moment from 'moment'


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  question: '',
  answer: '',
  status: true,

};

const useStyles = makeStyles(styles);
declare var google: any;


export default function AddFaq(props: any) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [searchLocation, setSearchLocation] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [zone, setZone] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [schedule, setSchedule] = useState<any>([]);
  const [editData, setEditData] = useState<any>(null)
  const [resetMap, setResetMap] = useState<any>(false);
  const [mapValues, setMapValues] = useState<any>(null);
  const [city, setCity] = useState<any>({});
  const [type, setType] = useState<any>([]);
  const [disabled, setDisabled] = useState<any>(false)
  // const [edit,setEdit] = useState<any>([]);
  // const typeOption = [{ id: 1, name: 'Activity' }, { id: 2, name: 'Facility' }]
  let history = useHistory();

  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(FaqValidation),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "FAQ",
      link: "/admin/faq",
    },

    {
      'name': headerTitle,
      'link': ''
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];

  useEffect(() => {

    getFaqType().then((res) => {
      setType(res?.data?.result)
      

    }).catch((err) => {
      console.log(err)
    })



  }, [])

  const getFaqData = () => {
    getFaq({uuid:uuid}).then((res) => {
      // console.log("data", res.data.result)
      let data = res.data.result?.[0]
      console.log("singleData", data);
      setValue("question", data?.question);
      setValue("answer", data?.answer);
      setValue("status", data?.status);
      setValue("type", data?.type);
      setEditData(data)
      let temp: any = new URLSearchParams(search).get("type");
          temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
          temp == 'view' ? setDisabled(true) : setDisabled(false)
      dispatch(spinLoaderShow(false))

    }).catch((err) => {
      console.log(err)
    })

  }


  useEffect(() => {
    if (uuid) {
        dispatch(spinLoaderShow(true))
      getFaqData()
    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData([])

    }
  }, [uuid]);

  const onSubmit = async (data: any) => {
    console.log("data", data);

    dispatch(spinLoaderShow(true));
    // data.lat=mapValues.lat
    // data.long =mapValues.lng
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
    console.log(data)
  };

  const createOnSubmit = (data: any) => {
    data.type = data.type.uuid

    console.log("data", data)
 


    createFaq(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/faq");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    console.log("onsubmit", data);
    data.type = data.type.uuid
    updateFaq(data).then((res) => {
      console.log("post-faq", res);
      toast.success(UPDATE_SUCCESS_MSG);
      history.push("/admin/faq");

      // reset(defaultFormValues)
      // dispatch(spinLoaderShow(false))
    }).catch((err) => {
      console.log(err)
    })

  };


  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="FAQ "
              routes={routes}
              newItem={[
                //{ text: 'Add', link: "/admin/branch-manager/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/faq" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} FAQ</h4>
                  {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null } 
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={5}>

                  <GridItem xs={6} sm={6} md={6}>
                    <InputField
                      errors={!!errors?.question}
                      fieldName="question"
                      type="text"
                      autoComplete="off"
                      label="* Question"
                      control={control}
                      iProps={{
                        disabled: disabled
                      }}
                      // valueGot={editData.find( ({ name })})
                      setValue={setValue}
                      helperText={
                        errors?.question && errors?.question?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="type"
                      autoComplete="off"
                      label="* Type"
                      control={control}
                      setValue={setValue}
                      options={type}
                      returnObject={true}
                      isLoading={true}
                      optionKey="uuid"
                      iProps={{
                        disabled: disabled
                      }}
                      optionValue="typeName"
                      helperText={
                        errors?.type && errors?.type?.message
                      }
                      valueGot={editData?.type && type?.find(({ uuid }: any) => { return uuid == editData?.type })}

                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} className={"mt-15"}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete='off'
                      label="status"
                      defaultChecked="true"
                      control={control}
                      optionKey="uuid"
                      optionValue="status"
                      disabled={disabled}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Typography> Answer: </Typography>
                    <InputField
                      errors={!!errors?.answer}
                      fieldName="answer"
                      type="text"
                      autoComplete="off"
                      label="* Answer"
                      control={control}
                      multiline={true}
                      iProps={{
                        disabled: disabled
                      }}
                      rows={6}
                      valueGot={''}
                      setValue={setValue}
                      helperText={
                        errors?.answer && errors?.answer?.message
                      }
                    />
                   
                  </GridItem>

                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled}className={"custom-btn"} type="submit" >{uuid ? 'Update FAQ' : "Create FAQ "}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

export function getBusinessSection(body: any = null) {
  return httpservice.get("/business", {
    params: body,
  });
}
//   export function createHeaderSection(body: any) {
//     return httpservice.post('/themesetting', body)
//   }
export function updateBusinessSection(body: any) {
  return httpservice.put("/business", body);
}

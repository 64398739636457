import React, { useState, useRef, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { ReferralAccordion } from "components/Accordian/refererral-accordion.component";
import { HeaderAccordion } from "components/website/themeSettings-Accordian/header-accordian-component";
import { MenuAccordion } from "components/website/themeSettings-Accordian/menu-accordian-component";
import { InnerMenuAccordion } from "components/website/themeSettings-Accordian/innerMenu-accordian-component";
import { FooterAccordion } from "components/website/themeSettings-Accordian/footer-accordian-component";
import { HomeBannerAccordion } from "components/website/home_page/banner-accordian-component";
import { HowToUseJinoAccordion } from "components/website/home_page/how-to-use-jino-accordian-component";
import { WhyUseJinoAccordion } from "components/website/home_page/why-jino-accordian-component";
import { TestimonialsAccordion } from "components/website/home_page/testimonials-accordian-component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getPagesSection,
  updatePagesSection,
} from "services/website Services/pagesServices";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { BusinessBannerAccordion } from "components/website/Business/banner-accordian";
import { IconSectionAccordion } from "components/website/Business/icon-section-accordian";
import { RepeaterSectionAccordion } from "components/website/Business/repeater-section";
import { ForBusinessAccordion } from "components/website/Business/jino-for-business";
import {
  getBusinessSection,
  updateBusinessSection,
} from "services/website Services/businessServices";
import { BusinessValidation } from "./validations/business-validation";
import { verifyPermission } from "helper/permission";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const BusinessfComponent = (props: any) => {
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState<any>(false);
  const [apiData, setApiData] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false);

  const routes: any = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Business",
      link: "",
    },
  ];

  const defaultFormValues: any = {};

  useEffect(() => {
    dispatch(spinLoaderShow(false));
    getApiData();
    let ispermission = !verifyPermission(
      getPermission,
      "Website",
      "Website Business",
      "update"
    );
    setDisabled(ispermission);
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(BusinessValidation),
  });

  useEffect(() => {
    if (apiData) {
      setValue("name", apiData[0]?.name);
      setValue("slug", apiData[0]?.slug);
      setValue("business_banner_title", apiData[0]?.banner?.heading);

      //setvalue for icon section
      setValue(
        "icon_section_appstore_url",
        apiData[0]?.icon_section?.app_store_url
      );
      setValue(
        "icon_section_description",
        apiData[0]?.icon_section?.description
      );
      setValue(
        "icon_section_playstore_url",
        apiData[0]?.icon_section?.play_store_url
      );
      setValue("icon_section_title", apiData[0]?.icon_section?.title);
    }
  }, [apiData]);

  useEffect(() => {
    getApiData();
  }, []);

  // get Business Api Data
  const getApiData = () => {
    getBusinessSection().then((res: any) => {
      setApiData(res?.data?.result);
    });
  };

  // console.log("apiData", apiData[0]?.uuid);
  // console.log("apiData", apiData);

  const onSubmit = (data: any) => {
    data.banner = {
      image: data.image,
      heading: data.business_banner_title,
      name: data.name,
      slug: data.slug,
      sub_headings: data.business_array,
    };
    data.icon_section = {
      icons: data.icon_section_array,
      title: data.icon_section_title,
      description: data.icon_section_description,
      play_store_url: data.icon_section_playstore_url,
      app_store_url: data.icon_section_appstore_url,
    };
    data.content_section_1 = {
      for_business_array: data.for_business_array,
    };

    data.content_section_2 = {
      repeater_array: data.repeater_array,
    };

    delete data.playstore_url;
    delete data.business_array;
    delete data.icon_section_appstore_url;
    delete data.icon_section_array;
    delete data.icon_section_description;
    delete data.icon_section_playstore_url;
    delete data.icon_section_title;
    delete data.business_banner_title;
    delete data.image;
    delete data.slug;
    delete data.name;
    delete data.business_card_title;
    delete data.for_business_array;
    delete data.repeater_array;

    let modifyData: any = data;
    // console.log(JSON.stringify(modifyData));
    console.log("data", modifyData);
    if (apiData[0]?.uuid) {
      modifyData.uuid = apiData[0]?.uuid;
      updateOnSubmit(data);
    }
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateBusinessSection(data)
      .then((res: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };
  console.log("Errors", errors);

  const toggleAll = () => setCollapse(!collapse);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader title="Business" routes={routes} />
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" className="card-header-export-btn">
              <h4 className={classes.cardTitleWhite}>Business</h4>
              <Button
                variant="outlined"
                className="custom-btn export-btn"
                onClick={toggleAll}
              >
                {collapse ? "Collapse All" : "Expand All"}
              </Button>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12} sm={12}>
                  <BusinessBannerAccordion
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    errors={errors}
                    apiData={apiData}
                    collapse={collapse}
                  />
                </GridItem>
                <GridItem xs={12} md={12} sm={12}>
                  <IconSectionAccordion
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    errors={errors}
                    apiData={apiData}
                    collapse={collapse}
                  />
                </GridItem>
                <GridItem xs={12} md={12} sm={12}>
                  <ForBusinessAccordion
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    errors={errors}
                    apiData={apiData}
                    collapse={collapse}
                  />
                </GridItem>
                <GridItem xs={12} md={12} sm={12}>
                  <RepeaterSectionAccordion
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    errors={errors}
                    apiData={apiData}
                    collapse={collapse}
                  />
                </GridItem>
              </GridContainer>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%", marginTop: "15px" }}
              >
                <Button color="primary" className={"custom-btn"} type="submit">
                  Submit
                </Button>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { FormControl, FormControlLabel, FormHelperText, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, SwitchField, SwitchFieldDefault, DateField, TextEditorField, ColorPickerField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import { createPackageValidations } from './Validations/packageValidations'
import DeleteIcon from '@material-ui/icons/Delete';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { type } from "os";
import { createPackages, getPackages, updatePackages, packagesFileUpload } from 'services/packageservices'
import moment from "moment";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { AccordionActions } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { FileUploadArea } from 'components/FileUpload/fileuploadArea.component';
import { CardMedia } from "@material-ui/core";
import PackageConfigComponent from '../PackageConfig/packageconfig-c.container'
import { SketchPicker } from 'react-color';
const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain"
  },
  swiperContainer: {
    ".swiper-slide": {
      width: '150px'
    }
  },
  button: {
    width: '100%'
  },
  dot: {
    height: "70px",
    width: "70px",
    borderRadius:" 50%",
    display:" inline-block"
  }
};

const defaultFormValues: any = {
  name: '',
  description: '',
  duration_days: '',
  color: '',
  status: true,

}



const useStyles = makeStyles(styles);
declare var google: any;


export default function AddPackage(props: any) {
  const classes = useStyles();
  const childRef: any = useRef();

  const [serviceconfig, setServiceconfig] = useState<any>([])
  const [editData, setEditData] = useState<any>(null)
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [imageSlider, setImageSlider] = useState<any>([])
  const [videoSlider, setVideoSlider] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  const [color ,setColor]=useState<any>("")
  const [colorSecond ,setColorSecond]=useState<any>("")

  const { search } = useLocation()
  let history = useHistory();
  let uuid = new URLSearchParams(search).get("uuid");

  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createPackageValidations)
  });


  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Package ",
      'link': '/admin/package'
    },
    {
      'name': headerTitle,
      'link': ''
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];

  const getPackageData = () => {
    getPackages({ 'uuid': uuid }).then((e: any) => {
      let data: any = e?.data?.result?.[0];
      setValue("name", data.name)
      setValue("description", data.description)
      setValue("duration_days", data.duration_days)
      setValue("status", data.status)
      setValue("color", data?.color)
      setValue("color_secondary",data?.color_secondary)
      setColorSecond(data?.color_secondary)
      setColor(data?.color)
      setValue("color_name",data?.color_name)
      setImageSlider(data?.images ? data?.images : [])
      setVideoSlider(data?.video ? data?.video : [])
      setEditData(data)
      let temp: any = new URLSearchParams(search).get("type");
      temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
      temp == 'view' ? setDisabled(true) : setDisabled(false)
      dispatch(spinLoaderShow(false))
    })
  }

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getPackageData()
    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData({})
    }
  }, [uuid])

  const handleSaveImage = async (files: any) => {

    // save image in s3 and return its url
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append("images", files[i].file);
      }
      const { data } = await packagesFileUpload(formData)
      let temp: any = [];
      data?.result?.map((item: any, index: any) => {
        temp.push(item);
      });
      const mergeTemp = [...imageSlider, ...temp];
      setImageSlider(mergeTemp)
    }
    dispatch(spinLoaderShow(false))
  }
  const handleSaveVideo = async (files: any) => {
    // save video in s3 return its url 
    if (files.length > 0) {
      dispatch(spinLoaderShow(true))
      const formData = new FormData()
      for (let i = 0; i < files.length; i++) {
        formData.append("images", files[i].file);
      }
      const { data } = await packagesFileUpload(formData)
      let temp: any = [];
      data?.result?.map((item: any, index: any) => {
        temp.push(item);
      });
      const mergeTemp = [...videoSlider, ...temp];
      setVideoSlider(mergeTemp)
      dispatch(spinLoaderShow(false))
    }
  }

  const onSubmit = (data: any) => {
    // trigger child component onsubmit function 
    var packageconfig = childRef?.current.getAlert()
    var error = false;
  packageconfig.configs.map((item:any)=>{
      if(+(item.count) < 1 && item.status){
        // toast.error("Quantity Cannot be less than 1")
        error = true;
      }
    })
    packageconfig.packageData.name = data?.name
    packageconfig.packageData.description = data?.description
    packageconfig.packageData.duration_days = Number(data?.duration_days)
    packageconfig.packageData.status = data?.status
    packageconfig.packageData.images = imageSlider
    packageconfig.packageData.video = videoSlider
    packageconfig.packageData.color = color
    packageconfig.packageData.color_name = data?.color_name
    packageconfig.packageData.color_secondary=colorSecond
    if(error) {
      toast.error("Quantity Cannot be less than 1")
      return;
    }
    dispatch(spinLoaderShow(true))
    if (uuid) {
      packageconfig.packageData.uuid = uuid
      createOnSubmit(packageconfig)

    }
    else {
      createOnSubmit(packageconfig)
    }


  }

  const createOnSubmit = (data: any) => {

    createPackages(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues)
        history.push("/admin/package");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  // const updateOnSubmit = (data: any) => {

  //   updatePackages(data)
  //     .then((e: any) => {
  //       dispatch(spinLoaderShow(false));
  //       setEditData(null)
  //       toast.success(UPDATE_SUCCESS_MSG);
  //       reset(defaultFormValues)
  //       history.push("/admin/package");

  //     })
  //     .catch((e: any) => {
  //       dispatch(spinLoaderShow(false));
  //     });

  // };

  useEffect(() => {
    console.log("getValues", getValues("color"));
  }, [getValues])

  const onChangeColorFirst = (data:any)=>{
    setColor(data)
  }
  const onChangeColorSecond = (data:any)=>{
    setColorSecond(data)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Package "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/package" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                  <h4 className={classes.cardTitleWhite}> {headerTitle} Package</h4>
                  {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: {moment(editData?.updatedAt).format('llll')}</h4> : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete='off'
                      label="* Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.duration_days}
                      fieldName="duration_days"
                      type="number"
                      autoComplete='off'
                      label="* Duration Days"
                      control={control}
                      iProps={
                        {
                          type: "number",
                          disabled: disabled
                        }
                      }
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.duration_days && errors?.duration_days?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputField
                      errors={!!errors?.color_name}
                      fieldName="color_name"
                      type="text"
                      autoComplete='off'
                      label="* Color Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.color_name && errors?.color_name?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Typography> Package Description: </Typography>
                    <TextEditorField
                      className={"mt-15"}
                      errors={!!errors?.description}
                      textareaName="description"
                      fieldName="description"
                      type="text"
                      autoComplete="false"
                      label="Description"
                      control={control}
                      isLoading={true}
                      disabled={disabled}
                      valueGot={editData?.description}
                      setValue={setValue}
                      helperText={errors?.description && errors?.description?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Add Images</Typography>
                      </AccordionSummary>
                      <AccordionDetails>

                        <GridItem xs={12} md={12} sm={12} className="swiperContainer">
                          {/* Swiper */}
                          <Swiper

                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView='auto'
                            navigation
                          >
                            {
                              // swiper map
                              imageSlider?.map((item: any, index: any) => {
                                return <SwiperSlide>
                                  <Box>
                                    <img src={item} className={classes.img} />
                                    <Button
                                      variant="outlined"
                                      className={classes.button}
                                      disabled={disabled}
                                      startIcon={<DeleteIcon />}
                                      onClick={
                                        () => {
                                          const deletImg = imageSlider.filter((fileObjects: any, i: any) => index !== i);
                                          setImageSlider(deletImg)
                                        }
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </SwiperSlide>
                              })
                            }

                          </Swiper>
                        </GridItem>


                      </AccordionDetails>
                      <AccordionActions>
                        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>

                          {disabled == false ? <FileUploadArea handleSave={handleSaveImage} /> : null}
                        </Box>
                      </AccordionActions>
                    </Accordion>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Add Video</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} md={12} sm={12} className="swiperContainer">
                          {/* Swiper */}
                          <Swiper

                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView='auto'
                            navigation
                          >
                            {
                              // swiper map
                              videoSlider?.map((item: any, index: any) => {
                                return <SwiperSlide>
                                  <Box>
                                    < CardMedia
                                      component="video"
                                      autoPlay
                                      controls
                                      src={item}
                                      className={classes.img}
                                    />
                                    <Button
                                      variant="outlined"
                                      className={classes.button}
                                      startIcon={<DeleteIcon />}
                                      disabled={disabled}
                                      onClick={
                                        () => {
                                          const deletImg = videoSlider?.filter((fileObjects: any, i: any) => index !== i);
                                          setVideoSlider(deletImg)
                                        }
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </SwiperSlide>
                              })
                            }

                          </Swiper>
                        </GridItem>


                      </AccordionDetails>
                      <AccordionActions>
                        <Box display="flex" justifyContent="center" style={{ width: '100%' }}>

                          {disabled == false ? <FileUploadArea video={['video/*']} message={"Only mp4 is allowed.The maximum size is 20mb"} handleSave={handleSaveVideo} /> : null}
                        </Box>
                      </AccordionActions>
                    </Accordion>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <PackageConfigComponent ref={childRef} disabled={disabled} />
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3}>
                  <span className={classes.dot} style={{ backgroundColor: color }}></span>
                  <ColorPickerField
                    errors={!!errors?.color}
                    fieldName="color"
                    control={control}
                    isLoading={true}
                    setValue={setValue}
                    helperText={errors?.color && errors?.color?.message}
                    valueGot={editData && editData?.color}
                    iProps={{
                      onChange: onChangeColorFirst
                    }}
                  />

                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <span className={classes.dot} style={{ backgroundColor: colorSecond }}></span>
                  <ColorPickerField
                    errors={!!errors?.color_secondary}
                    fieldName="color_secondary"
                    control={control}
                    isLoading={true}
                    setValue={setValue}
                    helperText={errors?.color_secondary && errors?.color_secondary?.message}
                    valueGot={editData && editData?.color_secondary}
                    iProps={{
                      onChange: onChangeColorSecond
                    }}
                  />

                </GridItem>
                </GridContainer>

               

              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" className={"custom-btn"} disabled={disabled} type="submit" >{uuid ? 'Update Package' : "Create Package"}</Button>
                  {/* <button type="button" onClick={() => childRef?.current.getAlert()}>Click</button> */}

                </Box>

              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

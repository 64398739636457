import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import _ from 'lodash';
import Button from "components/CustomButtons/Button";
import { DateField } from "components/Forms/Formfield.component"
import { useForm } from "react-hook-form";
import { Box } from "@material-ui/core";
import { getBooking } from "services/userservices";
import moment from "moment";
import {BookingReports} from './booking-reports'
import {PackagesReports} from './packages-reports'
import {TransactionPackageReports} from './transaction-package-reports'
import {TransactionServiceReports} from './transaction-service-reports'
import {CustomerReports} from './customer-reports'
const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};



const useStyles = makeStyles(styles);
declare var google: any;

export default function Reports(props: any) {
  const classes = useStyles();
 
  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Reports",
      'link': '/admin/reports'
    },

  ];
 

 

  return (
    <>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Reports"
            routes={routes}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Reports Generate</h4>
            </CardHeader>
            <CardBody>
              <GridContainer spacing={1}>
                <GridItem xs={12} md={12} sm={12} className={'mt-15'}>
                  <BookingReports />
                </GridItem>
                {/* <GridItem xs={12} md={12} sm={12} className={'mt-15'}>
                  <TransactionPackageReports />
                </GridItem>*/}

                {/* <GridItem xs={12} md={12} sm={12} className={'mt-15'}>
                  <TransactionServiceReports />
                </GridItem>  */}

                <GridItem xs={12} md={12} sm={12} className={'mt-15'}>
                  <PackagesReports />
                </GridItem>
                <GridItem xs={12} md={12} sm={12} className={'mt-15'}>
                  <CustomerReports />
                </GridItem>
                

              </GridContainer>

            </CardBody>
            <CardFooter>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
    
  );
}


import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getPromotionsView, getPromotionsViewAutoApplied } from "services/promotionServices";
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import { bulkPromotionEnable, bulkPromotionDisable } from 'services/bulkEnableServices'
import { toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { ColDef } from "ag-grid-community";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);



export const PromotionListAutoApplied = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Offers",
      'link': ''
    }
  ];

  useEffect(() => {
    getApiData()
  }, [])

  useEffect(() => {
    let ispermission = !(verifyPermission(
      getPermission,
      'Promotion',
      'Manage',
      'update'
    ))
    setDisabled(ispermission)
  }, [getPermission])


  const columnDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "name",
      minWidth: 160
    },
    {
      headerName: "Code",
      field: "code",
      resizable: true
    },
    {
      headerName: "Services",
      field: "service_names"
    },
    {
      headerName: "Usage Limit",
      field: "user_limit",

    },
    {
      headerName: "Code Limit",
      field: "code_limit",

    },
    {
      headerName: "Level",
      field: "level_type"
    },
    // {
    //   headerName: "City",
    //   field: "city",
    // },
    // {
    //   headerName: "Country",
    //   field: "country",
    // },
    // {
    //   headerName: "Zone",
    //   field: "zone",
    // },
    {
      headerName: "Branch",
      field: "branch",
    },
    {
      headerName: "Discount Percentage",
      field: "discount_percentage"
    },
    {
      headerName: "Expiry Date",
      field: "end_date"
    },
    {
      headerName: "Usage Count",
      field: "use_count"
    },
    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
      sort: "desc",
      //   valueGetter: (params: any) => {
      //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

      // }
      filter: 'agDateColumnFilter',
      valueFormatter: function (data: any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)

          console.log('cellValue ', cellValue)

          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },
    {
      headerName: 'Created Date',
      field: "createdAt",

      //   valueGetter: (params: any) => {
      //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

      // },
      filter: 'agDateColumnFilter',
      valueFormatter: function (data: any) {
        return moment(data.value).format('YYYY-MM-DD HH:mm')
      },
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'greaterThan'],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log('filtrlocaldate ', filterLocalDateAtMidnight)

          console.log('cellValue ', cellValue)

          console.log(
            'filter ',
            moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
          )
          const filter = moment(filterLocalDateAtMidnight).format(
            'YYYY-MM-DD HH:mm',
          )
          const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

          if (filter === compare) {
            return 0
          }
          if (compare < filter) {
            return -1
          }
          if (compare > filter) {
            return 1
          }
        },
      },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'/admin/promotions/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'/admin/promotions/edit?uuid=' + params?.data?.uuid + "&type=view"}><VisibilityIcon /></Link>;
      }
    },
  ]

  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getPromotionsViewAutoApplied({ "deprecated": false }).then((e) => {

      let data = e?.data?.result.map((item: any) => {
        item.status = item.status ? 'active' : 'inactive'
        return item;
      })
      setRowData(data)
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
      dispatch(spinLoaderShow(false))
    })
  }

  const enableSelectedRows = () => {
    let temp: any = {}
    temp = exportData.getSelectedRows().map((item: any) => {
      return { uuid: item?.uuid }

    })
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true))
      bulkPromotionEnable(temp).then((res: any) => {
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getApiData()
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        getApiData()
      })
    } else {
      toast.error("Please select a row")
    }


  }
  const disableSelectedRows = () => {
    let temp: any = {}
    temp = exportData.getSelectedRows().map((item: any) => {
      return { uuid: item?.uuid }

    })
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true))
      bulkPromotionDisable(temp).then((res: any) => {
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getApiData()
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        getApiData()
      })
    } else {
      toast.error("Please select a row")
    }
  }
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()

  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Offers "
            routes={routes}
            newItem={[{ text: 'Add New Offer', link: "/admin/promotions/add" }]}
            permission="Promotion.Manage.create"

          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Offers Listing</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>


          </CardHeader>
          <CardBody>
            <Button disabled={disabled} variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) => enableSelectedRows()}>Enable</Button>
            <Button disabled={disabled} variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) => disableSelectedRows()}>Disable</Button>
            <Button variant="outlined" color="primary" className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={(data: any) => {
                return data.uuid;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

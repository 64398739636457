import React ,{useEffect, useState} from "react"
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { InputField, LoadingButton, SwitchField,TextEditorField, SwitchFieldDefault,AutoCompleteField} from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import {createReferralValidation} from './Validations/referral-validation'
import { yupResolver } from "@hookform/resolvers/yup"
import {getServices} from "services/servicesServices";
import ControlledAccordion from "components/common/controlledAccordion.component";
 
const styles: any = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
  }; 

  const useStyles = makeStyles(styles);
export const ReferralAccordion = (props:any) =>{
    
    const classes = useStyles();
   const {sendToParent,data,permission , collapse} = props
   const [services ,setServices]=useState<any>([])
    const [expand,setExpand]=useState<any>(props.collapse)
  

    const defaultFormValues: any = {
      referral_service_id:'',
      referral_user_threshold: '',
      referral_sender_discount: '',
      referral_reciever_discount: '',
      referral_end_days: '',
    
        
       
      }  
      useEffect(()=>{
        if(data){
          setValue('referral_user_threshold',Number(data.referral_user_threshold))
          setValue('referral_sender_discount',Number(data.referral_sender_discount))
          setValue('referral_reciever_discount',Number(data.referral_reciever_discount))
          setValue('referral_end_days',Number(data.referral_end_days))
        }
      },[props])

      useEffect(()=>{
        getServices().then((res)=>{
         setServices(res?.data?.result)
        }).catch((err)=>{
        })
      },[])
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
      } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultFormValues,
        resolver:yupResolver(createReferralValidation)
       
      });

      const onSubmit = (data:any) => {
      sendToParent(data)
    
      // reset(defaultFormValues)

      }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
                <ControlledAccordion collapse={collapse}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={()=> setExpand(true)} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Referral</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <GridItem xs={4} md={4} sm={4}>      
        <AutoCompleteField
                      errors={!!errors?.referral_service_id}
                      fieldName="referral_service_id"
                      autoComplete='off'
                      label="* Select Service"
                      control={control}
                      setValue={setValue}
                      isLoading={true}
                      options={services}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.referral_service_id && errors?.referral_service_id?.message}
                      valueGot={[data?.referral_service_id ] && services.find(({uuid}:any)=>{ return uuid == data?.referral_service_id})}
                    />  
          </GridItem>
        <GridItem xs={4} md={4} sm={4}>        
        <InputField
                    errors={!!errors?.referral_user_threshold}
                    fieldName="referral_user_threshold"
                    type="text"
                    autoComplete="false"
                    label="* No Of Users"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    iProps={{
                      type: "number"
                    }}
                    helperText={errors?.referral_user_threshold && errors?.referral_user_threshold?.message}

                  />


                </GridItem>
                <GridItem xs={4} md={4} sm={4} >
                  <InputField
                    errors={!!errors?.referral_sender_discount}
                    fieldName="referral_sender_discount"
                    type="text"
                    autoComplete="false"
                    label="* Sender User Discount"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={errors?.referral_sender_discount && errors?.referral_sender_discount?.message}
                    iProps={{
                      type: "number"
                    }}

                  />
                </GridItem>
                <GridItem xs={4} md={4} sm={4} >
                  <InputField
                    errors={!!errors?.referral_reciever_discount}
                    fieldName="referral_reciever_discount"
                    type="text"
                    autoComplete="false"
                    label="* Reciever User Discount"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={errors?.referral_reciever_discount && errors?.referral_reciever_discount?.message}
                    iProps={{
                      type: "number"
                    }}

                  />
                </GridItem>
                
                <GridItem xs={4} md={4} sm={4} >
                  <InputField
                    errors={!!errors?.referral_end_days}
                    fieldName="referral_end_days"
                    type="text"
                    autoComplete="false"
                    label="* Referral End Days"
                    control={control}
                    valueGot={""}
                    iProps={{
                      type: "number"
                    }}
                    setValue={setValue}
                    helperText={errors?.referral_end_days && errors?.referral_end_days?.message}

                  />
                </GridItem>
             
    
        </AccordionDetails>
        <AccordionActions>
        <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
        {permission === false ?<Button color="primary" className={"custom-btn"} type="submit" >Submit</Button> : ''} 
                </Box>

        </AccordionActions>
        </ControlledAccordion>

        </form>
    )
}
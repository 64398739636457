import React, { useEffect, useState } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-material.css';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Button from "components/CustomButtons/Button";
import { Box } from "@material-ui/core";
const AgGridCustom = (props: any) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [pageSize, setPageSize] = useState<number>(10)
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const onGridReady = (params: any) => {
    if (props?.setExportData) {
      props?.setExportData(params?.api);
    }

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const {
    columnDefs,
    rowData,
    serverSideDatasource,
    headerCheckboxSelection,
    headerCheckboxSelectionFilteredOnly,
    checkboxSelection,
    getRowNodeId,
    id,
    defaultExportParams,
  } = props;
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(parseInt(e.target.value))
    gridApi.paginationSetPageSize(e.target.value);
  }

  function isFirstColumn(params: any) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
  return (
    <div className="example-wrapper">
      {!props.hideGrid && (
        <div
          id="myGrid"
          style={{
            height: "80vh",
            width: "100%",
          }}
          className="ag-theme-alpine"
        >
          <div className="paging" style={{ bottom: "9.5em" }}>
            <label>Page Size:</label>
            <select onChange={(e) => handleChange(e)}>
              <option defaultValue="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
              <option value="60">60</option>
              <option value="100">100</option>
              {rowData.length > 100 && (
                <option value={rowData.length}>{rowData.length}</option>
              )}
            </select>
            {/* <button onClick={()=>{gridApi.refreshServerSideStore({ purge: true });}}>Refresh</button> */}
          </div>
          {/* <Box
          display="flex"
          justifyContent="flex-end"
          style={{ width: "100%" }}
        >

          <Button color="primary" className="custom-btn" onClick={() => onBtnExport()}>Export to Csv</Button>
        </Box> */}
          <div className="ag-grid-custom-table" style={{ height: "92%" }}>
            <AgGridReact
              defaultCsvExportParams={defaultExportParams}
              columnDefs={columnDefs}
              defaultColDef={{
                flex: 1,
                sortable: true,
                filter: true,
                resizable: true,
                headerCheckboxSelection: isFirstColumn,
                checkboxSelection: isFirstColumn,
              }}
              autoGroupColumnDef={{
                headerName: "Athlete",
                field: "athlete",
                minWidth: 250,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true },
              }}
              rowSelection={"multiple"}
              id={id}
              animateRows={true}
              rowData={rowData}
              scroll={true}
              getRowNodeId={getRowNodeId}
              onGridReady={onGridReady}
              serverSideDatasource={serverSideDatasource}
              {...props}
              pagination={true}
              paginationPageSize={pageSize}
              paginationPageSizeSelector={[10, 20, 50, 100, rowData.length > 100 ? rowData.length : 500]}
              enableCellTextSelection={true}
            />
          </div>
        </div>
      )}
      <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
        <Button color="primary" type="submit">
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default AgGridCustom;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
import { HeaderValidation } from "./validations/header-validation";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const HeaderAccordion = (props: any) => {
  const { apiData } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { sendToParent, data, permission, collapse } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);

  const defaultFormValues: any = {
    location_text: "90 to 90",
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
    setValue,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(HeaderValidation),
  });

  useEffect(() => {
    if (apiData) {
      setValue("location_text", apiData?.header_section?.location_text);
      setValue("logo_text", apiData?.header_section?.logo_text);
      setValue("image", apiData?.header_section?.image);
      setUrl(apiData?.header_section?.image);
    }
  }, [apiData]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("image", data.result[0]);
    setUrl(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  const onSubmit = (data: any) => {
    data.image = url;
    var payload = { header_section: data };
    console.log(payload);

    updateOnSubmit(payload);
  };
  const updateOnSubmit = (data: any) => {
    updateHeaderSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledAccordion collapse={collapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Header</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
            <input {...register("image")} type="hidden" />

            <Box
              style={{ display: "flex", alignItems: "center" }}
              alignItems="center"
            >
              <Avatar style={{height:"100px",width:"100px"}} alt="Remy Sharp" src={url} className={classes.avatar} />
              <Button
                variant="outlined"
                color="primary"
                disabled={disabled}
                className={classes.uploadButton}
                onClick={() => setDropOpen(true)}
              >
                Header Logo
              </Button>
              <FileUploadDialog
                open={dropOpen}
                setOpen={setDropOpen}
                handleSave={handleSave}
                message={
                  "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                }
              />
            </Box>
          </GridItem>
          <GridItem xs={6} md={6} sm={6} alignItems="center" style={{marginTop:"25px"}}>
            <InputField
              errors={!!errors?.location_text}
              fieldName="location_text"
              type="text"
              autoComplete="false"
              label="* Location Text "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={
                errors?.location_text && errors?.location_text?.message
              }
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6} alignItems="center" style={{marginTop:"25px"}}>
            <InputField
              errors={!!errors?.logo_text}
              fieldName="logo_text"
              type="text"
              autoComplete="false"
              label="* Logo Text "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.logo_text && errors?.logo_text?.message}
            />
          </GridItem>
        </AccordionDetails>
        <AccordionActions>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            {/* {permission === false ? (
             
            ) : (
              ""
            )} */}
            <Button color="primary" className={"custom-btn"} type="submit">
              Update
            </Button>
          </Box>
        </AccordionActions>
      </ControlledAccordion>
    </form>
  );
};

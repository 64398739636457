import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getFaq } from "services/faqServices";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import ForwardSharpIcon from "@material-ui/icons/ForwardSharp";
import ArrowForwardIosTwoToneIcon from "@material-ui/icons/ArrowForwardIosTwoTone";
import { Link } from "react-router-dom";
import { verifyPermission } from "helper/permission";
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getPartnership } from "services/website Services/partnershipServices";
import { getMediaDetail } from "services/website Services/media_detailServices";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const PageComponentNew = (props: any) => {
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });
  const classes = useStyles();
  const [rowData, setRowData] = useState<any>([]);
  const [disabled, setDisabled] = useState<any>(false);
  const [exportData, setExportData] = useState<any>(null);

  useEffect(() => {
    let ispermission = !verifyPermission(
      getPermission,
      "Website",
      "Website Pages",
      "update"
    );
    setDisabled(ispermission);
  }, []);
  useEffect(() => {
    console.log("ispermission",);
  }, []);

  let data: any = [
    {
      key: "1",
      name: "Home",
      pageurl: "/admin/pages/homepage",
    },
    {
      key: "2",
      name: "About us",
      pageurl: "/admin/pages/aboutus",
    },
    {
      key: "3",
      name: "Partner",
      pageurl: "/admin/pages/partner",
    },
    {
      key: "4",
      name: "News",
      pageurl: "/admin/pages/mediapage",
    },
    {
      key: "5",
      name: "Event",
      pageurl: "/admin/pages/eventpage",
    },
   
  ];

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Pages ",
      link: "",
    },
  ];

  const columnDefs: any = [
    // { field: 'id', headerName: 'ID', width: 30 },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 1000,
    },
    {
      headerName: "Actions",
      field: "pageurl",
      maxWidth: 100,
      sortable: false,
      filter: false,
      // hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return (
          <Link to={`${params?.value}`}>
            <ArrowForwardIosTwoToneIcon style={{ marginTop: "5px" }} />
          </Link>
        );
      },
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader title="Pages " routes={routes} />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>All Pages</h4>
            {/* <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button> */}
          </CardHeader>
          <CardBody>
            <AgGridCustom
              disabledCheckBox={true}
              columnDefs={columnDefs}
              rowData={data}
              getRowNodeId={(data: any) => {
                return data.key;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

import React,{useEffect} from "react";
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputField, SwitchFieldDefault } from 'components/Forms/Formfield.component';
import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";

export const PrivacyTCLinkAccordian = (props:any) =>{
    const { sendToParent, data,permission ,collapse} = props
    const defaultFormValues: any = {
        privacy_link:'',
        tc_link:'',
    }

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultFormValues,
        // resolver: yupResolver(createPhoneNumberValidation)

    });

    useEffect(()=>{
        setValue("privacy_link",String(data?.privacy_link))
        setValue("tc_link",String(data?.tc_link))
        

        },[data])

    const onSubmit = (data: any) => {
        console.log(data)
         sendToParent(data)
        
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledAccordion collapse={collapse}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>Privacy & TC Links</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <GridItem xs={4} md={4} sm={4}>
                <InputField
            errors={!!errors?.privacy_link}
            fieldName="privacy_link"
            type="text"
            autoComplete="false"
            label="*Privacy Link"
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={errors?.privacy_link && errors?.privacy_link?.message}
          />          
           </GridItem>
           <GridItem xs={4} md={4} sm={4}>
                <InputField
            errors={!!errors?.tc_link}
            fieldName="tc_link"
            type="text"
            autoComplete="false"
            label="*Terms & Condition Link"
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={errors?.tc_link && errors?.tc_link?.message}
          />          
           </GridItem>
            


            </AccordionDetails>
            <AccordionActions>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                {permission === false ?<Button color="primary" className={"custom-btn"} type="submit" >Submit</Button> : ''}
                </Box>

            </AccordionActions>
        </ControlledAccordion>

    </form>
    )
}
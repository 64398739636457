import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomDate from "components/CustomDate";
import { IServerSideDatasource, ServerSideStoreType } from "ag-grid-community";
import moment from "moment";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-042971 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( JINO )_is_granted_a_( Single Application )_Developer_License_for_the_application_( JINO )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( JINO )_need_to_be_licensed___( JINO )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 29 May 2024 )____[v2]_MTcxNjkzNzIwMDAwMA==72174ee155e645f283bf6ed675901c68"
);
var bookingStatus: any = {
  completed: 6,
  upcoming: 12,
  ongoing: 7,
  cancelledByUser: 8,
  cancelledByAdmin: 9,
  onTheWay: 13,
  driverReached: 10,
  incart: 1,
};
const AgGridEnterprise = (props: any) => {
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnDefs, setColumnDefs] = useState<any>(props?.columnDefs);
  const [rowData, setRowData] = useState([]);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState<any>(null);

  // useEffect(() => {
  //   setColumnDefs(props?.columnDefs);
  // }, [props]);

  const onGridReady = useCallback((params: any) => {
    setGridApi(params.api);
    if (props?.setExportData) {
      console.log("Grid is ready!");
      props?.setExportData(params?.api);
    }
  }, []);

  const fetchPageData = (params: any, filterModel?: any) => {
    const { startRow, endRow } = params.request;
    const sortModel = params.request.sortModel;
    let queryString1: any = "";
    // if (
    //   props?.componentType == "notification" ||
    //   props?.componentType == "notification_alert"
    // ) {
    //   const filterQuery = buildFilterQuery(filterModel);

    //   queryString1 = {
    //     limit: paginationPageSize,
    //     page: params?.api?.paginationProxy?.currentPage + 1,
    //   };
    //   queryString1 = Object.assign({}, queryString1, filterQuery);
    // } else {
    const filterQuery = buildFilterQuery(filterModel);

    let filterString = `$filter=${filterQuery}`;
    const sortString =
      sortModel?.length > 0
        ? `$orderby=${sortModel[0]?.colId} ${sortModel[0]?.sort}`
        : "";
    queryString1 = `$top=${
      endRow - startRow > 0 ? endRow - startRow : 5
    }&$skip=${startRow ? startRow : 0}&${sortString}&${filterString}`;
    // }

    // if (
    //   props?.componentType == "notification" ||
    //   props?.componentType == "notification_alert"
    // ) {
    //   queryString1 =
    //     props?.componentType == "notification_alert"
    //       ? Object.assign({}, queryString1, { type: "other" })
    //       : queryString1;
    //   props
    //     ?.dataSourceUrl(queryString1)
    //     .then((res: any) => {
    //       const data: any = res?.data?.result;

    //       gridApi?.hideOverlay();
    //       if (typeof params.successCallback === "function") {
    //         setTotalCount(data?.totalCount);
    //         params.successCallback(data?.rowData, data?.totalCount);
    //       }
    //     })
    //     .catch((error: any) => {
    //       console.error("Error fetching data:", error);
    //       gridApi?.showNoRowsOverlay();
    //       if (typeof params.failCallback === "function") {
    //         params.failCallback();
    //       }
    //     });
    // } else {
    if (props?.componentType == "Booking") {
      let filterQuery = buildFilterQuery(filterModel);
      queryString1 = props.bookingType
        ? filterQuery
          ? queryString1.includes("substring")
            ? `${queryString1}`
            : `${queryString1} and booking_status_id eq ${
                bookingStatus[props.bookingType]
              }`
          : `${queryString1}booking_status_id eq ${
              bookingStatus[props.bookingType]
            }`
        : `${queryString1}`;
      queryString1 = props?.driverId
        ? filterQuery
          ? `${queryString1} and driver_id eq '${String(props?.driverId)}'`
          : `${queryString1}driver_id eq '${String(props?.driverId)}'`
        : queryString1;

      console.log("queryString1", queryString1);
      props
        ?.dataSourceUrl(`${queryString1}`)
        .then((res: any) => {
          const data: any = res?.data?.result;

          gridApi?.hideOverlay();
          if (typeof params.successCallback === "function") {
            setTotalCount(data?.totalCount);
            params.successCallback(data?.rowData, data?.totalCount);
          }
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
          gridApi?.showNoRowsOverlay();
          if (typeof params.failCallback === "function") {
            params.failCallback();
          }
        });
    } else {
      props
        ?.dataSourceUrl(`${queryString1}`)
        .then((res: any) => {
          const data: any = res?.data?.result;

          gridApi?.hideOverlay();
          if (typeof params.successCallback === "function") {
            setTotalCount(data?.totalCount);
            params.successCallback(data?.rowData, data?.totalCount);
          }
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
          gridApi?.showNoRowsOverlay();
          if (typeof params.failCallback === "function") {
            params.failCallback();
          }
        });
    }
    // }
  };

  const serverSideDatasource: IServerSideDatasource = {
    getRows: (params: any) => {
      console.log("getRows", params);
      fetchPageData(params, params.request.filterModel);
    },
  };
  const buildFilterQuery = (filterModel: any) => {
    let filterQuery: any = "";

    for (const field in filterModel) {
      if (filterModel.hasOwnProperty(field)) {
        let filter = filterModel[field]?.filter;
        const type = filterModel[field]?.type;
        const filterType = filterModel[field]?.filterType;
        const searchBy = filterModel[field]?.searchBy;

        if (filterType == "date") {
          filter = moment(filterModel[field]?.dateFrom)
            .utc(false)
            .format("YYYY-MM-DDTHH:mm");
          filter = `datetime'${filter}'`;
        }
        if (field == "status") {
          if (String(filter)?.toLocaleLowerCase()?.startsWith("a")) {
            // for searching boolean field which in listing displayed as active converting to boolean
            filter = true;
          } else {
            filter = false;
          }
        }

        const result: any = typeChecked({
          searchBy: filter,
          type,
          filter: filterChange[type],
        });

        if (type === "notinrange") {
          filterQuery = `${field} ${result} and _not `;
        } else if (type === "startswith" || type === "endswith") {
          filterQuery += `${result}(${filter}, ${field}) and `;
        } else if (
          type == "equals" ||
          type == "notEqual" ||
          type == "lessThan" ||
          type == "lessThanOrEqual" ||
          type == "greaterThan"
        ) {
          filterQuery = `${field} ${Object.keys(result)} ${Object.values(
            result
          )}`;
        } else if (
          props?.componentType == "notification" ||
          props?.componentType == "notification_alert"
        ) {
          let val: any = Object.values(result)?.toString();
          if (
            String(field) == "customer_first_name" ||
            String(field) == "customer_last_name"
          ) {
            filterQuery = {
              [String(field)]: val.replace(/'/g, ""),
            };
          } else {
            filterQuery = {
              filter: "contains",
              value: val.replace(/'/g, ""),
              key: String(field),
            };
          }
        } else {
          filterQuery = `${Object.keys(result)}(${Object.values(
            result
          )}, ${field})`;
        }

        if (filterType === "date" && type === "equals") {
          filterQuery = `${field} ge datetime'${moment(
            filterModel[field]?.dateFrom
          )
            .startOf("day")
            .utc(false)
            .format("YYYY-MM-DDTHH:mm")}' and ${field} le datetime'${moment(
            filterModel[field]?.dateFrom
          )
            .endOf("day")
            .utc(false)
            .format("YYYY-MM-DDTHH:mm")}'`;
        }
      }
    }

    // Remove the trailing "and" from the filter query

    // filterQuery = filterQuery.slice(0, -5);
    return filterQuery;
  };
  function isFirstColumn(params: any) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
  const getRowStyle: any = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#f7f7f7e8" };
    }
  };

  const handlePageChange = (event: any) => {
    const pagesize = event.target.value;
    setPaginationPageSize(pagesize);
    gridApi.paginationSetPageSize(pagesize);
  };

  const frameworkComponents = {
    agDateInput: CustomDate,
  };

  const onFilterChanged = (event: any) => {
    console.log("onfilter CHANGE");
    const filterModel = gridApi?.getFilterModel();
    fetchPageData({ request: { filterModel } }, filterModel);
  };
  const columnTypes = useMemo(() => {
    return {
      number: { filter: "agNumberColumnFilter" },
    };
  }, []);

  const typeChecked = ({ searchBy, type, filter }: any) => {
    console.log("typechecking", searchBy, type, filter);
    if (type == "contains") {
      return { [filter]: `'${searchBy}'` };
    } else if (type == "notContains") {
      return { [filter]: `'${searchBy}'` };
    } else if (type == "startsWith") {
      return { [filter]: `'${String(searchBy)}'` };
    } else if (type == "endsWith") {
      return { [filter]: `'${searchBy}'` };
    } else if (type == "equals") {
      return {
        [filter]: searchBy.includes("datetime")
          ? searchBy
          : `'${String(searchBy)}'`,
      };
    } else if (type == "notEqual") {
      return { [filter]: searchBy };
    } else if (type == "after") {
      return { [filter]: searchBy };
    } else if (type == "afterOrOn") {
      return { [filter]: searchBy };
    } else if (type == "before") {
      return { [filter]: searchBy };
    } else if (type == "beforeOrOn") {
      return { [filter]: searchBy };
    } else if (type == "inrange") {
      return { [filter.start]: searchBy.start, [filter.end]: searchBy.end };
    } else if (type == "notinrange") {
      return { [filter.start]: searchBy.start, [filter.end]: searchBy.end };
    } else if (type == "greaterThan") {
      return { [filter]: searchBy };
    } else if (type == "gte") {
      return { [filter]: searchBy };
    } else if (type == "lessThan") {
      return { [filter]: searchBy };
    } else if (type == "lessThanOrEqual") {
      return { [filter]: searchBy };
    }
  };
  const filterChange: any = {
    contains: "substringof",
    notContains: "_nilike",
    startsWith: "startswith",
    endsWith: "endswith",
    equals: "eq",
    notEqual: "ne",
    after: "gt",
    gte: "ge",
    greaterThan: "gt",
    lessThanOrEqual: "le",
    lessThan: "lt",
    afterOrOn: "ge",
    before: "lt",
    beforeOrOn: "le",
    inrange: {
      start: "ge",
      end: "le",
    },
  };

  return (
    <div className="example-wrapper">
      <div
        id="myGrid"
        style={{
          height: "80vh",
          width: "100%",
        }}
        className="ag-theme-alpine"
      >
        {/* <Box
        display="flex"
        justifyContent="flex-end"
        style={{ width: "100%" }}
      >

        <Button color="primary" className="custom-btn" onClick={() => onBtnExport()}>Export to Csv</Button>
      </Box> */}
        <div className="paging">
          <label>Page Size:</label>
          <select onChange={(e) => handlePageChange(e)}>
            <option defaultValue="10">10</option>
            <option value="20">20</option>
            <option value="40">40</option>
            <option value="60">60</option>
            <option value="100">100</option>
            {totalCount > 100 && (
              <option value={totalCount}>{totalCount}</option>
            )}
          </select>
          {/* <button onClick={()=>{gridApi.refreshServerSideStore({ purge: true });}}>Refresh</button> */}
        </div>
        <div
          className="ag-grid-custom-table"
          style={{ height: "100%", width: "100%" }}
        >
          <AgGridReact
            getRowStyle={getRowStyle}
            columnDefs={columnDefs}
            defaultColDef={{
              flex: 1,
              sortable: true,
              // filter: true,
              resizable: true,
              wrapText: true,
              minWidth: 230,
              autoHeight: true,
              cellStyle: {
                textAlign: "left",
                alignItems: "left",
                "border-left-color": "#e2e2e2",
                minHeight: "100%",
              },
              headerCheckboxSelection: props.disabledCheckBox
                ? false
                : isFirstColumn,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: props.disabledCheckBox ? false : isFirstColumn,
              menuTabs: ["filterMenuTab"],
            }}
            serverSideDatasource={serverSideDatasource}
            rowBuffer={0}
            rowModelType={"serverSide"}
            cacheBlockSize={paginationPageSize}
            maxBlocksInCache={1}
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={paginationPageSize}
            enableCellTextSelection={true}
            frameworkComponents={frameworkComponents}
            serverSideStoreType={"partial"}
            onFilterChanged={onFilterChanged}
            // floatingFilter={true}
            columnTypes={columnTypes}
            rowSelection="multiple"
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default memo(AgGridEnterprise);

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputField, AutoCompleteField, SwitchFieldDefault, PhoneNoField ,AutoCompleteMultipleField} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { createVendor, updateVendor, getVendor, vendorLogo, vendorClientLogo } from "services/vendorServices";
import { createVendorValidations } from './Validations/vendorValidations'
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { FileUploadDialog } from 'components/FileUpload/fileuploadDialog.component';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { getCountry, getCity } from 'services/locationservices'
import { getServiceCategory, getServices } from 'services/servicesServices'
const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  uploadButton: {
    backgroundColor: '#043cae',
    color: '#fff',
    marginLeft: "1em"
  },
  avatar: {
    height: "60px",
    width: "60px"
  }
};

const defaultFormValues: any = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_no: '',
  client_name: '',
  website: '',
  status: true
}


const options: any = [{ id: 1, name: "Admin" }, { id: 2, name: "User" }, { id: 2, name: "Developer" }];
const useStyles = makeStyles(styles);
declare var google: any;


export default function AddVendor(props: any) {
  const classes = useStyles();
  let history = useHistory();
  const [roles, setRoles] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false);
  const [codeDisabled, setcodeDisabled] = useState<any>(true);
  const [editData, setEditData] = useState<any>(null)
  const [openVendor, setOpenVendor] = useState(false)
  const [openClient, setOpenClient] = useState(false)
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [city, setCity] = useState<any>({})
  const [country, setCountry] = useState<any>({})

  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [vendorUrl, setVendorUrl] = useState<any>("")
  const [clientUrl, setclientUrl] = useState<any>("")
  const [serviceCategories, setServiceCategories] = useState<any>([])
  const [services,setServices]=useState<any>([])
  const [service,setService]=useState<any>([])
  const [serviceCategory,setServiceCategory]=useState<any>([])

  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");
  const dispatch = useDispatch()
  const { control, register, handleSubmit, formState: { errors }, setValue, reset, getValues } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createVendorValidations)
  });



  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': headerTitle,
      'link': ''
    },

    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getVendor({ uuid: uuid }).then((e: any) => {
        let data = e?.data?.result?.[0]
        setValue("first_name", data.first_name)
        setValue("email", data.email)
        setValue("mobile_no", data.mobile_no)
        setValue("client_name", data.client_name)
        setValue("website", data.website)
        setValue("status", data.status)
        setValue("vendor_logo", data?.vendor_logo)
        setValue("client_logo", data?.client_logo)
        setServiceCategory(data?.service_categories)
        setService(data?.services)
        setCountry(countries.find((x:any) => x.uuid == data.country_id));
        getCitiesByCountry({uuid:data.country_id})
        setVendorUrl(data?.vendor_logo)
        setclientUrl(data?.client_logo)
        setEditData(data)
        let temp: any = new URLSearchParams(search).get("type");
        temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
        temp == 'view' ? setDisabled(true) : setDisabled(false)
        dispatch(spinLoaderShow(false))
      }).catch((err: any) => {
      })
    }
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {
    })
    getServiceCategory({ status: true }).then((e: any) => {
      setServiceCategories(e.data.result)
    }).catch((e: any) => {
    })
    getServices({status:true}).then((e:any)=>{
     setServices(e?.data?.result)    })


  }, [])
  const getCitiesByCountry = (data: any) => {
    setCities([])
    setCity({})
    if (data?.uuid) {
      dispatch(spinLoaderShow(true))
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
          dispatch(spinLoaderShow(false))

        })
    }
  }

  const handleSaveVendor = async (files: any) => {
    dispatch(spinLoaderShow(true))
    setOpenVendor(false)
    const formData = new FormData()
    formData.append('images', files[0])
    const { data } = await vendorLogo(formData)
    setValue("vendor_logo", data.result[0])
    setVendorUrl(data.result[0])
    dispatch(spinLoaderShow(false))


  }
  const handleSaveClient = async (files: any) => {
    dispatch(spinLoaderShow(true))
    setOpenClient(false)
    const formData = new FormData()
    formData.append('images', files[0])
    const { data } = await vendorClientLogo(formData)
    setValue("client_logo", data.result[0])
    setclientUrl(data.result[0])
    dispatch(spinLoaderShow(false))


  }

  const onSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    data.country_id =data?.country?.uuid
    data.city_id=data?.city?.uuid
    data.services = data?.services?.map((item:any)=>{
      return {
        "uuid":item?.uuid,
        "name":item?.name
        
      }
    })
    data.service_categories = data?.service_categories?.map((item:any)=>{
      return { 
        "uuid":item?.uuid,
        "name":item?.name
      }
    })
    console.log("[s[as",data)
    if (uuid) {
      data.uuid = uuid
      updateOnSubmit(data)
    } else {
      createOnSubmit(data)
    }
  }
  const updateOnSubmit = (data: any) => {

    updateVendor(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/vendor")

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };


  const createOnSubmit = (data: any) => {
    createVendor(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        history.push("/admin/vendor")
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const handleServiceChange = (data:any)=>{
    setService(data)
  }

const handleServiceCategoryChange=(data:any)=>{
  setServiceCategory(data)
}





  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Vendor "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
                { text: 'Back', link: "/admin/vendor" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                  <h4 className={classes.cardTitleWhite}> {headerTitle} Vendor</h4>
                  {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: {moment(editData?.updatedAt).format('llll')}</h4> : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>

                  <GridItem xs={4} md={4} sm={4} style={{ marginTop: "06px" }}>
                    <input {...register("vendor_logo")} type="hidden" />

                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Avatar alt="Remy Sharp" src={vendorUrl} className={classes.avatar} />
                      <Button
                        className={classes.uploadButton}
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                        onClick={() => setOpenVendor(true)}
                      >
                        Vendor Logo
                      </Button>
                      <FileUploadDialog open={openVendor} setOpen={setOpenVendor} handleSave={handleSaveVendor} />
                    </Box>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.first_name}
                      fieldName="first_name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.first_name && errors?.first_name?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.email}
                      fieldName="email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.email && errors?.email?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} >
                    <PhoneNoField
                      errors={!!errors?.mobile_no}
                      fieldName="mobile_no"
                      type="text"
                      autoComplete="false"
                      label="* Mobile Number"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled,
                        disableDropdown:disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.mobile_no && errors?.mobile_no?.message}

                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.client_name}
                      fieldName="client_name"
                      type="text"
                      autoComplete="false"
                      label="Client Name"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.client_name && errors?.client_name?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} md={4} sm={4} style={{ marginTop: "06px" }}>
                    <input {...register("client_logo")} type="hidden" />

                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Avatar alt="Remy Sharp" src={clientUrl} className={classes.avatar} />
                      <Button
                        className={classes.uploadButton}
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                        onClick={() => setOpenClient(true)}
                      >
                        Client Logo
                      </Button>
                      <FileUploadDialog open={openClient} setOpen={setOpenClient} handleSave={handleSaveClient} />
                    </Box>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.website}
                      fieldName="website"
                      type="text"
                      autoComplete="false"
                      label="Website"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.website && errors?.website?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.country}
                      fieldName="country"
                      autoComplete='off'
                      label="Select Country"
                      control={control}
                      setValue={setValue}
                      options={countries}
                      optionKey="uuid"
                      returnObject={true}
                      isLoading={countries?.length > 0 ? true : false}
                      optionValue="country_name"
                      helperText={errors?.country && errors?.country?.message}
                      valueGot={countries?.find((x:any) => x.uuid == editData?.country_id)}
                      iProps={{
                        onChange: getCitiesByCountry,
                        disabled: disabled
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.city}
                      fieldName="city"
                      autoComplete='off'
                      label="Select City"
                      control={control}
                      setValue={setValue}
                      options={cities}
                      isLoading={true}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="city_name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={errors?.city && errors?.city?.message}
                      valueGot={cities?.find((x:any) => x.uuid == editData?.city_id)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteMultipleField
                      errors={!!errors?.service_categories}
                      fieldName="service_categories"
                      autoComplete='off'
                      label="Select Service Category"
                      control={control}
                      setValue={setValue}
                      options={serviceCategories}
                      optionKey="uuid"
                      iProps={{
                        onChange:handleServiceCategoryChange,
                        disabled: disabled
                      }}
                      isLoading={true}
                      optionValue="name"
                      helperText={errors?.service_categories && errors?.service_categories?.message}
                      valueGot={serviceCategory}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteMultipleField
                      errors={!!errors?.services}
                      fieldName="services"
                      autoComplete='off'
                      label="Select Service"
                      control={control}
                      setValue={setValue}
                      options={services}
                      optionKey="uuid"
                      iProps={{
                        onChange: handleServiceChange,
                        disabled: disabled
                      }}
                      isLoading={true}
                      optionValue="name"
                      helperText={errors?.services && errors?.services?.message}
                      valueGot={service}
                    />
                  </GridItem>



                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>


                </GridContainer>

              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} type="submit" >{uuid ? 'Update Vendor' : "Create Vendor"}</Button>
                </Box>

              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
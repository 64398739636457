import React,{useEffect} from "react";
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Typography } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InputField } from 'components/Forms/Formfield.component';
import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";

export const PhoneNumberAccordion = (props:any) =>{
    const { sendToParent, data,permission ,collapse} = props
    const defaultFormValues: any = {
        phone_number:''
    }

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setError,
        setValue,
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultFormValues,
        resolver: yupResolver(createPhoneNumberValidation)

    });

    useEffect(()=>{
        setValue("phone_number",String(data?.phone_number))
     
        },[data])

    const onSubmit = (data: any) => {
        console.log(data)
         sendToParent(data)
        
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
        <ControlledAccordion collapse={collapse}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography>Phone Number </Typography>
            </AccordionSummary>
            <AccordionDetails>

                <GridItem xs={12} md={12} sm={12}>
                <InputField
            errors={!!errors?.phone_number}
            fieldName="phone_number"
            type="text"
            autoComplete="false"
            label="* Phone Number "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={errors?.phone_number && errors?.phone_number?.message}
          />          
           </GridItem>
            


            </AccordionDetails>
            <AccordionActions>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                {permission === false ?<Button color="primary" className={"custom-btn"} type="submit" >Submit</Button> : ''}
                </Box>

            </AccordionActions>
        </ControlledAccordion>

    </form>
    )
}
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import _ from 'lodash';
import { useState } from "react";
import { useForm } from "react-hook-form";
import { AutoCompleteField, InputField, LoadingButton, PhoneNoField, SwitchFieldDefault } from "components/Forms/Formfield.component";
import { useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box, Checkbox, Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
} from "@material-ui/core";
import { useHistory, useLocation } from 'react-router-dom'
import { permissionsx } from 'json/permissions'

import { createRoles, getRoles, updateRoles } from "services/userservices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createRoleValidation } from "./Validations/adminValidations";
const PermisisoJson = require('json/permissions')
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import Button from "components/CustomButtons/Button";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const defautlFormValues = {
  name: '',
  status: 'true'
}

const useStyles = makeStyles(styles);
declare var google: any;

export default function AddRole(props: any) {
  const classes = useStyles();
  const [permissions, setPermissions]: any = useState([])
  const [permissions1, setPermissions1]: any = useState([])
  const [checkAll, setcheckAll] = useState(false)
  const [isEditMode, setIsEdit] = useState(false)
  const [visibleAlert, setVisibleAlert] = useState(false)
  const [header, setHeader] = useState<any>("Add")
  const [disabled, setDisabled] = useState<any>(false)
  const [modifiedDate , setModifedDate]=useState<any>("")
  const { control, register, setError, formState: { errors }, setValue, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defautlFormValues,
    resolver: yupResolver(createRoleValidation)
  });

  const routes = [
    {
      'name': "Admin",
      'link': '/admin/users'
    },
    {
      'name': "Role",
      'link': '/admin/users'
    },
    {
      'name': header,
      'link': ''
    }
  ];

  const history: any = useHistory()
  const state: any = history.location.state

  const dispatch = useDispatch()
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const getRolesData = () => {
    getRoles({ 'uuid': uuid }).then((e: any) => {
      let data: any = e?.data?.result?.[0];
      console.log(data.permissions)
      setPermissions(data.permissions)
      setValue("name", data.name)
      setValue("status", data.status)
      setModifedDate(data?.updatedAt)
      dispatch(spinLoaderShow(false))
      let temp: any = new URLSearchParams(search).get("type");
      temp ? setHeader('View') : setHeader("Edit")
      temp == 'view' ? setDisabled(true) : setDisabled(false)

    })
  }


  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getRolesData()
    }
    else {
      console.log('checking Permit', permissionsx)
      let dats = Array.from(permissionsx)
      setPermissions(dats)
      setHeader("Add")
    }
  }, [])



  // useEffect(() => {
  //   //setLoading(true)
  //   // if (id) {
  //   //   getRoleData(id).then((res:any) => {
  //   //     if (res) {
  //   //       json = res.data.result[0].permissions;
  //   //       setPermissions(json)
  //   //       setValue("roleName", res.data.result[0].role_name)
  //   //       setRoleName(res.data.result[0].role_name)
  //   //       setLoading(false)
  //   //       setIsEdit(true);
  //   //     }
  //   //   })
  //   // }
  //   //else {
  //   // setLoading(false)

  //   // }
  // }, [])

  useEffect(() => {
    console.log(permissions)

    ModifyJson(Object.assign([], permissions)
    )

  }, [permissions])

  const mergeSubSection = (data: any) => {
    return (
      data &&
      Object.entries(data).map(([key, value]: any, index: any) => {
        var c = value.find((index: any) => index.specific === 'create')
        var u = value.find((index: any) => index.specific === 'update')
        var r = value.find((index: any) => index.specific === 'read')
        return (
          <React.Fragment key={index}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{key}</TableCell>

              {c ? (
                <TableCell align="center">
                  <Checkbox
                    className={classes.checkMargin}
                    onChange={(e: any) => handleChange(c, e.target.checked, "create")}
                    checked={JSON.parse(c.permission)}
                    name={`${key}-create`}
                    inputProps={{ 'aria-label': `${index}-Create` }}
                    disabled={disabled}
                  />
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
              {u ? (
                <TableCell align="center">
                  <Checkbox
                    className={classes.checkMargin}
                    onChange={(e) => handleChange(u, e.target.checked, "update")}
                    checked={JSON.parse(u.permission)}
                    name={`${key}-update`}
                    inputProps={{ 'aria-label': `${index}-Update` }}
                    disabled={disabled}
                  />
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
              {r ? (
                <TableCell align="center">
                  <Checkbox
                    className={classes.checkMargin}
                    onChange={(e) => handleChange(r, e.target.checked, "read")}
                    checked={JSON.parse(r.permission)}
                    name={`${key}-read`}
                    inputProps={{ 'aria-label': `${index}-Read` }}
                    disabled={disabled}
                  />
                </TableCell>
              ) : (
                <TableCell></TableCell>
              )}
            </TableRow>
          </React.Fragment>
        )
      })
    )
  }

  const getTable = (data: any) => {
    return (
      data &&
      Object.entries(data).map(([key, value]: any, index: any) => (
        <React.Fragment key={index}>
          <TableRow>
            <TableCell className={classes.textStyle} colSpan={5}>
              {key}
            </TableCell>
          </TableRow>
          {mergeSubSection(value['subsec'])}
        </React.Fragment>
      ))
    )
  }

  const ModifyJson = (data: any) => {

    var result = data.reduce(function (r: any, a: any) {
      r[a['sectionName']] = r[a['sectionName']] || []
      r[a['sectionName']].push(a)
      return r
    }, Object.create(null))
    Object.entries(result).map(([key, value]: any) => {
      var temp = value.reduce(function (r: any, a: any) {
        r[a['subsection']] = r[a['subsection']] || []
        r[a['subsection']].push(a)
        return r
      }, Object.create(null))
      result[key]['subsec'] = temp
    })
    setPermissions1(Object.assign([], result))
  }

  const handleChange = (data: any, permission: any, type: any) => {
    var tempState: any = []
    tempState = Object.assign([], permissions)
    var tempNode: any = permissions.find(
      (x: any) =>
        x.sectionName === data.sectionName &&
        x.subsection === data.subsection &&
        x.specific === data.specific,
    )
    var tempNodeIndex = permissions.findIndex(
      (x: any) =>
        x.sectionName === data.sectionName &&
        x.subsection === data.subsection &&
        x.specific === data.specific,
    );
    if (type == "read" && permission === false) {

      var UpdateNode: any = permissions.find(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'update',
      )
      var UpdateNodeIndex: any = permissions.findIndex(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'update',
      )
      if (UpdateNode) {
        UpdateNode.permission = false
        tempState[UpdateNodeIndex] = UpdateNode
      }


      var CreateNode: any = permissions.find(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'create',
      )
      var CreateNodeIndex: any = permissions.findIndex(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'create',
      )
      if (CreateNode) {
        CreateNode.permission = false
        tempState[CreateNodeIndex] = CreateNode
      }
    }
    if (
      (tempNode?.specific === 'update' && permission === true) ||
      (tempNode?.specific === 'create' && permission === true)
    ) {
      var readNode: any = permissions.find(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'read',
      )
      var readIndex = permissions.findIndex(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'read',
      )
      var updateNode: any = permissions.find(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'update',
      )
      var updateIndex = permissions.findIndex(
        (x: any) =>
          x.sectionName === data.sectionName &&
          x.subsection === data.subsection &&
          x.specific === 'update',
      )
      if (readNode) {
        readNode.permission = true
        tempState[readIndex] = readNode
      }
      if (updateNode) {
        updateNode.permission = true
        tempState[updateIndex] = updateNode
      }
    }

    tempNode.permission = permission
    tempState[tempNodeIndex] = tempNode
    console.log("per", PermisisoJson.permissionsx)
    setPermissions(Object.assign([], tempState))
  }

  const handleCheckedAll = (event: any) => {
    const tempArr: any = [...permissions]
    tempArr.map((item: any, index: any) => {
      item.permission = event
    })
    setPermissions(tempArr)
    setcheckAll(event)
  }

  const onFinish = () => {

    if (getValues()?.name == undefined || getValues()?.name == '' || getValues()?.name == null) {
      setError("name", {
        type: "manual",
        message: "please enter role name",
      });
      return;
    }
    dispatch(spinLoaderShow(true))
    if (!uuid) {
      var result: any = {
        name: getValues()?.name,
        status: getValues()?.status,
        permissions: permissions,
      }
      createRoles(result).then((res) => {
        dispatch(spinLoaderShow(false))
        toast.success("Record created successfully.");
        history.push("/admin/users?list=role")
      }).catch((err) => {
        dispatch(spinLoaderShow(false));
      })
    } else {
      var result: any = {
        name: getValues()?.name,
        status: getValues()?.status,
        permissions: permissions,
        uuid
      }
      updateRoles(result).then((res) => {
        dispatch(spinLoaderShow(false))
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/users?list=role")
      }).catch((err) => {
        dispatch(spinLoaderShow(false));
      })
    }

    // console.log({ result })
    // if (isEditMode) {
    //   result = { ...result, uuid: id }
    //   setLoading(true)
    //   httpservice.put('/role/', result).then((response:any) => {
    //     setTimeout(() => {
    //       let message = 'Role Update Successfully.'
    //       setLoading(false)
    //       setAlert({ type: 'success', message: message })
    //       setVisibleAlert(true)
    //       goBack()
    //     }, 2000)
    //   })
    // } else {
    //   httpservice.post('/role/', result).then((response) => {
    //     setLoading(true)
    //     setTimeout(() => {
    //       let message = 'Role Added Successfully.'
    //       setLoading(false)
    //       setAlert({ type: 'success', message: message })
    //       setVisibleAlert(true)
    //       goBack()
    //     }, 2000)
    //   })
    // }
  }

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return
    }
    setVisibleAlert(false)
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Role Manager"
            routes={routes}
            newItem={[
              { text: 'Back', link: "/admin/users?list=role" }
            ]}
            backIcon
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
            <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {header} Role</h4>
                {modifiedDate ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(modifiedDate).format('llll') }</h4> : null}  
                </Box>
            </CardHeader>
            <CardBody>
              <GridContainer spacing={5}>
                <GridItem xs={6} sm={6} md={6}>
                  <InputField
                    errors={!!errors?.name}
                    fieldName="name"
                    type="text"
                    autoComplete='off'
                    label="Role Name"
                    control={control}
                    valueGot={''}
                    setValue={setValue}
                    helperText={errors?.name && errors?.name?.message}
                    iProps={{
                      disabled: disabled
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ marginTop: "15px" }}>
                  <SwitchFieldDefault
                    errors={!!errors?.status}
                    fieldName="status"
                    autoComplete="off"
                    label="Status"
                    control={control}
                    isLoading={true}
                    setValue={setValue}
                    disabled={disabled}
                    helperText={
                      errors?.status &&
                      errors?.status?.message
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>

                  <Table aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Sections</TableCell>
                        <TableCell align="left">Sub Sections</TableCell>
                        <TableCell align="center" colSpan={3}>
                          Permissions
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                className={classes.checkMargin}
                                checked={checkAll}
                                onChange={(e) => handleCheckedAll(e.target.checked)}
                                name="checkAll"
                                disabled={disabled}
                              />
                            }
                            label="Check All"
                          />{' '}
                        </TableCell>
                        <TableCell className={classes.tableHead}></TableCell>
                        <TableCell align="center" className={classes.tableHead}>
                          Create
                        </TableCell>
                        <TableCell align="center" className={classes.tableHead}>
                          Update
                        </TableCell>
                        <TableCell align="center" className={classes.tableHead}>
                          Read
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{permissions1 ? getTable(permissions1) : null}</TableBody>
                  </Table>

                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%" }}
              >
                <Button color="primary" disabled={disabled}   onClick={onFinish} type="submit">
                  {uuid ? "Update Roles" : "Create Roles"}
                </Button>
              </Box>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}


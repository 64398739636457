import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import { FooterValidation } from "./validations/footer-validatiion";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";

export const FooterAccordion = (props: any) => {
  const dispatch = useDispatch();
  const { apiData, permission, collapse } = props;
  // console.log("apiData",apiData?.footer_section);

  const defaultFormValues: any = {};

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(FooterValidation),
  });

  useEffect(() => {
    if (apiData) {
      setValue("phone_number", apiData?.footer_section?.phone_number);
      setValue("whatsapp_number", apiData?.footer_section?.whatsapp_number);
      setValue("playstore_link", apiData?.footer_section?.playstore_link);
      setValue("appstore_link", apiData?.footer_section?.appstore_link);
      setValue("huawei_link", apiData?.footer_section?.huawei_link);
      setValue("Facebook_url", apiData?.footer_section?.Facebook_url);
      setValue("twitter_url", apiData?.footer_section?.twitter_url);
      setValue("Instagram_url", apiData?.footer_section?.Instagram_url);
    }
  }, [apiData]);

  const onSubmit = (data: any) => {
    var payload = { footer_section: data };
    updateOnSubmit(payload);
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateHeaderSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledAccordion collapse={collapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Footer </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <GridContainer>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.phone_number}
                fieldName="phone_number"
                type="text"
                autoComplete="false"
                label="* Phone Number "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.phone_number && errors?.phone_number?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.whatsapp_number}
                fieldName="whatsapp_number"
                type="text"
                autoComplete="false"
                label="* Whatsapp Number "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.whatsapp_number && errors?.whatsapp_number?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.playstore_link}
                fieldName="playstore_link"
                type="text"
                autoComplete="false"
                label="* PlayStore Url "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.playstore_link && errors?.playstore_link?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.appstore_link}
                fieldName="appstore_link"
                type="text"
                autoComplete="false"
                label="* AppStore Url "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.appstore_link && errors?.appstore_link?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.huawei_link}
                fieldName="huawei_link"
                type="text"
                autoComplete="false"
                label="* Huawei Url "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.huawei_link && errors?.huawei_link?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.Facebook_url}
                fieldName="Facebook_url"
                type="text"
                autoComplete="false"
                label="* Facebook Url "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.Facebook_url && errors?.Facebook_url?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.Instagram_url}
                fieldName="Instagram_url"
                type="text"
                autoComplete="false"
                label="* Instagram Url "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={
                  errors?.Instagram_url && errors?.Instagram_url?.message
                }
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.twitter_url}
                fieldName="twitter_url"
                type="text"
                autoComplete="false"
                label="* Twitter Url "
                control={control}
                isLoading={true}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.twitter_url && errors?.twitter_url?.message}
              />
            </GridItem>
          </GridContainer>
        </AccordionDetails>
        <AccordionActions>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <Button color="primary" className={"custom-btn"} type="submit">
              Update
            </Button>
          </Box>
        </AccordionActions>
      </ControlledAccordion>
    </form>
  );
};

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Divider,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useFieldArray, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
// import { HeaderValidation } from "./validations/header-validation";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const BusinessBannerAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `business_array`,
  });
  useEffect(() => {
    [1, 2, 3].forEach(() => {
      append({
        sub_title: "",
      });
    });
  }, []);

  const defaultFormValues: any = {};

  useEffect(() => {
    if (apiData) {
      setValue("business_array", apiData[0]?.banner?.sub_headings);
      setUrl(apiData[0]?.banner?.image);
      // console.log("apiData", apiData[0]?.banner?.heading);
    }
  }, [apiData]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("image", data.result[0]);
    setUrl(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography> Business Banner</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        <Typography
          variant="h6"
          style={{ marginTop: "5px", marginBottom: "5px", padding: "10px" }}
        >
          {" "}
          B2B CORPORATE DEALS:{" "}
        </Typography>
        <Divider style={{ marginBottom: "10px" }} />

        <GridContainer spacing={3} style={{ flexGrow: 1 }}>
          <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
            <Box
              style={{ display: "flex", alignItems: "center" }}
              alignItems="center"
            >
              <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />
              <Button
                variant="outlined"
                color="primary"
                disabled={disabled}
                className={classes.uploadButton}
                onClick={() => setDropOpen(true)}
              >
                Banner Logo
              </Button>
              <FileUploadDialog
                open={dropOpen}
                setOpen={setDropOpen}
                handleSave={handleSave}
                message={
                  "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                }
              />
            </Box>
          </GridItem>
          {/* <GridItem xs={6} md={6} sm={6} alignItems="center">
            <InputField
              errors={!!errors?.business_banner_title}
              fieldName="business_banner_title"
              type="text"
              autoComplete="false"
              label="* Title "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={
                errors?.business_banner_title &&
                errors?.business_banner_title?.message
              }
            />
          </GridItem> */}
          <GridItem xs={6} sm={6} md={6}></GridItem>
          <GridItem xs={6} md={6} sm={6} alignItems="center">
            <InputField
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete="false"
              label="* Name "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.name && errors?.name?.message}
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6} alignItems="center">
            <InputField
              errors={!!errors?.slug}
              fieldName="slug"
              type="text"
              autoComplete="false"
              label="* Slug "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.slug && errors?.slug?.message}
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails>
      <AccordionDetails style={{ display: "block" }}>
        <Typography
          variant="h6"
          style={{ marginTop: "5px", marginBottom: "5px", padding: "10px" }}
        >
          {" "}
          Points:{" "}
        </Typography>
        <Divider style={{ marginBottom: "10px" }} />
        {fields.map((item: any, index) => {
          //   console.log("item", item)
          return (
            <GridContainer spacing={5} key={item.id}>
              <GridItem xs={6} sm={6} md={6}>
                <InputField
                  errors={!!errors?.business_array?.[index]?.sub_title}
                  fieldName={`business_array[${index}].sub_title`}
                  type="text"
                  label="* Sub Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.business_array?.[index]?.sub_title &&
                    errors?.business_array?.[index]?.sub_title.message
                  }
                />
              </GridItem>
            </GridContainer>
          );
        })}
      </AccordionDetails>
      <Button
        variant="contained"
        color="primary"
        className={"custom-btn"}
        type="button"
        style={{ marginLeft: "15px" }}
        onClick={() => append({ sub_title: "" })}
      >
        Add
      </Button>
      <AccordionActions></AccordionActions>
    </ControlledAccordion>
  );
};

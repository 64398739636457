import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { useEffect } from "react";


const styles: any = {
    success: {
        backgroundColor: "#81c784",
        color: "#fff",
        textTransform: "capitalize"
    },
    danger: {
        backgroundColor: "#f44336",
        color: "#fff",
        textTransform: "capitalize"
    }
};
const useStyles = makeStyles(styles);

const StatusComponent = (props: any) => {
    const classes = useStyles();
    const { data } = props;
    const [statusColor, setStatusColor] = useState<any>()

    useEffect(() => {
        if (data.value == 'active' || data.value == true) {
            setStatusColor(classes.success)
        } else if (data.value == 'inactive' || data.value == false || data.value == null) {
            setStatusColor(classes.danger)
        }
    }, [props]);


    return (<Chip
        className={statusColor}
        //color={statusColor}
        label={data.value == true || data.value == 'active' ? 'Active' : 'Inactive'}
        size="small"
    />);
}
export default StatusComponent;
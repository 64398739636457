import React, { useEffect, useState } from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { vehicleBrandLogo } from "services/vehiclesServices";
import GridItem from "components/Grid/GridItem";
import { Button } from "@material-ui/core";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const styles: any = {
  dropboxContainer: {
    heigth: "50px",
  },
  customStyle: {
    height: "100%",
    width: "100%",
  },
};

const useStyles = makeStyles(styles);

export const FileUploadFieldArray = (props: any) => {
  // console.log("props",props);

  const dispatch = useDispatch();
  const [getdata, setData] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [setOpen, setDropOpen] = useState<any>(false);

  const classes = useStyles();
  const {
    // setOpen,
    message,
    index,
    getValuesX,
    setValue,
    control,
    keyss,
    disabled,
    apiData,
  } = props;

  useEffect(() => {
    if (props?.getValuesX) {
      setImage(getValuesX(props?.keyss));
    }
  }, [getValuesX]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setImage(data.result[0]);
    setValue(keyss, data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  return (
    <>
      <div className={classes.dropboxContainer}>
        <div>
          <GridItem
            xs={3}
            sm={3}
            md={3}
            className={"mt-15" + " " ? "svg-background" : ""}
          >
            <img
              style={{ height: "100px", width: "100px" }}
              src={
                image
                  ? image
                  : "https://image.shutterstock.com/image-vector/photo-icon-vector-isolated-on-260nw-456059428.jpg"
                  
              }
            />
          </GridItem>
        </div>

        <DropzoneDialog
          acceptedFiles={["image/jpeg", "image/png", "video/*"]}
          cancelButtonText={"cancel"}
          submitButtonText={"submit"}
          maxFileSize={10000000}
          open={setOpen}
          onClose={() => setDropOpen(false)}
          onSave={handleSave}
          showPreviews={true}
          showFileNamesInPreview={true}
          showAlerts={false}
          filesLimit={1}
          dropzoneText={message}
        />
      </div>
      <Button
        style={{ marginLeft: "25px" }}
        variant="outlined"
        color="primary"
        disabled={disabled}
        className={classes.uploadButton}
        onClick={() => setDropOpen(true)}
      >
        {props?.lable}
      </Button>
    </>
  );
};

import React, { useState, useRef, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { ReferralAccordion } from "components/Accordian/refererral-accordion.component";
import { HeaderAccordion } from "components/website/themeSettings-Accordian/header-accordian-component";
import { MenuAccordion } from "components/website/themeSettings-Accordian/menu-accordian-component";
import { InnerMenuAccordion } from "components/website/themeSettings-Accordian/innerMenu-accordian-component";
import { FooterAccordion } from "components/website/themeSettings-Accordian/footer-accordian-component";
import { HomeBannerAccordion } from "components/website/home_page/banner-accordian-component";
import { HowToUseJinoAccordion } from "components/website/home_page/how-to-use-jino-accordian-component";
import { WhyUseJinoAccordion } from "components/website/home_page/why-jino-accordian-component";
import { TestimonialsAccordion } from "components/website/home_page/testimonials-accordian-component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getPagesSection,
  updatePagesSection,
} from "services/website Services/pagesServices";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { MediaBannerAccordion } from "components/website/Media Banner/media-banner";
import { MediaValidation } from "./validations/media-validation";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const MediaPageBanner = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState<any>(false);
  const [apiData, setApiData] = useState<any>(null);

  const routes: any = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Pages",
      link: "/admin/pages",
    },
    {
      name: "News",
      link: "",
    },
  ];

  const defaultFormValues: any = {};

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(MediaValidation)
  });
  useEffect(() => {
    getApiData();
  }, []);

  // console.log("aaaa", apiData?.page_content?.home_banner?.app_store_url);

  // get Api Data
  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getPagesSection()
      .then((res: any) => {
        setApiData(
          res?.data?.result?.find((e: any) => e.page_name === "news")
        );
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));
      });
  };

  // console.log("media data", apiData);

  const onSubmit = (data: any) => {
    data.media_banner = {
      image: data.image,
    };
    data.media = {
      name: data.name,
      description: data.media_description,
    };

    delete data.name;
    delete data.media_description;
    delete data.image;

    let modifyData: any = {
      page_content: data,
    };

    if (apiData?.uuid) {
      modifyData.uuid = apiData?.uuid;
    }
    updateOnSubmit(modifyData);
    console.log("data", modifyData);
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updatePagesSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const toggleAll = () => setCollapse(!collapse);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader title="News" routes={routes} />
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" className="card-header-export-btn">
              <h4 className={classes.cardTitleWhite}>News</h4>
              <Button
                variant="outlined"
                className="custom-btn export-btn"
                onClick={toggleAll}
              >
                {collapse ? "Collapse All" : "Expand All"}
              </Button>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12} sm={12}>
                  <MediaBannerAccordion
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    errors={errors}
                    apiData={apiData}
                    collapse={collapse}
                  />
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                  <PartnersLogoSection
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    errors={errors}
                    apiData={apiData}
                    collapse={collapse}
                  />
                </GridItem> */}
              </GridContainer>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%", marginTop: "15px" }}
              >
                <Button color="primary" className={"custom-btn"} type="submit">
                  Submit
                </Button>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

import * as yup from 'yup'

const createVendorValidations = yup.object().shape({
    first_name:yup.string().required("Please enter first name").nullable(),
    email:yup.string().required("Please enter email").email().nullable(),
    mobile_no:yup.string().required("Please enter mobile number").nullable(),
   client_name:yup.string().required("Please enter client name").nullable(),
    website:yup.string().matches(
        /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/, "Invalid website").required("Please enter website").nullable(),


})

export{
    createVendorValidations
}
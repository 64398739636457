import * as yup from "yup";


const startTimeRules: any = yup.date().when("status", {
  is: false,
  then: yup.date().typeError("Please Select Start Time").required('Please select start time.').nullable()
}).when("status", {
  is: true,
  then: yup.date().nullable()
}).when("end_time", {
  is: !null,
  then: yup.date().required('Please select start time.').max(yup.ref('end_time'), 'Start time should be greater than end time').nullable()
});


const BstartTimeRules: any = yup.date().when("status", {
  is: false,
  then: yup.date().nullable()
}).when("status", {
  is: true,
  then: yup.date().nullable()
}).when("break_end_time", {
  is: !null,
  then: yup.date().required('Please select start time.').max(yup.ref('break_end_time'), 'Break Start time should be greater than Break end time').nullable()
});

const endTimeRules: any = yup.date().when("status", {
  is: false,
  then: yup.date().required('Please select end time.').min(yup.ref('start_time'), 'End time should be later than start time').nullable()
}).when("status", {
  is: true,
  then: yup.date().nullable()
});

const BendTimeRules: any = yup.date().when("status", {
  is: false,
  then: yup.date().min(yup.ref('break_start_time'), 'Break End time should be later than Break start time').nullable()
  
  
  
  
}).when("status", {
  is: true,
  then: yup.date().nullable()
});





const createBranchTimeScheduleValidation: any = yup.object().shape({
  schedule: yup.object().shape({
    name: yup.string().required('Please enter schedule name.').nullable(),
  }),
  schedule_details: yup.array().of(yup.object().shape({
    monday: yup.object().shape({
      status: yup.boolean().default(true),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    }),
    tuesday: yup.object().shape({
      status: yup.boolean().default(true),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    }),
    wednesday: yup.object().shape({
      status: yup.boolean().default(true),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    }),
    thursday: yup.object().shape({
      status: yup.boolean(),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    }),
    friday: yup.object().shape({
      status: yup.boolean(),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    }),
    saturday: yup.object().shape({
      status: yup.boolean(),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    }),
    sunday: yup.object().shape({
      status: yup.boolean(),
      start_time: startTimeRules,
      end_time: endTimeRules,
      break_start_time: BstartTimeRules,
      break_end_time: BendTimeRules
    })
  }))
});

export {
  createBranchTimeScheduleValidation

}
import React,{useState} from 'react'
import { DropzoneAreaBase } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";


const styles: any = {
    dropboxContainer: {
        width:'100%',
        minHeight:'100px'
    }
  };
  
  const useStyles = makeStyles(styles);

export const FileUploadArea = (props:any)=>{
    const classes = useStyles();
    const{handleSave }=props
    const[fileObjects,setFileObjects]=useState<any>([])

return (
   <DropzoneAreaBase
   dropzoneClass={classes.dropboxContainer}
  fileObjects={fileObjects}
  onAdd={handleSave}
  dropzoneText={ props.message ? props?.message: "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."  }
  showAlerts={false}
  showPreviews={true}
  filesLimit={10}
  maxFileSize={props.limit? props.limit : 80000000}
  acceptedFiles={props?.video ? props?.video : ['image/jpeg', 'image/png', 'image/svg+xml']} />
)

}



import React from "react";
import { DropzoneDialog } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";

const styles: any = {
  dropboxContainer: {},
};

const useStyles = makeStyles(styles);

export const FileUploadDialog = (props: any) => {
  const classes = useStyles();
  const { open, setOpen, handleSave } = props;
  return (
    <div className={classes.dropboxContainer}>
      <DropzoneDialog
        acceptedFiles={["image/jpeg", "image/png", "video/*"]}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
        maxFileSize={10000000}
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleSave}
        showPreviews={true}
        showFileNamesInPreview={true}
        showAlerts={false}
        filesLimit={1}
      />
    </div>
  );
};

import * as yup from "yup";
export const HomeValidation: any = yup.object().shape({
  //home banner validation
  home_title: yup.string().required("please enter Title").nullable(),
  home_description: yup
    .string()
    .required("please enter Description")
    .nullable(),
  home_video_url: yup
    .string()
    .required("please enter Video Url")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%_+*^~]+&?)?$/,
      "Enter correct url!"
    )
    .nullable(),
  home_play_store_url: yup
    .string()
    .required("please enter Playstore Url")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%_+*^~]+&?)?$/,
      "Enter correct url!"
    )
    .nullable(),
  home_app_store_url: yup
    .string()
    .required("please enter Appstore Url")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%_+*^~]+&?)?$/,
      "Enter correct url!"
    )
    .nullable(),

  //how to use jino validation
  use_jino_title: yup.string().required("please enter Title").nullable(),
  use_jino_sub_title: yup
    .string()
    .required("please enter Sub Title")
    .nullable(),
  use_jino_description: yup
    .string()
    .required("please enter Description")
    .nullable(),

  //fieldArray validation how to use jino
  how_to_use_jino_array: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      sub_title: yup.string().required("Please enter Sub Title").nullable(),
    })
  ),

  //why use jino validation
  why_use_title: yup.string().required("please enter Title").nullable(),
  why_use_sub_title: yup.string().required("please enter Sub Title").nullable(),
  why_use_description: yup
    .string()
    .required("please enter Description")
    .nullable(),
  cta_text: yup.string().required("please enter field").nullable(),
  cta_url: yup.string().required("please enter field").nullable(),

  //fieldArray validation why use jino
  why_use_jino_array: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      short_description: yup
        .string()
        .required("Please enter Short Description")
        .nullable(),
      // video_link: yup
      //   .string()
      //   .matches(
      //     /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      //     "please enter correct Url"
      //   )
      //   .required("Please enter Video url")
      //   .nullable(),
    })
  ),

  //why use jino validation
  testimonials_title: yup.string().required("please enter Title").nullable(),
  testimonials_sub_title: yup
    .string()
    .required("please enter Sub Title")
    .nullable(),

  //fieldArray validation testimonials_array
  testimonials_array: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Please enter Title").nullable(),
      description: yup
        .string()
        .required("Please enter Short Description")
        .nullable(),
      no_of_stars: yup
        .string()
        .required("Please enter Number Of Stars")
        .matches(/^[1-9]\d*(\.\d+)?$/, "Numaric value allow only")
        .nullable(),
    })
  ),
});

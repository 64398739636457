import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DateField, InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const MediaBannerAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const [UpcomingDate, setUpcomingEvent] = useState<any>(null);
  const [dropOpenDetail, setDropOpenDetail] = useState<any>(false);
  const [urlDetails, setUrlDetail] = useState<any>("");

  const defaultFormValues: any = {};
  useEffect(() => {
    if (apiData) {
      setValue("name", apiData?.page_content?.media?.name);
      setValue("media_description", apiData?.page_content?.media?.description);
      setValue("image", apiData?.page_content?.media_banner?.image);
      setUrl(apiData?.page_content?.media_banner?.image);
    }
  }, [apiData]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("image", data.result[0]);
    setUrl(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  const handleSaveDetails = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpenDetail(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("image_detail", data.result[0]);
    setUrlDetail(data.result[0]);
    dispatch(spinLoaderShow(false));
  };
  const UpcomingEventDate = (data: any) => {
    setUpcomingEvent(data);
  };

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>News Banner</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
          <Box
            style={{ display: "flex", alignItems: "center" }}
            alignItems="center"
          >
            <Avatar
              alt="Remy Sharp"
              src={url}
              className={classes.avatar}
              style={{ height: "100px", width: "100px" }}
            />
            <Button
              variant="outlined"
              color="primary"
              disabled={disabled}
              className={classes.uploadButton}
              onClick={() => setDropOpen(true)}
            >
              News Logo
            </Button>
            <FileUploadDialog
              open={dropOpen}
              setOpen={setDropOpen}
              handleSave={handleSave}
              message={
                "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
              }
            />
          </Box>
        </GridItem>
        <GridItem
          xs={6}
          md={6}
          sm={6}
          alignItems="center"
          style={{ marginTop: "30px" }}
        >
          <InputField
            errors={!!errors?.name}
            fieldName="name"
            type="text"
            autoComplete="false"
            label="*Title "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={errors?.name && errors?.name?.message}
          />
        </GridItem>
      </AccordionDetails>
      {/* <AccordionDetails>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Typography
              variant="h6"
              align={"left"}
              style={{ marginTop: "5px" }}
            >
              Upcoming Event Detail
            </Typography>
          </GridItem>
          <GridItem
            xs={6}
            sm={6}
            md={6}
            style={{ marginTop: "25px", padding: "5px", marginLeft: "0px" }}
          >
            <Box
              style={{ display: "flex", alignItems: "center" }}
              alignItems="center"
            >
              <Avatar
                style={{ height: "100px", width: "100px" }}
                alt="Remy Sharp"
                src={urlDetails}
                className={classes.avatar}
              />
              <Button
                style={{ marginLeft: "20px" }}
                variant="outlined"
                color="primary"
                disabled={disabled}
                className={classes.uploadButton}
                onClick={() => setDropOpenDetail(true)}
              >
                Detail Image
              </Button>
              <FileUploadDialog
                open={dropOpenDetail}
                setOpen={setDropOpenDetail}
                handleSave={handleSaveDetails}
                message={
                  "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                }
              />
            </Box>
          </GridItem>
          <GridItem
            xs={6}
            sm={6}
            md={6}
            style={{ marginTop: "25px", padding: "5px", marginLeft: "0px" }}
          >
            <InputField
              errors={!!errors?.event_name}
              fieldName="event_name"
              type="text"
              autoComplete="off"
              label="* Upcoming Event Name"
              control={control}
              iProps={{
                disabled: disabled,
              }}
              // valueGot={editData.find( ({ name })})
              setValue={setValue}
              helperText={errors?.event_name && errors?.event_name?.message}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <DateField
              errors={!!errors?.upcoming_date}
              fieldName="upcoming_date"
              autoComplete="off"
              label="* Upcoming Event Date"
              setValue={setValue}
              control={control}
              inputVariant="outlined"
              iProps={{
                disablePast: true,
                disabled: disabled,
                format: "dd-MM-yyyy",
                onChange: UpcomingEventDate,
              }}
              helperText={
                errors?.upcoming_date && errors?.upcoming_date?.message
              }
              valueGot={UpcomingDate}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <InputField
              errors={!!errors?.event_location}
              fieldName="event_location"
              type="text"
              autoComplete="off"
              label="* Upcoming Event Location"
              control={control}
              iProps={{
                disabled: disabled,
              }}
              // valueGot={editData.find( ({ name })})
              setValue={setValue}
              helperText={
                errors?.event_location && errors?.event_location?.message
              }
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails> */}
      <AccordionActions></AccordionActions>
    </ControlledAccordion>
  );
};

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import PageHeader from "components/PageHeader/PageHeader";
import { Popup } from 'components/Popup/Popup.component'
import { TypePopup } from "containers/VehicleTypeManager/type.popup.component"
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getVehicleType } from "services/vehiclesServices";
import { bulkVehicleTypeEnable, bulkVehicleTypeDisable } from "services/bulkEnableServices";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment-timezone";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  icon: {
    color: "#043cae",
    '&:hover': {
      color: "#4287f5",
    }
  }
};

const useStyles = makeStyles(styles);

export const VehicleTypeList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null)
  const [gridColumnApi, setGridColumnApi] = useState<any>(null)
  const [rowData, setRowData] = useState<any>([])
  const [vehicletypeRow, setVehicleTypeRow] = useState([]);
  const [editData, setEditData] = useState<any>(null)
  const [headerTitle, setHeaderTitle] = useState<any>("")
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)

  }

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Vehicle Type",
      link: "",
    },
  ];

  const addNew = () => {

    setOpen(true);
    setHeaderTitle("Add Vehicle Type")
  }

  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getVehicleType().then((e) => {
      let data = e?.data?.result.map((item: any) => {
        item.status = item.status ? 'active' : 'inactive'
        return item;
      })
      setRowData(data)
      dispatch(spinLoaderShow(false));
    }).catch((err) => {
    })
  }


  const handleClose = () => {
    getApiData()
    setOpen(false);
    setEditData(null)

  };

  useEffect(() => {
    getApiData()

  }, [])
  useEffect(() => {
    let ispermission = !(verifyPermission(
      getPermission,
      'Vehicles',
      'Type',
      'update'
    ))
    setDisabled(ispermission)
  }, [getPermission])
  const columnDefs: any = [
    {
      headerName: "Vehicle Type",
      field: "name",
    },

    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
     
      sort: "desc",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

    // }
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
     
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
  },
    {
      headerName: 'Created Date',
      field: "createdAt",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

    // },
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        return <EditIcon className={classes.icon} color={"inherit"} onClick={() => {
          setOpen(true)
          params.data.status = params.data.status == 'active' ? true : false
          setEditData(params.data)
          setHeaderTitle("Edit Vehicle Type")
        }} />;
      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter:false,
      cellRendererFramework: (params: any) => {
        return <VisibilityIcon className={classes.icon} color={"inherit"} onClick={() => {
          setOpen(true)
          params.data.status = params.data.status == 'active' ? true : false
          params.data.view = 'view'
          setEditData(params.data)
          setHeaderTitle("View Vehicle Type")
        }} />;
      }
    }
  ]



  const enableSelectedRows = () => {
    let temp: any = {}
    temp = exportData.getSelectedRows().map((item: any) => {
      return { uuid: item?.uuid }

    })
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true))
      bulkVehicleTypeEnable(temp).then((res: any) => {
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getApiData()
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        getApiData()
      })
    }
    else {
      toast.error("Please select a row")
    }

  }
  const disableSelectedRows = () => {
    let temp: any = {}
    temp = exportData.getSelectedRows().map((item: any) => {
      return { uuid: item?.uuid }

    })
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true))
      bulkVehicleTypeDisable(temp).then((res: any) => {
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getApiData()
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        getApiData()
      })
    } else {
      toast.error("Please select a row")
    }


  }
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()
  
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Vehicle Type"
            routes={routes}
            modalButton={{ text: "Add New", onClick: addNew }}
            permission="Vehicles.Type.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Vehicle Type List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>
          </CardHeader>
          <CardBody>
            <Button disabled={disabled}  variant="outlined" className="custom-btn export-btn" color="primary" onClick={(e: any) => enableSelectedRows()}>Enable</Button>
            <Button disabled={disabled}  variant="outlined" className="custom-btn export-btn" color="primary" onClick={(e: any) => disableSelectedRows()}>Disable</Button>
            <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.uuid;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
      <Popup handleClose={handleClose} open={open} title={headerTitle} content={<TypePopup handleClose={handleClose} title={headerTitle} editData={editData} />} />
    </GridContainer>
  );
}

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Button, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box } from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import PageHeader from "components/PageHeader/PageHeader";
import {
  InputField,
  LoadingButton,
  SwitchField,
} from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import zxcvbn from "zxcvbn";
import "../../../src/assets/css/PasswordStrengthMeter.css";
import { ChangePassword } from "./changePassword-c.container";
import { UpdateProfile } from "./updateProfile-c.container";


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const defautlValues: any = {};

const useStyles = makeStyles(styles);

export const Profile = (props: any) => {
  const createPasswordLabel = (result: any) => {
    switch (result.score) {
      case 0:
        return "";
      case 1:
        return "Weak";
      case 2:
        return "Fair";
      case 3:
        return "Good";
      case 4:
        return "Excellent";
      default:
        return "Weak";
    }
  };

  const classes = useStyles();
  

  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defautlValues,
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Profile",
      link: "",
    },
  ];

  
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader title="Profile " routes={routes} />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Profile</h4>
          </CardHeader>
          <UpdateProfile />
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <ChangePassword />
      </GridItem>
    </GridContainer>
  );
};

import * as yup from "yup";

const levelDependent: any = {
  Country: ["country"],
  City: ["country", "city"],
  Branch: ["country", "city", "branch"],
  Zone: ["country", "city", "branch", "zone"],
};

const createPromotionValidations: any = yup.object().shape({
  name: yup.string().required("Please enter name").nullable(),
  discount_percentage: yup
    .number()
    .required("Please enter discount percentage")
    .typeError("Please enter discount percentage")
    .min(0)
    .max(100, "discount percentage must be less than or equal to 100")
    .nullable(),
  code: yup
    .string()
    .matches(/^(?:[a-zA-Z0-9-]+(?:, [a-zA-Z0-9-]+)*)?$/, "Please enter valid promo code")
    .nullable(),
  user_limit: yup
    .string()
    .notRequired()
    .matches(/^(\s{0}|[1-9][0-9]*)$/, " 1 to 9 numbers only")
    .nullable(true),
  code_limit: yup
    .string()
    .notRequired()
    .matches(/^(\s{0}|[1-9][0-9]*)$/, " 1 to 9 numbers only")
    .nullable(true),
  count: yup
    .string()
    .notRequired()
    .matches(/^(\s{0}|[1-9][0-9]*)$/, " 1 to 9 numbers only")
    .nullable(true),
  start_date: yup.date().required("Please enter start date").nullable(),
  end_date: yup
    .date()
    .required("Please enter end date")
    .min(yup.ref("start_date"), "End date should be later than start date")
    .nullable(),
  level_id: yup.object().required("Please select level").nullable(),
  description: yup.string().required("Please enter description").nullable(),
  notification_title: yup
    .string()
    .when("automate_notifications", {
      is: true,
      then: yup.string().required("Please enter notification title"),
    })
    .nullable(),
  notification_body: yup
    .string()
    .when("automate_notifications", {
      is: true,
      then: yup.string().required("Please enter notification body"),
    })
    .nullable(),
  // color_name:yup.string().required("Please enter color name").nullable(),

  country_id: yup
    .string()
    .when("level_id", {
      is: function (data: any) {
        if (data !== null && data.name) {
          return levelDependent[data.name].includes("country");
        }
        return false;
      },
      then: yup.string().required("Please select country").nullable(),
    })
    .nullable(),
  city_id: yup
    .string()
    .when("level_id", {
      is: function (data: any) {
        if (data !== null && data.name) {
          return levelDependent[data.name].includes("city");
        }
        return false;
      },
      then: yup.string().required("Please select city").nullable(),
    })
    .nullable(),
  branch_id: yup
    .string()
    .when("level_id", {
      is: function (data: any) {
        if (data !== null && data.name) {
          return levelDependent[data.name].includes("branch");
        }
        return false;
      },
      then: yup.string().required("Please select branch").nullable(),
    })
    .nullable(),
  zone_id: yup
    .string()
    .when("level_id", {
      is: function (data: any) {
        if (data !== null && data.name) {
          return levelDependent[data.name].includes("zone");
        }
        return false;
      },
      then: yup.string().required("Please select zone").nullable(),
    })
    .nullable(),
});

export { createPromotionValidations };

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import PageHeader from "components/PageHeader/PageHeader";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { verifyPermission } from 'helper/permission'
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import { getDriverConfig,updateDriverConfig, getDriverConfigView,getDriverConfigData,getDriver ,getDriverConfigDataView } from 'services/driverServices'
import { spinLoaderShow } from "redux/action/spinLoader";
import moment from "moment";
import { Switch, Grid } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import {bulkDriverConfigsEnable,bulkDriverConfigsDisable} from 'services/bulkEnableServices'


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  dialog88: {
    zIndex:"88 !important"
   }
};

const useStyles = makeStyles(styles);




export const DriverConfigList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false)
  const [rowData, setRowData] = useState([]);
  const [rowDataMain, setRowDataMain] = useState([]);

  const [exportData, setExportData] = useState<any>(null)
  const [open, setOpen] = useState<any>(false)
  const [temp, setTemp] = useState<any>(false)
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Driver Config",
      'link': ''
    }
  ];

const handleClickOpen = () => {
    setOpen(true);
  };
  

  const handleClose = () => {
    setOpen(false);
    getDataFromApi();
  };

  const handleSwitchChange: any = (data: any) => {
    let val = {
      driver_id: data.uuid,
      service_config_id: data.service_config_id,
      status: !data.status
    }
    setTemp(val)
    setOpen(true)
  }

  // // GET DATA FROM API
  // const getData = () =>{
  //     // START LOADER
  //   dispatch(spinLoaderShow(true))

  //   getDriverConfigView().then((e: any) => {

  //     // SET GRID DATA
  //     let data =  e?.data?.result.map((item:any)=>{
  //       item.status=item.status? 'active' : 'inactive' 
  //        return item;
  //      })
  //     setRowData(data)
  //     setRowDataMain(Object.assign([], data));

  //     // STOP LOADER 
  //     dispatch(spinLoaderShow(false))

  //   }).catch((err) => {+
  //     dispatch(spinLoaderShow(false))
  //   })

  // }  

  const getDataFromApi = () => {
    dispatch(spinLoaderShow(true))

    getDriverConfigDataView().then((res:any)=>{  
      let data = res?.data?.result 
      setRowData(data)
    dispatch(spinLoaderShow(false))
    }).catch((err:any)=>{ 
    dispatch(spinLoaderShow(false))
    })
  }

  useEffect(() => {
    

    getDataFromApi();
    let ispermission = !(verifyPermission(
      getPermission,
      'Driver',
      'Config',
      'create'
    ))
    setDisabled(ispermission)
  }, [])

  const columnDefs: any = [
    {
      headerName: "First Name",
      field: "first_name",

    },
    {
      headerName: "Last Name",
      field: "last_name",
    },
    // {
    //   headerName:"Email",
    //   field:"email"
    // },
    // {
    //   headerName: "City",
    //   field: "city"
    // },
    {
      headerName: "Services",
      field: "services"
    },
    {
      headerName: "Zones (Buffer Mins)",
      field: "zones"
    },
    {
      headerName: 'Driver Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    
    // {
    //   headerName: 'Modified Date',
    //   field: "updatedAt",
    //   sort: "desc",
    // //   valueGetter: (params: any) => {
    // //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

    // // },
    // filter: 'agDateColumnFilter',
    // valueFormatter: function (data:any) {
    //   return moment(data.value).format('YYYY-MM-DD HH:mm')
    // },
    // filterParams: {
    //   filterOptions: ['equals', 'lessThan', 'greaterThan'],
    //   comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
    //     console.log('filtrlocaldate ', filterLocalDateAtMidnight)

    //     console.log('cellValue ', cellValue)

    //     console.log(
    //       'filter ',
    //       moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
    //     )
    //     const filter = moment(filterLocalDateAtMidnight).format(
    //       'YYYY-MM-DD HH:mm',
    //     )
    //     const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

    //     if (filter === compare) {
    //       return 0
    //     }
    //     if (compare < filter) {
    //       return -1
    //     }
    //     if (compare > filter) {
    //       return 1
    //     }
    //   },
    // },
    // },
    // {
    //   headerName: 'Created Date',
    //   field: "createdAt",
     
    // //   valueGetter: (params: any) => {
    // //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

    // // },
    // filter: 'agDateColumnFilter',
    // valueFormatter: function (data:any) {
    //   return moment(data.value).format('YYYY-MM-DD HH:mm')
    // },
    // filterParams: {
    //   filterOptions: ['equals', 'lessThan', 'greaterThan'],
    //   comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
    //     console.log('filtrlocaldate ', filterLocalDateAtMidnight)

    //     console.log('cellValue ', cellValue)

    //     console.log(
    //       'filter ',
    //       moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
    //     )
    //     const filter = moment(filterLocalDateAtMidnight).format(
    //       'YYYY-MM-DD HH:mm',
    //     )
    //     const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

    //     if (filter === compare) {
    //       return 0
    //     }
    //     if (compare < filter) {
    //       return -1
    //     }
    //     if (compare > filter) {
    //       return 1
    //     }
    //   },
    // },
    // },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false, 
      hide:disabled,
      cellRendererFramework: (params: any) => {
        return <Link to={'driver-config/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
   
   
  ]

  const updateDriverConfigStatus = () => {
    dispatch(spinLoaderShow(true))
   let updatedStatus:any = {
     configs:[temp]
   }

    // update Driver config api 
    updateDriverConfig(updatedStatus).then((e:any)=>{
      dispatch(spinLoaderShow(false))
      toast.success(UPDATE_SUCCESS_MSG);
      setTemp([])
      getDataFromApi()
      setOpen(false)
    }).catch((err)=>{
      dispatch(spinLoaderShow(false));
    })


  }

  const enableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { driver_id : item?.uuid,
        service_config_id: item.service_config_id}      
    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
      bulkDriverConfigsEnable(temp).then((res:any)=>{
          dispatch(spinLoaderShow(false))
          toast.success("Record Updated successfully")
          getDataFromApi()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
        getDataFromApi()
      })
    }else{
      toast.error("Please select a row")
    }
   
  
  }
  const disableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { driver_id : item?.uuid,
          service_config_id: item.service_config_id}  
  
    }) 
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
      bulkDriverConfigsDisable(temp).then((res:any)=>{
          dispatch(spinLoaderShow(false))
          toast.success("Record Updated successfully")
          getDataFromApi()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
        getDataFromApi()
      })
    } else{
      toast.error("Please select a row")
    }
   
    
  }
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getDataFromApi()
  
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Driver Config"
            routes={routes}
            // newItem={[{ text: 'Add New Driver Config', link: "driver-config/add" }]}
            permission="Driver.Config.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Driver Config List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
          {/* <Button variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>enableSelectedRows() }>Enable</Button>
          <Button variant="outlined" color="primary"className="custom-btn export-btn" onClick={(e: any) =>disableSelectedRows() }>Disable</Button> */}
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.uuid + data?.service_config_id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>

     {open && <Dialog
              open={open}
              onClose={handleClose}
              className={classes.dialog88}
              disableBackdropClick
            >
              <DialogTitle id="alert-dialog-title">{"Enable/Disable Driver Config?"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                 Are you sure you want to enable/disable ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={()=>{handleClose()}} color="primary">
                  No
                </Button>
                <Button onClick={()=>{updateDriverConfigStatus()}} color="primary" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog> } 
    </GridContainer>
  );
}


import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { InputField } from "components/Forms/Formfield.component";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";
import { Box, Button } from "@material-ui/core";

export const WebArray = (props: any) => {
  const { control, setValue, errors, item, getValues } = props;
//   console.log("item",item);
  
  const [dropOpen, setDropOpen] = useState<any>(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `${item?.name}.tiles`,
  });
  return (
    <>
      {fields.map((itemx: any, indexX) => {
        return (
          <GridContainer key={item.id} spacing={3}>
            {/* <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                alignItems="center"
              >
                <FileUploadFieldArray
                  fieldName={`${item?.name}.tiles[${indexX}].logo`}
                  keyss={`${item?.name}.tiles[${indexX}.logo]`}
                  open={dropOpen}
                  index={indexX}
                  lable={"Image"}
                  setOpen={setDropOpen}
                  setValue={setValue}
                  getValuesX={getValues}
                  control={control}
                  message={
                    "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                  }
                />
              </Box>
            </GridItem> */}
            <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
              <InputField
                errors={!!errors?.[item?.name]?.tiles?.[indexX]?.title}
                fieldName={`${item?.name}.tiles[${indexX}].title`}
                type="text"
                label="* Title"
                autoComplete="false"
                setValue={setValue}
                valueGot={""}
                control={control}
                helperText={
                  errors?.[item?.name]?.tiles?.[indexX]?.title &&
                  errors?.[item?.name]?.tiles?.[indexX]?.title.message
                }
              />
            </GridItem>
            <GridItem xs={6} sm={6} md={6} style={{marginTop:"35px"}}>
              <Button
                style={{ marginLeft: "20px" }}
                variant="contained"
                color="primary"
                className={"custom-btn"}
                onClick={() => {
                  remove(indexX);
                }}
              >
                Remove
              </Button>
            </GridItem>
          </GridContainer>
        );
      })}
      <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
        <Button
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
          variant="contained"
          color="primary"
          className={"custom-btn"}
          onClick={() => append({ title: ""})}
        >
          Add more
        </Button>
      </GridItem>
    </>
  );
};

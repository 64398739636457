import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from 'components/Mix/Status';
import Button from "components/CustomButtons/Button";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import { getCustomersVehicle } from "services/customerServices";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import { verifyPermission } from 'helper/permission'
import moment from "moment";
import {CirclePicker} from 'react-color'; 
import {bulkCustomerVehicleEnable,bulkCustomerVehicleDisable} from 'services/bulkEnableServices'
import { toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {customerVehiclesOData} from 'services/oDataServices'
import AgGridEnterprise from "components/AgGridCustom/AgGridEnterprise";
const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const CustomerVehicleList = (props: any) => {
  const classes = useStyles();
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [exportData, setExportData] = useState<any>(null)
  const [disabled, setDisabled] = useState<any>(false)
  const [gridApi, setGridApi] = useState<any>(null);
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch()

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Customer Vehicle",
      'link': ''
    }
  ];
  const columnDefs: any = [
    {
      headerName: "First Name",
      field: "customer_firstname",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }

    },
    {
      headerName: "Last Name",
      field: "customer_lastname",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Mobile Number",
      field: "customer_mobile",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: "Vehicle Type",
      field: "vehicle_type",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }

    },
    {
      headerName: "Model",
      field: "vehicle_brand_name",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }

    },
    {
      headerName: "Brand",
      field: "vehicle_model",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }

    },
    {
     headerName:"Color",
     field: 'color',
     filter: 'agTextColumnFilter',
     filterParams: {
       newRowsAction: 'keep', // Preserve the existing filters when adding new ones
       filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
       suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
     },
     cellRendererFramework: (params: any) => {
       
       return <CirclePicker colors={[params?.data?.color ? params?.data?.color : "white"]} />
     }
    },
    {
      headerName: "Plate Number",
      field: "plate_no",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }

    },
    {
      headerName: "Year",
      field: "year",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName:"Country",
      field:"country",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    { 
      headerName:"City",
      field:"city",
      filter: 'agTextColumnFilter',
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['contains', 'startsWith', 'endsWith', 'equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },

    {
      headerName: 'Status',
      field: "status",
      filter: 'agTextColumnFilter',
      cellRendererFramework: (params: any) => {
        return <StatusComponent data={params} />;
      },
      filterParams: {
        newRowsAction: 'keep', // Preserve the existing filters when adding new ones
        filterOptions: ['equals', 'notEqual'],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedat",
      //
      sort: "desc",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

    // }
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
      newRowsAction: 'keep', // Preserve the existing filters when adding new ones
      suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      filterOptions: ['lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
  },
  {
    headerName: 'Created Date',
    field: "createdat",
    sort: "desc",
  //   valueGetter: (params: any) => {
  //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

  // },
  filter: 'agDateColumnFilter',
  valueFormatter: function (data:any) {
    return moment(data.value).format('YYYY-MM-DD HH:mm')
  },
  filterParams: {
    newRowsAction: 'keep', // Preserve the existing filters when adding new ones
    suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
    filterOptions: [ 'lessThan', 'greaterThan'],
    comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
      console.log('filtrlocaldate ', filterLocalDateAtMidnight)

      console.log('cellValue ', cellValue)

      console.log(
        'filter ',
        moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
      )
      const filter = moment(filterLocalDateAtMidnight).format(
        'YYYY-MM-DD HH:mm',
      )
      const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

      if (filter === compare) {
        return 0
      }
      if (compare < filter) {
        return -1
      }
      if (compare > filter) {
        return 1
      }
    },
  },
  },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        return <Link to={'customer-vehicle/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        return <Link to={'customer-vehicle/edit?uuid=' + params?.data?.uuid +"&type=view"}><VisibilityIcon /></Link>;
      }
    },
  ]


  // useEffect(() => {
  //  getApiData() 
  // }, [])

  useEffect(()=>{
    let ispermission = !(verifyPermission(
      getPermission,
      'Customer',
      'Customer Vehicles',
      'update'
    ))
    setDisabled(ispermission)
  },[getPermission])
  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getCustomersVehicle().then((e) => {
      let data = e?.data?.result.map((item:any)=>{
        item.status=item.status? 'active' : 'inactive' 
         return item;
      })
      setRowData(data)
      dispatch(spinLoaderShow(false));
    }).catch((err: any) => {
      dispatch(spinLoaderShow(false))
    })
  }
  const enableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid}  
  
    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
      bulkCustomerVehicleEnable(temp).then((res:any)=>{
          dispatch(spinLoaderShow(false))
          toast.success("Record Updated successfully")
          getApiData()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
        getApiData()
      })
    }else{
      toast.error("Please select a row")
    }
    
  
  }
  const disableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid}  
  
    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
    bulkCustomerVehicleDisable(temp).then((res:any)=>{
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getApiData()
    }).catch((err)=>{
      dispatch(spinLoaderShow(false))
      getApiData()
    })
    } else{
      toast.error("Please select a row")
    }
   
    
  }
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()
  
  }
  const totalCount: number = 96;
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title={`Customer Vehicle `}
            routes={routes}
            newItem={[{ text: 'Add New vehicles', link: "customer-vehicle/add" }]}
            permission="Customer.Customer Vehicles.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Customer Vehicles Listing</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
          <Button disabled={disabled}  variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>enableSelectedRows() }>Enable</Button>
          <Button disabled={disabled}  variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>disableSelectedRows() }>Disable</Button>
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => {exportData && exportData?.refreshServerSideStore({ purge: true })}}>Refresh</Button>
          <AgGridEnterprise 
              dataSourceUrl={customerVehiclesOData}
              columnDefs={columnDefs}
              getRowNodeId={(data: any) => {
                return data.uuid;
              }}
              setExportData={setExportData}
              componentType="normal"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


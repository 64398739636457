import React, { useEffect,useState } from 'react';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { InputField, SwitchField, AutoCompleteField, SwitchFieldDefault } from 'components/Forms/Formfield.component'
import { useForm } from 'react-hook-form';
import { Label } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaqValidation } from './Validations/faqtypesValidation';
import { createFaqType,updateFaqType } from 'services/faqServices'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';
import { spinLoaderShow } from "redux/action/spinLoader";
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import {CREATE_SUCCESS_MSG} from 'json/constants'
import {Typography} from '@material-ui/core/'
import moment from 'moment'

export const FaqPopup = (props: any) => {
  
  const defaultFormValues: any = {
    name: "",
    status: true,
  }
  const { control, handleSubmit, formState: { errors }, setValue, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(FaqValidation)
  })
  const { handleClose, open, title, editData, setOpen } = props
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState<any>(false)
  
  useEffect(() => {
    if (editData && Object.keys(editData).length > 0) {
      setValue("typeName", editData.typeName);
      setValue("status", editData.status);
      if (editData?.view){
        setDisabled(true)
      }
    } else {
      reset(defaultFormValues);
    }
  }, [editData]);

  const onSubmit = async (data: any) => {    
    dispatch(spinLoaderShow(true))
    let uuid: any = editData?.uuid;
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
createFaqType(data).then((e: any) => {
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()
      // history.push("/admin/faq/type");

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false))
      });
  };

  const updateOnSubmit = (data: any) => {

    updateFaqType(data).then((e: any) => {
      toast.success(UPDATE_SUCCESS_MSG);
      reset(defaultFormValues);
      dispatch(spinLoaderShow(false))
      handleClose()
      // history.push("/admin/faq/type");

    })
    .catch((e: any) => {
      dispatch(spinLoaderShow(false))
    });
  };
  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <GridContainer>
        <GridItem  xs={12} md={12} sm={12}>
          {editData !=null ?  <Typography>Modified At:  { moment(editData?.updatedAt).format('llll')} </Typography> : null}
          </GridItem>
          <GridItem xs={8} sm={8} md={8} >
            <InputField
              errors={!!errors?.typeName}
              fieldName="typeName"
              type="text"
              autoComplete="false"
              label="Name"
              iProps={{
                disabled: disabled,
              }}
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.typeName && errors?.typeName?.message}

            />

          </GridItem>
          <GridItem xs={4} sm={4} md={4} className={"mt-15"}>
            <SwitchFieldDefault
              errors={!!errors?.status}
              fieldName="status"
              autoComplete='off'
              label="status"
              defaultChecked="true"
              control={control}
              valueGot={''}
              disabled={disabled}
              setValue={setValue}
              helperText={errors?.status && errors?.status?.message}
            />
          </GridItem>
        </GridContainer>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => { reset(defaultFormValues); handleClose() }} color="primary">
          Cancel
        </Button>
        <Button type="submit" disabled={disabled} color="primary">
          submit
        </Button>
      </DialogActions>
    </form>
  )
}
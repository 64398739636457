import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

export function getEventDetail(body: any = null) {
  return httpservice.get("/eventdetail", {
    params: body,
  });
}
export function createEventDetail(body: any) {
  console.log("bodyyyyyyyyyyyyy", body);
  return httpservice.post("/eventdetail", body);
}
export function updateEventDetail(body: any) {
  return httpservice.put("/eventdetail", body);
}
// export function updateEventDetail(body: any) {
//   return httpservice.put("/eventdetail", body);

// }

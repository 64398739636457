import httpservice from "./httpservice";
export function getPromotions(body: any = null) {
  return httpservice.get("/promotions", { params: body });
}
export function getPromotionsView(body: any = null) {
  return httpservice.get("/promotions/view/", { params: body });
}
export function getPromotionsViewLinked(body: any = null) {
  return httpservice.get("/promotions/view/linked", { params: body });
}
export function getPromotionsViewLinkedAll(body: any = null) {
  return httpservice.get("/promotions/view/linked/all", { params: body });
}
export function getPromotionsViewNormal(body: any = null) {
  return httpservice.get("/promotions/view/normal", { params: body });
}
export function getPromotionsViewAutoApplied(body: any = null) {
  return httpservice.get("/promotions/view/auto_applied", { params: body });
}
export function createPromotion(body: any) {
  return httpservice.post("/promotions", body);
}
export function createBulkPromotion(body: any) {
  return httpservice.post("/promotions/bulk", body);
}

export function updateBulkPromotion(body: any) {
  return httpservice.put("/promotions/bulk/update", body);
}
export function updatePromotion(body: any) {
  return httpservice.put("/promotions", body);
}

export const promotionFileUpload = (files: any) => {
  return httpservice.post("/upload/others", files, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

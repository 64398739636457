import httpservice from "./httpservice";

export const getApplicationSetting = async (body: any = null) => {
    return httpservice.get("/applicationsetting", { params: body })
  }
  export const updateApplicationSetting = (body: any) => {
    return httpservice.put("/applicationsetting", body)
  }
  
  export const getAlertSetting = async (body:any=null)=>{
    return httpservice.get("applicationsetting/alert",{params:body})
  }

  export const updateAlertSetting = async (body:any=null)=>{
    return httpservice.put("applicationsetting/alert",body)
  }
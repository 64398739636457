import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import React, { useEffect } from "react";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSelector } from "react-redux";
import { verifyPermission } from 'helper/permission'
import { useState } from "react";
const useStyles = makeStyles({
  cardBodyClasses: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const PageHeader = (props: any) => {
  const classes = useStyles(props);
  const getPermission = useSelector((state: any) => {  return state.userPermission.permissions })
  const [disabled, setDisabled] = useState<any>(false)
  const [disabledtwo ,setDisabledtwo]=useState<any>(false)
  const LinkRef = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
    (props, ref) => <RouterLink innerRef={ref} {...props} />
  );
  const { sectionName, subsection, permission, setPermissions } = props

  useEffect(() => {
    if (props?.permission) {
      const permission = (props?.permission?.split("."))

      let ispermission = (!verifyPermission(
        getPermission,
        permission[0],
        permission[1],
        permission[2]
      ))
      setDisabled(ispermission)
    }
    if (props?.permission2) {
      const permission = (props?.permission2?.split("."))

      let ispermission = (!verifyPermission(
        getPermission,
        permission[0],
        permission[1],
        permission[2]
      ))
      setDisabledtwo(ispermission)
    }

  }, [props])

  const checkper = (data:any)=>{
    if (data) {
      const permission = (data.split("."))

      let ispermission = (!verifyPermission(
        getPermission,
        permission[0],
        permission[1],
        permission[2]
      ))
      // setDisabled(ispermission)
      return ispermission
    }
  
  }
  return (
    <Card className="mt-0">
      <CardBody className={classes.cardBodyClasses}>
        <Box>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            {props?.routes?.map((item: any, i: any) => {
              
              if (!item.hasOwnProperty('uuid') || item.uuid) {
                return (item.link !== "") ? (
                  <RouterLink to={item.link} key={item.link}>
                    <Typography color="textSecondary">{item.name}{item?.uuid}</Typography>
                  </RouterLink>
                ) : (
                  <Typography color="textPrimary" key={item.link}>
                    {item.name}
                  </Typography>
                );
              }

            })}
          </Breadcrumbs>
        </Box>
        <ButtonGroup
          variant="text"
          color="primary"
          aria-label="text primary button group"
        >

          {props?.newItem?.length > 0 && props?.newItem?.filter((item: any) => !item.uuid).map((item: any, index: any) => {
            return <Button
              variant="contained"
              color="primary"
              className={"custom-btn"}
              component={LinkRef}
              to={item.link}
              key={index}
              disabled={item.permission ? checkper(item.permission) : disabled }

              startIcon={item.backIcon && <ArrowBackIcon />}
            >
              {item.text}
            </Button>
          })
          }

          {props?.modalButton?.onClick && (
            <Button
              variant="contained"
              color="primary"
              className={"custom-btn"}
              disabled={disabled}
              onClick={props?.modalButton?.onClick}
            >
              {props.modalButton.text}
            </Button>
          )}
        </ButtonGroup>
      </CardBody>
    </Card>
  );
};

export default PageHeader;

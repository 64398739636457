import * as yup from 'yup'

const createCustomerVehicleValidation:any = yup.object().shape({
    customer_id: yup.object().required("Please select customer ").nullable(),
    vehicle_model_id: yup.object().required("Please select vehicle model ").nullable(),
    // color:yup.string().required("Please enter color ").nullable(),
    year:yup.string().required("Please enter year ").nullable(),
    plate_no:yup.string().required("Please enter plate number ").nullable(),
    // nick_name:yup.string().required("Please enter nick name ").nullable(),
    battery_life:yup.string().required("Please enter battery percentage ").nullable(),
    // country_name:yup.string().required("Please enter country").nullable(),
    // city_name:yup.string().required("Please enter city").nullable(),
  });

  export{
      createCustomerVehicleValidation
  }
import React, { ChangeEvent, ChangeEventHandler } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import * as XLSX from 'xlsx';
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  CardMedia,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  InputField,
  AutoCompleteField,
  SwitchField,
  SwitchFieldDefault,
  DateField,
  ColorPickerField,
} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import { createAutomatedValidation } from "./Validations/automatedNotificationValidations";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { UPDATE_SUCCESS_MSG } from "json/constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UploadFileIcon from '@material-ui/icons/FileCopy';
import GoogleMap from "components/GoogleMap/GoogleMap";
import DrawMap from "components/GoogleMap/DrawMap";

import _ from "lodash";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { type } from "os";
import {
  createPromotion,
  getPromotions,
  updatePromotion,
  promotionFileUpload,
  createBulkPromotion,
} from "services/promotionServices";
import {
  getCity,
  getBranch,
  getZone,
  getLevels,
  getCountry,
} from "services/locationservices";
import { getServices, getServiceConfig, getServiceCategory } from "services/servicesServices";
import moment from "moment";
import { FileUploadArea } from "components/FileUpload/fileuploadArea.component";
import { getPackages, getViewPackages } from "services/packageservices";

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { createAutomatedNotification, getAutomatedNotification, updateAutomatedNotification } from "services/notificationServices";
import { checkPermission } from "services/common";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain",
  },
  media: {
    height: "100%",
    width: 300,
  },
  dot: {
    height: "70px",
    width: "70px",
    borderRadius: " 50%",
    display: " inline-block",
  },
};

const defaultFormValues: any = {
  name: "",
  days: "",
  title: "",
  body: "",
  type: "",
  status: false,
};

const useStyles = makeStyles(styles);

const levelDependent: any = {
  Country: ["country"],
  City: ["country", "city"],
  Branch: ["country", "city", "branch"],
  Zone: ["country", "city", "branch", "zone"],
};

export default function AddAutomatedNotification(props: any) {
  const classes = useStyles();
  const [editData, setEditData] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");
  const [serviceConfigs, setServiceConfigs] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const [serviceId, setserviceId] = useState<any>([]);
  const [serviceCategories, setServiceCategories] = useState<any>([]);
  const [typeGot, setTypeGot] = useState<any>({});
  const [disabled, setDisabled] = useState<any>(false);
  const { search } = useLocation();

  let uuid = new URLSearchParams(search).get("uuid");

  const types = [{
    name: "Activity",
    value: "activity_date"
  }, {
    name: "Oil Change",
    value: "oil_changed_at"
  }, {
    name: "General Order",
    value: "last_order_date"
  }, {
    name: "Service",
    value: "service"
  },
  {
    name: "Account Activation",
    value: "activate_account"
  },
  ]

  let history = useHistory();

  useEffect(() => {
    let temp: any = [];
    _.uniqBy(serviceConfigs, "service_id").map((row: any, index: any) => {
      let data = row?.service;
      let service_config_uuid = row?.uuid;

      console.log(serviceId.includes(row.service.uuid))

      temp.push({ ...data, ...{ service_config_id: service_config_uuid, isSelected: serviceId.includes(row.service.uuid) ?? false } });
    });
    setServices(temp);
  }, [serviceConfigs, serviceId]);
  console.log(serviceCategories)

  const getNotification = () => {
    getAutomatedNotification({ uuid: uuid }).then((e: any) => {
      let data: any = e?.data?.result?.[0];
      setValue("name", data.name);
      setValue("days", data.days);
      setValue("type", data.type);
      setValue("title", data?.title);
      setValue("body", data?.body);
      setValue("status", data.status);
      setserviceId(data.services || [])
      setTypeGot(types.find((ty) => ty.value === data.type))

      let temp: any = new URLSearchParams(search).get("type");
      temp ? setHeaderTitle("View") : setHeaderTitle("Edit");
      temp == "view" ? setDisabled(true) : setDisabled(false);
      dispatch(spinLoaderShow(false));
    });
  };

  const getServiceConfigData = () => {
    getServiceConfig({ level_id: "91769678-2427-4e72-b240-315fe948a135", status: true }).then((e: any) => {
      setServiceConfigs(e?.data?.result);
      dispatch(spinLoaderShow(false));
    });
    getServiceCategory({ status: true }).then((e: any) => {
      setServiceCategories(e.data?.result?.sort((a: any, b: any) => a?.proirity - b.proirity).map((category: any) => { return { ...category, isSelected: false } }))
      dispatch(spinLoaderShow(false));
    })
  };

  useEffect(() => {
    // useEffect(() => {
    getServiceConfigData()
    // }, []);
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getNotification();
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
    }
  }, [uuid]);

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createAutomatedValidation),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Automated Notifications ",
      link: "/admin/automated-notitification",
    },
    {
      name: headerTitle,
      link: "",
    },
  ];

  const onSubmit = (data: any) => {
    if (data.type === "service" && services.filter((svc: any) => svc.isSelected).length < 1) {
      toast.error("Please select atleast 1 service");
      return;
    }
    let modifyData: any = {
      name: data?.name,
      days: data?.days,
      type: data?.type,
      title: data?.title,
      body: data?.body,
      status: data?.status,
      services: data.type === "service" ? services.filter((svc: any) => svc.isSelected).map((svc: any) => svc.uuid) : null,
      text: data.type === "service" ? services.filter((svc: any) => svc.isSelected).map((svc: any) => svc.name).join(", ") : "",
    };
    dispatch(spinLoaderShow(true));
    if (uuid) {
      modifyData.uuid = uuid;
      updateOnSubmit(modifyData);
    } else {
      createOnSubmit(modifyData);
    }
  };

  const createOnSubmit = (data: any) => {
    createAutomatedNotification(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/automated-notification");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    updateAutomatedNotification(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        setEditData(null);
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/automated-notification");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const handleCategoryChecked = (item: any, e: any) => {
    setServiceCategories(serviceCategories.map((cat: any) => {
      let temp = { ...cat };
      if (temp.uuid === item.uuid) {
        temp.isSelected = e.target.checked;
      }
      return temp;
    }))
    setServices(services.map((cfgs: any) => {
      let temp = { ...cfgs }
      if (temp.service_category_id === item.uuid) {
        temp.isSelected = e.target.checked
      }
      return temp
    }))
  }

  useEffect(() => {
    let temp: any = [];

    // Accumulate modifications in the temp array
    temp = serviceCategories.map((cat: any) => {
      // Find configurations for the current category
      const checkedConfigs = services.filter((cfg: any) => cfg.service_category_id === cat.uuid);

      // Check if all configurations for the current category are selected
      const allConfigsSelected = checkedConfigs.every((cfg: any) => cfg.isSelected);

      // Update isSelected property in the temp array
      if (allConfigsSelected !== cat.isSelected) {
        return { ...cat, isSelected: allConfigsSelected };
      }

      return cat;
    });

    console.log(temp, "temp");

    // Update the state variable 'serviceCategories' with the modified temporary array
    setServiceCategories(temp);
  }, [services]);


  const handleServiceConfigChecked = (item: any, e: any) => {
    setServices(services.map((cfgs: any) => {
      if (cfgs.uuid === item.uuid) {
        cfgs.isSelected = e.target.checked
      }
      return cfgs
    }))
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Automated Notification"
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
                { text: "Back", link: "/admin/automated-notification" },
              ]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <h4 className={classes.cardTitleWhite}>
                    {" "}
                    {headerTitle} Automated Notification
                  </h4>
                  {uuid ? (
                    <h4 className={classes.cardTitleWhite}>
                      Modified At: {moment(editData?.updatedAt).format("llll")}
                    </h4>
                  ) : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      iProps={{
                        disabled: disabled,
                      }}
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.days}
                      fieldName="days"
                      type="text"
                      autoComplete="off"
                      label="* Days Limit"
                      control={control}
                      iProps={{
                        type: "number",
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.days &&
                        errors?.days?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="type"
                      autoComplete="off"
                      label="* Select Type"
                      control={control}
                      setValue={setValue}
                      options={types}
                      optionKey="value"
                      isLoading={true}
                      iProps={{
                        disabled: disabled,
                        onChange: (data: any) => {
                          setTypeGot(data)
                        }
                      }}
                      optionValue="name"
                      helperText={errors?.services && errors?.services?.message}
                      valueGot={typeGot}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>
                  <GridItem xs={12} md={12} sm={12}>
                    <InputField
                      errors={!!errors?.title}
                      fieldName="title"
                      type="text"
                      autoComplete="false"
                      label="* Notification Title"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      isLoading={true}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.title &&
                        errors?.title?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <InputField
                      errors={!!errors?.body}
                      fieldName="body"
                      multiline={true}
                      rows={7}
                      type="text"
                      autoComplete="false"
                      label="* Notification Body"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.body &&
                        errors?.body?.message
                      }
                    />
                  </GridItem>
                  {typeGot?.value === "service" && <>
                    <GridItem xs={12} sm={12} md={12}><Typography variant="h6">Services</Typography></GridItem>
                    <GridItem xs={6} sm={12} md={6} >
                      {serviceCategories.filter((cat: any) => cat.name !== "Oil Change").map((category: any) => {
                        return (
                          <Box key={category.uuid}>
                            <GridItem xs={6} sm={12} md={12} style={{ display: 'inline-flex' }}>
                              <Checkbox
                                checked={category.isSelected}
                                onChange={(e) => handleCategoryChecked(category, e)}
                                disabled={disabled}
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                              <Typography style={{ marginTop: "9px" }}> {category.name}</Typography>

                            </GridItem>
                          </Box>
                        )
                      })}
                    </GridItem>
                    <GridItem xs={6} sm={12} md={6}>
                      {serviceCategories.filter((cat: any) => cat.name !== "Oil Change").map((category: any) => {
                        return (
                          <GridItem xs={6} sm={12} md={12} key={category.uuid}>
                            <Box style={{ display: 'inline-flex' }}>
                              <Checkbox
                                checked={category.isSelected}
                                onChange={(e) => handleCategoryChecked(category, e)}
                                disabled={disabled}
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                              <Typography style={{ marginTop: "9px" }}> {category.name}</Typography></Box>
                            {services?.filter((svc: any) => svc.service_category_id === category.uuid).map((cfg: any) => {
                              return (<Box key={cfg.uuid}><GridItem xs={6} sm={12} md={12} style={{ display: 'inline-flex' }}>
                                <Checkbox
                                  checked={cfg.isSelected}
                                  onChange={(e) => handleServiceConfigChecked(cfg, e)}
                                  disabled={disabled}
                                  inputProps={{ "aria-label": "primary checkbox" }}
                                />
                                <Typography style={{ marginTop: "9px" }}> {cfg.name}</Typography></GridItem></Box>)
                            })}
                          </GridItem>
                        )
                      })}
                    </GridItem>
                  </>}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    disabled={disabled}
                    className={"custom-btn"}
                    type="submit"
                  >
                    {uuid ? "Update Automated Notification" : "Create Automated Notification"}
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

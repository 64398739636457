import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

export function getCategoryData(body: any = null) {
  return httpservice.get("/category", {
    params: body,
  });
}
export function createCategory(body: any) {
  return httpservice.post("/category", body);
}
export function updateCategory(body: any) {
  return httpservice.put("/category", body);
}

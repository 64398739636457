
import httpservice from "./httpservice";
export const getDriver = async (body: any = null) => {
    return httpservice.get('/driver', { params: body })
}

export const createDriver = (body: any) => {
    return httpservice.post("/driver", body)

}
export const updateDriver = (body: any) => {
    return httpservice.put("/driver/", body)
}
export const getDriverConfig = async (body: any = null) => {
    return httpservice.get('/driver/configs', { params: body })
}
export const getDriverConfigView = async (body: any = null) => {
    return httpservice.get('/driver/view', { params: body })
}
export const createDriverConfig = async (body: any) => {
    return httpservice.post("/driver/services", body)
} 
export const getDriverConfigData = async (body:any = null) => {
    return httpservice.get("/driver/services",{params:body})
}

export const getDriverConfigDataView = async (body:any = null) => {
    return httpservice.get("driver/services/view",{params:body})
}
export const updateDriverConfig = async (body: any) => {
    return httpservice.put("/driver/configs", body)
}

export const driverlogo = (files: any) => {
    return httpservice.post('/upload/driver', files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const getDriverSlot = async (body: any) => {
    return httpservice.post("booking/driver/time-slots", body)
}
export const changeDriver = async (body: any) => {
    return httpservice.post("/booking/change-driver", body)
} 
import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { AccordionActions } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { InputField, LoadingButton, SwitchField, TextEditorField, SwitchFieldDefault } from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import Button from "components/CustomButtons/Button";
import { createSiteMaintenanceValidation } from './Validations/sitemaintenance-validation'
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledAccordion from "components/common/controlledAccordion.component";


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
export const SiteMaintenanceAccordion = (props: any) => {
  const classes = useStyles();
  const { sendToParent, data,permission ,collapse } = props


  const defaultFormValues: any = {
    maintenance_message: '',
    maintenance_message_visibility_duration: '',
    maintenance_message_visibility:true,
    maintenance_mode:true

  }
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createSiteMaintenanceValidation)

  });

  useEffect(() => {
    if (data) {
      setValue("maintenance_message", data?.maintenance_message)
      setValue("maintenance_message_visibility_duration", Number(data?.maintenance_message_visibility_duration))
      setValue("ios_version", (data?.ios_version))
      setValue("android_version", (data?.android_version))

      setValue("maintenance_message_visibility",data?.maintenance_message_visibility == 'true'?true:false )
      setValue("maintenance_mode",data?.maintenance_mode=='true'?true:false)
    }
  }, [data])

  const onSubmit = (data: any) => {
    console.log("data",data)
    sendToParent(data)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledAccordion collapse={collapse}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Site Maintenance</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            <GridItem xs={12} md={12} sm={12}>
              <Typography> Maintenance Message </Typography>
              <TextEditorField
                className={"mt-15"}
                textareaName="maintenance_message"
                fieldName="maintenance_message"
                type="text"
                autoComplete="false"
                label="maintenance_msg"
                control={control}
                isLoading={true}
                valueGot={data?.maintenance_message}
                setValue={setValue}
                helperText={errors?.maintenance_message && errors?.maintenance_message?.message}
              />
            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.maintenance_message_visibility_duration}
                fieldName="maintenance_message_visibility_duration"
                type="text"
                autoComplete="false"
                label="* Maintenance Message Visibility Duration"
                control={control}
                valueGot={""}
                iProps={{
                  type: "number"
                }}
                setValue={setValue}
                helperText={errors?.maintenance_message_visibility_duration && errors?.maintenance_message_visibility_duration?.message}

              />

            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.ios_version}
                fieldName="ios_version"
                type="text"
                autoComplete="false"
                label="* IOS Version"
                control={control}
                valueGot={""}
                iProps={{
                  type: "text"
                }}
                setValue={setValue}
                helperText={errors?.ios_version && errors?.ios_version?.message}

              />

            </GridItem>
            <GridItem xs={6} md={6} sm={6}>
              <InputField
                errors={!!errors?.android_version}
                fieldName="android_version"
                type="text"
                autoComplete="false"
                label="* Android Version"
                control={control}
                valueGot={""}
                iProps={{
                  type: "text"
                }}
                setValue={setValue}
                helperText={errors?.android_version && errors?.android_version?.message}

              />

            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
              <SwitchFieldDefault
                errors={!!errors?.maintenance_mode}
                fieldName="maintenance_mode"
                autoComplete="off"
                label="Maintenance Mode"
                control={control}
                isLoading={true}
                defaultChecked={true}
                setValue={setValue}
                helperText={
                  errors?.maintenance_mode &&
                  errors?.maintenance_mode?.message
                }
              />
            </GridItem>

            <GridItem xs={6} sm={6} md={6} style={{ marginTop: "15px" }} >

              <SwitchFieldDefault
                errors={!!errors?.maintenance_message_visibility}
                fieldName="maintenance_message_visibility"
                autoComplete="off"
                label="Maintenance Message Visibility"
                control={control}
                isLoading={true}
                setValue={setValue}
                helperText={
                  errors?.maintenance_message_visibility &&
                  errors?.maintenance_message_visibility?.message
                }
              />
            </GridItem>
          </GridContainer>

        </AccordionDetails>
        <AccordionActions>
          <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
          {permission === false ?<Button type="submit" className={"custom-btn"} variant="contained" color="primary">Submit</Button> : ''} 
          </Box>

        </AccordionActions>
      </ControlledAccordion>
    </form>
  )

}
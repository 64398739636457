import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import PageHeader from "components/PageHeader/PageHeader";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { verifyPermission } from 'helper/permission'
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import { getDriver } from 'services/driverServices'
import { spinLoaderShow } from "redux/action/spinLoader";
import moment from "moment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory,useLocation } from "react-router";
import Avatar from '@material-ui/core/Avatar';
import { getAdmin } from "services/userservices";
import {bulkDriverEnable,bulkDriverDisable} from 'services/bulkEnableServices'
import { toast } from "react-toastify";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);




export const DriverList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false)
  const [rowData, setRowData] = useState([]);
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()
 

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Driver",
      'link': ''
    }
  ];



  useEffect(() => {
    

    getApi();

  }, [])


  const getApi = async ()=>{
    dispatch(spinLoaderShow(true))
  var adminUser:any = await getAdmin({uuid:localStorage.getItem("userId")});
  adminUser = adminUser?.data?.result?.[0];
    if(adminUser.vendor_id){
      getDriver({vendor_id:adminUser.vendor_id}).then((e: any) => {
        let data = e?.data?.result.map((item: any) => {
          item.status = item.status ? 'active' : 'inactive'
          return item;
        })
        setRowData(data)
        dispatch(spinLoaderShow(false))
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
      })
    }else{
      getDriver().then((e: any) => {
        let data = e?.data?.result.map((item: any) => {
          item.status = item.status ? 'active' : 'inactive'
          return item;
        })
        setRowData(data)
        dispatch(spinLoaderShow(false))
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
      })
    }
    
  }


  
  useEffect(()=>{
   
    let ispermission = !(verifyPermission(
      getPermission,
      'Driver',
      'Driver',
      'update'
    ))
    setDisabled(ispermission)
  }, [getPermission])

  const columnDefs: any = [
    {
      headerName: "First Name",
      field:'first_name',
      cellRendererFramework: (params: any) => {
        return <span style={{ display: 'flex', alignItems: 'center' }}>
                 <Avatar alt={params?.data.first_name} src={params?.data?.image} />
        <span style={{ marginLeft: '5px' }}>{params?.data?.first_name}</span>
        </span>
      }
    },
    {
      headerName: "Last Name",
      field:'last_name',
    },
    
    {
      headerName: "Mobile Number",
      field: "mobile_no"
    },
    {
      headerName: "Rating",
      field: "rating"
    },
    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Driver App Status',
      field: "active",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
     
      sort: "desc",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

    // }
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
  },
    {
      headerName: 'Created Date',
      field: "createdAt",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

    // },
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'driver/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
    {
      headerName: 'Booking',
      field: "booking",
      sortable: false,
      filter:false,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'booking?driverId=' + params?.data?.uuid}><VisibilityIcon /></Link>;
      }
    },
    {
      headerName: 'View',
      field: "view",
      sortable: false,
      filter:false,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'driver/edit?uuid=' + params?.data?.uuid + "&type=view"}><VisibilityIcon /></Link>;
      }
    },
  ]

  const enableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid,
        mobile_no : item?.mobile_no}  
  
    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
      bulkDriverEnable(temp).then((res:any)=>{
          dispatch(spinLoaderShow(false))
          toast.success("Record Updated successfully")
          getApi()
      }).catch((err)=>{
        dispatch(spinLoaderShow(false))
        getApi()
      })
    } else{
      toast.error("Please select a row")
    }
  
  
  }
  const disableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid,
        mobile_no : item?.mobile_no}  
  
    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
    bulkDriverDisable(temp).then((res:any)=>{
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getApi()
    }).catch((err)=>{
      dispatch(spinLoaderShow(false))
      getApi()
    })
    } else{
      toast.error("Please select a row")
    }   
  }
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApi()
  
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Driver"
            routes={routes}
            newItem={[{ text: 'Add New Driver', link: "driver/add" }]}
            permission="Driver.Driver.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Driver List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
          <Button disabled={disabled}  variant="outlined" color="primary" className="custom-btn export-btn" onClick={(e: any) =>enableSelectedRows() }>Enable</Button>
          <Button disabled={disabled}  variant="outlined" color="primary"className="custom-btn export-btn" onClick={(e: any) =>disableSelectedRows() }>Disable</Button>
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.uuid;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


import React, { useState, useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import { ReferralAccordion } from 'components/Accordian/refererral-accordion.component'
import { SiteMaintenanceAccordion } from 'components/Accordian/sitemaintenance-accordion.component'
import { ArrivalConfigAccordion } from 'components/Accordian/arrivalconfig-accordion.component'
import { TermsAndConditionAccordion } from 'components/Accordian/terms-accordion.component'
import { CookiesAndPolicyAccordion } from 'components/Accordian/cookies-accoridion.component'
import { PrivacyPoliciesAccordion } from 'components/Accordian/privacy-policy.accordion.component'
import { SocialsAccordion } from 'components/Accordian/socials-accoridion.component'
import { BannerImageAccordion } from "components/Accordian/banner-image-accordion.component"
import { getApplicationSetting, updateApplicationSetting } from "services/applicationSettingServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { toast } from "react-toastify";
import { VatNumberAccordion } from 'components/Accordian/vat_num.accordion.component';
import { VatAccordion } from 'components/Accordian/vat.accordion.component';
import { EmailAccordion } from 'components/Accordian/email-accordion.component'
import { WhatsappAccordion } from 'components/Accordian/whatsapp-accordion.component'
import { PhoneNumberAccordion } from 'components/Accordian/phone-num-accordion.component';
import { RefundDaysAccordian } from 'components/Accordian/refund-days-accordion.component';
import { DistanceMatrixAccordian } from 'components/Accordian/distance-matrix-accordion.component';
import { SlotSplitAccordian } from 'components/Accordian/slot-split-accordion.component';

import { PrivacyTCLinkAccordian } from 'components/Accordian/privacy-tc-link-accordion.component';

import { DeliveryChargesAccordion } from 'components/Accordian/deliverycharges.accordion.component'
import { verifyPermission } from 'helper/permission'
import { Box } from "@material-ui/core"
import Button from "components/CustomButtons/Button";






const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {

      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);



export const ApplicationSetting = (props: any) => {
  const classes = useStyles();
  const [getData, setGetData] = useState<any>({})
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const [disabled, setDisabled] = useState<any>(false)
  const [collapse, setCollapse] = useState<any>(false)
  const dispatch = useDispatch()




  const routes: any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Application Setting",
      'link': ''
    }
  ];

  const getApiData = () => {
    getApplicationSetting().then((res) => {
      let data = res?.data?.result
      setGetData(data)
      dispatch(spinLoaderShow(false))

    }).catch((err) => {
      dispatch(spinLoaderShow(false))
    })
  }
  useEffect(() => {
    dispatch(spinLoaderShow(true))
    getApiData()
    let ispermission = !(verifyPermission(
      getPermission,
      'Application Setting',
      'Manage',
      'update'
    ))
    setDisabled(ispermission)
  }, [])


  const toggleAll = () => setCollapse(!collapse);


  const sendToParent = (data: any) => {
    dispatch(spinLoaderShow(true))
    updateApplicationSetting(data).then((res) => {
      dispatch(spinLoaderShow(false))
      toast.success(UPDATE_SUCCESS_MSG)


    }).catch((err) => {
      dispatch(spinLoaderShow(false))
    })
  }

  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Application Setting"
            routes={routes}

          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Application Setting</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={toggleAll}>{collapse ? "Collapse All" : "Expand All"}</Button>

          </CardHeader>
          <CardBody>


            <GridContainer >


              <GridItem xs={12} md={12} sm={12}>
                <ReferralAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <SlotSplitAccordian sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <SiteMaintenanceAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              {/* <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <ArrivalConfigAccordion sendToParent={sendToParent}   data={getData} permission={disabled} collapse={collapse} />
              </GridItem> */}
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <TermsAndConditionAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              {/* <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <CookiesAndPolicyAccordion sendToParent={sendToParent}   data={getData}/>
              </GridItem> */}
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <PrivacyPoliciesAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <SocialsAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              {/* <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <BannerImageAccordion sendToParent={sendToParent} data={getData}/>
              </GridItem> */}
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <VatNumberAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <VatAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <EmailAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <WhatsappAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <PhoneNumberAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <RefundDaysAccordian sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <DistanceMatrixAccordian sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>
              <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <PrivacyTCLinkAccordian sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
              </GridItem>

              {/* <GridItem xs={12} md={12} sm={12} className={"mt-15"}>
                <DeliveryChargesAccordion sendToParent={sendToParent} data={getData} permission={disabled} collapse={collapse} />
               </GridItem> */}

            </GridContainer>
          </CardBody>
          <CardFooter>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>

  );
}


import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormControlLabel } from '@material-ui/core';
import {InputField,SwitchField,AutoCompleteField, SwitchFieldDefault } from 'components/Forms/Formfield.component'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createVehicleModelValidation } from './Validations/vehicleModelValidations';
import { useState,useEffect } from 'react';
import { getVehicleType,getVehicleBrand, createVehicleModel, updateVehicleModel ,vehicleModelImage} from 'services/vehiclesServices';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';
import { spinLoaderShow } from 'redux/action/spinLoader';
import {CREATE_SUCCESS_MSG} from 'json/constants'
import {UPDATE_SUCCESS_MSG} from 'json/constants'
import {FileUploadDialog} from 'components/FileUpload/fileuploadDialog.component';
import Avatar from '@material-ui/core/Avatar';
import { Box } from "@material-ui/core";
import {Typography} from '@material-ui/core/'
import moment from 'moment'
const styles:any={
  uploadButton:{
    backgroundColor:'#043cae',
    color:'#fff',
    marginLeft:"1em"
  },
  avatar:{
    height:"60px",
    width:"60px"
  }
}
const useStyles = makeStyles(styles);

export const  ModelPopup = (props:any) =>{
 const classes = useStyles()
  const defaultFormValues:any = {
    name:'',
    brand_id:'',
    vehicle_type_id:'',
    status:true,
  }
  const {control,handleSubmit,formState:{errors},setValue,reset,register}=useForm({
    mode:'onChange',
    reValidateMode:'onChange',
    defaultValues:defaultFormValues,
    resolver:yupResolver(createVehicleModelValidation)
  })
    const {handleClose,open,title,editData}= props
    const [vehicletype,setVehicleType]=useState<any>([])
    const [brand,setBrand]=useState<any>([])
    const [dropOpen,setDropOpen]=useState<any>(false)
    const [url,setUrl]=useState<any>("")
    const [disabled , setDisabled]=useState<any>(false)
    const dispatch=useDispatch()
    useEffect(() => {
   
      if (editData) {
        setValue("name", editData.name);
        setValue("brand_id",editData.brand_id);
        setValue("vehicle_type_id",editData.vehicle_type_id)
        setValue("status",editData.status)
        setUrl(editData?.image)
        setValue("image",editData?.image) 
        if (editData?.view ){
           setDisabled(true)
        }
       
      } else {
        reset(defaultFormValues);
      }
    }, [editData]);

 useEffect(()=>{
   getVehicleType({status:true}).then((e)=>{
     setVehicleType(e.data.result)
   })
   getVehicleBrand({status:true}).then((e)=>{
    setBrand(e.data.result)
  })
 },[])

 const handleSave= async(files:any)=>{
  dispatch(spinLoaderShow(true))
  setDropOpen(false)
  const formData = new FormData()
  formData.append('images', files[0])
  const { data } = await vehicleModelImage(formData)
  setValue("image",data.result[0])
  setUrl(data.result[0])
  dispatch(spinLoaderShow(false))
}


 

    const onSubmit = async(data:any)=>{
      dispatch(spinLoaderShow(true))
      let uuid:any =editData?.uuid
      data.status = (data?.status == null || data?.status == true) ? true : false
      if(uuid){
        data.uuid = uuid
        updateOnSubmit(data)
       
      }
      else{
        createOnSubmit(data)
       
      }
    }

    const createOnSubmit = (data: any) => {
      createVehicleModel(data)
        .then((e: any) => {
          toast.success(CREATE_SUCCESS_MSG);
          reset(defaultFormValues);
          handleClose()
          dispatch(spinLoaderShow(false))

        })
        .catch((e: any) => {
        });
    };
    const updateOnSubmit = (data: any) => {
    updateVehicleModel(data)
        .then((e: any) => {
          toast.success(UPDATE_SUCCESS_MSG);
          reset(defaultFormValues);
          handleClose()
          dispatch(spinLoaderShow(false))
        })
        .catch((e: any) => {
        });
    };
    return (
          <form action="" onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
          <DialogContent>
            <GridContainer>
               <GridItem  xs={12} md={12} sm={12}>
          {editData !=null ?  <Typography>Modified At:  { moment(editData?.updatedAt).format('llll')} </Typography> : null}
          </GridItem>
            <GridItem xs={6} md={6} sm={6} style={{ marginTop: "06px" }}>
                <input {...register("image")} type="hidden" />

                  <Box style={{display:"flex",alignItems:"center"}}>
                  <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={disabled}
                      className={classes.uploadButton}
                      onClick={() => setDropOpen(true)}
                    >
                    Model Image
                    </Button>
                    <FileUploadDialog  open={dropOpen} setOpen={setDropOpen} handleSave={handleSave}/>
                    </Box>     
                  </GridItem>
            <GridItem  xs={6} >
            <InputField
                errors={!!errors?.name}
                fieldName="name"
                type="text"
                autoComplete="false"
                label="Model"
                control={control}
                isLoading={true}
                valueGot={""}
                iProps={{
                  disabled:disabled,
                }}
                setValue={setValue}
                helperText={errors?.name && errors?.name?.message}
                
              />
             
              </GridItem>
              <GridItem  xs={6} >
              <AutoCompleteField
                      errors={!!errors?.vehicle_type_id}
                      fieldName="vehicle_type_id"
                      autoComplete="off"
                      label="Select Type"
                      control={control}
                      setValue={setValue}
                      isLoading={brand.length > 0 ? true : false}
                      options={vehicletype}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled:disabled,
                      }}
                      helperText={
                        errors?.vehicle_type_id && errors?.vehicle_type_id?.message
                      }
                      valueGot={editData?.vehicle_type}
                    />
              </GridItem>
              <GridItem  xs={6} >
              <AutoCompleteField
                      errors={!!errors?.brand_id}
                      fieldName="brand_id"
                      autoComplete="off"
                      label="Select Brand"
                      control={control}
                      setValue={setValue}
                      isLoading={brand.length > 0 ? true : false}
                      options={brand}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled:disabled,
                      }}
                      helperText={
                        errors?.brand_id && errors?.brand_id?.message
                      }
                      valueGot={editData?.brand}
                    />
              </GridItem>
          
              <GridItem xs={4} sm={4} md={4}  style={{ marginTop: "15px" }}>
              <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      defaultChecked="true"
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
              </GridItem>
              </GridContainer>
              
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> {reset(defaultFormValues);handleClose()}} color="primary">
              Cancel
            </Button>
            <Button  type="submit" disabled={disabled} color="primary">
              submit
            </Button>
          </DialogActions>
          </form>
    )
}
import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { ReferralAccordion } from "components/Accordian/refererral-accordion.component";
import { HeaderAccordion } from "components/website/themeSettings-Accordian/header-accordian-component";
import { MenuAccordion } from "components/website/themeSettings-Accordian/menu-accordian-component";
import { InnerMenuAccordion } from "components/website/themeSettings-Accordian/innerMenu-accordian-component";
import { FooterAccordion } from "components/website/themeSettings-Accordian/footer-accordian-component";
import { getHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { BookJinoAccordion } from "components/website/themeSettings-Accordian/book-jino-accordian-component";
import { PrivacyPolicy } from "components/website/themeSettings-Accordian/privacy-policy-accordian-component";
import { TermsAndCondition } from "components/website/themeSettings-Accordian/terms-condition-accordian-component";
import { FaqComponent } from "components/website/themeSettings-Accordian/faq-accordian-component";
import { ContactDetailAccordion } from "components/website/themeSettings-Accordian/contact_detail-accordian-component";
import { verifyPermission } from "helper/permission";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
export const ThemeSettings = (props: any) => {
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const [collapse, setCollapse] = useState<any>(false);
  const [apiData, setApiData] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false);

  useEffect(() => {
    getApiData();
  }, []);

  useEffect(() => {
    dispatch(spinLoaderShow(true));
    getApiData();
    let ispermission = !verifyPermission(
      getPermission,
      "Website",
      "Theme Settings",
      "update"
    );
    setDisabled(ispermission);
  }, []);

  // get Api Data
  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getHeaderSection()
      .then((res: any) => {
        setApiData(res?.data?.result);

        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const routes: any = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Theme Settings",
      link: "",
    },
  ];
  const toggleAll = () => setCollapse(!collapse);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader title="Theme Setting" routes={routes} />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Theme Setting</h4>
            <Button
              variant="outlined"
              className="custom-btn export-btn"
              onClick={toggleAll}
            >
              {collapse ? "Collapse All" : "Expand All"}
            </Button>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={12} sm={12}>
                <HeaderAccordion
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <MenuAccordion
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <FooterAccordion
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <BookJinoAccordion
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <PrivacyPolicy
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <TermsAndCondition
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <FaqComponent
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                <ContactDetailAccordion
                  apiData={apiData}
                  // data={getData}
                  // permission={disabled}
                  collapse={collapse}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

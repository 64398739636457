import * as yup from "yup";
export const MediaDetailValidation: any = yup.object().shape({
  //Media detal  validation
  name: yup.string().required("please enter Name").nullable(),
  slug: yup.string().matches(/^[a-z\-\d]+$/,"do not use space and special character").required("please enter Slug").nullable(),
  // title: yup.string().required("please enter Title").nullable(),
  description: yup.string().required("please enter Description").nullable(),
  publish_date: yup.date().required("Please enter Publish Date").nullable(),
  type: yup.string().required("please enter Category").nullable(),
});

import * as yup from "yup";
export const EventDetailValidation: any = yup.object().shape({
  //event detal  validation
  name: yup.string().required("please enter Name").nullable(),
  slug: yup.string().matches(/^[a-z\-\d]+$/,"do not use space and special character").required("please enter Slug").nullable(),
  title: yup.string().required("please enter Title").nullable(),
  event_name: yup.string().required("please enter Event Name").nullable(),
  description: yup.string().required("please enter Description").nullable(),
  event_location: yup.string().required("please enter Event Location").nullable(),
  type: yup.string().required("please select Category").nullable(),
  publish_date: yup.date().required("Please enter Publish Date").nullable(),
  upcoming_date: yup.date().required("Please enter Upcoming Date").nullable(),

});

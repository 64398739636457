import { USER_PERMISSIONS } from '../types/user';

const initialState = {
    permissions: null
};

const getPermission = (state:any, payload:any) => ({
    ...state,
    permissions: payload
})

const userPermissions = (state = initialState, action:any) => {
    switch (action.type) {
        case USER_PERMISSIONS:
            return getPermission(state, action.payload);

        default:
            return state
    }
}

export default userPermissions;
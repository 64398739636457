import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "./httpservice";

export const getRoles: any = async (body: any = null) => {
  return httpservice.get("/admin/roles", { params: body });
};
export const createAdmin = (body: any) => {
  return httpservice.post("/admin/user", body);
};
export const verifyCode = (body: any) => {
  return httpservice.post("/admin/user/verify", body);
};
export const getAdmin = async (body: any = null) => {
  return httpservice.get("/admin/user", { params: body });
};
export const createRoles = (body: any) => {
  return httpservice.post("/admin/roles", body);
};
export const updateRoles = (body: any) => {
  return httpservice.put("/admin/roles", body);
};
export const updateAdmin = (body: any) => {
  return httpservice.put("/admin/user", body);
};
export const verifyCodeValidity = (body: any) => {
  return httpservice.put("/admin/user/verify/code", body);
};

export const regenerateCode = (body: any) => {
  return httpservice.put("/admin/user/regenerate", body);
};

export const getContactus = async (body: any = null) => {
  return httpservice.get("/contact", { params: body });
};

export const getBooking = (body: any = null) => {
  return httpservice.get("/booking", {
    params: body,
  });
};
export const getBookingCount = (body: any = null) => {
  return httpservice.get("/booking/count", {
    params: body,
  });
};

export const getBookingByDate = (body: any = null) => {
  return httpservice.get("/booking/count-date", {
    params: body,
  });
};

export const getBookingByService = (body: any = null) => {
  return httpservice.get("/booking/count-service", {
    params: body,
  });
};

export const getViewBooking = (body: any = null) => {
  return httpservice.get("/booking/view", {
    params: body,
  });
};

export const getViewBookingPackage = (body: any = null) => {
  return httpservice.get("/booking/view/package", {
    params: body,
  });
};

export const getCustomer = (body: any = null) => {
  return httpservice.get("/customer", {
    params: body,
  });
};
export const markBookingCompleted = async (body: any) => {
  return httpservice.post("/driver/booking/complete", body);
};
export const adminmarkBookingCompleted = async (body: any) => {
  return httpservice.post("/booking/change/booking/complete", body);
};
export const startBooking = async (body: any) => {
  return httpservice.post("/booking/change/booking/start", body);
};

export const CanceledBooking = async (body: any) => {
  return httpservice.post("/booking/change/booking/cancel", body);
};
export const reachedBooking = async (body: any) => {
  return httpservice.post("/booking/change/booking/reached", body);
};
export const onTheWayBooking = async (body: any) => {
  return httpservice.post("/booking/change/booking/ontheway", body);
};

export const TransactionCommentApi = async (body: any) => {
  return httpservice.put("/booking/transaction/comment", body);
};

//Get Transactions
export const getTransactions = (body: any = null) => {
  return httpservice.get("/admin/user/transactions", {
    params: body,
  });
};

export const getHeatMapAPI = (body: any = null) => {
  return httpservice.get("/admin/user/heatmap", {
    params: body,
  });
};

import { min } from "lodash";
import * as yup from "yup";

export const TermsConditionValidation = yup.object().shape({
  terms_condition: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      heading: yup.string().required("Please enter Heading").nullable(),
      description: yup.string().required("Please enter Description").nullable(),
    })
  ),
});

import React, { useState, useRef, useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { HomeBannerAccordion } from "components/website/home_page/banner-accordian-component";
import { HowToUseJinoAccordion } from "components/website/home_page/how-to-use-jino-accordian-component";
import { WhyUseJinoAccordion } from "components/website/home_page/why-jino-accordian-component";
import { TestimonialsAccordion } from "components/website/home_page/testimonials-accordian-component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getPagesSection,
  updatePagesSection,
} from "services/website Services/pagesServices";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { HomeValidation } from "./validations/home-validation";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const HomePage = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState<any>(false);
  const [apiData, setApiData] = useState<any>(null);

  const routes: any = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Pages",
      link: "/admin/pages",
    },
    {
      name: "Home Page",
      link: "",
    },
  ];

  const defaultFormValues: any = {};

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(HomeValidation)
  });
  useEffect(() => {
    getApiData();
  }, []);

  useEffect(() => {
    if (apiData) {
      // console.log(apiData?.page_content);
      setValue("home_title", apiData?.page_content?.home_banner?.home_title);
      setValue(
        "home_description",
        apiData?.page_content?.home_banner?.home_description
      );
      setValue(
        "home_video_url",
        apiData?.page_content?.home_banner?.home_video_url
      );
      setValue(
        "home_play_store_url",
        apiData?.page_content?.home_banner?.home_play_store_url
      );
      setValue(
        "home_app_store_url",
        apiData?.page_content?.home_banner?.home_app_store_url
      );

      //how to use jino

      setValue(
        "use_jino_title",
        apiData?.page_content?.how_to_use_jino?.use_jino_title
      );
      setValue(
        "use_jino_sub_title",
        apiData?.page_content?.how_to_use_jino?.use_jino_sub_title
      );
      setValue(
        "use_jino_description",
        apiData?.page_content?.how_to_use_jino?.use_jino_description
      );
      setValue(
        "use_jino_description",
        apiData?.page_content?.how_to_use_jino?.use_jino_description
      );
      // setValue("how_to_use_jino_array", apiData?.how_to_use_jino?.how_to_use_jino_array);

      // why use jino
      setValue(
        "why_use_title",
        apiData?.page_content?.why_use_jino?.why_use_title
      );
      setValue(
        "why_use_sub_title",
        apiData?.page_content?.why_use_jino?.why_use_sub_title
      );
      setValue(
        "why_use_description",
        apiData?.page_content?.why_use_jino?.why_use_description
      );
      setValue(
        "video_link",
        apiData?.page_content?.why_use_jino?.video_link
      );
      setValue(
        "cta_text",
        apiData?.page_content?.why_use_jino?.cta_text
      );
      setValue(
        "cta_url",
        apiData?.page_content?.why_use_jino?.cta_url
      );
      setValue(
        "why_use_jino_array",
        apiData?.page_content?.why_use_jino?.why_use_jino_array
      );

      //  testimonials
      setValue(
        "testimonials_title",
        apiData?.page_content?.testimonials?.testimonials_title
      );
      setValue(
        "testimonials_sub_title",
        apiData?.page_content?.testimonials?.testimonials_sub_title
      );
      setValue(
        "testimonials_array",
        apiData?.page_content?.testimonials?.testimonials_array
      );
    }
  }, [apiData]);

  // console.log("aaaa",apiData?.page_content?.home_banner?.app_store_url);

  // get Api Data
  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getPagesSection()
      .then((res: any) => {
        setApiData(res?.data?.result?.find((e: any) => e.page_name === "home"));
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const onSubmit = (data: any) => {
    data.home_banner = {
      home_app_store_url: data?.home_app_store_url,
      home_description: data?.home_description,
      home_title: data?.home_title,
      home_play_store_url: data?.home_play_store_url,
      home_video_url: data?.home_video_url,
    };
    data.how_to_use_jino = {
      use_jino_title: data?.use_jino_title,
      use_jino_sub_title: data?.use_jino_sub_title,
      use_jino_description: data?.use_jino_description,
      how_to_use_jino_array: data?.how_to_use_jino_array,
    };
    data.why_use_jino = {
      why_use_title: data?.why_use_title,
      why_use_sub_title: data?.why_use_sub_title,
      why_use_description: data?.why_use_description,
      video_link: data?.video_link,
      cta_text: data?.cta_text,
      cta_url: data?.cta_url,
      why_use_jino_array: data?.why_use_jino_array,
    };
    data.testimonials = {
      testimonials_title: data?.testimonials_title,
      testimonials_sub_title: data?.testimonials_sub_title,
      testimonials_array: data?.testimonials_array,
    };

    delete data?.home_description;
    delete data?.home_title;
    delete data?.play_store_url;
    delete data?.app_store_url;
    delete data?.video_url;
    delete data?.how_to_use_jino_array;
    delete data?.testimonials_array;
    delete data?.testimonials_sub_title;
    delete data?.testimonials_title;
    delete data?.use_jino_description;
    delete data?.use_jino_sub_title;
    delete data?.use_jino_title;
    delete data?.why_use_description;
    delete data?.why_use_jino_array;
    delete data?.why_use_sub_title;
    delete data?.home_app_store_url;
    delete data?.why_use_title;
    delete data?.home_play_store_url;
    delete data?.home_video_url;
    delete data?.cta_text;
    delete data?.cta_url;

    let modifyData: any = {
      page_content: data,
    };
    // console.log(modifyData);

    if (apiData.uuid) {
      modifyData.uuid = apiData.uuid;
      updateOnSubmit(modifyData);
    }
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updatePagesSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

console.log("error",errors);


  const toggleAll = () => setCollapse(!collapse);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader title="Home page" routes={routes} />
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" className="card-header-export-btn">
              <h4 className={classes.cardTitleWhite}>Home Page</h4>
              <Button
                variant="outlined"
                className="custom-btn export-btn"
                onClick={toggleAll}
              >
                {collapse ? "Collapse All" : "Expand All"}
              </Button>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={12} sm={12}>
                  <HomeBannerAccordion
                    setError={setError}
                    setValue={setValue}
                    control={control}
                    collapse={collapse}
                    apiData={apiData}
                    errors={errors}
                    getValues={getValues}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                  <HowToUseJinoAccordion
                    setError={setError}
                    setValue={setValue}
                    control={control}
                    collapse={collapse}
                    apiData={apiData}
                    errors={errors}
                    getValues={getValues}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                  <WhyUseJinoAccordion
                    setError={setError}
                    setValue={setValue}
                    control={control}
                    collapse={collapse}
                    apiData={apiData}
                    errors={errors}
                    getValues={getValues}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                  <TestimonialsAccordion
                    setError={setError}
                    setValue={setValue}
                    control={control}
                    collapse={collapse}
                    apiData={apiData}
                    errors={errors}
                    getValues={getValues}
                  />
                </GridItem>
              </GridContainer>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%", marginTop: "15px" }}
              >
                <Button color="primary" className={"custom-btn"} type="submit">
                  Submit
                </Button>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
};

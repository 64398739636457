import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import PageHeader from "components/PageHeader/PageHeader";
import StatusComponent from "components/Mix/Status";
import Button from "components/CustomButtons/Button";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
// import { getCustomers } from "services/customerServices";
import { verifyPermission } from "helper/permission";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import {
  bulkCustomerEnable,
  bulkCustomerDisable,
} from "services/bulkEnableServices";
import { toast } from "react-toastify";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getCustomerOdata } from "services/oDataServices";
import AgGridEnterprise from "components/AgGridCustom/AgGridEnterprise";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const CustomerList = (props: any) => {
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [exportData, setExportData] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false);
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Customer ",
      link: "",
    },
  ];

  const columnDefs: any = [
    {
      headerName: "Image",
      field: "image",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return (
          <Avatar
            alt={params?.data.first_name}
            src={params?.data?.profile_image}
          />
        );
      },
    },
    {
      headerName: "Phone No",
      field: "phone_number",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: [
          "contains",
          "startsWith",
          "endsWith",
          "equals",
          "notEqual",
        ],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },

    {
      headerName: "First Name",
      field: "first_name",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: [
          "contains",
          "startsWith",
          "endsWith",
          "equals",
          "notEqual",
        ],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
      // cellRendererFramework: (params: any) => {
      //   return <span style={{ display: 'flex', alignItems: 'center' }}>
      //     <Avatar alt={params?.data.first_name} src={params?.data?.profile_image} />
      //     <span style={{ marginLeft: '5px' }}>{params?.data?.first_name + " " + params?.data?.last_name  }</span>
      //   </span>;
      // }
    },
    {
      headerName: "Last Name",
      field: "last_name",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: [
          "contains",
          "startsWith",
          "endsWith",
          "equals",
          "notEqual",
        ],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Created Date",
      field: "createdAt",
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Email",
      field: "email",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: [
          "contains",
          "startsWith",
          "endsWith",
          "equals",
          "notEqual",
        ],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Last Active",
      field: "activity_date",
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return moment(data.value).format("YYYY-MM-DD") === "2024-01-16"
          ? ""
          : moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Previous Order",
      field: "last_order_date",
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return !data.value ? "" : moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Oil Change Date",
      field: "oil_changed_at",
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return !data.value ? "" : moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Device Type",
      field: "device_type",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: [
          "contains",
          "startsWith",
          "endsWith",
          "equals",
          "notEqual",
        ],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Email Verified",
      field: "verfied",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: [
          "contains",
          "startsWith",
          "endsWith",
          "equals",
          "notEqual",
        ],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },

    {
      headerName: "Status",
      field: "status",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["equals", "notEqual"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      },
    },
    {
      headerName: "Modified Date",
      field: "updatedAt",
      sort: "desc",
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
    {
      headerName: "Actions",
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        return (
          <Link to={"customer/edit?uuid=" + params?.data?.uuid}>
            <EditIcon />
          </Link>
        );
      },
    },
    {
      headerName: "View",
      field: "action",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        return (
          <Link to={"customer/edit?uuid=" + params?.data?.uuid + "&type=view"}>
            <VisibilityIcon />
          </Link>
        );
      },
    },
  ];

  // useEffect(() => {
  //   getApiData()
  // }, [])
  useEffect(() => {
    let ispermission = !verifyPermission(
      getPermission,
      "Customer",
      "Manage",
      "update"
    );
    setDisabled(ispermission);
  }, [getPermission]);

  //  const getApiData = () => {
  //   dispatch(spinLoaderShow(true));
  //   getCustomers().then((e) => {
  //     let data =  e?.data?.result.map((item:any)=>{
  //       item.status=item.status? 'active' : 'inactive'
  //       item.verfied = item?.email != null ?  "true" : "false"
  //        return item;
  //      })
  //     setRowData(data)
  //     dispatch(spinLoaderShow(false));
  //   }).catch((err: any) => {
  //     dispatch(spinLoaderShow(false))
  //   })
  //  }
  const enableSelectedRows = () => {
    let temp: any = {};
    temp = exportData.getSelectedRows().map((item: any) => {
      return { uuid: item?.uuid, phone_number: item?.phone_number };
    });

    console.log("dkfpdfpdskf", temp);
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true));
      bulkCustomerEnable(temp)
        .then((res: any) => {
          dispatch(spinLoaderShow(false));
          toast.success("Record Updated successfully");
          // getApiData()
        })
        .catch((err) => {
          dispatch(spinLoaderShow(false));
          // getApiData()
        });
    } else {
      toast.error("Please select a row");
    }
  };
  const disableSelectedRows = () => {
    let temp: any = {};
    temp = exportData.getSelectedRows().map((item: any) => {
      return { uuid: item?.uuid, phone_number: item?.phone_number };
    });
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true));
      bulkCustomerDisable(temp)
        .then((res: any) => {
          dispatch(spinLoaderShow(false));
          toast.success("Record Updated successfully");
          // getApiData()
        })
        .catch((err) => {
          dispatch(spinLoaderShow(false));
          // getApiData()
        });
    } else {
      toast.error("Please select a row");
    }
  };
  const refreshData = () => {
    dispatch(spinLoaderShow(true));
    // getApiData()
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader title={`Customer`} routes={routes} />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Customer Listing</h4>
            <Button
              variant="outlined"
              className="custom-btn export-btn"
              onClick={(e: any) => exportData.exportDataAsCsv()}
            >
              Export to Csv
            </Button>
          </CardHeader>
          <CardBody>
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              className="custom-btn export-btn"
              onClick={(e: any) => enableSelectedRows()}
            >
              Enable
            </Button>
            <Button
              disabled={disabled}
              variant="outlined"
              color="primary"
              className="custom-btn export-btn"
              onClick={(e: any) => disableSelectedRows()}
            >
              Disable
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="custom-btn export-btn "
              onClick={(e: any) => {
                exportData &&
                  exportData?.refreshServerSideStore({ purge: true });
              }}
            >
              Refresh
            </Button>
            <AgGridEnterprise
              dataSourceUrl={getCustomerOdata}
              columnDefs={columnDefs}
              getRowNodeId={(data: any) => {
                return data.uuid;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

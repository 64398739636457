import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

import "assets/css/material-dashboard-react.css?v=1.10.0";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library
import DateFnsUtils from '@date-io/date-fns';

import { App } from "./app";
import { rootReducer } from './redux/reducer/rootReducer'

const container = document.getElementById("root");

// @ts-ignore
const root = ReactDOM.createRoot(container);

const theme = createTheme({
  palette: {
    primary: {
      main: "#043cae",
    },
    secondary: {
      main: "#0661f5",
    },
  },
});
/// APOLLO CLIENT AND REDUX

const store = createStore(rootReducer);

// Initial render: Render an element to the root.
root.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  </Provider>
);

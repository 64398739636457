import React, { useEffect, useState,useRef } from 'react';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { DateField, AutoCompleteField } from "components/Forms/Formfield.component"
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { getPackages } from "services/packageservices";
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import {getCity,getBranch,getZone,getLevels,getCountry} from 'services/locationservices'
import { getPackagesReports } from 'services/reportsServices';
import {  CSVLink } from "react-csv";
import { toast } from "react-toastify";


export const PackagesReports = () => {
  const downloadRef:any = useRef();
  const [packages, setPackages] = useState<any>([])
  const [level, setLevel] = useState<any>({});
  const [levels, setLevels] = useState<any>([])
  const [cities, setCities] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [branch, setBranch] = useState<any>('');
  const [city, setCity] = useState<any>('');
  const [zone, setZone] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);
  const [levelId, setLevelId] = useState<any>(null)
  const [countries, setCountries] = useState<any>([]);
  const [packageReport ,setPackageReport]=useState<any>(null)
  const [startDate, setStartDate] = useState<any>(moment(new Date).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<any>(moment(new Date).format('YYYY-MM-DD'));

  const dispatch=useDispatch()
  const defaultFormValues: any = {
    booking_from: '',
    booking_to: ''
  }
  const levelDependent: any = {
    'Country': ['country'],
    'City': ['country', 'city'],
    'Branch': ['country', 'city', 'branch'],
    'Zone': ['country', 'city', 'branch', 'zone'],
  };
  const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues, watch } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    getPackagesData()
    getLevels().then((e) => {
      setLevels(e.data.result)
    }).catch((e) => {
    })
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {
    })
  }, [])

  useEffect(() => {
    if (packageReport != null && packageReport?.length > 0) {
      downloadRef?.current?.link?.click();   
    }
  }, [packageReport]);

  const getPackagesData = () => {
    getPackages().then((res: any) => {
      let data: any = res?.data?.result
      setPackages(data)

    }).catch((err: any) => {
    })
  }


  const handleConfigLevel = (data: any) => {
    setCities([])
    setBranches([])
    setZones([])
    setCity({})
    setBranch({})
    setZone({})
    setCountry({})
    setLevel(data)
    setLevelValidationShow(levelDependent[data?.name])
  }

  const getCitiesByCountry = (data: any = null) => {
    setCities([])
    setBranches([])
    setZones([])
    setCity({})
    setBranch({})
    setZone({})

    if (data?.uuid) {
      setCountry(data)
      dispatch(spinLoaderShow(true))
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
          dispatch(spinLoaderShow(false))
        }).catch((err) => {
          dispatch(spinLoaderShow(false))
        })
    }
    if (data && levelValidationShow.at(-1) == 'country') {

      setLevelId(data.uuid)
    }
  }

  const getBranchesByCity = (data: any = null) => {
    setBranches([])
    setZones([])
    setBranch({})
    setZone({})

    if (data?.uuid) {
      dispatch(spinLoaderShow(true))
      getBranch({ city_id: data?.uuid, status: true }).then((e) => {
        setBranches(e.data.result)
        dispatch(spinLoaderShow(false))
      }).catch((e) => {
        dispatch(spinLoaderShow(false))

      })
    }
    if (data && levelValidationShow.at(-1) == 'city') {
      setLevelId(data.uuid)
    }

  }

  const getZonesByBranch = (data: any = null) => {
    setZones([])
    setZone({})
    if (data?.uuid) {
      dispatch(spinLoaderShow(true))

      getZone({ branch_id: data?.uuid, status: true }).then((e) => {
        setZones(e.data.result)
        dispatch(spinLoaderShow(false))

      }).catch((e) => {
        dispatch(spinLoaderShow(false))

      })
    }
    if (data && levelValidationShow.at(-1) == 'branch') {
      setLevelId(data.uuid)
    }

  }

  const handleZoneChange = (data: any) => {

    if (data && levelValidationShow.at(-1) == 'zone') {
      setLevelId(data.uuid)
    }
  }

  const onStartChange = (data:any) =>{
    setStartDate(data)
  }

  const onEndChange = (data:any) =>{
     setEndDate(data)
  }

  const onSubmit = (data: any) => {
    let format = 'YYYY-MM-DD'
    data.booking_from = moment(data.booking_from).format(format)
    data.booking_to = moment(data.booking_to).format(format)
    dispatch(spinLoaderShow(true))
    if (data) {
      getPackagesReports({ start: data?.booking_from, end: data?.booking_to, level_id: levelId, package_id:data?.packages}).then((res: any) => {

        if (res?.data?.result?.length > 0) {
          setPackageReport(res?.data?.result);
          dispatch(spinLoaderShow(false))
          toast.success("Downloading Record");

        }else{
          dispatch(spinLoaderShow(false));
          toast.error("No Record Found");
        }       
      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        toast.error("No Record Found");

      })
    }

  }
  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Packages Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>         <GridItem xs={12} sm={12} md={3}>
            <DateField
              errors={!!errors?.booking_from}
              fieldName="booking_from"
              autoComplete='off'
              label="* From"
              setValue={setValue}
              control={control}
              iProps={{
                onChange: onStartChange,
                format: 'dd-MM-yyyy',
              }}
              inputVariant="outlined"
              helperText={errors?.booking_from && errors?.booking_from?.message}
              valueGot={startDate}
            />
          </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <DateField
                errors={!!errors?.booking_to}
                fieldName="booking_to"
                autoComplete='off'
                label="* To"
                setValue={setValue}
                control={control}
                iProps={{
                  onChange: onEndChange,
                  format: 'dd-MM-yyyy',
                }}
                inputVariant="outlined"
                helperText={errors?.booking_to && errors?.booking_to?.message}
                valueGot={endDate}
              />
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={3}>
              <AutoCompleteField
                errors={!!errors?.packages}
                fieldName="packages"
                autoComplete="off"
                label="* Package"
                control={control}
                setValue={setValue}
                options={packages}
                isLoading={true}
                optionKey="uuid"
                optionValue="name"
                helperText={
                  errors?.packages && errors?.packages?.message
                }
                valueGot={''}
              />
            </GridItem> */}
            <GridItem xs={12} sm={12} md={3}>
              <AutoCompleteField
                errors={!!errors?.level_id}
                fieldName="level_id"
                autoComplete='off'
                label="* Select Level"
                control={control}
                setValue={setValue}
                options={levels}
                optionKey="id"
                returnObject={true}
                iProps={{
                  onChange: handleConfigLevel,

                }}
                isLoading={levels?.length > 0 ? true : false}
                optionValue="name"
                helperText={errors?.level_id && errors?.level_id?.message}
                valueGot={level}
              />
            </GridItem>

            {levelValidationShow?.includes('country') &&
              <GridItem xs={12} sm={12} md={3} >
                <AutoCompleteField
                  errors={!!errors?.country_id}
                  fieldName="country_id"
                  autoComplete='off'
                  label="* Select Country"
                  control={control}
                  setValue={setValue}
                  options={countries}
                  isLoading={countries?.length > 0 ? true : false}
                  optionKey="uuid"
                  iProps={{
                    onChange: getCitiesByCountry,
                  }}
                  optionValue="country_name"
                  helperText={errors?.country_id && errors?.country_id?.message}
                  valueGot={country}
                />
              </GridItem>
            }
            {levelValidationShow?.includes('city') &&
              <GridItem xs={12} sm={12} md={3} >
                <AutoCompleteField
                  errors={!!errors?.city_id}
                  fieldName="city_id"
                  autoComplete='off'
                  label="* Select City"
                  control={control}
                  setValue={setValue}
                  options={cities}
                  isLoading={true}
                  optionKey="uuid"
                  iProps={{
                    onChange: getBranchesByCity,
                  }}
                  optionValue="city_name"
                  helperText={errors?.city_id && errors?.city_id?.message}
                  valueGot={city}
                />
              </GridItem>
            }
            {levelValidationShow?.includes('branch') &&
              <GridItem xs={12} sm={12} md={3} >
                <AutoCompleteField
                  errors={!!errors?.branch_id}
                  fieldName="branch_id"
                  autoComplete='off'
                  label="* Select Branch"
                  iProps={{
                    onChange: getZonesByBranch,
                  }}
                  control={control}
                  setValue={setValue}
                  options={branches}
                  optionKey="uuid"
                  optionValue="name"
                  helperText={errors?.branch_id && errors?.branch_id?.message}
                  valueGot={branch}
                  isLoading={true}
                />
              </GridItem>
            }
            {levelValidationShow?.includes('zone') &&
              <GridItem xs={12} sm={12} md={3} >
                <AutoCompleteField
                  errors={!!errors?.zone_id}
                  fieldName="zone_id"
                  autoComplete='off'
                  label="* Select Zone"
                  control={control}
                  setValue={setValue}
                  options={zones}
                  iProps={{
                    onChange: handleZoneChange
                  }}
                  optionKey="uuid"
                  optionValue="name"
                  helperText={errors?.zone_id && errors?.zone_id?.message}
                  valueGot={zone}
                  isLoading={true}
                />
              </GridItem>
            }
            <GridItem xs={12} sm={12} md={3} >
              <Box style={{ marginTop: "11px" }}>
                <Button color="primary" type="submit">Packages Reports</Button>
                {packageReport  != null ? <CSVLink
                  filename={"packages" + moment().format('YYYY-MM-DD')+ '.csv'}
                  ref={downloadRef}
                  data={packageReport}
                /> : null}

              </Box>

            </GridItem>
          </GridContainer>
        </AccordionDetails>
      </Accordion>

    </form>
  )

}
import * as yup from 'yup'

const createCustomerAddressValidation:any = yup.object().shape({
    customer_id: yup.string().required("Please select customer").nullable(),
    // first_name: yup.string().required("Please enter first name").nullable(),
    // last_name: yup.string().required("Please enter last name").nullable(),
    label:yup.string().required("Please enter label").nullable(),
    area:yup.string().required("Please enter area").nullable(),
    landmark:yup.string().required("Please enter landmark").nullable(),
    street_one:yup.string().required("Please enter street one").nullable(),
    unitNumber:yup.string().required("Please enter Unit Number").nullable(),
    unitType:yup.string().required("Please enter Unit Type").nullable(),
    country_id:yup.string().required("Please select country").nullable(),
    city_id:yup.string().required("Please select city").nullable(),
  });

  export{
      createCustomerAddressValidation
  }
import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
// import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import GridContainer from "components/Grid/GridContainer";

export const HomeBannerAccordion = (props: any) => {
  const { collapse, apiData, control, errors, setValue, getValues } = props;

  const defaultFormValues: any = {};
  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Home Banner </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer spacing={3} style={{ flexGrow: 1 }}>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.home_title}
              fieldName="home_title"
              type="text"
              autoComplete="false"
              label="* Title "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.home_title && errors?.home_title?.message}
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.home_description}
              fieldName="home_description"
              type="text"
              autoComplete="false"
              label="* Description "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={
                errors?.home_description && errors?.home_description?.message
              }
            />
          </GridItem>
          {/* <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.home_app_store_url}
              fieldName="home_app_store_url"
              type="text"
              autoComplete="false"
              label="* App Store url "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={
                errors?.home_app_store_url &&
                errors?.home_app_store_url?.message
              }
            />
          </GridItem> */}
          {/* <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.home_play_store_url}
              fieldName="home_play_store_url"
              type="text"
              autoComplete="false"
              label="* PlayStore Link "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={
                errors?.home_play_store_url &&
                errors?.home_play_store_url?.message
              }
            />
          </GridItem> */}
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.home_video_url}
              fieldName="home_video_url"
              type="text"
              autoComplete="false"
              label="* Video Link "
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={
                errors?.home_video_url && errors?.home_video_url?.message
              }
            />
          </GridItem>
        </GridContainer>
      </AccordionDetails>
      <AccordionActions>
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          {/* {permission === false ? (
             
            ) : (
              ""
            )} */}
          {/* <Button color="primary" className={"custom-btn"} type="submit">
                Submit
              </Button> */}
        </Box>
      </AccordionActions>
    </ControlledAccordion>
  );
};

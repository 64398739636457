import React, { useState, useRef, useEffect } from "react";
import { Avatar, Box, makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import PageHeader from "components/PageHeader/PageHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  getPagesSection,
  updatePagesSection,
} from "services/website Services/pagesServices";
import {
  AutoCompleteField,
  AutoCompleteMultipleField,
  InputField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { getServices, getServiceCategory } from "services/servicesServices";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";
import * as yup from "yup";

import {
  getWebSection,
  updateWebSection,
} from "services/website Services/webServices";
import { WebArray } from "./webArray";
import { UPDATE_SUCCESS_MSG, CREATE_SUCCESS_MSG } from "json/constants";
import { useLocation } from "react-router-dom";
import { WebServiceValidation } from "./validations/webservice-validation";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";

// import { AboutValidation } from "./validations/about-validation";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export const WebServicesPage = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [collapse, setCollapse] = useState<any>(false);
  const [editData, setEditData] = useState<any>(null);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [headerTitle, setHeaderTitle] = useState<string>("Add");
  const [servicesData, setServicesdata] = useState<any>([]);
  const [selectedService, setSelectedService] = useState<any>([]);
  const [selectedServiceCategory, setSelectedServiceCategory] = useState<any>(
    {}
  );
  const [url, setUrl] = useState<any>("");

  const [selectedServiceEdit, setSelectedServiceEdit] = useState<any>([]);
  const [dropOpen, setDropOpen] = useState<any>(false);

  const [validation, setValidation] = useState<any>(
    yup.object().shape({
      service_category: yup
        .string()
        .typeError("Please select category")
        .required("Please select category")
        .nullable(),
      services: yup
        .array()
        .min(1, "Please select minimum one service")
        .required("Please select atleast one service")
        .nullable(),
    })
  );
  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");

  const typeOptions = [
    { uuid: "1", name: "Media" },
    { uuid: "2", name: "Events" },
  ];

  const routes: any = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Website Services",
      link: "/admin/webservices",
    },
    {
      name: headerTitle,
      link: "",
    },
  ];

  useEffect(() => {
    if (editData) {
      //SET CATEGORY

      setValue("slug", editData?.slug);
      setValue("status", editData?.status);
      setValue("category_title", editData?.category_title);
      setValue("category_description", editData?.category_description);
      setValue("header_title", editData?.name);
      setUrl(editData?.image);

      setSelectedServiceCategory(
        categoryData.find((x: any) => x.uuid == editData.service_category_id)
      );
      setValue(
        "service_category",
        categoryData.find((x: any) => x.uuid == editData.service_category_id)
          ?.uuid
      );
      getServices({ service_category_id: editData.service_category_id })
        .then((e: any) => {
          // console.log("Service", e?.data?.result);
          let temp = e?.data?.result?.map((item: any) => {
            return item;
          });
          setServicesdata(temp);
          // console.log("heeere");
          let selectedServiceX = editData.web_category_services
            .filter((x: any) => x.status)
            .map((item: any) => item.service_id);
          //  console.log("sssssssssssssssssssssssssssssssssssssssssss",selectedServiceX,temp , )
          let foundServicesSelected = temp.filter((x: any) =>
            selectedServiceX.includes(x.uuid)
          );
          serviceHandleChange(foundServicesSelected);
          setSelectedServiceEdit(foundServicesSelected);
          foundServicesSelected.map((item: any, index: number) => {
            setValue(
              `${item?.name}.description`,
              editData.web_category_services.find(
                (x: any) => x.service_id == item.uuid
              ).description
            );
            setValue(
              `${item?.name}.distance_text`,
              editData.web_category_services.find(
                (x: any) => x.service_id == item.uuid
              ).distance_text
            );
            setValue(
              `${item?.name}.status`,
              editData.web_category_services.find(
                (x: any) => x.service_id == item.uuid
              ).status
            );
            setValue(
              `${item?.name}.featuredImage`,
              editData.web_category_services.find(
                (x: any) => x.service_id == item.uuid
              ).featuredImage
            );
            setValue(
              `${item?.name}.tiles`,
              editData.web_category_services.find(
                (x: any) => x.service_id == item.uuid
              ).tiles
            );
          });
          // setType(temp);
          dispatch(spinLoaderShow(false));
        })
        .catch((err: any) => {
          dispatch(spinLoaderShow(false));
        });
    }
  }, [editData, categoryData]);

  useEffect(() => {
    if (uuid) {
      setHeaderTitle("Edit");
      getCategory();
      getWebSection({ uuid: uuid }).then((res) => {
        // console.log("res", res);
        setEditData(res?.data?.result?.[0]);
      });
    } else {
      getCategory();
      setHeaderTitle("Add");
      setEditData(null);
      // setServicesdata([]);
    }
  }, [uuid]);

  const getCategory = () => {
    dispatch(spinLoaderShow(true));
    getServiceCategory({ status: true })
      .then((e: any) => {
        let data = e?.data?.result?.map((item: any) => {
          return item;
        });
        setCategoryData(data);
        // console.log("CategoryData", data);
        dispatch(spinLoaderShow(false));
      })
      .catch((err: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const typeHandleChange = (data: any) => {
    dispatch(spinLoaderShow(true));
    if (data !== null) {
      getServices({ service_category_id: data?.uuid })
        .then((e: any) => {
          // console.log("Service", e?.data?.result);
          let temp = e?.data?.result?.map((item: any) => {
            return item;
          });
          setServicesdata(temp);

          dispatch(spinLoaderShow(false));
        })
        .catch((err: any) => {
          dispatch(spinLoaderShow(false));
        });
    } else {
      dispatch(spinLoaderShow(false));
      data ? [] : setServicesdata([]);
    }
  };
  const serviceHandleChange = (data: any) => {
    if (data !== null) {
      setSelectedService(data);
    } else {
      setSelectedService([]);
    }
  };

  const defaultFormValues: any = {};
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(validation),
  });

  useEffect(() => {
    let temObj: any = {}; // all facility dynamic key are concatenated in this object
    selectedService?.map((serviceItem: any, serviceIndex: any) => {
      // console.log("selectedService", serviceItem);
      temObj = {
        ...temObj,
        [serviceItem?.name]: yup.object().shape({
          description: yup
            .string()
            .required("please enter description")
            .nullable(),
          distance_text: yup
            .string()
            .required("please enter Tile Heading")
            .nullable(),

          tiles: yup.array().of(
            yup.object().shape({
              title: yup.string().required("Please enter title").nullable(),
            })
          ),
        }),
      };
    }); // tempObj and tempschema merge and then set in validation schema state
    let tempSchema = yup.object().shape({
      // service_category: yup
      //   .string()
      //   .typeError("Please select category")
      //   .required("Please select category")
      //   .nullable(),
      header_title: yup
        .string()
        .typeError("please enter header title")
        .required("please enter header title")
        .nullable(),
      category_description: yup
        .string()
        .typeError("please enter category description")
        .required("please enter category description")
        .nullable(),
      category_title: yup
        .string()
        .typeError("please enter category title")
        .required("please enter category title")
        .nullable(),
      services: yup
        .array()
        .min(1, "Please select minimum one service")
        .required("Please select atleast one service")
        .nullable(),
      slug: yup
        .string()
        .matches(/^[a-z\-\d]+$/, "do not use space and special character")
        .required("please enter Slug")
        .nullable(),

      ...temObj,
    });
    setValidation(tempSchema);
  }, [getValues, selectedService]);
  //post data in api

  const onSubmit = (data: any) => {
    // return console.log("data", data);
    data.service_category_id = data?.service_category?.uuid;
    data.category_title = data?.category_title;
    data.category_description = data?.category_description;
    data.name = data?.header_title;
    data.image = data?.header_image;
    data.services = data.services?.map((item: any) => {
      return {
        service_id: item?.uuid,
        distance_text: data?.[item?.name]?.distance_text,
        description: data?.[item?.name]?.description,
        status: data?.[item?.name]?.status,
        tiles: data?.[item?.name]?.tiles,
        featuredImage: data?.[item?.name]?.featuredImage,
      };
    });
    let serviceIds = data.services.map((item: any) => item.service_id);
    console.log("serviceIds", serviceIds);
    let exludedNow = editData?.["web_category_services"]?.filter(
      (x: any) => !serviceIds.includes(x.service_id)
    );
    exludedNow?.map((item: any) => {
      if (data.services.find((x: any) => x.service_id == item.service_id)) {
        let findIndex = data.services.findIndex(
          (x: any) => x.service_id == item.service_id
        );
        data.services[findIndex] = {
          service_id: item?.service_id,
          distance_text: null,
          description: null,
          status: false,
          tiles: null,
          featuredImage: null,
        };
      } else {
        data.services.push({
          service_id: item?.service_id,
          distance_text: null,
          description: null,
          status: false,
          tiles: null,
          featuredImage: null,
        });
      }
    });

    let foundExcludedServices = selectedServiceEdit.filter(
      (x: any) =>
        !data?.services?.map((item: any) => item?.service_id).includes(x.uuid)
    );
    // console.log("Exlcude", foundExcludedServices);
    foundExcludedServices?.map((item: any) => {
      let oldRec = editData?.web_category_services?.find(
        (x: any) => x.service_id == item.uuid
      );
      data.services.push({
        service_id: oldRec.service_id,
        description: oldRec.description,
        status: false,
        tiles: oldRec.tiles,
        featuredImage: oldRec?.featuredImage,
      });
    });
    selectedService?.map((item1: any) => {
      delete data[item1?.name];
    });

    delete data?.tiles;
    delete data?.service_category;
    delete data?.categ_description;
    delete data?.categ_description;

    updateOnSubmit(data);
    console.log("data", data);
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateWebSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const toggleAll = () => setCollapse(!collapse);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("header_image", data?.result[0]);
    setUrl(data?.result[0]);
    dispatch(spinLoaderShow(false));
  };

  useEffect(() => {
    console.log("errors", errors);
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridItem xs={12} sm={12} md={12}>
              <PageHeader title="Website Services" routes={routes} />
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" className="card-header-export-btn">
                <h4 className={classes.cardTitleWhite}>Website Services</h4>
                {/* <Button
                  variant="outlined"
                  className="custom-btn export-btn"
                  onClick={toggleAll}
                >
                  {collapse ? "Collapse All" : "Expand All"}
                </Button> */}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
                    <Box
                      style={{ display: "flex", alignItems: "center" }}
                      alignItems="center"
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src={url}
                        className={classes.avatar}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: "25px" }}
                        // disabled={disabled}
                        className={classes.uploadButton}
                        onClick={() => setDropOpen(true)}
                      >
                        Service Image
                      </Button>
                      <FileUploadDialog
                        open={dropOpen}
                        setOpen={setDropOpen}
                        handleSave={handleSave}
                        message={
                          "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                        }
                      />
                    </Box>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="status"
                      defaultChecked="true"
                      control={control}
                      optionKey="uuid"
                      optionValue="status"
                      // disabled={disabled}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>

                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: "10px" }}
                  >
                    <InputField
                      errors={!!errors?.header_title}
                      fieldName="header_title"
                      type="text"
                      autoComplete="off"
                      label="* Header Title"
                      control={control}
                      returnObject={false}
                      iProps={
                        {
                          // disabled: disabled,
                        }
                      }
                      setValue={setValue}
                      helperText={
                        errors?.header_title && errors?.header_title?.message
                      }
                    />
                  </GridItem>

                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: "10px" }}
                  >
                    <InputField
                      errors={!!errors?.category_title}
                      fieldName="category_title"
                      type="text"
                      autoComplete="off"
                      label="* Category Title"
                      control={control}
                      returnObject={false}
                      iProps={
                        {
                          // disabled: disabled,
                        }
                      }
                      setValue={setValue}
                      helperText={
                        errors?.category_title &&
                        errors?.category_title?.message
                      }
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: "10px" }}
                  >
                    <InputField
                      errors={!!errors?.category_description}
                      fieldName="category_description"
                      type="text"
                      autoComplete="off"
                      label="* Category Description"
                      control={control}
                      returnObject={false}
                      iProps={
                        {
                          // disabled: disabled,
                        }
                      }
                      setValue={setValue}
                      helperText={
                        errors?.category_description &&
                        errors?.category_description?.message
                      }
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: "10px" }}
                  >
                    <InputField
                      errors={!!errors?.slug}
                      fieldName="slug"
                      type="text"
                      autoComplete="off"
                      label="* Slug"
                      control={control}
                      returnObject={false}
                      iProps={
                        {
                          // disabled: disabled,
                        }
                      }
                      setValue={setValue}
                      helperText={errors?.slug && errors?.slug?.message}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: "10px" }}
                  >
                    <AutoCompleteField
                      errors={!!errors?.service_category}
                      fieldName="service_category"
                      autoComplete="off"
                      label="* Service Category"
                      control={control}
                      setValue={setValue}
                      options={categoryData}
                      isLoading={true}
                      iProps={{
                        onChange: typeHandleChange,
                      }}
                      returnObject={true}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={
                        errors?.service_category &&
                        errors?.service_category?.message
                      }
                      valueGot={selectedServiceCategory}
                    />
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={6}
                    style={{ marginBottom: "10px" }}
                  >
                    {servicesData.length > 0 && (
                      <AutoCompleteMultipleField
                        errors={!!errors?.services}
                        fieldName="services"
                        autoComplete="off"
                        label="* Services"
                        control={control}
                        setValue={setValue}
                        options={servicesData}
                        isLoading={true}
                        iProps={{
                          onChange: serviceHandleChange,
                        }}
                        returnObject={true}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={
                          errors?.services && errors?.services?.message
                        }
                        // valueGot={
                        //   categoryData?.type &&
                        //   typeOptions?.filter(({ name }: any) => {
                        //     return name == categoryData?.type;
                        //   })
                        // }
                        valueGot={selectedService}
                      />
                    )}
                  </GridItem>

                  {selectedService?.map((item: any, index: any) => {
                    // console.log(item);

                    return (
                      <GridItem xs={12} md={12} sm={12}>
                        <Accordion>
                          <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>
                              {item?.name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GridContainer>
                              <GridItem xs={6} md={6} sm={6}>
                                <FileUploadFieldArray
                                  fieldName={`[${item?.name}].featuredImage`}
                                  keyss={`[${item?.name}].featuredImage`}
                                  open={dropOpen}
                                  lable={"Image"}
                                  setOpen={setDropOpen}
                                  setValue={setValue}
                                  getValuesX={getValues}
                                  control={control}
                                  message={
                                    "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                                  }
                                />
                              </GridItem>
                              <GridItem
                                xs={6}
                                md={6}
                                sm={6}
                                alignItems="center"
                                style={{ marginTop: "15px" }}
                              >
                                <SwitchFieldDefault
                                  errors={!!errors?.[item?.name]?.status}
                                  fieldName={`[${item?.name}].status`}
                                  type="text"
                                  autoComplete="false"
                                  label="* Status"
                                  control={control}
                                  isLoading={true}
                                  valueGot={true}
                                  setValue={setValue}
                                  helperText={
                                    errors?.status && errors?.status?.message
                                  }
                                />
                              </GridItem>
                              <GridItem
                                xs={6}
                                md={6}
                                sm={6}
                                alignItems="center"
                              >
                                <InputField
                                  errors={!!errors?.[item?.name]?.distance_text}
                                  fieldName={`[${item?.name}].distance_text`}
                                  type="text"
                                  autoComplete="false"
                                  label="* Tile Heading"
                                  control={control}
                                  isLoading={true}
                                  valueGot={""}
                                  setValue={setValue}
                                  helperText={
                                    errors?.[item?.name]?.distance_text &&
                                    errors?.[item?.name]?.distance_text.message
                                  }
                                />
                              </GridItem>
                              <GridItem
                                xs={6}
                                md={6}
                                sm={6}
                                alignItems="center"
                              >
                                <InputField
                                  errors={!!errors?.[item?.name]?.description}
                                  fieldName={`[${item?.name}].description`}
                                  type="text"
                                  autoComplete="false"
                                  label="* Description"
                                  control={control}
                                  isLoading={true}
                                  valueGot={""}
                                  setValue={setValue}
                                  helperText={
                                    errors?.[item?.name]?.description &&
                                    errors?.[item?.name]?.description.message
                                  }
                                />
                              </GridItem>
                            </GridContainer>
                          </AccordionDetails>

                          <AccordionDetails style={{ display: "block" }}>
                            <WebArray
                              setValue={setValue}
                              valueGot={""}
                              control={control}
                              errors={errors}
                              item={item}
                              getValues={getValues}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </GridItem>
                    );
                  })}
                </GridContainer>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%", marginTop: "15px" }}
                >
                  <Button
                    color="primary"
                    className={"custom-btn"}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </>
  );
};

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from "react-hook-form";
import { InputField, LoadingButton, SwitchField,AutoCompleteField ,TextEditorField} from "components/Forms/Formfield.component";
import { Box} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { createContentManagementValidation } from "./Validations/contentValidation";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const countryOptions:any = [{id:1,name:"INDIA"},{id:2,name:"QATAR"},{id:2,name:"DUBAI"}];
const defautlFormValues = {
  country_id:null,
  name:null,
  content:null,
  status:false,
  description:''
}


const useStyles = makeStyles(styles);
declare var google: any;


export default function ContentEdit(props: any) {
  const classes = useStyles();
  const countryOptions: any = [{ id: 1, name: "INDIA" }, { id: 2, name: "QATAR" }, { id: 2, name: "DUBAI" }];
  
  

  const { control,register, handleSubmit,clearErrors,setError, formState: { errors },setValue } = useForm({
    mode:'onChange',
    reValidateMode:"onChange",
    defaultValues:defautlFormValues,
    resolver:yupResolver(createContentManagementValidation)
  });

  const routes:any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Content ",
      'link': '/admin/content'
    },
    {
      'name': "Add",
      'link': ''
    }
  ];
  const containerStyle = {
    height: "400px",
    width: "100%"
  }

 

  const  onSubmit = async (data:any)=>{
    console.log('data',data)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12}>
        <PageHeader 
          title="Content"
          routes={routes}
          newItem={{ text: 'Back', link: "/admin/content" }}
          backIcon
        />
      </GridItem>
    </GridItem>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}> *New Content</h4>
         
        </CardHeader>
        <CardBody>          
               <GridContainer>
               <GridItem xs={12} sm={12} md={4}>
               <AutoCompleteField
                      errors={!!errors?.country_id}
                      fieldName="country_id"
                      autoComplete='off'
                      label="* Select Country"
                      control={control}
                      setValue={setValue}
                      options={countryOptions}
                      optionKey="id"
                      isLoading={countryOptions?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.country_id && errors?.country_id?.message}
                      valueGot={''}
                     />
                </GridItem>
               <GridItem  xs={12} sm={12} md={4} >
               <InputField
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete="false"
              label="Name"
              control={control}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.name && errors?.name?.message}
              
            />
    

               </GridItem>
             
              <FormControlLabel
          value="end"
          control={<SwitchField 
            errors={!!errors?.status}
            fieldName="status"
            autoComplete='off'
            label="status"
            control={control}
            valueGot={''}
            setValue={setValue}
            helperText={errors?.status && errors?.status?.message}
            
          />}
          label="status"
          labelPlacement="end"
        />
              <GridItem xs={12} sm={12} md={12}>
              <TextEditorField
                      className={"mt-15"}
                      errors={!!errors?.description}
                      textareaName="description"
                      fieldName="description"
                      type="text"
                      autoComplete="false"
                      label="Description"
                      control={control}
                      isLoading={true}
                      valueGot={''}
                      setValue={setValue}
                      helperText={errors?.description && errors?.description?.message}
                    />
              </GridItem>
               
              </GridContainer>
       
        </CardBody>
        <CardFooter>
              <Box display="flex" justifyContent="flex-end" style={{width:'100%'}}>
                    <LoadingButton
                      type="submit"
                      loading={false}
                      text="Submit"
                    />
                  </Box>
              </CardFooter>
      </Card>
    </GridItem>
    </GridContainer>
    </form>
  );
}
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import StatusComponent from "components/Mix/Status";
import PageHeader from "components/PageHeader/PageHeader";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getZone, getSchedules } from "services/locationservices";
import { bulkZoneEnable,bulkZoneDisable } from "services/bulkEnableServices";
import { Link } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from 'react-redux'
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);



export const ZoneList = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [zoneRow, setZoneRow] = useState<any>([])
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const dispatch = useDispatch()


  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Zone",
      'link': ''
    }
  ];


  const columnDefs: any = [
  
    {
      headerName: "Zone Name",
      field: 'name',
    },
    {
      headerName: 'Branch',
      field: 'branch.name',
    },
    {
      headerName: 'Schedule',
      field: 'schedule.name',
    },
    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Modified Date',
      field: "updatedAt",
     
      sort: "desc",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.updatedAt).format('DD-MM-YYYY HH:MM:ss');

    // }
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
     
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
  },
    {
      headerName: 'Created Date',
      field: "createdAt",
    //   valueGetter: (params: any) => {
    //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

    // },
    filter: 'agDateColumnFilter',
    valueFormatter: function (data:any) {
      return moment(data.value).format('YYYY-MM-DD HH:mm')
    },
    filterParams: {
      filterOptions: ['equals', 'lessThan', 'greaterThan'],
      comparator: function (filterLocalDateAtMidnight:any, cellValue:any) {
        console.log('filtrlocaldate ', filterLocalDateAtMidnight)

        console.log('cellValue ', cellValue)

        console.log(
          'filter ',
          moment(filterLocalDateAtMidnight).format('YYYY-MM-DD HH:mm'),
        )
        const filter = moment(filterLocalDateAtMidnight).format(
          'YYYY-MM-DD HH:mm',
        )
        const compare = moment(cellValue).format('YYYY-MM-DD HH:mm')

        if (filter === compare) {
          return 0
        }
        if (compare < filter) {
          return -1
        }
        if (compare > filter) {
          return 1
        }
      },
    },
    },
    {
      headerName: 'Actions',
      field: "action",
      sortable: false,
      filter: false,
      hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <Link to={'zone/edit?uuid=' + params?.data?.uuid}><EditIcon /></Link>;
      }
    },
    {
      headerName: 'View',
      field: "action",
      sortable: false,
      filter: false,
      cellRendererFramework: (params: any) => {
        // put the value in bold

        return <Link to={'zone/edit?uuid=' + params?.data?.uuid + "&type=view"}><VisibilityIcon /></Link>;
      }
    },
  ];
  useEffect(() => {
    getZoneData()
   

  }, [])
  
  useEffect(()=>{
    let ispermission = !(verifyPermission(
      getPermission,
      'Location',
      'Zone',
      'update'
    ))
    setDisabled(ispermission)
  },[getPermission])

  const getZoneData = () =>{
    dispatch(spinLoaderShow(true));
    getZone().then((e: any) => {
      let data =  e?.data?.result.map((item:any)=>{
        item.status=item.status? 'active' : 'inactive' 
        item.type = item?.vendor_id != null ? 'Vendor' : 'Admin'
         return item;
       })
       dispatch(spinLoaderShow(false));
       setRowData(data)
    }).catch((err)=>{
      dispatch(spinLoaderShow(false))
    })
    
  }

  const enableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid}  
  
    })
    if (exportData.getSelectedRows().length > 0){
      dispatch(spinLoaderShow(true))
    bulkZoneEnable(temp).then((res:any)=>{
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getZoneData()
    }).catch((err)=>{
      dispatch(spinLoaderShow(false))
    })
    getZoneData()
    }else{
      toast.error("Please select a row")
    }
   
  }
  const disableSelectedRows = ()=>{
    let temp:any = {}
     temp = exportData.getSelectedRows().map((item:any)=>{
      return { uuid : item?.uuid}  
  
    })
    if (exportData.getSelectedRows().length > 0) {
      dispatch(spinLoaderShow(true))
    bulkZoneDisable(temp).then((res:any)=>{
        dispatch(spinLoaderShow(false))
        toast.success("Record Updated successfully")
        getZoneData()
    }).catch((err)=>{
      dispatch(spinLoaderShow(false))
      getZoneData()
    })
    }
    else{
      toast.error("Please select a row")
    }
    
    
  }
  
  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getZoneData()
  
  }


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Zone"
            routes={routes}
            newItem={[{ text: 'Add New Zone', link: "zone/add" }]}
            permission="Location.Zone.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Zone List</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
          <Button disabled={disabled} variant="outlined" className="custom-btn export-btn" color="primary" onClick={(e: any) =>enableSelectedRows() }>Enable</Button>
          <Button disabled={disabled} variant="outlined" className="custom-btn export-btn" color="primary" onClick={(e: any) =>disableSelectedRows() }>Disable</Button>
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              columnDefs={columnDefs}
              rowData={rowData}
              headerCheckboxSelection="true"
              headerCheckboxSelectionFilteredOnly="true"
              checkboxSelection="true"
              getRowNodeId={function (data: any) {
                return data.uuid;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}





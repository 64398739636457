import { makeStyles } from '@material-ui/core/styles'

const secretStyles = makeStyles((theme) => ({
  intro: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  Splash: {
    display: 'flex',
    width: '100%',
    height: 'auto',
  },
  splashImg: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
  },
  text: {
    padding: '2rem',
  },
  loginhead: {
    margin: '2rem 0',
    textAlign: 'center',
  },
  introSide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    width: '100%',
  },
  secretSide: {
    position: 'relative',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    height: '100%',
  },
  mainContain: {
    display: 'flex',
    minHeight: '100vh',
  },
  formBox: {
    position: 'relative',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  backdrop: {
    background: 'rgba(0,0,0,0.4)',
    zIndex: theme.zIndex.drawer + 2,
  },
}))

export default secretStyles

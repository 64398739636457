import * as yup from 'yup'

const createVehicleModelValidation:any = yup.object().shape({
    name: yup.string().required("Please enter model").nullable(),
    brand_id: yup.string().required("Please enter make").nullable(),
    vehicle_type_id: yup.string().required("Please enter type").nullable()
  });

  export {
      createVehicleModelValidation
  }
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  InputField,
  AutoCompleteField,
  SwitchField,
  SwitchFieldDefault,
  AutoCompleteMultipleField,
  AutoCompleteMultipleFieldDuplicate,
  CheckboxField,
} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { type } from "os";
import {
  getDriver,
  createDriverConfig,
  getDriverConfigData,
} from "services/driverServices";
import {
  getLevels,
  getCity,
  getBranch,
  getZone,
  getCountry,
} from "services/locationservices";
import { getVehicleType } from "services/vehiclesServices";
import { getServiceConfig } from "services/servicesServices";
import moment from "moment";
import { driverConfigValidation } from "containers/DriverConfig/Validations/driverConfigValidation";
import { getServices } from "services/servicesServices";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { useFieldArray } from "react-hook-form";


const routes = [
  {
    name: "Admin",
    link: "/admin",
  },
  {
    name: "Driver Config ",
    link: "/admin/driver-config",
  },
  {
    name: "Edit",
    link: "",
  },
  // {
  //   'name': uuid,
  //   'link': '',
  //   'uuid': uuid ? true : false
  // }
];

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  driver_id: "",
};

const levelDependent: any = {
  // 'Country': ['country'],
  // 'City': ['country', 'city'],
  // 'Branch': ['country', 'city', 'branch'],
  Zone: ["country", "city", "branch", "zone"],
};

const useStyles = makeStyles(styles);
declare var google: any;

export default function AddDriverConfig(props: any) {
  const classes = useStyles();
  const [driverId, setDriverID] = useState<any>([]);
  const [driverSelected, setDriverSelected] = useState<any>({});

  const [levels, setLevels] = useState<any>([]);
  const [levelValidationShow, setLevelValidationShow] = useState<any>(
    levelDependent?.Zone
  );
  const [country, setCountry] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [branch, setBranch] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [zone, setZone] = useState<any>(null);
  const [serviceConfigs, setServiceConfigs] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const [service, setService] = useState<any>([]);
  const [vehicleTypes, setVehicleTypes] = useState<any>([]);
  const [vehicleType, setVehicleType] = useState<any>([]);
  const [editData, setEditData] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");

  let history = useHistory();
  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");

  useEffect(() => {
    getCountry({ status: true })
      .then((e) => {
        setCountries(e.data.result);
      })
      .catch((e) => {});
    getLevels()
      .then((e) => {
        setLevels(e.data.result);
      })
      .catch((e) => {});
    getVehicleType({ status: true })
      .then((e) => {
        setVehicleTypes(e.data.result);
      })
      .catch((e) => {});
    getDriverData();
    getZonesData();
    getServicesData();
  }, []);

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(driverConfigValidation),
  });

 

  // field array initialization function
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "data", // unique name for your Field Array
    }
  );


  //   useEffect(()=>{
  //     let temp:any = []
  //    serviceConfigs.map((row:any,index:any)=>{
  //      let data = row?.service
  //      let service_config_uuid=row?.uuid

  //      temp.push({...data,...{service_config_id:service_config_uuid }})
  // })

  //    setServices(temp)

  //   },[serviceConfigs])

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true));
      setHeaderTitle("Edit");
      getDriverConfigData({ driver_id: uuid })
        .then((res: any) => {
          let data: any = res?.data?.result;
          const arrayUniqueByKey: any = [
            ...new Map(
              data?.map((item: any) => [item["level_id"], item])
            ).values(),
          ]; // [ 'A', 'B']

          console.log("arrayUniqueByKey", arrayUniqueByKey);
          setZone(
            zones
              ?.filter((x: any) =>
                arrayUniqueByKey?.map((y: any) => y.level_id).includes(x?.uuid)
              )
              .map((item: any) => {
                console.log("ASdasdasd", item);
                item.checked = res?.data?.result.find(
                  (x: any) => x.level_id == item.uuid && x.status
                )
                  ? true
                  : false;
                return item;
              })
          );
          var zoneNew = zones?.filter((x: any) =>
            arrayUniqueByKey?.map((y: any) => y.level_id).includes(x?.uuid)
          );
          arrayUniqueByKey?.map((itemX: any) => {
            // var services
            var serviceIDs = data
              ?.filter((x: any) => x.level_id == itemX.level_id && x?.status)
              ?.map((xY: any) => {
                // console.log("XYY",xY)
                return xY?.service_id;
              });
            console.log(
              "zones?.findIndex((x:any)=>x.uuid== itemX.level_id)",
              zoneNew?.findIndex((x: any) => x.uuid == itemX.level_id)
            );
            if (zoneNew?.findIndex((x: any) => x.uuid == itemX.level_id) >= 0) {
              setValue(
                `data.${[
                  zoneNew?.findIndex((x: any) => x.uuid == itemX.level_id),
                ]}.serviceIds`,
                services
                  ?.filter((x: any) => serviceIDs.includes(x?.uuid))
                  ?.map((y: any) => {
                    return { uuid: y?.uuid, name: y?.name, status: y?.status };
                  })
              );
              setValue(
                `data.${[
                  zoneNew?.findIndex((x: any) => x.uuid == itemX.level_id),
                ]}.buffer_time`,
                data?.find((x: any) => x.level_id == itemX.level_id)
                  ?.buffer_time
              );
            }

            // setValue( `data.${[zone?.findIndex((x:any)=>x.uuid== itemX.level_id)]}.serviceIds`, services?.filter((x:any)=> serviceIDs.includes(x?.uuid))?.map((y:any)=>{  return {"uuid":y?.uuid, "name":y?.name ,"status":y?.status}}))
            // setValue( `data.${[zone?.findIndex((x:any)=>x.uuid== itemX.level_id)]}.buffer_time`,data?.find((x:any)=>x.level_id== itemX.level_id)?.buffer_time )
          });
          setEditData(data);
          dispatch(spinLoaderShow(false));
        })
        .catch((err: any) => {
          dispatch(spinLoaderShow(false));
        });
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
      setEditData([]);
    }
  }, [uuid, zones, services]);

  // useEffect(()=>{
  //  console.log("getValues",wahtch)
  // },[wahtch])

  const getZonesData = () => {
    getZone()
      .then((res: any) => {
        setZones(res?.data?.result);
      })
      .catch((err: any) => {});
  };
  const getServicesData = () => {
    getServices()
      .then((res: any) => {
        setServices(res?.data?.result);
      })
      .catch((err: any) => {});
  };
  const getDriverData = () => {
    getDriver().then((e) => {
      let data: any = e?.data?.result?.map((item: any) => {
        item.name = item?.first_name + " " + item?.last_name;
        return item;
      });
      setDriverID(data);
    });
  };

  const getCitiesByCountry = (data: any = null) => {
    setCities([]);
    setBranches([]);
    setZones([]);
    setCity({});
    setBranch({});
    setZone([]);
    setService([]);
    if (data?.uuid) {
      getCity({ country_id: data?.uuid, status: true }).then((e) => {
        setCities(e?.data?.result);
      });
    }
    if (data && levelValidationShow.at(-1) == "country") {
      setServiceConfigs([]);
      getServiceConfigData(data);
    }
  };

  const getBranchesByCity = (data: any = null) => {
    setBranches([]);
    setZones([]);
    setBranch({});
    setZone([]);
    setService([]);
    if (data?.uuid) {
      getBranch({ city_id: data?.uuid, status: true })
        .then((e) => {
          setBranches(e.data.result);
        })
        .catch((e) => {});
    }

    if (data && levelValidationShow.at(-1) == "city") {
      setServiceConfigs([]);
      getServiceConfigData(data);
    }
  };

  const getZonesByBranch = (data: any = null) => {
    setZones([]);
    setZone([]);
    setService([]);
    if (data?.uuid) {
      getZone({ branch_id: data?.uuid, status: true })
        .then((e) => {
          setZones(e.data.result);
        })
        .catch((e) => {});
    }
    if (data && levelValidationShow.at(-1) == "branch") {
      setServiceConfigs([]);
      getServiceConfigData(data);
    }
  };

  const getServiceConfigData = (data: any) => {
    dispatch(spinLoaderShow(true));
    getServiceConfig({
      level_id: data?.uuid,
      vehicle_type_id: vehicleType.uuid,
      status: true,
    })
      .then((e: any) => {
        setServiceConfigs(e?.data?.result);
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const handleZoneChange = (data: any) => {
    console.log("Data", data);

    setServiceConfigs([]);
    setService([]);
    if (data) {
      let founXone = zone;
      let foundItem = founXone.find((x: any) => x.uuid == data.data.uuid);
      console.log("HHHH1", foundItem);
      if (foundItem) {
        let foundItemIndex = founXone.findIndex(
          (x: any) => x.uuid == data.data.uuid
        );
        foundItem["checked"] = data.checked;
        founXone[foundItemIndex] = foundItem;
        console.log("HHHH2", founXone);
        if (!data.checked) {
          setValue(`data.${[foundItemIndex]}.checked`, false);
        } else {
          setValue(`data.${[foundItemIndex]}.checked`, true);
        }
      } else {
        data.data["checked"] = data.checked;
        founXone.push(data.data);
        console.log("HHHHX", founXone);
      }
      setZone(founXone);
    }
    // if (data && levelValidationShow.at(-1) == 'zone') {
    //   getServiceConfigData(data);

    // }
  };

  useEffect(() => {
    console.log("sds", errors);
  }, [errors]);

  const handleConfigLevel = (data: any) => {
    setCities([]);
    setBranches([]);
    setZones([]);
    setCity({});
    setBranch({});
    setZone([]);
    setCountry({});
    setServiceConfigs([]);
    setService([]);
    setLevelValidationShow(levelDependent[data?.name]);
  };

  const handleServiceChange = (data: any) => {
    setService(data);
  };

  const vehicleTypeChange = (data: any) => {
    if (data) {
      setVehicleType(data);
    } else {
      setVehicleType({});
    }
  };

  const onSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    data.data = data?.data
      ?.map((x: any, index: any) => {
        (x.driver_id = driverId?.find((x: any) => {
          return x?.uuid == uuid;
        })?.uuid),
          (x.serviceIds = x?.serviceIds?.map((serviceItem: any) => {
            return {
              uuid: serviceItem?.uuid,
              status: !x.checked ? false : true,
            };
          }));
        // console.log("serviceIds", x?.serviceIds)
        return x;
      })
      .filter(
        (x: any) =>
          x.serviceIds && x.serviceIds.length && x.serviceIds.length > 0
      );

    if (editData) {
      for (let index = 0; index < editData?.length; index++) {
        const element = editData[index];
        // console.log("element",element)
        let temp: any = data?.data?.find((x: any) => {
          return (
            x?.level_id == element?.level_id &&
            x?.serviceIds?.find((y: any) => {
              return y.uuid == element?.service_id;
            })
          );
        });
        if (!temp) {
          let Index = data?.data?.findIndex((x: any) => {
            return x?.level_id == element?.level_id;
          });
          console.log("Indexx", Index);
          data?.data[Index]?.serviceIds?.push({
            uuid: element?.service_id,
            status: false,
          });
        }
      }
    }

    delete data?.zone_id;
    delete data?.driver_id;
    // console.log("DDDDDDDDDD",data)
    // return data;
    createDriverConfig(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        reset(defaultFormValues);
        toast.success(CREATE_SUCCESS_MSG);
        history.push("/admin/driver-config");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <PageHeader
              title="Driver COnfig"
              routes={routes}
              newItem={[

                { text: 'Back', link: "/admin/driver-config" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  {" "}
                  Driver Config For Driver:{" "}
                  {uuid &&
                    driverId &&
                    driverId?.find((x: any) => {
                      return x?.uuid == uuid;
                    })?.name}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  {/* <GridItem container xs={12} sm={12} md={12} > */}
                  {/* <AutoCompleteMultipleField
                      errors={!!errors?.zone_id}
                      fieldName="zone_id"
                      autoComplete='off'
                      label="* Select Zone"
                      control={control}
                      setValue={setValue}
                      options={zones}
                      optionKey="uuid"
                      iProps={{
                        onChange: handleZoneChange,
                      }}
                      isLoading={true}
                      optionValue="name"
                      helperText={errors?.zone_id && errors?.zone_id?.message}
                      valueGot={zone}
                    /> */}
                  {zones &&
                    zones.length > 0 &&
                    zones.map((item: any, index: any) => {
                      return (
                        <GridItem md={3} container>
                          <Typography style={{marginTop:"9px"}}> {item.name}</Typography>
                          <Checkbox
                            checked={
                              zone &&
                              zone.find((x: any) => x.uuid == item.uuid) != null
                                ? zone.find((x: any) => x.uuid == item.uuid)
                                    .checked
                                : false
                            }
                            onChange={(event: any) => {
                              handleZoneChange({
                                data: item,
                                checked: event?.target?.checked,
                              });
                            }}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </GridItem>
                      );
                    })}
                  {/* </GridItem> */}
                  <GridItem xs={12} sm={12} md={6}>
                  <p></p>
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                            <AutoCompleteMultipleField
                              errors={!!errors?.services}
                              fieldName="services"
                              autoComplete='off'
                              label="* Select Service"
                              control={control}
                              setValue={setValue}
                              options={services}
                              optionKey="uuid"
                              iProps={{
                                onChange: handleServiceChange
                              }}
                              isLoading={true}
                              optionValue="name"
                              helperText={errors?.services && errors?.services?.message}
                              valueGot={service}
                            />
                          </GridItem> */}

                  {/* <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px", marginBottom: "30px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={12}>
                  <Divider />
                  </GridItem>
                  {zone &&
                    zone?.map((zoneItem: any, zoneIndex: any) => {
                      return (
                        <>
                          <GridItem
                            md={5}
                            sm={5}
                            xs={5}
                            style={{ marginTop: "25px" }}
                          >
                            <Typography variant="subtitle2">
                              {zoneItem?.name +
                                " " +
                                "|" +
                                " " +
                                zoneItem?.branch?.name +
                                " " +
                                "|" +
                                " " +
                                zoneItem.branch?.city?.city_name +
                                " " +
                                "|" +
                                zoneItem?.branch?.city?.country?.country_name}
                            </Typography>
                          </GridItem>
                          <InputField
                            errors={!!errors?.data?.[zoneIndex]?.level_id}
                            fieldName={`data.${[zoneIndex]}.level_id`}
                            type="hidden"
                            autoComplete="off"
                            label="* Buffer Time (Min)"
                            control={control}
                            valueGot={zoneItem?.uuid}
                            setValue={setValue}
                            helperText={
                              errors?.data?.[zoneIndex]?.level_id &&
                              errors?.data?.[zoneIndex]?.level_id?.message
                            }
                          />
                          <InputField
                            errors={false}
                            fieldName={`data.${[zoneIndex]}.checked`}
                            type="hidden"
                            autoComplete="off"
                            label="Checked"
                            control={control}
                            valueGot={zoneItem?.checked != "" ? zoneItem?.checked  : false }
                            setValue={setValue}
                            helperText={""}
                          />
                          <GridItem md={3} sm={3} xs={3}>
                            <InputField
                              errors={!!errors?.data?.[zoneIndex]?.buffer_time}
                              fieldName={`data.${[zoneIndex]}.buffer_time`}
                              type="text"
                              autoComplete="off"
                              label="* Buffer Time (Min)"
                              control={control}
                              valueGot={""}
                              setValue={setValue}
                              helperText={
                                errors?.data?.[zoneIndex]?.buffer_time &&
                                errors?.data?.[zoneIndex]?.buffer_time?.message
                              }
                              iProps={{ disabled: !zoneItem.checked }}
                            />
                          </GridItem>
                          <GridItem md={3} sm={3} xs={3}>
                            <AutoCompleteMultipleField
                              errors={!!errors?.data?.[zoneIndex]?.serviceIds}
                              fieldName={`data.${[zoneIndex]}.serviceIds`}
                              autoComplete="off"
                              label="* Select Service"
                              control={control}
                              setValue={setValue}
                              options={services}
                              optionKey="uuid"
                              iProps={{ disabled: !zoneItem.checked }}
                              isLoading={true}
                              optionValue="name"
                              valueGot={getValues(
                                `data.${[zoneIndex]}.serviceIds`
                              )}
                              helperText={
                                errors?.data?.[zoneIndex]?.serviceIds &&
                                errors?.data?.[zoneIndex]?.serviceIds?.message
                              }
                            />
                          </GridItem>
                        </>
                      );
                    })}

                  {/* <GridItem md={12} sm={12} xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={"custom-btn"}
                      onClick={() => {

                        append({


                        })
                        // let _id = getValues(`schedule`)?.length;
                        // console.log("_ddd",_id)
                        // setDateDisabled({ ...dateDisabled, ...{ [_id]: false } });

                      }}
                    >
                      Add more

                    </Button>
                  </GridItem> */}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    className={"custom-btn"}
                    type="submit"
                  >
                    {uuid ? "Update Driver Config" : "Create Driver Config"}
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

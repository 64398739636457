import React, { useState,useEffect } from 'react';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { FormControlLabel } from '@material-ui/core';
import { InputField, SwitchField, AutoCompleteField, SwitchFieldDefault } from 'components/Forms/Formfield.component'
import { useForm } from 'react-hook-form';
import { any } from 'prop-types';
import { Label } from '@material-ui/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { createVehicleTypeValidation } from './Validations/vehicleTypeValidation';
import { createVehicleType, updateVehicleType } from 'services/vehiclesServices'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from 'react-redux';
import { spinLoaderShow } from "redux/action/spinLoader";
import { useSelector } from 'react-redux'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import {Typography} from '@material-ui/core/'
import moment from 'moment'
export const TypePopup = (props: any) => {
  const defaultFormValues: any = {
    name: '',
    status: true,
  }
  const { control, handleSubmit, formState: { errors }, setValue, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createVehicleTypeValidation)
  })
  const { handleClose, open, title, editData, setOpen } = props
  const [disabled, setDisabled] = useState<any>(false)
  const dispatch = useDispatch()
  useEffect(() => {

    if (editData) {
      setValue("name", editData.name);
      setValue("status", editData.status);
      if (editData?.view){
        setDisabled(true)
      }
    } else {
      reset(defaultFormValues);
    }
  }, [editData ,title]);

  const onSubmit = async (data: any) => {
    dispatch(spinLoaderShow(true))
    let uuid: any = editData?.uuid;
    data.status = (data?.status == null || data?.status == true) ? true : false

    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    createVehicleType(data)
      .then((e: any) => {
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false))
      });
  };

  const updateOnSubmit = (data: any) => {
    updateVehicleType(data)
      .then((e: any) => {
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues);
        dispatch(spinLoaderShow(false))
        handleClose()
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false))
      });
  };

  useEffect(()=>{
   console.log("edit data",editData)
  },[])

  const spinLoaderShow_: any = useSelector((state: any) => state?.spinLoader?.spinLoaderShow)

  return (
    <form action="" onSubmit={handleSubmit(onSubmit)}>
      <Backdrop open={spinLoaderShow_}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogContent style={{ marginBottom: "15px" }}>
        <GridContainer>
          
          <GridItem  xs={12} md={12} sm={12}>
          {editData != null ?  <Typography>Modified At:  { moment(editData?.updatedAt).format('llll')} </Typography> : null}
          </GridItem>
          <GridItem xs={8} sm={8} md={8} >
            <InputField
              errors={!!errors?.name}
              fieldName="name"
              type="text"
              autoComplete="false"
              label="Name"
              control={control}
              valueGot={""}
              setValue={setValue}
              iProps={{
                disabled: disabled
              }}
              helperText={errors?.name && errors?.name?.message}
            />
          </GridItem>

          <GridItem xs={4} sm={4} md={4} style={{ marginTop: "15px" }}>
            <SwitchFieldDefault
              errors={!!errors?.status}
              fieldName="status"
              autoComplete="off"
              label="Status"
              defaultChecked="true"
              control={control}
              isLoading={true}
              setValue={setValue}
              disabled={disabled}
              helperText={
                errors?.status &&
                errors?.status?.message
              }
            />
          </GridItem>
        </GridContainer>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => { reset(defaultFormValues); handleClose() }} color="primary">
          Cancel
        </Button>
        <Button disabled={disabled} type="submit" color="primary">
          submit
        </Button>
      </DialogActions>
    </form>
  )
}
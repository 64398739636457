import React, { useState, useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import { getAlertSetting, updateApplicationSetting,updateAlertSetting } from "services/applicationSettingServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { toast } from "react-toastify";
import { verifyPermission } from 'helper/permission'
import { Box } from "@material-ui/core"
import Button from "components/CustomButtons/Button";
import { AutoCompleteField, InputField,SwitchFieldDefault } from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { getServices } from 'services/servicesServices'
import { Typography } from "@material-ui/core";






const styles: any = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {

            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
};

const useStyles = makeStyles(styles);



export const AlertForm = (props: any) => {
    const classes = useStyles();
    const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
    const [disabled, setDisabled] = useState<any>(false)
    const [collapse, setCollapse] = useState<any>(false)
    const [services, setServices] = useState<any>([]);
    const [alerts, setAlerts] = useState<any>()
    const [checked, setChecked] = useState<any>("");
    const dispatch = useDispatch()
    const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
    });


    useEffect(() => {

        if (props.services) { 
            setServices(props.services) 
        }
    }
    , [props.services])
    useEffect(() => {

        if (props.apidata) { 
            setAlerts(props.apidata) 
        }
    }
    , [props.apidata])





    const onSubmit = (data: any, key: any) => {
        if(data.service_id == "" || data.service_id == null){
            toast.error("Please Select Service")
            return;
        }
        dispatch(spinLoaderShow(true))

        updateAlertSetting(data).then(x =>{
            dispatch(spinLoaderShow(false))

            toast.success("Updated Successfully")
            props.refresh();
        }).catch(e =>{
            dispatch(spinLoaderShow(false))

            toast.error("Failed to update")
       
        })

    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <GridContainer>
                <GridItem md={2} className={"mt-15"}>
                    <Typography>
                        {alerts?.alert_name}
                    </Typography>
                    <InputField
                        
                        fieldName={"uuid"}
                        type="hidden"
                        autoComplete='off'
                        control={control}
                        valueGot={alerts?.uuid && alerts?.uuid}
                        setValue={setValue}
                        

                    />
                </GridItem>
                <GridItem md={3}>
                    <AutoCompleteField
                        errors={!!errors?.service_id}
                        fieldName={"service_id"}
                        autoComplete='off'
                        label="* Select Service"
                        control={control}
                        setValue={setValue}
                        isLoading={services?.length > 0 ? true : false}
                        options={services}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.service_id && errors?.service_id?.message}
                        valueGot={alerts && services.find(({ uuid }: any) => { return uuid == alerts?.service_id })}
                    />
                </GridItem>
                <GridItem md={3}>
                    <InputField
                        errors={!!errors?.send_before}
                        fieldName={"send_before"}
                        type="text"
                        autoComplete='off'
                        label={String(alerts?.alert_name.toLowerCase()).includes("battery-low") ? "* Percentage" : String(alerts?.alert_name.toLowerCase()).includes("tyre-pressure") ? "* PSI" :  "* Days"}
                        control={control}
                        valueGot={alerts?.send_before}
                        setValue={setValue}
                        helperText={errors?.send_before && errors?.send_before?.message}

                    />
                </GridItem>
                <GridItem md={2}>
                <SwitchFieldDefault
                errors={!!errors?.status}
                fieldName="status"
                autoComplete="off"
                label="Status"
                control={control}
                isLoading={true}
                setValue={setValue}
                helperText={
                  errors?.status &&
                  errors?.status?.message
                }
              />  
                </GridItem>
                <GridItem className="mt-10">
                    <Button color="primary" disabled={props?.permission} type="submit">
                        Update
                    </Button>
                </GridItem>
            </GridContainer>





        </form>

    );
}


import { min } from "lodash";
import * as yup from "yup";

export const FooterValidation = yup.object().shape({
  phone_number: yup
    .string()
    .required("please enter Phone number")
    .matches(
      /^(\s*|\d+)$/,
      "please enter number without using space & alphabets"
    )
    .min(7, "minimum number limit is 7")
    .max(11, "maximun number limit is 11")
    .nullable(),
  whatsapp_number: yup
    .string()
    .matches(
      /^(\s*|\d+)$/,
      "please enter number without using space & alphabets"
    )
    .required("please enter  Whatsapp number")
    .min(7, "minimum number limit is 7")
    .max(11, "maximun number limit is 11")
    .nullable(),
  Facebook_url: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .required("please enter  Url")
    .nullable(),
  twitter_url: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .required("please enter Url")
    .nullable(),
  Instagram_url: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .required("please enter  Url")
    .nullable(),
  playstore_link: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .required("Please enter Video url")
    .nullable(),
  appstore_link: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .required("Please enter Video url")
    .nullable(),
  huawei_link: yup
    .string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@=.?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "please enter correct Url"
    )
    .required("Please enter Video url")
    .nullable(),
});

import * as yup from 'yup'

const levelDependent: any = {
    'Country': ['country'],
    'City': ['country', 'city'],
    'Branch': ['country', 'city', 'branch'],
    'Zone': ['country', 'city', 'branch', 'zone'],
};

const createDriverValidation: any = yup.object().shape({

     first_name: yup.string().required("Please enter first name").nullable(),
    last_name: yup.string().required("Please enter last name").nullable(),
    email: yup.string().email().required("Please enter email").nullable(),
    mobile_no: yup.string().required("Please enter mobile number").nullable(),
    license_number: yup.string().required("Please enter license number").nullable(),
    license_expiry: yup.string().required("Please enter license expiry").nullable(),
    vendor_id:yup.string().required("Please select vendor id").nullable(),
    schedule_id:yup.string().required("Please enter schedule id").nullable(),
    country:yup.string().required("Please enter country").nullable(),
    city:yup.string().required("Please enter city").nullable(),
    


    // country_id: yup.string().when("level_id", {
    //     is: function (data: any) {
    //         if (data !== null) {
    //             return levelDependent[data.name].includes('country');
    //         }
    //         return false;
    //     },
    //     then: yup.string().required('Please select country').nullable()
    // }).nullable(),
    // city_id: yup.string().when("level_id", {
    //     is: function (data: any) {
    //         if (data !== null) {
    //             return levelDependent[data.name].includes('city');
    //         }
    //         return false;
    //     },
    //     then: yup.string().required('Please select city').nullable()
    // }).nullable(),
    // branch_id: yup.string().when("level_id", {
    //     is: function (data: any) {
    //         if (data !== null) {
    //             return levelDependent[data.name].includes('branch');
    //         }
    //         return false;
    //     },
    //     then: yup.string().required('Please select branch').nullable()
    // }).nullable(),
    // zone_id: yup.string().when("level_id", {
    //     is: function (data: any) {
    //         if (data !== null) {
    //             return levelDependent[data.name].includes('zone');
    //         }
    //         return false;
    //     },
    //     then: yup.string().required('Please select zone').nullable()
    // }).nullable(),
});



  export {
    createDriverValidation
  }
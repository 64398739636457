import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  AutoCompleteField,
  InputField,
  PhoneNoField,
  SwitchFieldDefault
} from "components/Forms/Formfield.component";
import { useEffect } from "react";
import Button from "components/CustomButtons/Button";

import { FormHelperText, Typography } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { createBranchValidation } from "./Validations/branchValidations";
import { Box } from "@material-ui/core";
import GoogleMap from "components/GoogleMap/GoogleMap";
import { getZone, createBranch, getCity, getSchedules, getBranch, updateBranch, getCountry } from "services/locationservices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { spinLoaderShow } from 'redux/action/spinLoader'
import { useDispatch } from "react-redux";
import DrawMap from 'components/GoogleMap/DrawMap';
import {
  BrowserRouter as Router,
  useHistory,
  useLocation
} from "react-router-dom";
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import moment from "moment"


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  name: '',
  polygon: {},
  city_id: '',
  schedule_id: null,
  country_id: '',
  status: true,
};

const useStyles = makeStyles(styles);
declare var google: any;


export default function AddBranch(props: any) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [searchLocation, setSearchLocation] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [headerTitle, setHeaderTitle] = React.useState<string>('Add');
  const [zone, setZone] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [schedule, setSchedule] = useState<any>([]);
  const [editData, setEditData] = useState<any>(null)
  const [resetMap, setResetMap] = useState<any>(false);
  const [mapValues, setMapValues] = useState<any>(null);
  const [countrycode, setCountryCode] = useState<any>("")
  const [city, setCity] = useState<any>({});
  const [disabled, setDisabled] = useState<any>(false)
  let history = useHistory();

  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createBranchValidation),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Branch ",
      link: "/admin/branch",
    },
    {
      'name': headerTitle,
      'link': ''
    },
  ];


  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true))
      getBranch({ uuid: uuid })
        .then((e) => {
          let data: any = e?.data?.result?.[0];
          setValue("name", data.name);
          // setValue("mobile_no", data.mobile_no);
          setValue("status", data.status);
          // setValue("email", data.email);
          setValue("schedule_id", data.schedule_id)
          setCity(data?.city)
          setMapValues(data.polygon)
          setEditData(data);
          let temp: any = new URLSearchParams(search).get("type");
          temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
          temp == 'view' ? setDisabled(true) : setDisabled(false)
          dispatch(spinLoaderShow(false))
        })

    }
    else {
      setHeaderTitle('Add')
      reset(defaultFormValues)
      setEditData([])

    }
  }, [uuid]);

  useEffect(() => {
    if (!!mapValues?.latLng && Object.keys(mapValues?.latLng)?.length > 0) {
      setValue("polygon", mapValues?.latLng);
    } else {
      setValue("polygon", {});
    }
    if (!!mapValues?.autocomplete) {
      setSearchLocation(mapValues?.autocomplete);
    }
  }, [mapValues]);

  useEffect(() => {
    getSchedules({ status: true, type: "Location" }).then((e) => {
      setSchedule(e.data.result)
    })
    getZone().then((e) => {
      setZone(e.data.result)
    }).catch((e) => {
    })
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((err) => {
    })
  }, [])



  const onSubmit = async (data: any) => {

    dispatch(spinLoaderShow(true));
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
  };

  const createOnSubmit = (data: any) => {
    createBranch(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        setResetMap(true);
        history.push("/admin/branch");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    updateBranch(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(UPDATE_SUCCESS_MSG);
        setResetMap(true);
        history.push("/admin/branch");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const getCitiesByCountry = (data: any) => {
    setCities([])
    setCity({})
    if (data?.uuid) {
      dispatch(spinLoaderShow(true))
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
          dispatch(spinLoaderShow(false))
        }).catch((err)=>{
          dispatch(spinLoaderShow(false))
        })
      setCountryCode(data?.country_code)
    }
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Branch "
              routes={routes}
              newItem={[
                { text: 'Back', link: "/admin/branch" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Branch</h4>
            {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null }      
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={5}>

                  <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete="off"
                      label="* Branch Name"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.name && errors?.name?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.country_id}
                      fieldName="country_id"
                      autoComplete='off'
                      label="* Select Country"
                      control={control}
                      setValue={setValue}
                      options={countries}
                      isLoading={countries?.length > 0 ? true : false}
                      optionKey="uuid"
                      iProps={{
                        onChange: getCitiesByCountry,
                        disabled: disabled
                      }}
                      optionValue="country_name"
                      helperText={errors?.country_id && errors?.country_id?.message}
                      valueGot={editData?.city?.country}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.city_id}
                      fieldName="city_id"
                      autoComplete='off'
                      label="* Select City"
                      control={control}
                      setValue={setValue}
                      options={cities}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="city_name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={errors?.city_id && errors?.city_id?.message}
                      valueGot={city}
                    />
                  </GridItem>

                  {/* <GridItem xs={4} sm={4} md={4}>
                    <PhoneNoField
                      errors={!!errors?.mobile_no}
                      fieldName="mobile_no"
                      autoComplete="off"
                      label="* Mobile No"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.mobile_no && errors?.mobile_no?.message
                      }
                      countrycode={countrycode}
                    />
                  </GridItem> */}
                  {/* <GridItem xs={4} sm={4} md={4}>
                    <InputField
                      errors={!!errors?.email}
                      fieldName="email"
                      type="text"
                      autoComplete="false"
                      label="* Email Address"
                      control={control}
                      valueGot={""}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={
                        errors?.email && errors?.email?.message
                      }
                    />
                  </GridItem> */}

                  <GridItem xs={4} sm={4} md={4} >
                    <AutoCompleteField
                      errors={!!errors?.schedule_id}
                      fieldName="schedule_id"
                      autoComplete='off'
                      label="Select Schedule"
                      control={control}
                      setValue={setValue}
                      options={schedule}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={errors?.schedule_id && errors?.schedule_id?.message}
                      valueGot={editData?.schedule}
                      isLoading={schedule?.length > 0 ? true : false}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>

                </GridContainer>
                <Box mt={2}>
                  <GridContainer mt={2}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography variant={"subtitle1"}>
                        Draw area: <span className="google-map-error-text">{errors.polygon && errors?.polygon?.latLng?.message}</span>
                      </Typography>
                      {editData &&
                        <FormHelperText error={errors.polygon && true} component="div" className="google-map-error">
                          <GoogleMap
                            value={setMapValues}
                            setValue={{
                              latLng: editData?.polygon
                            }}
                            autocomplete
                            polygon={disabled ? false : true}
                            reset={resetMap}
                            zoom={editData?.polygon?.zoom ? editData?.polygon?.zoom : 11}
                            center={editData?.polygon?.center ? editData?.polygon?.center : {
                              lat: 25.276987,
                              lng: 55.296249
                            }}
                          >
                            <DrawMap data={editData} />
                          </GoogleMap>
                        </FormHelperText>
                      }
                    </GridItem>
                  </GridContainer>
                </Box>
              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} className={"custom-btn"} type="submit" >{uuid ? 'Update Branch' : "Create Branch"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

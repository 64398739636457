import React, { useState, useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import PageHeader from "components/PageHeader/PageHeader";
import { useForm } from "react-hook-form";
import { InputField,TextEditorField,AutoCompleteField,AutoCompleteMultipleField, DateField, SwitchField, SwitchFieldDefault} from "components/Forms/Formfield.component";
import { Box, FormControlLabel } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPushNotificationValidation } from "./Validations/pushNotValidations";
import AgGridCustomer from "components/AgGridCustom/AgGridCustomer";
import StatusComponent from "components/Mix/Status";
import { getNotification, createNotificationBulk, createNotificationToAll,} from 'services/notificationServices'
import {getCity,getBranch,getZone,getCountry,getLevels} from 'services/locationservices'
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import moment from "moment";
import {useLocation} from 'react-router-dom'


const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const defautlFormValues = {
  lastavaildate_from:'',
  lastavaildate_to:''
};

const levelDependent: any = {
  'Country': ['country'],
  'City': ['country', 'city'],
  'Branch': ['country', 'city', 'branch'],
  'Zone': ['country', 'city', 'branch', 'zone'],
};
const useStyles = makeStyles(styles);

export const PushNotification = (props: any) => {
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [exportData, setExportData] = useState<any>(null)
  const [customers, setCustomers] = useState<any>([])
  const [customer,setCustomer]=useState<any>([])
  const [levels, setLevels] = useState<any>([])
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [branch, setBranch] = useState<any>('');
  const [city, setCity] = useState<any>('');
  const [zone, setZone] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [level, setLevel] = useState<any>({});
  const [levelName, setLevelName] = useState<any>(null);
  const [levelTypee, setlevelTypee] = useState<any>(null);
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);
  const [levelId , setLevelId]=useState<any>(null)
  const [lastAvailDateFrom , setlastAvailDateFrom]=useState<any>(moment(new Date).format('YYYY-MM-DD'))
  const [lastAvailDateTo , setlastAvailDateTo]=useState<any>(moment(new Date).format('YYYY-MM-DD'))
  const [hideGrid , setHideGrid]=useState<any>(false)

  // const [levelId , setLevelId]=useState<any>(null)

  const { search } = useLocation()
  let customerId = new URLSearchParams(search).get("customer_id");
  const dispatch=useDispatch()
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    getValues,
    formState: { errors },
    setValue,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defautlFormValues,
    resolver: yupResolver(createPushNotificationValidation)
  });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Push Notification",
      link: "",
    },
  ];
  const columnDefs: any = [
   
    // {
    //   headerName: 'checked',
    //   field: "checked",
    //   cellRendererFramework: (params: any) =>{
    //     // put the value in bold
    //     return  <CheckboxField
    //     errors={!!errors?.checked}
    //     control={control}
    //     setValue={setValue}
    //     helperText={errors?.checked && errors?.checked?.message}
    //     fieldName={params.uuid}
    //     valueGot={params?.status}
    //     checked={params?.uuid}
    //     onChange={()=>{ checkedChange}}
    // />;
    //   }
    // },
    {
      headerName:  " First Name",
      field: "first_name",  
    },
    {
      headerName:  "Last Name",
      field: "last_name",
      
    },
   
   
    ,
    // {
    //   headerName: "Email",
    //   field: "email"
    // }
    // ,
    {
      headerName: "Phone number",
      field: "phone_number"
    },
    {
      headerName: "Date of Birth",
      field: "date_of_birth",
      valueGetter: (params: any) => {
        return params?.data?.date_of_birth;
      },
    },
    {
      headerName: 'Notification Enabled',
      field: "is_notification_enabled",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },

    
  ];


  // useEffect(() => {

  //   if(customerId){
  //     console.log("true....")
  //     getCustomerData({customer_id: customerId})
  //   }
  // }
  // ,[customerId])
 
  
  const getCustomerData = (data={}) =>{
    dispatch(spinLoaderShow(true))
    getNotification(data).then((e) => {
      let data:any = e?.data?.result
      // data = data.map((item:any) =>{
      //   return{
      //     uuid:item.uuid,
      //       first_name:item.first_name,
      //       last_name:item.last_name,
      //       date_of_birth:item.date_of_birth,
      //       is_notification_enabled:item.is_notification_enabled,
      //   }
      // })
      console.log("getNot",data)
      setCustomers(data)
      dispatch(spinLoaderShow(false))
    }).catch((e) => {
      dispatch(spinLoaderShow(false))
    })
  }

  const handleCustomerChange = (data:any)=>{
    console.log("customer data",data)
  }

  useEffect(()=>{
    if(customerId){
      getCustomerData({uuid:customerId})
    } else {
      getCustomerData()
    }
    //  
    //  getNotification()
     getLevels().then((e) => {
      setLevels(e.data.result)
    }).catch((e) => {
    })
    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {
    })
  },[])
  const handleConfigLevel = (data: any) => {
    setCities([])
    setBranches([])
    setZones([])
    setCity({})
    setBranch({})
    setZone({})
    setCountry({})
    setLevel(data)
    setLevelValidationShow(levelDependent[data?.name])
  }

  const getCitiesByCountry = (data: any = null) => {
    setCities([])
    setBranches([])
    setZones([])
    setCity({})
    setBranch({})
    setZone({})
    
    if (data?.uuid) {
          setCountry(data)
      dispatch(spinLoaderShow(true))
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result)
          dispatch(spinLoaderShow(false))
        }).catch((err)=>{
          dispatch(spinLoaderShow(false))
        })
    }
    if (data && levelValidationShow.at(-1) == 'country') {
      setLevelName(data.country_name)
      setlevelTypee("Country")
      setLevelId(data.uuid)
    }
  }

  const getBranchesByCity = (data: any = null) => {
    setBranches([])
    setZones([])
    setBranch({})
    setZone({})

    if (data?.uuid) {
      dispatch(spinLoaderShow(true))
      getBranch({ city_id: data?.uuid, status: true }).then((e) => {
        setBranches(e.data.result)
        dispatch(spinLoaderShow(false))
      }).catch((e) => {
        dispatch(spinLoaderShow(false))

      })
    }
    if (data && levelValidationShow.at(-1) == 'city') {
      setLevelName(data.city_name)
      setlevelTypee("City")
      setLevelId(data.uuid)
    }

  }

  const getZonesByBranch = (data: any = null) => {
    setZones([])
    setZone({})
    if (data?.uuid) {
      dispatch(spinLoaderShow(true))

      getZone({ branch_id: data?.uuid, status: true }).then((e) => {
        setZones(e.data.result)
        dispatch(spinLoaderShow(false))

      }).catch((e) => {
        dispatch(spinLoaderShow(false))

      })
    }
    if (data && levelValidationShow.at(-1) == 'branch') {
      setLevelName(data.name)
      setlevelTypee("Branch")
      setLevelId(data.uuid)
    }

  }

  const handleZoneChange = (data: any) => {

    if (data && levelValidationShow.at(-1) == 'zone') {
      setLevelName(data.name)
      setlevelTypee("Zone")

      setLevelId(data.uuid)
    }
  }

  const onSubmit =  (data: any) => {

    if(data.send_to_all){


      let notificationData:any = {}
  
      notificationData.type = "General";
      if(levelId){
        notificationData.level_name = levelName;
        notificationData.level_type = levelTypee;
        notificationData.level_id = levelId;
      }
     
    
      
      notificationData.notification = {
          "title":data?.title,
          "body":data?.body,
          "type":"General"
        }
        
        notificationData.data = {
          "title":data?.title,
          "body":data?.body,
          "type":"General"
        }
      

        createNotificationToAll(notificationData)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success("Notification has been sent Successfully.");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });


      return;
    }

    data.customer_id =  exportData.getSelectedRows().map((item:any)=>{
      return item.uuid
    })


    // customers.filter((x: { uuid: any; })=> data.customer_id.includes(x.uuid))

    // console.log("xx", customers.filter((x: { uuid: any; })=> data.customer_id.includes(x.uuid)).map((item:any)=>{
    //   return item.notification_token
    // }))
    // console.log("data",data)

  //  data.customers_id= data?.customers_id?.map((item:any)=>{
  //  return item.uuid
  //   })

  let notificationData:any = {}
  
  notificationData.type = "General";
  if(levelId){
    notificationData.level_name = levelName;
    notificationData.level_type = levelTypee;
    notificationData.level_id = levelId;
  }
 

  notificationData.device_token = customers.filter((x: { uuid: any; })=> data.customer_id.includes(x.uuid)).map((item:any)=>{
    return item.notification_token
  })
  notificationData.notification = {
      "title":data?.title,
      "body":data?.body,
      "type":"General"
    }
    notificationData.customer_id = customers.filter((x: { uuid: any; })=> data.customer_id.includes(x.uuid)).map((item:any)=>{
      return item.uuid
    })
    notificationData.data = {
      "title":data?.title,
      "body":data?.body,
      "type":"General"
    }
  

    if(data){
      dispatch(spinLoaderShow(true))
      createNotificationBulk(notificationData)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success("Notification has been sent Successfully.");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };
    }

  useEffect(() => {
    if(levelId){
      getCustomerData({level_id:levelId})
    }
  },[levelId])

const filterByLastAvail = () =>{
  let lastavaildate_from =moment(lastAvailDateFrom).format('YYYY-MM-DD')
  let lastavaildate_to = moment(lastAvailDateTo).format('YYYY-MM-DD')
  console.log("lastavaildate_from lastavaildate_from",lastavaildate_from,lastavaildate_to)
  if (moment(lastavaildate_to).isAfter(lastavaildate_from,'days')){
      let temp= {
        "lastavaildate_from":lastavaildate_from,
        "lastavaildate_to":lastavaildate_to
      }
      if(levelId){
      
        temp = Object.assign({}, temp,{level_id:levelId})
      }
      getCustomerData(temp)
      
  } else {
    toast.error("last availdate to should be after last availdate from")
  }
}

const LastAvailFromeExpiryChange = (data:any) =>{
  setlastAvailDateFrom(data)
}

const LastAvailToExpiryChange = (data:any) =>{
  setlastAvailDateTo(data);
}

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader title="Push Notification" routes={routes}
           newItem={[
            { text: "Back", link: `/admin/push-notification` },
          ]}
          backIcon
           />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Push Notification</h4>
            {/* <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button> */}

          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <GridContainer>
              <GridItem xs={12}md={12} sm={12}>
              <InputField
                              errors={!!errors?.title}
                              fieldName="title"
                              type="text"
                              autoComplete="false"
                              label="* Title"
                              control={control}
                              isLoading={true}
                              valueGot={""}
                              setValue={setValue}
                              helperText={errors?.title && errors?.title?.message}

                            />

              </GridItem>
              {/* <GridItem xs={12} sm={12} md={4}>
                            <AutoCompleteMultipleField
                              errors={!!errors?.customer_id}
                              fieldName="customers_id"
                              autoComplete='off'
                              label="* Select Customers"
                              control={control}
                              setValue={setValue}
                              options={customers}
                              optionKey="uuid"
                              iProps={{
                                onChange: handleCustomerChange
                              }}
                              isLoading={true}
                              optionValue="first_name"
                              helperText={errors?.customer_id && errors?.customer_id?.message}
                              valueGot={customer}
                            />
                          </GridItem> */}
                          
              <GridItem xs={12} sm={12} md={12} >
               <InputField
                errors={!!errors?.body}
                fieldName="body"
                multiline={true}
                rows={7}
                type="text"
                autoComplete="false"
                label="body"
                control={control}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.body && errors?.body?.message}
                
              />
              </GridItem>
              <GridItem xs={12} md={12} sm={12}>
                {/* <TextEditorField
                  className={"mt-15"}
                  errors={!!errors?.body}
                  textareaName="body"
                  fieldName="body"
                  type="text"
                  autoComplete="false"
                  label="Body"
                  control={control}
                  isLoading={true}
                  valueGot={''}
                  setValue={setValue}
                  helperText={errors?.body && errors?.body?.message}
                /> */}
                <Box
                  m={1}
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  
                </Box>
              </GridItem>

              <GridItem xs={12} md={12} sm={12}>

                    <SwitchFieldDefault

                    fieldName="send_to_all"
                    autoComplete="off"
                    label="Send To All"
                    control={control}
                    isLoading={true}
                    defaultChecked={false}
                    setValue={setValue}
                    iProps={
                      {
                        onChange:(e:any)=>{
                          console.log("Sada",e)
                          setHideGrid(e)
                        }
                      }
                    }
                    />
                </GridItem>
               {!hideGrid && <>
              <GridItem xs={12} sm={12} md={3}>
                    <AutoCompleteField
                      errors={!!errors?.level_id}
                      fieldName="level_id"
                      autoComplete='off'
                      label="* Select Level"
                      control={control}
                      setValue={setValue}
                      options={levels}
                      optionKey="id"
                      returnObject={true}
                      iProps={{
                        onChange: handleConfigLevel,
                       
                      }}
                      isLoading={levels?.length > 0 ? true : false}
                      optionValue="name"
                      helperText={errors?.level_id && errors?.level_id?.message}
                      valueGot={level}
                    />
                  </GridItem>

                  {levelValidationShow?.includes('country') &&
                    <GridItem xs={12} sm={12} md={3} >
                      <AutoCompleteField
                        errors={!!errors?.country_id}
                        fieldName="country_id"
                        autoComplete='off'
                        label="* Select Country"
                        control={control}
                        setValue={setValue}
                        options={countries}
                        isLoading={countries?.length > 0 ? true : false}
                        optionKey="uuid"
                        iProps={{
                          onChange: getCitiesByCountry,
                        }}
                        optionValue="country_name"
                        helperText={errors?.country_id && errors?.country_id?.message}
                        valueGot={country}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('city') &&
                    <GridItem xs={12} sm={12} md={3} >
                      <AutoCompleteField
                        errors={!!errors?.city_id}
                        fieldName="city_id"
                        autoComplete='off'
                        label="* Select City"
                        control={control}
                        setValue={setValue}
                        options={cities}
                        isLoading={true}
                        optionKey="uuid"
                        iProps={{
                          onChange: getBranchesByCity,
                        }}
                        optionValue="city_name"
                        helperText={errors?.city_id && errors?.city_id?.message}
                        valueGot={city}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('branch') &&
                    <GridItem xs={12} sm={12} md={3} >
                      <AutoCompleteField
                        errors={!!errors?.branch_id}
                        fieldName="branch_id"
                        autoComplete='off'
                        label="* Select Branch"
                        iProps={{
                          onChange: getZonesByBranch,
                        }}
                        control={control}
                        setValue={setValue}
                        options={branches}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.branch_id && errors?.branch_id?.message}
                        valueGot={branch}
                        isLoading={true}
                      />
                    </GridItem>
                  }
                  {levelValidationShow?.includes('zone') &&
                    <GridItem xs={12} sm={12} md={3} >
                      <AutoCompleteField
                        errors={!!errors?.zone_id}
                        fieldName="zone_id"
                        autoComplete='off'
                        label="* Select Zone"
                        control={control}
                        setValue={setValue}
                        options={zones}
                        iProps={{
                          onChange:handleZoneChange
                        }}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.zone_id && errors?.zone_id?.message}
                        valueGot={zone}
                        isLoading={true}
                      />
                    </GridItem>
                  }
                   <GridItem xs={2} md={3} sm={3}>
                  <DateField
                    errors={!!errors?.lastavaildate_from}
                    fieldName="lastavaildate_from"
                    autoComplete='off'
                    label=" Last Avail Date From"
                    iProps={{
                      format: 'dd-MM-yyyy',
                      onChange:LastAvailFromeExpiryChange
                    }}
                    min
                    setValue={setValue}
                    control={control}
                    inputVariant="outlined"
                    helperText={errors?.lastavaildate_from && errors?.lastavaildate_from?.message}
                    valueGot={lastAvailDateFrom}
                  />
                </GridItem>
                <GridItem xs={2} md={3} sm={3}>
                  <DateField
                    errors={!!errors?.lastavaildate_to}
                    fieldName="lastavaildate_to"
                    autoComplete='off'
                    label="Last Avail Date To"
                    setValue={setValue}
                    iProps={{
                      format: 'dd-MM-yyyy',
                      onChange:LastAvailToExpiryChange

                    }}
                    control={control}
                    inputVariant="outlined"
                    helperText={errors?.lastavaildate_to && errors?.lastavaildate_to?.message}
                    valueGot={lastAvailDateTo}
                  />
                </GridItem>
                <GridItem xs={2} md={2} sm={1}>

                  <Button color="primary" className={"mt-15"} onClick={(e: any) => { filterByLastAvail() }}>Filter</Button>
                </GridItem>
                <GridItem xs={2} md={2} sm={1}>

                  <Button color="primary" className={"mt-15"} onClick={(e: any) => { getCustomerData() }}>Clear</Button>
                </GridItem>

                </>}
              </GridContainer>

           
             <AgGridCustomer
              columnDefs={columnDefs}
              rowData={customers}
              getRowNodeId={function (data: any) {
                return data.uuid;
              }}
              setExportData={setExportData}  
              hideGrid={hideGrid}
            />
             </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { DateField, AutoCompleteField } from "components/Forms/Formfield.component"
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { getBooking, getCustomer } from "services/userservices";
import { getCity, getBranch, getZone, getLevels, getCountry } from 'services/locationservices'
import { Box } from '@material-ui/core';
import Button from "components/CustomButtons/Button";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { getVendor } from "services/vendorServices";
import { getBookingsReports, getOldCustomerReports, getNewCustomerReports } from 'services/reportsServices'
import {  CSVLink } from "react-csv";

export const CustomerReports = () => {
  const downloadRef:any = useRef();
  const downloadRefNew:any = useRef();

  const [bookings, setBookings] = useState<any>(null)
  const [oldcustomer, setOldCustomer] = useState<any>(null)
  const [newcustomer, setNewCustomer] = useState<any>(null)
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);

  const dispatch = useDispatch()
  const defaultFormValues: any = {
    booking_from: '',
    booking_to: '',
    vendor_id: ''
  }

  const levelDependent: any = {
    'Country': ['country'],
    'City': ['country', 'city'],
    'Branch': ['country', 'city', 'branch'],
    'Zone': ['country', 'city', 'branch', 'zone'],
  };
  const { control, handleSubmit, formState: { errors }, setValue, reset, register, getValues, watch } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  });



  const getBookingsData = () => {
    getBooking().then((res: any) => {
      let data: any = res?.data?.result
      setBookings(data)

    }).catch((err: any) => {
      console.log(err)
    })
  }

  const getCustomerData = () => {
    getCustomer().then((res: any) => {
      let data: any = res?.data?.result
      setOldCustomer(data)
      setNewCustomer(data)

    }).catch((err: any) => {
      console.log(err)
    })
  }

  //     const ExportBookings = (data: any) => {
  //   console.log("data...", bookings)

  //   let temp:any =[]

  //   bookings?.map((item: any) => {
  //      item.booking_details = item.booking_details?.filter((x: any) => {
  //       return moment(x.bookingAt, 'YYYY-MM-DD').isAfter(moment(data?.booking_from),"day") && moment(x.bookingAt, 'YYYY-MM-DD').isBefore(moment(data?.booking_to),"day")
  //     })

  //    if (item.booking_details.length >0){
  //      temp.push(item)
  //    }
  //   })
  //     console.log("temp",temp)
  //   getBookingsData()


  // }

  
useEffect(() => {
    if (oldcustomer != null && oldcustomer?.length > 0) {
      downloadRef?.current?.link?.click();   
    }
  }, [oldcustomer]);

    
useEffect(() => {
  if (newcustomer != null && newcustomer?.length > 0) {
    downloadRefNew?.current?.link?.click();   
  }
}, [newcustomer]);


  const onSubmit = (data: any) => {
    let format = 'YYYY-MM-DD'
    // data.booking_from = moment(data.booking_from).format(format)
    // data.booking_to = moment(data.booking_to).format(format)
    // console.log("data....", data)

    dispatch(spinLoaderShow(true))
    if (data == "old") {
      getOldCustomerReports().then((res: any) => {

        if (res?.data?.result?.length > 0) {
          let data = res?.data?.result.map((item:any) =>{
            delete item.gender;
            return item;
          })
          setOldCustomer(data);
          dispatch(spinLoaderShow(false))
          toast.success("Downloading Record");

        }else{
          dispatch(spinLoaderShow(false));
          toast.error("No Record Found");
        }

      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        toast.error("No Record Found");

      })
    }else{
      getNewCustomerReports().then((res: any) => {

        if (res?.data?.result?.length > 0) {
          let data = res?.data?.result.map((item:any) =>{
            delete item.gender;
            return item;
          })

          setNewCustomer(data);
          dispatch(spinLoaderShow(false))
          toast.success("Downloading Record");

        }else{
          dispatch(spinLoaderShow(false));
          toast.error("No Record Found");
        }

      }).catch((err) => {
        dispatch(spinLoaderShow(false))
        toast.error("No Record Found");

      })
    }

  }

  return (

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Customer Reports</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <GridContainer
  
  direction="column"
  justifyContent="flex-start"
  alignItems="flex-start"
>
          

            {/* Old Customer */}
            <GridItem xs={12} sm={12} md={3} >
              <Box style={{ marginTop: "11px" }}>
                <Button color="primary" type="button" onClick={()=>{onSubmit("new")}}>New Customer Reports</Button>
                {newcustomer != null ? <CSVLink
                  filename={"NewCustomer" + moment().format('YYYY-MM-DD')+ '.csv'}
                  ref={downloadRefNew}
                  data={newcustomer}
                /> : null}
              </Box>

            </GridItem>

            {/* New Customer */}
            <GridItem xs={12} sm={12} md={3} >
              <Box style={{ marginTop: "11px" }}>
                <Button color="primary" type="button" onClick={()=>{onSubmit("old")}}>Old Customer Reports</Button>
                {oldcustomer != null ? <CSVLink
                  filename={"OldCustomer" + moment().format('YYYY-MM-DD')+ '.csv'}
                  ref={downloadRef}
                  data={oldcustomer}
                /> : null}
              </Box>

            </GridItem>

          </GridContainer>
        </AccordionDetails>
      </Accordion>

  )

}
import httpservice from "./httpservice";

export function getPackages(body: any = null) {
    return httpservice.get("/packages", { params: body })
  }
  export function getViewPackages(body: any = null) {
    return httpservice.get("/packages/view", { params: body })
  }
  export function createPackages(body: any) {
    return httpservice.post("/packages", body)
  }
  export function updatePackages(body: any) {
    return httpservice.put("/packages", body)
  }
  export function createPackageConfig(body: any) {
    return httpservice.post("/packages/configs", body)
  }
  export function updatePackageConfig(body: any) {
    return httpservice.put("/packages/configs", body)
  }
  export function getPackageConfigs(body: any = null) {
    return httpservice.get("/packages/configs", { params: body })
  }

  export const  packagesFileUpload= (files:any)=> {
    return httpservice.post('/upload/packages', files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
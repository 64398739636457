import * as yup  from 'yup'

const levelDependent: any = {
    'Country': ['country'],
    'City': ['country', 'city'],
    'Branch': ['country', 'city', 'branch'],
    'Zone': ['country', 'city', 'branch', 'zone'],
};


// const createDriverConfigValidation:any = yup.object().shape({
//     driver_id:yup.string().required("Please Select Driver Id").nullable(),
//     level_id: yup.object().required("Please select level").nullable(),
//     services: yup.array().min(1).required("Please select services"),

//     country_id: yup.string().when("level_id", {
//         is: function (data: any) {

//             if (data !== null && data.name) {
                
//                 return levelDependent[data.name].includes('country');
//             }
//             return false;
//         },
//         then: yup.string().required('Please select country').nullable()
//     }).nullable(),
//     city_id: yup.string().when("level_id", {
//         is: function (data: any) {
//             if (data !== null && data.name) {
//                 return levelDependent[data.name].includes('city');
//             }
//             return false;
//         },
//         then: yup.string().required('Please select city').nullable()
//     }).nullable(),
//     branch_id: yup.string().when("level_id", {
//         is: function (data: any) {
//             if (data !== null && data.name) {
//                 return levelDependent[data.name].includes('branch');
//             }
//             return false;
//         },
//         then: yup.string().required('Please select branch').nullable()
//     }).nullable(),
//     zone_id: yup.string().when("level_id", {
//         is: function (data: any) {
//             if (data !== null && data.name) {
//                 return levelDependent[data.name].includes('zone');
//             }
//             return false;
//         },
//         then: yup.string().required('Please select zone').nullable()
//     }).nullable(),
// })

// export {
//     createDriverConfigValidation
// }

const driverConfigValidation:any = yup.object().shape({
    // driver_id:yup.string().required("Please Select Driver").nullable(),
    // zone_id: yup.array().min(1,"minimum one zone is required").required("Please select zone").nullable(),
    data:yup.array().of(yup.object().shape({
        checked:yup.mixed(),
        buffer_time: yup.mixed().when("checked", {
            is: true,
            then: yup.number().min(1).typeError("buffer time should be only number").required("Please enter buffer time").nullable(),
            otherwise: yup.string().nullable(),

        }),
        serviceIds: yup.array().when("checked", {
            is: (checked:any)=>{console.log(checked); return checked},
            then: yup.array().min(1,"Please Select One").required("Please Select One").nullable(),
            otherwise: yup.array().nullable()

        }),
        // buffer_time:yup.number().min(1).typeError("buffer time should be only number").required("Please enter buffer time").nullable(),
        // serviceIds:yup.array().nullable()
    }))
})

export {
    driverConfigValidation
}
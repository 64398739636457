import React,{useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from "react-hook-form";
import { InputField, LoadingButton, SwitchField,TextEditorField } from "components/Forms/Formfield.component";
import { Box} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { createEmailTemplateValidation } from "./Validations/createEmailValidation";
import { Typography } from "@material-ui/core";


const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};


const defautlFormValues = {
title:null,
subject:null,
description:null,
status:true
}


const useStyles = makeStyles(styles);


export default function EmailEdit(props: any) {
  const classes = useStyles();
  const [initialValue, setInitialValue] = useState('');
  
  
  useEffect(()=>{
    
   setInitialValue(`<!doctype html>
   <html>
   <head>
   <meta charset="utf-8">
   <title>Registration Code</title>
   <style>
   body {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
   }
   </style>
   </head>
   
   
   
   <body>
   <table width="660" border="0" cellspacing="0" cellpadding="0" align="center">
   <tbody>
   <tr>
   <td>
   <table width="660" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td style="height: 29px; line-height: 9px"></td>
   </tr>
   <tr>
   <td>
   <a href="https://www.jino.ae/" target="_blank"><img src="https://jinodev.futuristic.agency/jinologo.png" alt="" border="0" height="60px" ></a>
   </td>
   </tr>
   <tr>
   <td style="height: 29px; line-height: 9px; border-bottom:1px solid #bfbfbf"></td>
   </tr>
   <tr>
   <td style="height: 18px; line-height: 9px"></td>
   </tr>
   <tr>
   <td style=" font-family:Arial; font-size: 22px; color: #5ca4a9; font-weight: bold; text-align: center" align="center">Verification Email</td>
   </tr>
   <tr>
   <td style="height: 13px; line-height: 9px"></td>
   </tr>
   <tr>
   <td style=" font-family:Arial;font-size: 14px; font-weight: bold; color: #000000; text-align: left" align="left">Hey [Username],</td>
   </tr>
   <tr>
   <td style="height: 13px; line-height: 9px"></td>
   </tr>
   <tr>
   <td style=" font-family:Arial; font-size: 13px; font-weight: normal; color: #808285; text-align: left" align="left">Account craeted successfully, Please <a href="[link]">click here</a> to complete the process.</td>
   </tr>
   <tr>
   <td style="height: 20px; line-height: 9px;"></td>
   </tr>
   <tr>
   <td style=" font-family:Arial; font-size: 13px; font-weight: normal; color: #808285; text-align: left" align="left">If you are unable to click on the link, please copy the address and visit the link below:</td>
   </tr>
   <tr>
   <td style="height: 20px; line-height: 9px;"></td>
   </tr>
   <tr>
   <td style=" font-family:Arial; font-size: 13px; font-weight: normal; color: #808285; text-align: left" align="left">[link]</td>
   </tr>
   
   <tr>
   <td style="height: 48px; line-height: 9px;"></td>
   </tr>
   <tr>
   <td style="border-bottom:1px solid #bfbfbf; border-top:1px solid #bfbfbf;">
   <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td style="height: 25px; line-height: 9px"></td>
   </tr>
   <tr>
   <td>
   <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td style=" font-family:Arial;font-size: 13px; font-weight: normal; color: #858585; text-align: center">If you have any query regarding your prescription detail,</td>
   </tr>
   <tr>
   <td style=" font-family: Arial;font-size: 13px; font-weight: normal; color: #858585; text-align: center">please contact us <a href="mailto:hello@jino.ae" target="_blank" style="color: #18428e; text-decoration: none">hello@jino.ae</a> or visit our website <a href="http://www.jino.ae"
   target="_blank" style="color: #18428e; text-decoration: none">www.jino.ae
   </tr>
   </tbody>
   </table>
   </td>
   </tr>
   <tr>
   <td style="height: 25px; line-height: 9px" </td>
   </tr>
   </tbody>
   </table>
   </td>
   </tr>
   <tr>
   <td>
   <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td>
   <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td colspan="3" style="height: 25px; line-height: 9px"></td>
   </tr>
   <tr>
   <td style="text-align: left; width: 50%">
   <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td style="height: 20px; width: 20px"><a href="https://www.facebook.com/" target="_blank"><img src="https://picpaxapp.futuristic.agency/emailers/images/facebook.png" alt="" border="0" style="height: 20px; width: 20px"></a></td>
   <td style="height: 20px; width: 20px"></td>
   <td style="height: 20px; width: 20px"><a href="https://www.linkedin.com/" target="_blank"><img src="https://picpaxapp.futuristic.agency/emailers/images/linkedin.png" alt="" border="0" style="height: 20px; width: 20px"></a></td>
   <td style="height: 20px; width: 20px"></td>
   <td style="height: 20px; width: 20px"><a href="https://www.instagram.com/" target="_blank"><img src="https://picpaxapp.futuristic.agency/emailers/images/instagram.png" alt="" border="0" style="height: 20px; width: 20px"></a></td>
   <td style="height: 20px; width: 20px"></td>
   <td style="height: 20px; width: 30px"><a href="https://twitter.com/" target="_blank"><img src="https://picpaxapp.futuristic.agency/emailers/images/twitter.png" alt="" border="0" style="height: 18px; width: 20px"></a></td>
   <td style="height: 20px; width: 140px"></td>
   </tr>
   </tbody>
   </table>
   </td>
   <td style="width: 50%">
   <table width="100%" border="0" cellspacing="0" cellpadding="0">
   <tbody>
   <tr>
   <td style="height: 12px; line-height: 9px; width: 60px"></td>
   <td style=" font-family:Arial; font-size: 13px; font-weight: normal; color: #5ca4a9; text-align: right; width: 160px" align="right"><a href="https://www.jino.ae/terms-conditions" target="_blank" style="color: #5ca4a9; text-decoration: none">Terms of Service</a></td>
   <td style=" font-family:Arial; font-size: 13px; font-weight: normal; color: #5ca4a9; text-align: right; width: 120px" align="right"><a href="https://www.jino.ae/privacy-policy" target="_blank" style="color: #5ca4a9; text-decoration: none">Privacy Policy</a></td>
   </tr>
   </tbody>
   </table>
   </td>
   </tr>
   <tr>
   <td colspan="3" style="height: 25px; line-height: 9px"></td>
   </tr>
   </tbody>
   </table>
   </td>
   </tr>
   </tbody>
   </table>
   </td>
   </tr>
   
   </tbody>
   </table>
   </td>
   </tr>
   </tbody>
   </table>
   <table class="gmail-app-fix">
   <tr>
   <td>
   <table cellpadding="0" cellspacing="0" border="0" align="center" width="600">
   <tr>
   <td cellpadding="0" cellspacing="0" border="0" height="1"; style="line-height: 1px; min-width: 200px;">
   <img src="transparent.gif" width="200" height="1" style="display: block; max-height: 1px; min-height: 1px; min-width: 200px; width: 200px;"/>
   </td>
   <td cellpadding="0" cellspacing="0" border="0" height="1"; style="line-height: 1px; min-width: 200px;">
   <img src="transparent.gif" width="200" height="1" style="display: block; max-height: 1px; min-height: 1px; min-width: 200px; width: 200px;"/>
   </td>
   <td cellpadding="0" cellspacing="0" border="0" height="1"; style="line-height: 1px; min-width: 200px;">
   <img src="transparent.gif" width="200" height="1" style="display: block; max-height: 1px; min-height: 1px; min-width: 200px; width: 200px;"/>
   </td>
   </tr>
   </table>
   </td>
   </tr>
   </table>
   </body>
   </html>`)
  },[])

  const { control,register, handleSubmit,clearErrors,setError, formState: { errors },setValue } = useForm({
    mode:'onChange',
    reValidateMode:'onChange',
    defaultValues:defautlFormValues,
    resolver:yupResolver(createEmailTemplateValidation)
  });

  const routes:any = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Email Template",
      'link': '/admin/email-template'
    },
    {
      'name': "Add",
      'link': ''
    }
  ];
  const containerStyle = {
    height: "400px",
    width: "100%"
  }

 

  const  onSubmit = async (data:any)=>{
    console.log('data',data.description)
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <GridItem xs={12} sm={12} md={12}>
        <PageHeader 
          title="Email Template"
          routes={routes}
          newItem={[{ text: 'Back', link: "/admin/email-template" }]}
          backIcon
        />
      </GridItem>
    </GridItem>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}> *New Email Template</h4>
         
        </CardHeader>
        <CardBody>
         
               <GridContainer>
               <GridItem  xs={6} sm={6} md={6} >
      
            <InputField
                errors={!!errors?.title}
                fieldName="title"
                type="text"
                autoComplete="false"
                label="Title"
                control={control}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.title && errors?.title?.message}
               
              />
    

      
               </GridItem>
               <GridItem xs={6} sm={6} md={6} >
               <InputField
                errors={!!errors?.subject}
                fieldName="subject"
                type="text"
                autoComplete="false"
                label="Subject"
                control={control}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.subject && errors?.subject?.message}
                
              />
    

      
               </GridItem>
               <Box display="flex" justifyContent="flex-end" style={{width:'100%'}}>
              <FormControlLabel
          value="end"
          control={<SwitchField 
            errors={!!errors?.status}
            fieldName="status"
            autoComplete='off'
            label="status"
            control={control}
            valueGot={''}
            setValue={setValue}
            helperText={errors?.status && errors?.status?.message}
            
          />}
          label="status"
          labelPlacement="end"
        />
              </Box>
              <GridItem>
              <Typography>
              Note: These two variables [USERNAME] and [SP_NAME] are used in this template. If you don't use these variables data will not be populated
           </Typography>
              </GridItem>
        
              <GridItem xs={12} sm={12} md={12} >
               <InputField
                errors={!!errors?.description}
                fieldName="description"
                multiline={true}
                rows={7}
                type="text"
                autoComplete="false"
                label="Description"
                control={control}
                valueGot={""}
                setValue={setValue}
                helperText={errors?.description && errors?.description?.message}
                
              />
              </GridItem>
               
              </GridContainer>
       
        </CardBody>
        <CardFooter>
              <Box display="flex" justifyContent="flex-end" style={{width:'100%'}}>
                    <LoadingButton
                      type="submit"
                      loading={false}
                      text="Submit"
                    />
                  </Box>
              </CardFooter>
      </Card>
    </GridItem>
    </GridContainer>
    </form>
  );
}
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
// import { HeaderValidation } from "./validations/header-validation";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const WhoWeAreAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);

  useEffect(() => {
    if (apiData) {
      setValue("who_we_are_title", apiData?.page_content?.who_we_are?.title);
      setValue("who_we_are_description", apiData?.page_content?.who_we_are?.description);
      setValue("logo", apiData?.page_content?.who_we_are?.logo);
      setUrl(apiData?.page_content?.who_we_are?.logo);
    }
  }, [apiData]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("logo", data.result[0]);
    setUrl(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Who We Are</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridItem xs={4} md={4} sm={4} style={{ marginTop: "07px" }}>
          <Box
            style={{ display: "flex", alignItems: "center" }}
            alignItems="center"
          >
            <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />
            <Button
              variant="outlined"
              color="primary"
              disabled={disabled}
              className={classes.uploadButton}
              onClick={() => setDropOpen(true)}
            >
              Who We Are Image
            </Button>
            <FileUploadDialog
              open={dropOpen}
              setOpen={setDropOpen}
              handleSave={handleSave}
              message={
                "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
              }
            />
          </Box>
        </GridItem>
        <GridItem xs={6} md={6} sm={6} alignItems="center">
          <InputField
            errors={!!errors?.who_we_are_title}
            fieldName="who_we_are_title"
            type="text"
            autoComplete="false"
            label="* Title "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={
              errors?.who_we_are_title && errors?.who_we_are_title?.message
            }
          />
        </GridItem>
        <GridItem xs={6} md={6} sm={6} alignItems="center">
          <InputField
            errors={!!errors?.who_we_are_description}
            fieldName="who_we_are_description"
            type="text"
            autoComplete="false"
            label="* Description "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={
              errors?.who_we_are_description &&
              errors?.who_we_are_description?.message
            }
          />
        </GridItem>
      </AccordionDetails>
      <AccordionActions></AccordionActions>
    </ControlledAccordion>
  );
};

import * as yup from 'yup'


const createEmailTemplateValidation:any = yup.object().shape({
    title: yup.string().required("Please enter title").nullable(),
    subject: yup.string().required("Please enter subject").nullable(),
    description: yup.string().required("Please enter description").nullable()
  })

  export{
      createEmailTemplateValidation
  }
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import moment from "moment";
import PageHeader from "components/PageHeader/PageHeader";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getServices } from "services/servicesServices";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { verifyPermission } from "helper/permission";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import Button from "components/CustomButtons/Button";
import { getNotificationList } from "services/notificationServices";
import { getNotificationOdata } from "services/oDataServices";
import AgGridEnterprise from "components/AgGridCustom/AgGridEnterprise";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const PushNotificationList = (props: any) => {
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false);
  const [exportData, setExportData] = useState<any>(null);
  const dispatch = useDispatch();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Push Notification ",
      link: "",
    },
  ];
  const defaultExportParams = [
    {
      allColumns: true,
    },
  ];
  const columnDefs: any = [
    {
      headerName: "Title",
      field: "notification.title",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Description",
      field: "notification.body",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Read",
      field: "read_at",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Customer First Name",
      field: "customer_first_name",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Customer Last Name",
      field: "customer_last_name",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Level Type",
      field: "level_type",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Country",
      field: "country",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "City",
      field: "city",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Branch",
      field: "branch",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },
    {
      headerName: "Zone",
      field: "zone",
      filter: "agTextColumnFilter",
      filterParams: {
        newRowsAction: "keep", // Preserve the existing filters when adding new ones
        filterOptions: ["contains"],
        suppressAndOrCondition: true, // Remove the "AND" and "OR" checkboxes
      },
    },

    {
      headerName: "Created Date",
      field: "createdAt",

      //   valueGetter: (params: any) => {
      //     return moment(params?.data?.createdAt).format('DD-MM-YYYY HH:MM:ss');

      // },
      filter: "agDateColumnFilter",
      valueFormatter: function (data: any) {
        return moment(data.value).format("YYYY-MM-DD HH:mm");
      },
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan"],
        comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
          console.log("filtrlocaldate ", filterLocalDateAtMidnight);

          console.log("cellValue ", cellValue);

          console.log(
            "filter ",
            moment(filterLocalDateAtMidnight).format("YYYY-MM-DD HH:mm")
          );
          const filter = moment(filterLocalDateAtMidnight).format(
            "YYYY-MM-DD HH:mm"
          );
          const compare = moment(cellValue).format("YYYY-MM-DD HH:mm");

          if (filter === compare) {
            return 0;
          }
          if (compare < filter) {
            return -1;
          }
          if (compare > filter) {
            return 1;
          }
        },
      },
    },
  ];

  // useEffect(() => {
  //   // dispatch(spinLoaderShow(true));
  //   getPushNotifications()

  // }, [])
  useEffect(() => {
    let ispermission = !verifyPermission(
      getPermission,
      "Notification",
      "Push Notification",
      "update"
    );
    setDisabled(ispermission);
  }, [getPermission]);

  const getPushNotifications = () => {
    getNotificationList({ type: "General" })
      .then((e) => {
        let data = e?.data?.result.map((item: any) => {
          //item.status=item.status? 'active' : 'inactive'
          return item;
        });
        setRowData(data);
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(spinLoaderShow(false));
      });
  };

  const refreshData = () => {
    dispatch(spinLoaderShow(true));
    getPushNotifications();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Notification"
            routes={routes}
            newItem={[{ text: "Add New", link: "push-notification/add" }]}
            permission="Notification.Push Notification.create"
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Push Notification List</h4>
            <Button
              variant="outlined"
              className="custom-btn export-btn"
              onClick={(e: any) => exportData.exportDataAsCsv()}
            >
              Export to Csv
            </Button>
          </CardHeader>
          <CardBody>
            <Button
              variant="outlined"
              color="primary"
              className="custom-btn export-btn "
              onClick={(e: any) => {
                exportData &&
                  exportData?.refreshServerSideStore({ purge: true });
              }}
            >
              Refresh
            </Button>
            <AgGridEnterprise
              dataSourceUrl={getNotificationOdata}
              disabledCheckBox={true}
              defaultExportParams={defaultExportParams}
              rowData={rowData}
              headerCheckboxSelection="true"
              headerCheckboxSelectionFilteredOnly="true"
              checkboxSelection="true"
              columnDefs={columnDefs}
              getRowNodeId={(data: any) => {
                return data.uuid;
              }}
              // componentType="notification"
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
export default PushNotificationList;

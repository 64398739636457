import * as yup from 'yup'

const profileValidation = yup.object().shape({
    first_name:yup.string().required("Please enter first name").nullable(),
    last_name:yup.string().required("Please enter last name").nullable(),
    email:yup.string().email().required("Please enter email").nullable()

})

export {
    profileValidation
}
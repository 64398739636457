import * as yup from 'yup'

const createCustomerValidation:any = yup.object().shape({
    first_name: yup.string().required("Please enter first name").nullable(),
    last_name: yup.string().required("Please enter last name").nullable(),
    email:yup.string().required("Please enter email").email().nullable(),
    phone_number:yup.string().required("Please enter phone number").nullable(),
    
  });

  export{
      createCustomerValidation
  }
import * as yup from "yup";

export const forgotPasswordValidation: any = yup.object().shape({
  email: yup.string().email().required("Email address is required"),
});

export const forgotPasswordSubmitValidation: any = yup.object().shape({
  code: yup.string().required("OTP is required"),

  password: yup.string()
  .required('Please Enter your password')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  ),
});


// export const secretPageValidation: any = yup.object().shape({
    
//   password: yup.string()
//   .required('Please Enter your password')
//   .matches(
//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
//     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
//   ),
//   confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
// });

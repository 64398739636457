import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import { routes } from "../routes";
import { itemsList } from "json/NavigationList";
import adminStyle from "assets/jss/material-dashboard-react/layouts/adminStyle";
const styles: any = adminStyle;
import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/reactlogo.png";
import { useEffect } from "react";
import ProtectedRoute from "components/Route/ProtectedRoute";
import PrivateRoute from "components/Route/PrivateRoute";
import { ZoneList } from "containers/ZoneManager/zonemaanger-list-contrainter";
import AddZone from "containers/ZoneManager/zonemanager-c.container";
import { CustomerVehicleList } from "containers/CustomerVehcileManager/customervehicle-list-contrainter";
import AddCustomerVehicle from "containers/CustomerVehcileManager/customervehicle-c.container";
import { BranchList } from "containers/BranchManager/branchmanager-list-contrainter";
import AddBranch from "containers/BranchManager/branchmanager-c.container";
import { CompanyList } from "containers/CompanyManager/companymanager-list-contrainter";
import AddCompany from "containers/CompanyManager/companymanager-c.container";
import { CountryList } from "containers/CountryManager/countrymanager-list-container";
import AddCountry from "containers/CountryManager/countrymanager-c.container";
import { CurrencyList } from "containers/CurrencyManager/currencymanager-list-container";
import { useSelector } from "react-redux";
import { CityList } from "containers/CitiesManager/citiesmanager-list-container";
import AddCity from "containers/CitiesManager/citiesmanager-c.container";
import { VehicleTypeList } from "containers/VehicleTypeManager/vehicletypemanager-list-container";
import { LanguageList } from "containers/LanguageManager/Languagemanager-list-container";
import { ModelList } from "containers/VehicleModelManager/modelmanager-list-container";
import { MakeList } from "containers/VehicleMakeManager/makemanager-list-container";
import { PushNotification } from "containers/PushNotification/pushnotification-c-container";
import { SiteMaintenance } from "containers/SiteMaintenance/sitmaintenance.container";
import { EmailTemplate } from "containers/EmailTemplateManager/emailtemplate.container";
import EmailEdit from "containers/EmailTemplateManager/emailtemplate-e.container";
import { ApplicationSetting } from "containers/ApplicationSetting/applicationsetting.container";
import { ContentList } from "containers/ContentManager/contentmanager-list.container";
import ContentEdit from "containers/ContentManager/contentmanager-c.container";
import Scheduler from "containers/Scheduler/Scheduler";
import { SchedulerList } from "containers/Scheduler/scheduler-list-container";
import Dashboard from "containers/Dashboard/Dashboard";
import { DriverList } from "containers/DriverManager/drivermanager-list.container";
import AddDriver from "containers/DriverManager/drivermanager-c.container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdminList } from "containers/Admin/admin-list-contrainter";
import AddRole from "containers/Admin/roles-c.container";
import AddAdmin from "containers/Admin/admin-c.container";
import ServiceList from "containers/Services/servicemanager-list-container";
import AddService from "containers/Services/servicemanager-c.container";
import ServiceCategoryList from "containers/ServiceCategory/servicecategory-list-container";

import { PromotionList } from "containers/PromotionManager/promotionmanager-listNormal.container";
import { PromotionListLinkedAll } from "containers/PromotionManager/promotionmanager-listLinkedAll.container";
import { PromotionListAutoApplied } from "containers/PromotionManager/promotionmanager-listAutoApplied.container";
import { PromotionListLinked } from "containers/PromotionManager/promotionmanager-list.container";
import AddPromotion from "containers/PromotionManager/promotionmanager-c.container";
import { Profile } from "containers/Profile/profile-c.container";
import AddServiceConfig from "containers/ServicesConfig/serviceconfig-c.container";
import ServiceConfigList from "containers/ServicesConfig/serviceconfig-list-container";
import { PackageList } from "containers/PackageManager/packagemanager-list.container";
import AddPackage from "containers/PackageManager/packagemanager-c.container";
import PackageConfigList from "containers/PackageConfig/packageconfig-list-container";
import AddPackageConfig from "containers/PackageConfig/packageconfig-c.container";
import { VendorList } from "containers/VendorManager/vendormanager-list";
import AddVendor from "containers/VendorManager/vendormanager-c.container";
import { CustomerList } from "containers/CustomerManager/customer-list-container";
import AddCustomer from "containers/CustomerManager/customer-c.container";
import { CustomerAddressList } from "containers/CustomerAddress/customerAddress-list-container";
import AddCustomerAddress from "containers/CustomerAddress/customerAddress-c.container";
import Reports from "containers/Reports/reports-container";
import { DriverConfigList } from "containers/DriverConfig/driverconfig-list.container";
import AddDriverConfig from "containers/DriverConfig/driverconfig-c.container";
import { ArrivalConfig } from "containers/ArrivalConfig/arrivalconfig-c.container";
import { TransactionList } from "containers/Transaction/transaction-list.container";
import { ViewTransaction } from "containers/Transaction/viewtransaction-container";
import { BookingList } from "containers/Booking/booking-list.container";
import { BookingListPackage } from "containers/Booking/booking-list-package.container";

import { FaqList } from "containers/FAQ/faqmanager-list.container";
import AddFaq from "containers/FAQ/faqmanager-c.container";
import FaqTypeList from "containers/FaqTypes/faqtypes-list-container";
import { ContactusList } from "containers/Contactus/contactus-list.container";
import ContactView from "containers/Contactus/contact-view.container";
import { BookingDetail } from "containers/Booking/booking-view.container";
import AddBookingDetails from "containers/BookingDetails/booking-details.c.container";
import { CustomerPackageList } from "containers/CustomerPackages/customerPackage.list.container";
import { CustomerView } from "containers/CustomerPackages/customerPakageView.container";
import PushNotificationList from "containers/PushNotification/pushnotification.list-container";
import Alert from "containers/Alert/alert-list-container";
import { AlertSetting } from "containers/AlertSetting/alertsetting.container";
import { ThemeSettings } from "containers/Website/ThemeSettings/themeSettings-c-container";
import { HomePage } from "containers/Website/HomePage/homePage-c-container";
import { AboutUsPage } from "containers/Website/AboutusPage/aboutus-c-container";
import { Partner } from "containers/Website/Partner/partner-c-container";
import { PartnershipList } from "containers/Website/Partnerships/partnershipmanager-list.container";
import AddPartnershipData from "containers/Website/Partnerships/partnershipmanager-c.container";
import { BusinessfComponent } from "containers/Website/Business/business-c-container";
import { MediaPageBanner } from "containers/Website/Media/media-c-container";
import AddMediaData from "containers/Website/Media details/media-manager-c.container";
import { MediaListing } from "containers/Website/Media details/media-manager-list.container";
import CategoryList from "containers/Website/Category/category-list-container";
import { CategoryPopup } from "containers/Website/Category/category.popup.component";
import { EventListing } from "containers/Website/Event details/event-manager-list.container";
import AddEventData from "containers/Website/Event details/event-manager-c.container";
import { EventPageBanner } from "containers/Website/Events/event-c-container";
import { PageComponentNew } from "containers/Website/Pages/page-c-container";
import { WebServicesPage } from "containers/Website/Website Services/webservices-c-container";
import WebServicesList from "containers/Website/Website Services/website-list-container";
import { AutomatedNotifications } from "containers/AutomatedNotification/automatednotification-list.container";
import AddAutomatedNotification from "containers/AutomatedNotification/automatednotification-c.container";
import UpdatePromotion from "containers/PromotionManager/promotionmanager-update.container";

let ps: any;
const switchRoutes: any = (
  <Switch>
    <ProtectedRoute path="/admin/dashboard" exact component={Dashboard} />
    <PrivateRoute path="/admin/users/add" exact component={AddAdmin} />
    <PrivateRoute path="/admin/users/edit" exact component={AddAdmin} />
    <PrivateRoute path="/admin/role/add" exact component={AddRole} />
    <PrivateRoute path="/admin/role/edit" exact component={AddRole} />
    <PrivateRoute path="/admin/users" exact component={AdminList} />
    <PrivateRoute path="/admin/zone" exact component={ZoneList} />
    <PrivateRoute path="/admin/zone/add" exact component={AddZone} />
    <PrivateRoute path="/admin/zone/edit" exact component={AddZone} />
    <PrivateRoute path="/admin/vehicletype" exact component={VehicleTypeList} />
    <PrivateRoute path="/admin/branch" exact component={BranchList} />
    <PrivateRoute path="/admin/branch/add" exact component={AddBranch} />
    <PrivateRoute path="/admin/branch/edit" exact component={AddBranch} />
    {/* <PrivateRoute path="/admin/business" exact component={BusinessComponent} /> */}

    {/* <PrivateRoute
      path="/admin/company"
      exact
      component={CompanyList}
    />
    <ProtectedRoute
      path="/admin/company/add"
      exact
      component={AddCompany}
    /> */}
    {/* <PrivateRoute
      path="/admin/currency"
      exact
      component={CurrencyList}
    /> */}
    {/* <ProtectedRoute
      path="/admin/language"
      exact
      component={LanguageList}
    /> */}
    <PrivateRoute path="/admin/country" exact component={CountryList} />
    <PrivateRoute path="/admin/country/add" exact component={AddCountry} />
    <PrivateRoute path="/admin/country/edit" component={AddCountry} exact />

    <PrivateRoute path="/admin/city" exact component={CityList} />
    <PrivateRoute path="/admin/city/add" exact component={AddCity} />
    <PrivateRoute path="/admin/city/edit" component={AddCity} exact />
    <PrivateRoute path="/admin/vehiclemodel" exact component={ModelList} />
    <PrivateRoute path="/admin/vehiclemake" exact component={MakeList} />
    <PrivateRoute
      path="/admin/customer-vehicle"
      exact
      component={CustomerVehicleList}
    />
    <PrivateRoute
      path="/admin/customer-vehicle/add"
      exact
      component={AddCustomerVehicle}
    />
    <PrivateRoute
      path="/admin/customer-vehicle/edit"
      exact
      component={AddCustomerVehicle}
    />
    <PrivateRoute
      path="/admin/push-notification/add"
      exact
      component={PushNotification}
    />
    {/* <ProtectedRoute
      path="/admin/site-maintenance"
      exact
      component={SiteMaintenance}
    /> */}
    <PrivateRoute
      path="/admin/email-template"
      exact
      component={EmailTemplate}
    />
    <PrivateRoute path="/admin/email-template/add" component={EmailEdit} />
    <PrivateRoute
      path="/admin/application-setting"
      exact
      component={ApplicationSetting}
    />
    <PrivateRoute path="/admin/content" exact component={ContentList} />
    <PrivateRoute path="/admin/content/add" component={ContentEdit} />
    <PrivateRoute path="/admin/scheduler" component={SchedulerList} exact />
    <PrivateRoute path="/admin/scheduler/add" component={Scheduler} exact />
    <PrivateRoute path="/admin/scheduler/edit" component={Scheduler} exact />
    <PrivateRoute path="/admin/driver" exact component={DriverList} />
    <PrivateRoute path="/admin/content/add" component={ContentEdit} />
    <PrivateRoute path="/admin/driver/add" component={AddDriver} />
    <PrivateRoute
      path="/admin/service-category"
      exact
      component={ServiceCategoryList}
    />
    <PrivateRoute path="/admin/service" exact component={ServiceList} />
    <PrivateRoute path="/admin/service/add" exact component={AddService} />
    <PrivateRoute path="/admin/service/edit" exact component={AddService} />
    <PrivateRoute path="/admin/promotion" exact component={PromotionList} />
    <PrivateRoute
      path="/admin/promotion/linked"
      exact
      component={PromotionListLinked}
    />
    <PrivateRoute
      path="/admin/promotion/linked/all"
      exact
      component={PromotionListLinkedAll}
    />
    <PrivateRoute
      path="/admin/promotion/auto_applied"
      exact
      component={PromotionListAutoApplied}
    />
    <PrivateRoute path="/admin/promotions/update" component={UpdatePromotion} />
    <PrivateRoute path="/admin/promotions/add" component={AddPromotion} />
    <PrivateRoute
      path="/admin/promotions/edit"
      component={AddPromotion}
      exact
    />
    <ProtectedRoute path="/admin/profile" exact component={Profile} />
    <PrivateRoute
      path="/admin/service-config"
      exact
      component={ServiceConfigList}
    />
    <PrivateRoute
      path="/admin/service-config/add"
      exact
      component={AddServiceConfig}
    />
    <PrivateRoute
      path="/admin/service-config/edit"
      exact
      component={AddServiceConfig}
    />
    <PrivateRoute path="/admin/package" exact component={PackageList} />
    <PrivateRoute path="/admin/package/add" exact component={AddPackage} />
    <PrivateRoute path="/admin/package/edit" exact component={AddPackage} />
    <PrivateRoute
      path="/admin/package-config"
      exact
      component={PackageConfigList}
    />
    <PrivateRoute
      path="/admin/package-config/add"
      exact
      component={AddPackageConfig}
    />
    <PrivateRoute
      path="/admin/package-config/edit"
      exact
      component={AddPackageConfig}
    />
    <PrivateRoute path="/admin/driver/edit" component={AddDriver} />
    <PrivateRoute path="/admin/vendor" exact component={VendorList} />
    <PrivateRoute path="/admin/vendor/add" exact component={AddVendor} />
    <PrivateRoute path="/admin/vendor/edit" exact component={AddVendor} />
    <PrivateRoute path="/admin/customer" exact component={CustomerList} />
    <PrivateRoute path="/admin/customer/add" exact component={AddCustomer} />
    <PrivateRoute path="/admin/customer/edit" exact component={AddCustomer} />
    <PrivateRoute path="/admin/reports" exact component={Reports} />
    <PrivateRoute
      path="/admin/customer-address"
      exact
      component={CustomerAddressList}
    />
    <PrivateRoute
      path="/admin/customer-address/add"
      exact
      component={AddCustomerAddress}
    />
    <PrivateRoute
      path="/admin/customer-address/edit"
      exact
      component={AddCustomerAddress}
    />
    <PrivateRoute
      path="/admin/driver-config"
      exact
      component={DriverConfigList}
    />
    <PrivateRoute
      path="/admin/driver-config/add"
      exact
      component={AddDriverConfig}
    />
    <PrivateRoute
      path="/admin/driver-config/edit"
      exact
      component={AddDriverConfig}
    />
    {/* <PrivateRoute
      path="/admin/arrival-config"
      exact
      component={ArrivalConfig}
    /> */}
    <ProtectedRoute
      path="/admin/transaction"
      exact
      component={TransactionList}
    />
    {/* <ProtectedRoute 
    path="/admin/transaction/view"
    exact
    component={ViewTransaction}
    />  */}
    <PrivateRoute path="/admin/booking" exact component={BookingList} />
    <PrivateRoute
      path="/admin/booking/package"
      exact
      component={BookingListPackage}
    />

    <PrivateRoute
      path="/admin/booking/details"
      exact
      component={BookingDetail}
    />
    <PrivateRoute path="/admin/faq" exact component={FaqList} />
    <PrivateRoute path="/admin/faq/add" exact component={AddFaq} />
    <PrivateRoute path="/admin/faq/edit" exact component={AddFaq} />
    <PrivateRoute path="/admin/faq/type" exact component={FaqTypeList} />
    <PrivateRoute path="/admin/contactus" exact component={ContactusList} />
    <PrivateRoute path="/admin/contactus/view" exact component={ContactView} />
    <PrivateRoute
      path="/admin/booking/details/add"
      exact
      component={AddBookingDetails}
    />
    <PrivateRoute
      path="/admin/customer/packages"
      exact
      component={CustomerPackageList}
    />
    <PrivateRoute
      path="/admin/customer/packages/view"
      exact
      component={CustomerView}
    />
    <PrivateRoute
      path="/admin/push-notification"
      exact
      component={PushNotificationList}
    />
    <PrivateRoute
      path="/admin/automated-notification"
      exact
      component={AutomatedNotifications}
    />
    <PrivateRoute
      path="/admin/automated-notification/add"
      exact
      component={AddAutomatedNotification}
    />
    <PrivateRoute
      path="/admin/automated-notification/edit"
      exact
      component={AddAutomatedNotification}
    />
    <PrivateRoute
      path="/admin/automated-notification/view"
      exact
      component={AddAutomatedNotification}
    />
    <PrivateRoute path="/admin/alert" exact component={Alert} />
    <PrivateRoute path="/admin/alert-setting" exact component={AlertSetting} />

    {/* //routes for use in website folder */}

    <PrivateRoute path="/admin/themesettings" exact component={ThemeSettings} />
    <PrivateRoute path="/admin/pages/homepage" exact component={HomePage} />
    <PrivateRoute path="/admin/pages/aboutus" exact component={AboutUsPage} />
    <PrivateRoute path="/admin/pages" exact component={PageComponentNew} />
    <PrivateRoute path="/admin/pages/partner" exact component={Partner} />
    <PrivateRoute path="/admin/partnership" exact component={PartnershipList} />
    <PrivateRoute
      path="/admin/partnership/add"
      exact
      component={AddPartnershipData}
    />
    <PrivateRoute
      path="/admin/partnership/edit"
      exact
      component={AddPartnershipData}
    />
    <PrivateRoute path="/admin/business" exact component={BusinessfComponent} />

    {/* media routes */}

    <PrivateRoute
      path="/admin/pages/mediapage"
      exact
      component={MediaPageBanner}
    />
    <PrivateRoute path="/admin/media" exact component={MediaListing} />
    <PrivateRoute path="/admin/media/add" exact component={AddMediaData} />
    <PrivateRoute path="/admin/media/edit" exact component={AddMediaData} />
    {/* media routes */}
    {/* eventpage routes */}
    <PrivateRoute
      path="/admin/pages/eventpage"
      exact
      component={EventPageBanner}
    />
    <PrivateRoute path="/admin/event" exact component={EventListing} />
    <PrivateRoute path="/admin/event/add" exact component={AddEventData} />
    <PrivateRoute path="/admin/event/edit" exact component={AddEventData} />
    {/* eventpage routes */}
    {/* category routes */}
    <PrivateRoute path="/admin/category" exact component={CategoryList} />
    {/* category routes */}
    {/* Website services  routes */}
    <PrivateRoute path="/admin/webservices" exact component={WebServicesList} />
    <PrivateRoute
      path="/admin/webservices/add"
      exact
      component={WebServicesPage}
    />
    <PrivateRoute
      path="/admin/webservices/edit"
      exact
      component={WebServicesPage}
    />
    {/* Website services routes */}

    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

const Admin = ({ ...rest }) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef<HTMLDivElement>();
  // states and functions
  const color = "green";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const spinLoaderShow: any = useSelector(
    (state: any) => state?.spinLoader?.spinLoaderShow
  );

  // initialize and destroy the PerfectScrollbar plugin
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let _mainPanel: any = mainPanel.current;
  //     ps = new PerfectScrollbar(_mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", resizeFunction);
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //     window.removeEventListener("resize", resizeFunction);
  //   };
  // }, [mainPanel]);

  const [isOpened, setOpened] = useState<any>(true);

  return (
    <div className={classes.wrapper}>
      <ToastContainer />
      <Sidebar
        routes={itemsList}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        mainOpened={setOpened}
        {...rest}
      />
      {/* <div className={`${this.props.classes.container} ${this.props.classes.spacious}`}> */}

      <div
        className={`${classes.mainPanel} ${
          !isOpened ? classes.mainPanelCollapse : null
        }`}
        ref={mainPanel}
      >
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
      <Backdrop
        className={classes.backdrop}
        style={{ zIndex: 99 }}
        open={spinLoaderShow}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Admin;

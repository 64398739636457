import React, { useState, useRef, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import PageHeader from "components/PageHeader/PageHeader";
import { InputField, LoadingButton } from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import StatusComponent from "components/Mix/Status";
import { verifyPermission } from 'helper/permission'
import { useSelector } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import VisibilityIcon from '@material-ui/icons/Visibility';



const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const defautlFormValues = {
  message: null,
}

const useStyles = makeStyles(styles);

export const EmailTemplate = (props: any) => {
  const getPermission = useSelector((state: any) => { return state.userPermission.permissions })
  const classes = useStyles();
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [disabled, setDisabled] = useState<any>(false)
  const [exportData, setExportData] = useState<any>(null)
  const { control, register, handleSubmit, clearErrors, setError, formState: { errors }, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defautlFormValues,
  });

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Email Template ",
      'link': ''
    }
  ];

  const columnDefs: any = [
    {
      headerName: "Title",
      field: "title",
     
    },



    {
      headerName: 'Status',
      field: "status",
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <StatusComponent data={params} />;
      }
    },
    {
      headerName: 'Actions',
      field: "action",
sortable: false,
filter:false,
  
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return <Link to={'/admin/email-template/add' }><EditIcon /></Link>;
      }
    },
    // {
    //   headerName: 'View',
    //   field: "action",
    //   sortable: false,
    //   filter: false,
    //   cellRendererFramework: (params: any) => {
    //     // put the value in bold
    //     return <Link to={'email-template/edit?uuid=' + params?.data?.uuid +"&type=view"}><VisibilityIcon /></Link>;
    //   }
    // },
  ]

  useEffect(()=>{
    let ispermission = !(verifyPermission(
      getPermission,
      'Content',
      'Email',
      'update'
    ))
    setDisabled(ispermission)
  },[])

  const rowData = [
    { id: '1', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '2', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '3', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '4', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '5', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '6', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '7', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },
    { id: '8', title: 'send invoice', logo: 'logo', status: 'active', date_modified: "Aug 08, 2021 " },

  ]
  const refreshData = () => {
  

  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Email Template "
            routes={routes}
            newItem={[{ text: 'Add', link: "/admin/email-template/add" }]}
            permission="Content.Email.create"

          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Email Template</h4>
            <Button variant="outlined" className="custom-btn export-btn" onClick={(e: any) => exportData.exportDataAsCsv()}>Export to Csv</Button>

          </CardHeader>
          <CardBody>
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
                <AgGridCustom
                  columnDefs={columnDefs}
                  rowData={rowData}
                  getRowNodeId={function (data: any) {
                    return data.id;
                  }}
                  setExportData={setExportData}
                />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


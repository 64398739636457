import httpservice from "./httpservice";

export const getVendor = async (body: any = null) => {
    return httpservice.get('/vendor/', { params: body })
}
export const createVendor = (body: any) => {
    return httpservice.post("/vendor/", body)

}
export const updateVendor = (body: any) => {
    return httpservice.put("/vendor", body)
}

export const vendorClientLogo = (files: any) => {
    return httpservice.post('/upload/vendor/client', files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
export const vendorLogo = (files: any) => {
    return httpservice.post('/upload/vendor', files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
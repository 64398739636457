import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "./httpservice";

export function createCountry(body: any) {
    return httpservice.post("/country", body);
  }
  
  export function createZone(body: any) {
    return httpservice.post("/zone", body);
  }
  
  export function createBranch(body: any) {
    return httpservice.post("/branch", body);
  }
  
  export  function getCountry(body: any = null) {
    return httpservice.get("/country", { params: body });
  }
  export function createCity(body: any) {
    return httpservice.post("/city/", body);
  } 
  export function getCity(body: any = null) {
    return httpservice.get("/city", { params: body });
  }
  
  export function updateCountry(body: any = null) {
    return httpservice.put('/country', body)
  }

  export  function getBranch(body: any = null) {
    return httpservice.get("/branch", { params: body });
  }
  export function getZone(body: any = null) {
    return httpservice.get("/zone", { params: body });
  }

  

export function updateCity(body: any) {
    return httpservice.put("/city", body)
  }
  
  export function updateBranch(body: any) {
    return httpservice.put('/branch', body)
  }
  export function updateZone(body: any) {
    return httpservice.put('/zone', body)
  }

  
export function createSchedule(body: any) {
    return httpservice.post("/schedule/", body);
  } 
  export function getScheduleDays(body:any=null) {
    return httpservice.get("/schedule/days",{params:body});
  }
  
  export  function getSchedules(body: any = null) {
    return httpservice.get("/schedule", { params: body });
  }
  
 export function updateSchedule(body: any) {
    return httpservice.put("/schedule/", body);
  }
 export function getLevels(body: any = null) {
    return httpservice.get('/level', { params: body })
  }

  
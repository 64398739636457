import { USER_PERMISSIONS } from '../types/user';

export const userPermissions = (payload:any) => {

    return {
        type: USER_PERMISSIONS,
        payload: payload
    };

}


export default userPermissions;
import React, { ChangeEvent, ChangeEventHandler } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import * as XLSX from 'xlsx';
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import {
  Checkbox,
  FormHelperText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  CardMedia,
  InputAdornment,
} from "@material-ui/core";
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  InputField,
  AutoCompleteField, SwitchFieldDefault,
  DateField,
  ColorPickerField
} from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import { createPromotionValidations } from "./Validations/promotionValidations";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { UPDATE_SUCCESS_MSG } from "json/constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UploadFileIcon from '@material-ui/icons/FileCopy';
import GoogleMap from "components/GoogleMap/GoogleMap";
import DrawMap from "components/GoogleMap/DrawMap";

import _ from "lodash";

import {
  useHistory,
  useLocation
} from "react-router-dom";
import {
  createPromotion,
  getPromotions,
  updatePromotion,
  promotionFileUpload,
  createBulkPromotion,
} from "services/promotionServices";
import {
  getCity,
  getBranch,
  getZone,
  getLevels,
  getCountry,
} from "services/locationservices";
import { getServices, getServiceConfig, getServiceCategory } from "services/servicesServices";
import moment from "moment";
import { FileUploadArea } from "components/FileUpload/fileuploadArea.component";
import { getViewPackages } from "services/packageservices";

import "react-image-crop/dist/ReactCrop.css";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  img: {
    width: "150px",
    height: "150px",
    objectfit: "contain",
  },
  media: {
    height: "100%",
    width: 300,
  },
  dot: {
    height: "70px",
    width: "70px",
    borderRadius: " 50%",
    display: " inline-block",
  },
};

const defaultFormValues: any = {
  name: "",
  discount_percentage: "",
  code: "",
  start_date: null,
  end_date: null,
  level_id: "",
  services: null,
  package_id: null,
  status: true,
  auto_apply: false,
  show_homepage: false,
};

const useStyles = makeStyles(styles);
declare var google: any;

const levelDependent: any = {
  Country: ["country"],
  City: ["country", "city"],
  Branch: ["country", "city", "branch"],
  Zone: ["country", "city", "branch", "zone"],
};

export default function AddPromotion(props: any) {
  const classes = useStyles();
  const [editData, setEditData] = useState<any>(null);
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");
  const [levels, setLevels] = useState<any>([]);
  const [levelValidationShow, setLevelValidationShow] = useState<any>([]);
  const [country, setCountry] = useState<any>([]);
  const [countries, setCountries] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [branch, setBranch] = useState<any>([]);
  const [branches, setBranches] = useState<any>([]);
  const [zones, setZones] = useState<any>([]);
  const [zone, setZone] = useState<any>([]);
  const [serviceConfigs, setServiceConfigs] = useState<any>([]);
  const [serviceCategories, setServiceCategories] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const [packages, setPackages] = useState<any>([]);
  const [packageId, setpackageId] = useState<any>(null);
  const [packageData, setPackage] = useState<any>(null);
  const [mapValues, setMapValues] = useState<any>(null);

  const [resetMap, setResetMap] = useState<any>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [service, setService] = useState<any>(null);
  const [serviceId, setserviceId] = useState<any>([]);
  const [levelGot, setLevelGot] = useState<any>({});
  const [image, setImage] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const [disabledPackage, setDisabledPackage] = useState<any>(false);
  const [disabledService, setDisabledService] = useState<any>(false);
  const [disabledAutoapply, setDisabledAutoApply] = useState<any>(false);
  const [disabledCount, setDisabledCount] = useState<any>(false);
  const [color, setColor] = useState<any>("");
  const [colorSecond, setColorSecond] = useState<any>("");
  const [startDate, setStartDate] = useState<any>(null);
  const [codeDisable, setCodeDisable] = useState<any>(false);
  const [endDate, setEndDate] = useState<any>(null);
  const { search } = useLocation();
  const [searchLocation, setSearchLocation] = useState<any>(null);

  const [crop, setCrop] = useState<any>({ aspect: 16 / 9 });

  let uuid = new URLSearchParams(search).get("uuid");

  let history = useHistory();

  useEffect(() => {
    getCountry({ status: true })
      .then((e) => {
        setCountries(e.data.result);
      })
      .catch((e) => { });
    getLevels()
      .then((e) => {
        setLevels(e.data.result.filter((x: any) => x.name != "Zone"));
      })
      .catch((e) => { });
    console.clear();
  }, []);

  useEffect(() => {
    let temp: any = [];
    _.uniqBy(serviceConfigs, "service_id").map((row: any, index: any) => {
      let data = row?.service;
      let service_config_uuid = row?.uuid;

      temp.push({ ...data, ...{ service_config_id: service_config_uuid, isSelected: serviceId.includes(row.service.uuid) ?? false } });
    });
    setServices(temp);
  }, [serviceConfigs]);

  console.log(serviceCategories, "services")

  useEffect(() => {
    if (!!mapValues?.latLng && Object.keys(mapValues?.latLng)?.length > 0) {
      setValue("polygon", mapValues?.latLng);
    } else {
      setValue("polygon", null);
    }
    if (!!mapValues?.autocomplete) {
      setSearchLocation(mapValues?.autocomplete);
    }
  }, [mapValues]);

  const getPromotion = () => {
    getPromotions({ uuid: uuid }).then((e: any) => {
      let data: any = e?.data?.result?.[0];
      setValue("name", data.name);
      setValue("discount_percentage", data.discount_percentage);
      setValue("code", data.code);
      setValue("user_limit", data?.user_limit);
      setValue("code_limit", data?.code_limit);
      setValue("automate_notifications", data?.automate_notifications);
      setValue("notification_title", data?.notification_title);
      setValue("notification_body", data?.notification_body);
      setValue("status", data.status);
      setValue("start_date", moment(data.start_date).format("YYYY-MM-DD"));
      setStartDate(moment(data.start_date).format("YYYY-MM-DD"));
      setValue("end_date", moment(data.end_date).format("YYYY-MM-DD"));
      setEndDate(moment(data.end_date).format("YYYY-MM-DD"));
      setValue("end_date", data.end_date);
      setValue("auto_apply", data?.auto_apply);
      setShowNotification(data?.automate_notifications);
      // setValue("color_name", data?.color_name)
      setColor(data?.color);
      setValue("color", data?.color);
      setColorSecond(data?.color_secondary);
      setValue("color_secondary", data?.color_secondary);
      setValue("description", data?.description);
      setLevelGot("zone");
      setEditData(data);
      setserviceId(data?.service_config_ids);
      setImage(data?.image);
      setValue("show_homepage", data?.show_homepage);
      if (data?.service_id) {
        setDisabledPackage(true);
        setpackageId(null);
      }
      if (data?.package_id) {
        setpackageId(data?.package_id);
        setDisabledService(true);
        setDisabledAutoApply(true);
        setService(null);
      }
      let temp: any = new URLSearchParams(search).get("type");
      temp ? setHeaderTitle("View") : setHeaderTitle("Edit");
      temp == "view" ? setDisabled(true) : setDisabled(false);
      dispatch(spinLoaderShow(false));
    });
  };

  console.log(serviceId)

  useEffect(() => {
    if (serviceId[0]) {
      getServices({ uuid: serviceId[0] })
        .then((res: any) => {
          let data = res?.data.result?.[0];
          let related_value = editData?.related_value?.[0];
          let level_type = editData?.level_type;
          let level_id = data?.level_id;
          setLevelGot({ id: level_id, name: level_type });
          setLevelValidationShow(levelDependent[level_type]);

          if (level_type == "Zone") {
            setCountry(related_value?.branch?.city?.country);

            let country_id = related_value?.branch?.city?.country?.uuid;
            getCitiesByCountry({ uuid: country_id });
            setCity(related_value?.branch?.city);

            let city_id = related_value?.branch?.city?.uuid;
            getBranchesByCity({ uuid: city_id });
            setBranch(related_value?.branch);

            let branch_id = related_value?.branch_id;
            getZonesByBranch({ uuid: branch_id });
            setZone(related_value);
          } else if (level_type == "Branch") {
            setCountry(related_value?.city?.country);

            let country_id = related_value?.branch?.city?.country?.uuid;
            getCitiesByCountry({ uuid: country_id });
            setCity(related_value?.city);

            let city_id = related_value?.branch?.city?.uuid;
            getBranchesByCity({ uuid: city_id });
            setBranch(related_value);
          } else if (level_type == "City") {
            setCountry(related_value?.country);

            let country_id = related_value?.branch?.city?.country?.uuid;
            getCitiesByCountry({ uuid: country_id });
            setCity(related_value);
          } else if (level_type == "Country") {
            setCountry(related_value);
          }
          getServiceConfigData({ uuid: level_id })
        })
        .catch((err) => { });
    }
    if (packageId != null) {
      let related_value = editData?.related_value?.[0];
      let level_type = editData?.level_type;
      let level_id = editData?.level_id;
      setLevelGot({ id: level_id, name: level_type });
      setLevelValidationShow(levelDependent[level_type]);

      if (level_type == "Zone") {
        setCountry(related_value?.branch?.city?.country);

        let country_id = related_value?.branch?.city?.country?.uuid;
        getCitiesByCountry({ uuid: country_id });
        setCity(related_value?.branch?.city);

        let city_id = related_value?.branch?.city?.uuid;
        getBranchesByCity({ uuid: city_id });
        setBranch(related_value?.branch);

        let branch_id = related_value?.branch_id;
        getZonesByBranch({ uuid: branch_id });
        setZone(related_value);
      } else if (level_type == "Branch") {
        setCountry(related_value?.city?.country);

        let country_id = related_value?.branch?.city?.country?.uuid;
        getCitiesByCountry({ uuid: country_id });
        setCity(related_value?.city);

        let city_id = related_value?.branch?.city?.uuid;
        getBranchesByCity({ uuid: city_id });
        setBranch(related_value);
      } else if (level_type == "City") {
        setCountry(related_value?.country);

        let country_id = related_value?.branch?.city?.country?.uuid;
        getCitiesByCountry({ uuid: country_id });
        setCity(related_value);
      } else if (level_type == "Country") {
        setCountry(related_value);
      }
      getPackagesData({ uuid: level_id });
    }
  }, [serviceId, packageId]);

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getPromotion();
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
    }
  }, [uuid]);

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(createPromotionValidations),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Offers ",
      link: "/admin/promotion",
    },
    {
      name: headerTitle,
      link: "",
    },
    // {
    //   'name': uuid,
    //   'link': '',
    //   'uuid': uuid ? true : false
    // }
  ];

  const handleConfigLevel = (data: any) => {
    setCities([]);
    setBranches([]);
    setZones([]);
    setCity({});
    setBranch({});
    setZone({});
    setCountry({});
    setServiceConfigs([]);
    setLevelValidationShow(levelDependent[data?.name]);
  };

  const getCitiesByCountry = (data: any = null) => {
    setCities([]);
    setBranches([]);
    setZones([]);
    setCity({});
    setBranch({});
    setZone({});
    if (data?.uuid) {
      dispatch(spinLoaderShow(true));
      getCity({ country_id: data?.uuid, status: true })
        .then((e) => {
          setCities(e?.data?.result);
          dispatch(spinLoaderShow(false));
        })
        .catch((err) => {
          dispatch(spinLoaderShow(false));
        });
    }
    if (data && levelValidationShow.at(-1) == "country") {
      setServiceConfigs([]);
      getServiceConfigData(data);
      getPackagesData(data);
    }
  };

  const getBranchesByCity = (data: any = null) => {
    setBranches([]);
    setZones([]);
    setBranch({});
    setZone({});
    if (data?.uuid) {
      dispatch(spinLoaderShow(true));
      getBranch({ city_id: data?.uuid, status: true })
        .then((e) => {
          setBranches(e?.data?.result);
          dispatch(spinLoaderShow(false));
        })
        .catch((e) => {
          dispatch(spinLoaderShow(false));
        });
    }

    if (data && levelValidationShow.at(-1) == "city") {
      setServiceConfigs([]);
      getServiceConfigData(data);
      getPackagesData(data);
    }
  };

  const getZonesByBranch = (data: any = null) => {
    setZones([]);
    setZone({});
    if (data?.uuid) {
      dispatch(spinLoaderShow(true));
      getZone({ branch_id: data?.uuid, status: true })
        .then((e) => {
          setZones(e?.data?.result);
          dispatch(spinLoaderShow(false));
        })
        .catch((e) => {
          dispatch(spinLoaderShow(false));
        });
    }
    if (data && levelValidationShow.at(-1) == "branch") {
      setServiceConfigs([]);
      getServiceConfigData(data);
      getPackagesData(data);
    }
  };

  const handleZoneChange = (data: any) => {
    setServiceConfigs([]);
    if (data && levelValidationShow.at(-1) == "zone") {
      dispatch(spinLoaderShow(true));
      getServiceConfigData(data);
      getPackagesData(data);
    }
  };

  const getServiceConfigData = (data: any) => {
    getServiceConfig({ level_id: data?.uuid, status: true }).then((e: any) => {
      setServiceConfigs(e?.data?.result);
      dispatch(spinLoaderShow(false));
    });
    getServiceCategory({ status: true }).then((e: any) => {
      setServiceCategories(e.data?.result?.sort((a: any, b: any) => a?.proirity - b.proirity).map((category: any) => { return { ...category, isSelected: false } }))
      dispatch(spinLoaderShow(false));
    })

  };
  const getPackagesData = (data: any) => {
    getViewPackages({ level_id: data?.uuid, status: true }).then((res: any) => {
      let data: any = res?.data?.result?.map((item: any) => {
        item.PackageName = item.PackageName + " | " + item?.VehicleType;
        return item;
      });

      if (packageId) {
        setPackage({
          uuid: data?.find((x: any) => x.uuid == packageId).uuid,
          PackageName: data?.find((x: any) => x.uuid == packageId).PackageName,
        });
      }
      setPackages(data);
    });
  };

  const onSubmit = (data: any) => {
    let format = "YYYY-MM-DD";
    data.start_date = moment(data.start_date).format(format);
    data.end_date = moment(data.end_date).format(format);

    if (services.filter((cfg: any) => cfg.isSelected).length < 1 && !data?.package_id?.uuid) {
      toast.error("Please select at least one service or package");
      return;
    }

    if (data?.user_limit == " ") {
      data.user_limit = null;
    }

    if (data?.code_limit == " ") {
      data.code_limit = null;
    }

    if (!data?.code && !data?.auto_apply) {
      toast.error("Please enter Promotion Code or Turn on AutoApply");
      return;
    }

    let modifyData: any = {
      name: data?.name,
      code: data?.code != "" ? data.code : null,
      user_limit: data?.user_limit != "" ? data.user_limit : null,
      code_limit: data?.code_limit != "" ? data.code_limit : null,
      polygon: data?.polygon,
      specific_location: data?.polygon ? true : null,
      count: data?.count != "" ? data.count : null,
      discount_percentage: data?.discount_percentage,
      start_date: data?.start_date,
      end_date: data?.end_date,
      automate_notifications: data?.automate_notifications,
      notification_title: data?.notification_title,
      notification_body: data?.notification_body,
      status: data?.status,
      service_config_ids: services.filter((cfg: any) => cfg.isSelected).length > 0 ? services.filter((cfg: any) => cfg.isSelected).map((cfg: any) => cfg.uuid) : null,
      service_names: services.filter((cfg: any) => cfg.isSelected).length > 0 ? services.filter((cfg: any) => cfg.isSelected).map((cfg: any) => cfg.name).join(", ") : null,
      auto_apply: data?.auto_apply,
      level_type: data?.level_id.name,
      image: image,
      show_homepage: data?.show_homepage,
      description: data?.description,
      // "color_name": data?.color_name,
      color: data?.color,
      color_secondary: data?.color_secondary,
      package_id: data?.package_id ? data?.package_id?.uuid : null,
    };
    if (data?.level_id?.name == "Country") {
      modifyData.level_id = data?.country_id;
    } else if (data?.level_id?.name == "City") {
      modifyData.level_id = data?.city_id;
    } else if (data?.level_id?.name == "Branch") {
      modifyData.level_id = data?.branch_id;
    } else if (data?.level_id?.name == "Zone") {
      modifyData.level_id = data?.zone_id;
    }
    dispatch(spinLoaderShow(true));
    if (uuid) {
      modifyData.uuid = uuid;
      updateOnSubmit(modifyData);
    } else {
      if (modifyData.code && modifyData?.code?.includes(", ")) {
        createBulkSubmit({ ...modifyData, code: modifyData.code.split(", ") })
      } else {
        createOnSubmit(modifyData);
      }
    }
  };

  const createBulkSubmit = (data: any) => {
    createBulkPromotion(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/promotion");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const createOnSubmit = (data: any) => {
    createPromotion(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/promotion");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    updatePromotion(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        setEditData(null);
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/promotion");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const handleServiceChange = (data: any) => {
    if (data) {
      setService(data);
      setDisabledPackage(true);
      setPackage(null);
    } else {
      setDisabledPackage(false);
    }
  };
  const handlePackageChange = (data: any) => {
    if (data) {
      setPackage(data);
      setDisabledService(true);
      // setDisabledAutoApply(true)
      // setValue("auto_apply", false)
      setService(null);
    } else {
      setDisabledService(false);
      setDisabledAutoApply(false);
    }
  };
  const handleSaveImage = async (files: any) => {
    if (files.length > 0) {
      dispatch(spinLoaderShow(true));
      const formData = new FormData();
      formData.append("images", files[0].file);
      const { data } = await promotionFileUpload(formData);
      setImage(data.result[0]);
      dispatch(spinLoaderShow(false));
    }
  };
  const onChangeColorFirst = (data: any) => {
    setColor(data);
  };
  const onChangeColorSecond = (data: any) => {
    setColorSecond(data);
  };

  const onCountChange = (data: any) => {
    console.log("data", data.target.value);
    if (data.target.value) {
      setDisabledAutoApply(true);
      setValue("auto_apply", false);
      setValue("count", data.target.value);
    } else {
      if (!getValues("code")) {
        setDisabledAutoApply(false);
      }
      setValue("count", null);
    }
  };

  const onCodeChange = (data: any) => {
    console.log("data", data.target.value);
    if (data.target.value) {
      if (data.target.value.includes(",")) {
        setDisabledCount(true)
      }
      setDisabledAutoApply(true);
      setValue("auto_apply", false);
      setValue("code", data.target.value);
    } else {
      if (!getValues("count")) {
        setDisabledAutoApply(false);
      }
      setDisabledCount(false)
      setValue("code", null);
    }
  };

  const handleCategoryChecked = (item: any, e: any) => {
    setServiceCategories(serviceCategories.map((cat: any) => {
      let temp = { ...cat };
      if (temp.uuid === item.uuid) {
        temp.isSelected = e.target.checked;
      }
      return temp;
    }))
    setServices(services.map((cfgs: any) => {
      let temp = { ...cfgs }
      if (temp.service_category_id === item.uuid) {
        temp.isSelected = e.target.checked
      }
      return temp
    }))
  }

  useEffect(() => {
    let temp: any = []
    services.forEach((item: any) => {
      const checkedConfigs = services.filter((cfg: any) => cfg.service_category_id === item.service_category_id);
      const allConfigsSelected = checkedConfigs.every((cfg: any) => cfg.isSelected);

      temp = serviceCategories.map((cat: any) => {
        if (cat.uuid === item.service_category_id) {
          cat.isSelected = allConfigsSelected;
        }
        return cat;
      })
    });
    setServiceCategories(temp)
  }, [services])

  const handleServiceConfigChecked = (item: any, e: any) => {
    setServices(services.map((cfgs: any) => {
      if (cfgs.uuid === item.uuid) {
        cfgs.isSelected = e.target.checked
      }
      return cfgs
    }))
  }

  const onAutoApplyChange = (data: any) => {
    if (data == true) {
      setValue("code", null);
      setValue("count", null);
      setDisabledCount(true);
      setCodeDisable(true);
    } else {
      setDisabledCount(false);
      setCodeDisable(false);
    }
  };
  const onStartChange = (data: any) => {
    setStartDate(data);
  };
  const onEndChange = (data: any) => {
    setEndDate(data);
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target?.result;
        // Call a function to convert XLSX to array
        const dataArray = convertXLSXToArray(content);
        setDisabledAutoApply(true);
        setValue("auto_apply", false);
        setDisabledCount(true)
        setValue("code", dataArray.join(", "))
      };

      reader.readAsBinaryString(file);
    }
  };

  const convertXLSXToArray = (xlsxContent: any) => {
    const workbook = XLSX.read(xlsxContent, { type: 'binary' });
    const sheetName = workbook.SheetNames[0]; // Assuming there is only one sheet
    const sheet = workbook.Sheets[sheetName];
    // Convert sheet data to array
    const dataArray = XLSX.utils.sheet_to_txt(sheet);
    return dataArray.split("\n");
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Add an Offer "
              routes={routes}
              newItem={[
                // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
                { text: "Back", link: "/admin/promotion" },
              ]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <h4 className={classes.cardTitleWhite}>
                    {" "}
                    {headerTitle} Offer
                  </h4>
                  {uuid ? (
                    <h4 className={classes.cardTitleWhite}>
                      Modified At: {moment(editData?.updatedAt).format("llll")}
                    </h4>
                  ) : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      iProps={{
                        disabled: disabled,
                      }}
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>

                  {/* <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.service_config_id}
                      fieldName="service_config_id"
                      autoComplete="off"
                      label="* Select Service Config Id"
                      control={control}
                      setValue={setValue}
                      options={serviceconfig}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="level_type"
                      helperText={
                        errors?.service_config_id && errors?.service_config_id?.message
                      }
                      valueGot={editData?.service_config_id && serviceconfig?.find(({uuid}:any)=>{ return uuid == editData?.service_config_id})}
                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.discount_percentage}
                      fieldName="discount_percentage"
                      type="text"
                      autoComplete="off"
                      label="* Discount Percentage"
                      control={control}
                      iProps={{
                        type: "number",
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.discount_percentage &&
                        errors?.discount_percentage?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.code}
                      fieldName="code"
                      type="text"
                      autoComplete="off"
                      label="Code"
                      control={control}
                      iProps={{
                        onChange: onCodeChange,
                        disabled: disabled || codeDisable,
                      }}
                      InputProps={{
                        endAdornment: uuid ? <></> :
                          <InputAdornment position="end">
                            <label htmlFor="file-input" className="file-upload-label">
                              <UploadFileIcon />
                            </label>
                            <input type="file" id="file-input" onChange={handleFileUpload} style={{ display: 'none' }} />
                          </InputAdornment>
                        ,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.code && errors?.code?.message}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.code_limit}
                      fieldName="code_limit"
                      type="text"
                      autoComplete="off"
                      label="How many user? (Code Limit)"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.code_limit && errors?.code_limit?.message
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.user_limit}
                      fieldName="user_limit"
                      type="text"
                      autoComplete="off"
                      label="How many times for each user? (Usage Limit)"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.user_limit && errors?.user_limit?.message
                      }
                    />
                  </GridItem>
                  {!editData && (
                    <GridItem xs={12} sm={12} md={4}>
                      <InputField
                        errors={!!errors?.count}
                        fieldName="count"
                        type="text"
                        autoComplete="off"
                        label="Count"
                        control={control}
                        iProps={{
                          disabled: disabled || disabledCount,
                          onChange: onCountChange,
                        }}
                        valueGot={""}
                        setValue={setValue}
                        helperText={errors?.count && errors?.count?.message}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.description}
                      fieldName="description"
                      type="text"
                      autoComplete="off"
                      label="* Description"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.description && errors?.description?.message
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.start_date}
                      fieldName="start_date"
                      autoComplete="off"
                      label="* Start Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true,
                        disabled: disabled,
                        format: "dd-MM-yyyy",
                        onChange: onStartChange,
                      }}
                      helperText={
                        errors?.start_date && errors?.start_date?.message
                      }
                      valueGot={startDate}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.end_date}
                      fieldName="end_date"
                      autoComplete="off"
                      label="* End Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true,
                        disabled: disabled,
                        format: "dd-MM-yyyy",
                        onChange: onEndChange,
                      }}
                      helperText={errors?.end_date && errors?.end_date?.message}
                      valueGot={endDate}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.auto_apply}
                      fieldName="auto_apply"
                      autoComplete="off"
                      label="Auto Apply"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled || disabledAutoapply}
                      helperText={
                        errors?.auto_apply && errors?.auto_apply?.message
                      }
                      iProps={{
                        onChange: onAutoApplyChange,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.show_homepage}
                      fieldName="show_homepage"
                      autoComplete="off"
                      label="Show on Home Screen"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.show_homepage && errors?.show_homepage?.message
                      }
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "15px" }}
                  >
                    <SwitchFieldDefault
                      errors={!!errors?.automate_notifications}
                      fieldName="automate_notifications"
                      autoComplete="off"
                      label="Send Automate Notification"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.automate_notifications &&
                        errors?.automate_notifications?.message
                      }
                      iProps={{
                        onChange: (data: boolean) => setShowNotification(data),
                      }}
                    />
                  </GridItem>

                  {showNotification && (
                    <>
                      <GridItem xs={12} md={12} sm={12}>
                        <InputField
                          errors={!!errors?.notification_title}
                          fieldName="notification_title"
                          type="text"
                          autoComplete="false"
                          label="* Notification Title"
                          control={control}
                          isLoading={true}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.notification_title &&
                            errors?.notification_title?.message
                          }
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <InputField
                          errors={!!errors?.notification_body}
                          fieldName="notification_body"
                          multiline={true}
                          rows={7}
                          type="text"
                          autoComplete="false"
                          label="body"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.notification_body &&
                            errors?.notification_body?.message
                          }
                        />
                      </GridItem>
                    </>
                  )}

                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.level_id}
                      fieldName="level_id"
                      autoComplete="off"
                      label="* Select Level"
                      control={control}
                      setValue={setValue}
                      options={levels}
                      optionKey="id"
                      returnObject={true}
                      iProps={{
                        onChange: handleConfigLevel,
                        disabled: disabled,
                      }}
                      isLoading={true}
                      optionValue="name"
                      helperText={errors?.level_id && errors?.level_id?.message}
                      valueGot={levelGot}
                    />
                  </GridItem>
                  {levelValidationShow?.includes("country") && (
                    <GridItem xs={12} sm={12} md={4}>
                      <AutoCompleteField
                        errors={!!errors?.country_id}
                        fieldName="country_id"
                        autoComplete="off"
                        label="* Select Country"
                        control={control}
                        setValue={setValue}
                        options={countries}
                        isLoading={countries?.length > 0 ? true : false}
                        optionKey="uuid"
                        iProps={{
                          onChange: getCitiesByCountry,
                          disabled: disabled,
                        }}
                        optionValue="country_name"
                        helperText={
                          errors?.country_id && errors?.country_id?.message
                        }
                        valueGot={country}
                      />
                    </GridItem>
                  )}
                  {levelValidationShow?.includes("city") && (
                    <GridItem xs={12} sm={12} md={4}>
                      <AutoCompleteField
                        errors={!!errors?.city_id}
                        fieldName="city_id"
                        autoComplete="off"
                        label="* Select City"
                        control={control}
                        setValue={setValue}
                        options={cities}
                        isLoading={true}
                        optionKey="uuid"
                        iProps={{
                          onChange: getBranchesByCity,
                          disabled: disabled,
                        }}
                        optionValue="city_name"
                        helperText={errors?.city_id && errors?.city_id?.message}
                        valueGot={city}
                      />
                    </GridItem>
                  )}
                  {levelValidationShow?.includes("branch") && (
                    <GridItem xs={12} sm={12} md={4}>
                      <AutoCompleteField
                        errors={!!errors?.branch_id}
                        fieldName="branch_id"
                        autoComplete="off"
                        label="* Select Branch"
                        iProps={{
                          onChange: getZonesByBranch,
                          disabled: disabled,
                        }}
                        control={control}
                        setValue={setValue}
                        options={branches}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={
                          errors?.branch_id && errors?.branch_id?.message
                        }
                        valueGot={branch}
                        isLoading={true}
                      />
                    </GridItem>
                  )}
                  {levelValidationShow?.includes("zone") && (
                    <GridItem xs={12} sm={12} md={4}>
                      <AutoCompleteField
                        errors={!!errors?.zone_id}
                        fieldName="zone_id"
                        autoComplete="off"
                        label="* Select Zone"
                        control={control}
                        setValue={setValue}
                        iProps={{
                          onChange: handleZoneChange,
                          disabled: disabled,
                        }}
                        options={zones}
                        optionKey="uuid"
                        optionValue="name"
                        helperText={errors?.zone_id && errors?.zone_id?.message}
                        valueGot={zone}
                        isLoading={true}
                      />
                    </GridItem>
                  )}

                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.package_id}
                      fieldName="package_id"
                      autoComplete="off"
                      label="Select Package"
                      control={control}
                      setValue={setValue}
                      options={packages}
                      optionKey="uuid"
                      returnObject={true}
                      isLoading={true}
                      iProps={{
                        onChange: handlePackageChange,
                        disabled: disabled || disabledPackage,
                      }}
                      optionValue="PackageName"
                      helperText={
                        errors?.package_id && errors?.package_id?.message
                      }
                      valueGot={packageData}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>{services?.length > 0 && <Typography variant="h6">Services</Typography>}</GridItem>
                  <GridItem xs={6} sm={12} md={6} >
                    {serviceCategories?.length > 0 && serviceCategories.map((category: any) => {
                      return (
                        <Box key={category.uuid}>
                          <GridItem xs={6} sm={12} md={12} style={{ display: 'inline-flex' }}>
                            <Checkbox
                              checked={category.isSelected}
                              onChange={(e) => handleCategoryChecked(category, e)}
                              disabled={disabled}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <Typography style={{ marginTop: "9px" }}> {category.name}</Typography>

                          </GridItem>
                        </Box>
                      )
                    })}
                  </GridItem>
                  <GridItem xs={6} sm={12} md={6} >
                    {serviceCategories?.length > 0 && serviceCategories.map((category: any) => {
                      return (
                        <GridItem xs={6} sm={12} md={12} key={category.uuid}>
                          <Box style={{ display: 'inline-flex' }}>
                            <Checkbox
                              checked={category.isSelected}
                              onChange={(e) => handleCategoryChecked(category, e)}
                              disabled={disabled}
                              inputProps={{ "aria-label": "primary checkbox" }}
                            />
                            <Typography style={{ marginTop: "9px" }}> {category.name}</Typography></Box>
                          {services?.filter((svc: any) => svc.service_category_id === category.uuid).map((cfg: any) => {
                            return (<Box key={cfg.uuid}><GridItem xs={6} sm={12} md={12} style={{ display: 'inline-flex' }}>
                              <Checkbox
                                checked={cfg.isSelected}
                                onChange={(e) => handleServiceConfigChecked(cfg, e)}
                                disabled={disabled}
                                inputProps={{ "aria-label": "primary checkbox" }}
                              />
                              <Typography style={{ marginTop: "9px" }}> {cfg.name}</Typography></GridItem></Box>)
                          })}
                        </GridItem>
                      )
                    })}
                  </GridItem>


                  <GridItem xs={2} sm={2} md={2}>
                    <span
                      className={classes.dot}
                      style={{ backgroundColor: color }}
                    ></span>
                    <ColorPickerField
                      errors={!!errors?.color}
                      fieldName="color"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={errors?.color && errors?.color?.message}
                      valueGot={editData && editData?.color}
                      iProps={{
                        onChange: onChangeColorFirst,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2}>
                    <span
                      className={classes.dot}
                      style={{ backgroundColor: colorSecond }}
                    ></span>
                    <ColorPickerField
                      errors={!!errors?.color_secondary}
                      fieldName="color_secondary"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      helperText={
                        errors?.color_secondary &&
                        errors?.color_secondary?.message
                      }
                      valueGot={editData && editData?.color_secondary}
                      iProps={{
                        onChange: onChangeColorSecond,
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Image </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridItem xs={12} sm={12} md={12} className={"mt-15"}>
                          <Container className={classes.cont1}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4}>
                                <CardMedia
                                  className={classes.media}
                                  image={
                                    image
                                      ? image
                                      : "https://www.lifewire.com/thmb/P856-0hi4lmA2xinYWyaEpRIckw=/1920x1326/filters:no_upscale():max_bytes(150000):strip_icc()/cloud-upload-a30f385a928e44e199a62210d578375a.jpg"
                                  }
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={4}>
                                <Box className={classes.drop}>
                                  {disabled == false ? (
                                    <FileUploadArea
                                      handleSave={handleSaveImage}
                                    />
                                  ) : (
                                    <div></div>
                                  )}
                                </Box>
                                {/* <ReactCrop src={image}
                                crop={crop} onChange={newCrop => setCrop(newCrop)} 
                                /> */}
                              </GridItem>
                            </GridContainer>
                          </Container>
                        </GridItem>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Typography variant={"subtitle1"}>
                      Draw area for promotion:{" "}
                      <span className="google-map-error-text">
                        {errors.polygon && errors?.polygon?.latLng?.message}
                      </span>
                    </Typography>
                    {editData && (
                      <FormHelperText
                        error={errors.polygon && true}
                        className="google-map-error"
                      >
                        <GoogleMap
                          value={setMapValues}
                          setValue={{
                            latLng: editData?.polygon,
                          }}
                          autocomplete
                          polygon={disabled ? false : true}
                          reset={resetMap}
                          zoom={
                            editData?.polygon?.zoom
                              ? editData?.polygon?.zoom
                              : 14
                          }
                          center={
                            editData?.polygon?.center
                              ? editData?.polygon?.center
                              : {
                                lat: 25.276987,
                                lng: 55.296249,
                              }
                          }
                        >
                          <DrawMap data={editData} />
                        </GoogleMap>
                      </FormHelperText>
                    )}

                    {!editData && (
                      <FormHelperText
                        error={errors.polygon && true}
                        className="google-map-error"
                      >
                        <GoogleMap
                          value={setMapValues}
                          setValue={{
                            latLng: editData?.polygon,
                          }}
                          autocomplete
                          polygon={disabled ? false : true}
                          reset={resetMap}
                          zoom={
                            editData?.polygon?.zoom
                              ? editData?.polygon?.zoom
                              : 14
                          }
                          center={
                            editData?.polygon?.center
                              ? editData?.polygon?.center
                              : {
                                lat: 25.276987,
                                lng: 55.296249,
                              }
                          }
                        >
                          <DrawMap data={editData} />
                        </GoogleMap>
                      </FormHelperText>
                    )}
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    disabled={disabled}
                    className={"custom-btn"}
                    type="submit"
                  >
                    {uuid ? "Update Offer" : "Create Offer"}
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Table from "components/Table/Table";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import PageHeader from "components/PageHeader/PageHeader";
import { Popup } from "components/Popup/Popup.component";
import { TypePopup } from "containers/VehicleTypeManager/type.popup.component";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import StatusComponent from "components/Mix/Status";
import AgGridCustom from "components/AgGridCustom/AgGridCustom";
import { getBooking } from "services/userservices";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment-timezone";
import { verifyPermission } from "helper/permission";
import { useSelector } from "react-redux";
import Button from "components/CustomButtons/Button";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useLocation } from "react-router";

const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  icon: {
    color: "#043cae",
    "&:hover": {
      color: "#4287f5",
    },
  },
};

const useStyles = makeStyles(styles);

export const BookingDetail = (props: any) => {
  const getPermission = useSelector((state: any) => {
    return state.userPermission.permissions;
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [editData, setEditData] = useState<any>({});
  const [headerTitle, setHeaderTitle] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const [exportData, setExportData] = useState<any>(null);
  const dispatch = useDispatch();
  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Booking Details",
      link: "",
    },
  ];

  const getApiData = () => {
    dispatch(spinLoaderShow(true));
    getBooking({ uuid: uuid })
      .then((e) => {
        let data = e?.data?.result[0]?.booking_details
          .map((item: any) => {
            item.status = item.status ? "active" : "inactive";
            return item;
          })
          .filter((x: any) => {
            return x.type !== "package";
          });
        setRowData(data);
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        dispatch(spinLoaderShow(false));
      });
  };

  useEffect(() => {
    getApiData();
    // let ispermission = !(verifyPermission(
    //   getPermission,
    //   'Vehicles',
    //   'Type',
    //   'update'
    // ))
    // console.log(ispermission)
    // setDisabled(ispermission)
  }, []);
  const columnDefs: any = [
    {
      headerName: "Service Name",
      field: "service_config.service.name",
    },
    {
      headerName: "Booking Status",
      field: "booking_status.name",
    },
    {
      headerName: "Price ",
      field: "price",
    },
    {
      headerName: "Type",
      field: "type",
    },
    {
      headerName: "Booked At",

      field: "bookingAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format("llll");
      },
    },
    {
      headerName: "Start Time",
      field: "start_time",
    },
    {
      headerName: "End Time",
      field: "end_time",
    },

    {
      headerName: "Created Date",
      field: "createdAt",
      filter: "agDateColumnFilter",
      cellRendererFramework: (params: any) => {
        return moment(params.value).format("llll");
      },
    },
    {
      headerName: "Actions",
      field: "action",
      sortable: false,
      filter: false,
      // hide: disabled,
      cellRendererFramework: (params: any) => {
        // put the value in bold
        return (
          <Link
            to={`/admin/booking/details/add?booking_id=${params?.data?.booking_id}&uuid=${params?.data?.uuid}`}
          >
            <VisibilityIcon />
          </Link>
        );
      },
    },
  ];

  const refreshData = () => {
    dispatch(spinLoaderShow(true))
    getApiData()
  
  }


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <PageHeader
            title="Booking Details"
            routes={routes}
            newItem={[
              // { text: 'Add', link: "/admin/city/add", 'uuid': uuid ? false : true },
              { text: "Back", link: "/admin/booking" },
            ]}
            backIcon
          />
        </GridItem>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" className="card-header-export-btn">
            <h4 className={classes.cardTitleWhite}>Booking List</h4>
            <Button
              variant="outlined"
              className="custom-btn export-btn"
              onClick={(e: any) => exportData.exportDataAsCsv()}
            >
              Export to Csv
            </Button>
          </CardHeader>
          <CardBody>
          <Button variant="outlined" color="primary"  className="custom-btn export-btn " onClick={(e: any) => refreshData()}>Refresh</Button>
            <AgGridCustom
              disabledCheckBox={true}
              columnDefs={columnDefs}
              rowData={rowData}
              getRowNodeId={function (data: any) {
                return data.id;
              }}
              setExportData={setExportData}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

import * as yup from 'yup'


const createCitiesValidation:any = yup.object().shape({
    city_name: yup.string().required("Please enter city name ").nullable(),
   // city_code: yup.string().required("Please enter city code").nullable(),
    country_id: yup.string().required("Please enter country ").nullable(),
    // schedule_id:yup.string().required("Please enter schedule ").nullable(),
    // schedule_id:yup.string().nullable(),
    polygon: yup.object({
        latLng: yup.array().required("Please draw map."),
    }).required("Please draw map.").nullable(),
  });



  export {
      createCitiesValidation
  }
import * as yup from "yup";
export const BookJinoValidation: any = yup.object().shape({
  book_jino: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      description: yup.string().required("Please enter Description").nullable(),
      sub_title: yup.string().required("Please enter Sub Title").nullable(),
      main_title: yup.string().required("Please enter Main Title").nullable(),
      main_description: yup.string().required("Please enter Main Description").nullable(),
     
    })
  ),
});

import * as yup from 'yup'


const createServiceValidation: any = yup.object().shape({
    name: yup.string().required("Please enter name ").nullable(),
    description: yup.string().required("Please enter description").nullable(),
    // short_description:yup.string().required("Please enter description").nullable(),
    service_category_id: yup.string().required("Please select category ").nullable(),
    priority:yup.number().typeError("Please enter priority").required("Please enter priority").nullable(),
    // service_type_id: yup.object().required("Please select type").nullable(),
    pairs: yup.array().when("service_type_id", {
        is: (data: any) => {
            return data?.validation == 'pair' ? true : false;
        },
        then: yup.array().required("Please select service").nullable()
    }).nullable()
});

export {
    createServiceValidation
}
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Button, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Box } from "@material-ui/core";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import PageHeader from "components/PageHeader/PageHeader";
import {
  InputField,
  LoadingButton,
  SwitchField,
} from "components/Forms/Formfield.component";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import Divider from "@material-ui/core/Divider";

import "../../../src/assets/css/PasswordStrengthMeter.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidation } from './Validations/profileValidation'
import { getUser, getUserName, getSession, isvalid } from "services/cognitouser";
import { Auth } from 'aws-amplify'
import { getAdmin ,updateAdmin} from "services/userservices";
import { useDispatch } from "react-redux";
import { spinLoaderShow } from "redux/action/spinLoader";
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { toast } from "react-toastify";
const styles: any = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const defautlValues: any = {
  first_name: '',
  last_name: '',
  email: ''
};

const useStyles = makeStyles(styles);

export const UpdateProfile = (props: any) => {


  const classes = useStyles();
  const dispatch = useDispatch()
  const [editData,setEditData]=useState<any>([])


  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
    setValue,
    getValues
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defautlValues,
    resolver: yupResolver(profileValidation)
  });

  useEffect(() => {
   let email = localStorage.getItem("email")
   getUserInformation(email)
  }, [])
  const onSubmit = async (data: any) => {
   delete data.email 
   console.log("data",data)
   data.uuid = editData?.uuid
   dispatch(spinLoaderShow(true))
   updateAdmin(data).then((e: any) => {
    dispatch(spinLoaderShow(false));
    toast.success(UPDATE_SUCCESS_MSG);
  })
  .catch((e: any) => {
    dispatch(spinLoaderShow(false));
  });
  };

  const getUserInformation = (data:any) =>{
    getAdmin({email:data}).then((res:any)=>{
      let data : any = res?.data?.result[0];
      console.log("123",data)
      setValue("first_name",String(data?.first_name))
      setValue("last_name",String(data?.last_name))
      setValue("email",String(data?.email))
      setEditData(data)
      dispatch(spinLoaderShow(false))

    }).catch((err)=>{
      console.log(err)
      dispatch(spinLoaderShow(false))
    })
  }

  useEffect(()=>{
   console.log("err",errors)
  },[errors])

  return (


    <form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>

        <GridContainer spacing={3}>
          <GridItem xs={4} sm={4} md={4}>
            <InputField
              errors={!!errors?.first_name}
              fieldName="first_name"
              type="text"
              autoComplete="false"
              label="First Name"
              control={control}
              valueGot={""}
              setValue={setValue}
            //helperText={errors?.first_name && errors?.first_name?.message}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <InputField
              errors={!!errors?.last_name}
              fieldName="last_name"
              type="text"
              autoComplete="false"
              label="Last Name"
              control={control}
              valueGot={""}
              setValue={setValue}
            // helperText={errors?.last_name && errors?.last_name?.message}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <InputField
              errors={!!errors?.email}
              fieldName="email"
              type="text"
              autoComplete="false"
              label="* Email"
              iProps={{
                disabled:true
              }}
              control={control}
              isLoading={true}
              valueGot={""}
              setValue={setValue}
              helperText={errors?.email && errors?.email?.message}

            />

          </GridItem>
          <Divider />
        </GridContainer>

      </CardBody>
      <CardFooter>
              <Box
                display="flex"
                justifyContent="flex-end"
                style={{ width: "100%" }}
              >
                {/* <Button variant="contained"  color="primary" type="submit" className="button">Change password</Button>    */}
                <LoadingButton
                  className={"custom-btn"}
                  type="submit"
                  loading={false}
                  text="Update Profile"
                />
              </Box>
            </CardFooter>
     
    </form>





  );
};

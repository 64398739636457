import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Avatar,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
// import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const FactColumnAccordian = (props: any) => {
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [disabled, setDisabled] = useState<any>(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `fact_columns`,
  });

  useEffect(() => {
    if (apiData) {
      // console.log(apiData?.page_content?.accomplishments);
      setValue("fact_columns", apiData?.page_content?.fact_columns);
    }
  }, [apiData]);

  // useEffect(() => {
  //   [1, 2, 3, 4, 5, 6].forEach(() => {
  //     append({
  //       logo: "",
  //       title: "",
  //       description: "",
  //     });
  //   });
  // }, []);

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Fact Columns </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index) => {
          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={4} md={4} sm={4} style={{ marginTop: "07px" }}>
                <Box
                  style={{ display: "flex", alignItems: "center" }}
                  alignItems="center"
                >
                  <FileUploadFieldArray
                    fieldName={`fact_columns[${index}].logo`}
                    keyss={`fact_columns[${index}.logo]`}
                    open={dropOpen}
                    index={index}
                    lable={"Fact Image"}
                    setOpen={setDropOpen}
                    setValue={props?.setValue}
                    getValuesX={getValues}
                    control={props?.control}
                    disabled={disabled}
                    message={
                      "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                    }
                  />
                </Box>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.fact_columns?.[index]?.title}
                  fieldName={`fact_columns[${index}].title`}
                  type="text"
                  label="* Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.fact_columns?.[index]?.title &&
                    errors?.fact_columns?.[index]?.title.message
                  }
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.fact_columns?.[index]?.description}
                  fieldName={`fact_columns[${index}].description`}
                  type="text"
                  label="* Description"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.fact_columns?.[index]?.description &&
                    errors?.fact_columns?.[index]?.description.message
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} style={{ marginBottom: "25px" ,marginLeft:"10px"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={"custom-btn"}
                    onClick={() => remove(index)}
                  >
                    Delete
                  </Button>
                </GridItem>
            </GridContainer>
          );
        })}
        {/* <button
          type="button"
          onClick={() => append({ logo: "", title: "", description: "" })}
        >
          append
        </button> */}
      </AccordionDetails>
      <AccordionActions></AccordionActions>
    </ControlledAccordion>
  );
};

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  InputField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { MenuValidation } from "./validations/menu-validation";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const MenuAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const { apiData, data, permission, collapse } = props;

  const defaultFormValues: any = {
    menu: [],
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(MenuValidation),
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: `menu`,
  });
  useEffect(() => {
    [1, 2, 3, 4, 5, 6].forEach(() => {
      append({
        logo: "",
        title: "",
        appstore_link: "",
        page_url: "",
        playstore_link: "",
        status: true,
      });
    });
  }, []);
  useEffect(() => {
    if (apiData) {
      setValue("menu", apiData?.menu_section?.menu);
      // console.log("apiData", apiData);
    }
  }, [apiData]);
  const onSubmit = (data: any) => {
    const payload = { menu_section: data };
    updateOnSubmit(payload);
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateHeaderSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledAccordion collapse={collapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Menu</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {fields.map((item: any, index) => {
            return (
              <GridContainer key={item.id} spacing={2} >
                <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
                  <Box
                    style={{ display: "flex", alignItems: "center" }}
                    alignItems="center"
                  >
                    <FileUploadFieldArray
                      keyss={`menu[${index}.logo]`}
                      open={dropOpen}
                      index={index}
                      apiData={apiData}
                      lable={"Menu Image"}
                      setOpen={setDropOpen}
                      setValue={setValue}
                      getValuesX={getValues}
                      control={control}
                      disabled={disabled}
                      message={
                        "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                      }
                    />
                  </Box>
                </GridItem>
                <GridItem xs={4} sm={4} md={4} style={{ marginTop: "40px" }}>
                  <SwitchFieldDefault
                    errors={!!errors?.status}
                    fieldName={`menu[${index}].status`}
                    autoComplete="off"
                    label="status"
                    defaultChecked="true"
                    control={control}
                    optionKey="uuid"
                    optionValue="status"
                    disabled={disabled}
                    valueGot={""}
                    setValue={setValue}
                    h
                    helperText={
                      errors?.menu?.[index]?.status &&
                      errors?.menu?.[index]?.status.message
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6} >
                  <InputField
                    errors={!!errors?.menu?.[index]?.title}
                    fieldName={`menu[${index}].title`}
                    type="text"
                    label="* Title"
                    autoComplete="false"
                    control={control}
                    setValue={setValue}
                    valueGot={""}
                    helperText={
                      errors?.menu?.[index]?.title &&
                      errors?.menu?.[index]?.title.message
                    }
                  />
                </GridItem>
                {/* <GridItem xs={6} sm={6} md={6}>
                  <InputField
                    errors={!!errors?.menu?.[index]?.appstore_link}
                    fieldName={`menu[${index}].appstore_link`}
                    type="text"
                    label="* AppStore Link"
                    autoComplete="false"
                    control={control}
                    setValue={setValue}
                    valueGot={""}
                    helperText={
                      errors?.menu?.[index]?.appstore_link &&
                      errors?.menu?.[index]?.appstore_link.message
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{marginBottom:"30px"}}>
                  <InputField
                    errors={!!errors?.menu?.[index]?.playstore_link}
                    fieldName={`menu[${index}].playstore_link`}
                    type="text"
                    label="* PlayStore Link"
                    autoComplete="false"
                    control={control}
                    setValue={setValue}
                    valueGot={""}
                    helperText={
                      errors?.menu?.[index]?.playstore_link &&
                      errors?.menu?.[index]?.playstore_link.message
                    }
                  />
                </GridItem> */}
                <GridItem xs={6} sm={6} md={6} style={{marginBottom:"30px"}}>
                  <InputField
                    errors={!!errors?.menu?.[index]?.page_url}
                    fieldName={`menu[${index}].page_url`}
                    type="text"
                    label="* Page Url"
                    autoComplete="false"
                    control={control}
                    setValue={setValue}
                    valueGot={""}
                    helperText={
                      errors?.menu?.[index]?.page_url &&
                      errors?.menu?.[index]?.page_url.message
                    }
                  />
                </GridItem>
              </GridContainer>
            );
          })}

          <GridContainer
            spacing={3}
            justify="end"
            alignItems="center"
            className={"textAlignEnd"}
          >
            {/* <GridItem xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                className={"custom-btn"}
                onClick={() => {
                  append({
                    logo: "",
                    title: "",
                    appstore_link: "",
                    playstore_link: "",
                  });
                }}
              >
                Add more
              </Button>
            </GridItem> */}
          </GridContainer>
        </AccordionDetails>
        <AccordionActions>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            {/* {permission === false ? (
              
            ) : (
              ""
            )} */}
            <Button color="primary" className={"custom-btn"} type="submit">
              Update
            </Button>
          </Box>
        </AccordionActions>
      </ControlledAccordion>
    </form>
  );
};

import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import RTLNavbarLinks from "./RTLNavbarLinks";
import Button from "components/CustomButtons/Button";

//hooks
import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle";
const styles:any = headerStyle;
import {useRouteName} from "../../hooks";
import { useEffect } from "react";

const useStyles = makeStyles(styles);
export default function Header(props:any) {
  const classes = useStyles();
  const [routeName,setRouteName] = useState<any>('');
  // @ts-ignore
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });

  useEffect(()=>{
    setRouteName(useRouteName());
  },[window.location.href])

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* <Button color="transparent" href="#" className={classes.title}>
            {routeName}
          </Button> */}
        </div>
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

import React, { Fragment, useEffect, useState } from "react";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import {  InputField, TimePickerField, SwitchField } from "components/Forms/Formfield.component";
import { Typography } from "@material-ui/core";
import { useFieldArray } from "react-hook-form";
import _ from 'lodash';
import moment from 'moment';

const TimeScheduledModal = (props: any) => {

  const { control, errors, setValue, dateDisabled, setDateDisabled, editData, scheduleDays, getValues ,scheduleType} = props;
  const [grid, setGrid]=useState<any>(2)

  const { fields: afields, remove } = useFieldArray({
    control: control,
    name: 'schedule_details'
  });

  const handleChange = (event: any) => {
    let fieldName: any = event.target.name.split(".")?.[2];
    setDateDisabled({ ...dateDisabled, ...{ [fieldName]: !event.target.checked } })
    setValue(event.target.name, !event.target.checked)
  };


  useEffect(() => {
    let values = getValues('schedule_details');

    if (Object.keys(editData).length > 0) {
      let _data = editData?.details?.map((item: any) => {
        return {
          ...item,
          ...{
            day: scheduleDays?.find((element: any) => item?.day_id == element?.uuid)?.name?.toLowerCase()
          }
        };
      })

      const { temp, tempStatus }: any = fillValues(_data);
      console.log("Temp detel haziq",JSON.parse(JSON.stringify(temp)))
      let tempSorted : any = JSON.parse(JSON.stringify(temp));
    
    
      
      const mapData:any = {
        'monday': 1,'tuesday': 2,'wednesday': 3,'thursday': 4,'friday': 5,'saturday': 6,
        'sunday': 7
     };
     tempSorted.sort((a:any, b:any) => {
        return mapData[a.key] - mapData[b.key];
     });
     console.log("Sorted", tempSorted)

      setValue('schedule_details', JSON.parse(JSON.stringify(tempSorted)))
      setDateDisabled(tempStatus)
    } else if (Object.keys(values).length < 1) {
      let temp: any = [];
      scheduleDays.map((item: any, index: any) => {
        let obj = {
          key: item.name?.toLowerCase(),
          [item.name?.toLowerCase()]: {
            id: index,
            day_id: item.uuid,
            name: item.name?.toLowerCase(),
            status: true,
            start_time: null,
            end_time: null,
            break_start_time: null,
            break_end_time: null,
          }
        };
        temp.push(obj)
      })
      setValue('schedule_details', temp)
    } else {
      setValue("schedule_details", values)
    }
  }, [editData, scheduleDays])


  const fillValues = (data: any) => {
    let temp: any = [];
    let tempStatus: any = {};
    data.map((item: any, index: any) => {
      console.log("moment(item.start_time).format()",moment().format('YYYY-MM-DD')+' '+item.start_time)
      console.log("start_time",item?.start_time)

      let obj = {
        key: item.day,
        [item.day]: {
          id: index,
          day_id: item.day_id,
          name: item.day,
          status: item.status,
          start_time: item.start_time && moment().format('YYYY-MM-DD')+' '+item.start_time,
          end_time: item.end_time && moment().format('YYYY-MM-DD')+' '+item.end_time,
          break_start_time: item.break_start_time && moment().format('YYYY-MM-DD')+' '+item.break_start_time,
          break_end_time: item.break_end_time && moment().format('YYYY-MM-DD')+' '+item.break_end_time,
        }
      };
      temp.push(obj)
      tempStatus = { ...tempStatus, ...{ [item.day]: item.status } }
    })

    return {
      temp,
      tempStatus
    }
  }

 useEffect(()=>{
  console.log("errors",errors)
 },[errors])
  return (
    <GridContainer spacing={3} justify="center" alignItems="center">
      <>
        <GridItem xs={2} sm={2} md={2}>
          Day Name
        </GridItem>
        <GridItem xs={2} sm={2} md={2}>
          On/Off
        </GridItem>
        <GridItem xs={4} sm={4} md={2}>
          Start Time
        </GridItem>
        <GridItem xs={4} sm={4} md={2}>
          End Time
        </GridItem>
        <>
        <GridItem xs={4} sm={4} md={2}>
         Break Start Time
        </GridItem>
        <GridItem xs={4} sm={4} md={2}>
        Break End Time
        </GridItem></>   
        <GridItem xs={12} sm={12} md={12}>
          {afields.length > 0 && afields.map((item: any, index) => {
            let day_name: string = item?.key?.toLowerCase();
            return (
              <GridContainer key={item.id}>
                <GridItem xs={2} sm={2} md={2}>
                  <InputField
                    control={control}
                    setValue={setValue}
                    type="hidden"
                    valueGot={item.uuid}
                    isLoading={true}
                    iProps={{
                      disabled:props?.disabled,
                    }}
                    initValue={item.uuid}
                    fieldName={`schedule_details.${index}.${day_name}.day_id`}
                  />
                  <Typography variant="button" display="block" >
                    {day_name}
                  </Typography>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <SwitchField
                    errors={!!errors?.schedule_details?.[index]?.[day_name]?.status}
                    control={control}
                    setValue={setValue}
                    checked={!dateDisabled?.[day_name]}
                    isLoading={true}
                    helperText={errors?.schedule_details?.[index]?.[day_name]?.status && errors?.schedule_details?.[index]?.[day_name]?.status?.message}
                    iProps={{
                      onChange: handleChange
                    }}
                    disabled={ props?.disabled}
                    fieldName={`schedule_details.${index}.${day_name}.status`}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <TimePickerField
                    errors={!!errors?.schedule_details?.[index]?.[day_name]?.start_time}
                    fieldName={`schedule_details[${index}].${day_name}.start_time`}
                    type="date"
                    autoComplete='off'
                    label="Start Time"
                    control={control}
                    iProps={{
                      disabled: dateDisabled?.[day_name] || props?.disabled,
                      ampm: false
                    }}
                    setValue={setValue}
                    helperText={errors?.schedule_details?.[index]?.[day_name]?.start_time && errors?.schedule_details?.[index]?.[day_name]?.start_time?.message}

                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <TimePickerField
                    errors={!!errors?.schedule_details?.[index]?.[day_name]?.end_time}
                    fieldName={`schedule_details.${index}.${day_name}.end_time`}
                    type="text"
                    autoComplete='off'
                    label="End Time"
                    control={control}
                    iProps={{
                      disabled: dateDisabled?.[day_name] || props?.disabled,
                      ampm: false
                    }}
                    setValue={setValue}
                    helperText={errors?.schedule_details?.[index]?.[day_name]?.end_time && errors?.schedule_details?.[index]?.[day_name]?.end_time?.message}
                  />
                </GridItem>
              <>
                <GridItem xs={4} sm={4} md={2}>
                  <TimePickerField
                    errors={!!errors?.schedule_details?.[index]?.[day_name]?.break_start_time}
                    fieldName={`schedule_details[${index}].${day_name}.break_start_time`}
                    type="date"
                    autoComplete='off'
                    label="Break Start Time"
                    control={control}
                    iProps={{
                      disabled: dateDisabled?.[day_name] || props?.disabled,
                      ampm: false
                    }}
                    setValue={setValue}
                    helperText={errors?.schedule_details?.[index]?.[day_name]?.break_start_time && errors?.schedule_details?.[index]?.[day_name]?.break_start_time?.message}

                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <TimePickerField
                    errors={!!errors?.schedule_details?.[index]?.[day_name]?.break_end_time}
                    fieldName={`schedule_details.${index}.${day_name}.break_end_time`}
                    type="text"
                    autoComplete='off'
                    label="Break End Time"
                    control={control}
                    iProps={{
                      disabled: dateDisabled?.[day_name] || props?.disabled,
                      ampm: false
                    }}
                    setValue={setValue}
                    helperText={errors?.schedule_details?.[index]?.[day_name]?.break_end_time && errors?.schedule_details?.[index]?.[day_name]?.break_end_time?.message}
                  />
                </GridItem>
              </> 
                
              </GridContainer>
            )
          })}
        </GridItem>
      </>
      {false && scheduleDays?.length > 0 && scheduleDays?.map((item: any) => {
        let day_name: string = item?.name?.toLowerCase();
        return (
          <>
            <GridItem xs={2} sm={2} md={2}>
              <InputField
                control={control}
                setValue={setValue}
                type="hidden"
                valueGot={item.uuid}
                initValue={item.uuid}
                fieldName={"" + [day_name] + ".day_id"}
              />
              <Typography variant="button" display="block" >
                {item.name}
              </Typography>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <SwitchField
                errors={!!errors?.schedule_details?.[day_name]?.status}
                control={control}
                setValue={setValue}
                helperText={errors?.schedule_details?.[day_name]?.status && errors?.schedule_details?.[day_name]?.status?.message}
                iProps={{
                  onChange: handleChange
                }}
                isLoading={true}
                checked={!dateDisabled?.[day_name]}
                fieldName={"" + [day_name] + ".status"}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <TimePickerField
                errors={!!errors?.schedule_details?.[day_name]?.start_time}
                fieldName={"" + [day_name] + ".start_time"}
                type="date"
                autoComplete='off'
                label="Start Time"
                control={control}
                iProps={{
                  disabled: dateDisabled?.[day_name],
                }}
                setValue={setValue}
                helperText={errors?.schedule_details?.[day_name]?.start_time && errors?.schedule_details?.[day_name]?.start_time?.message}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <TimePickerField
                errors={!!errors?.schedule_details?.[day_name]?.end_time}
                fieldName={"" + [day_name] + ".end_time"}
                type="text"
                autoComplete='off'
                label="End Time"
                control={control}
                iProps={{
                  disabled: dateDisabled?.[day_name],
                }}
                valueGot={''}
                setValue={setValue}
                helperText={errors?.schedule_details?.[day_name]?.end_time && errors?.schedule_details?.[day_name]?.end_time?.message}
              />
            </GridItem>
             <>
              <GridItem xs={4} sm={4} md={4}>
              <TimePickerField
                errors={!!errors?.schedule_details?.[day_name]?.break_start_time}
                fieldName={"" + [day_name] + ".break_start_time"}
                type="date"
                autoComplete='off'
                label="Start Time"
                control={control}
                iProps={{
                  disabled: dateDisabled?.[day_name],
                }}
                setValue={setValue}
                helperText={errors?.schedule_details?.[day_name]?.break_start_time && errors?.schedule_details?.[day_name]?.break_start_time?.message}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <TimePickerField
                errors={!!errors?.schedule_details?.[day_name]?.break_end_time}
                fieldName={"" + [day_name] + ".break_end_time"}
                type="text"
                autoComplete='off'
                label="End Time"
                control={control}
                iProps={{
                  disabled: dateDisabled?.[day_name],
                }}
                valueGot={''}
                setValue={setValue}
                helperText={errors?.schedule_details?.[day_name]?.break_end_time && errors?.schedule_details?.[day_name]?.break_end_time?.message}
              />
            </GridItem>
            </> 
           
            
          </>
        )
      })
      }
    </GridContainer >

  );
}

export default TimeScheduledModal;
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  InputField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import { MenuValidation } from "./validations/menu-validation";
import { BookJinoValidation } from "./validations/book-jino-validation";
import { FaqValidation } from "./validations/faq-validatiion";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const FaqComponent = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const { apiData, data, permission, collapse } = props;

  const defaultFormValues: any = {};

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
    getValues,
    watch,
  } = useForm<any>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(FaqValidation),
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: `Faq`,
  });
  useEffect(() => {
    [1].forEach(() => {
      append({
        question: "",
        answer: "",
      });
    });
  }, []);
  useEffect(() => {
    if (apiData) {
      setValue("Faq", apiData?.faq?.Faq);
    }
  }, [apiData]);

  const onSubmit = (data: any) => {
    const payload = { faq: data };
    updateOnSubmit(payload);
    console.log(payload);
  };

  const updateOnSubmit = (data: any) => {
    dispatch(spinLoaderShow(true));
    updateHeaderSection(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlledAccordion collapse={collapse}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Faq</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          {fields.map((item: any, index) => {
            return (
              <GridContainer key={item.id} spacing={2} style={{ marginTop: "10px" }}>
                <GridItem xs={6} sm={6} md={6} >
                  <InputField
                    errors={!!errors?.Faq?.[index]?.question}
                    fieldName={`Faq[${index}].question`}
                    type="text"
                    label="* Question"
                    autoComplete="false"
                    control={control}
                    setValue={setValue}
                    valueGot={""}
                    helperText={
                      errors?.Faq?.[index]?.question &&
                      errors?.Faq?.[index]?.question.message
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6} >
                  <InputField
                    errors={!!errors?.Faq?.[index]?.answer}
                    fieldName={`Faq[${index}].answer`}
                    type="text"
                    label="* Answer"
                    autoComplete="false"
                    control={control}
                    setValue={setValue}
                    valueGot={""}
                    helperText={
                      errors?.Faq?.[index]?.answer &&
                      errors?.Faq?.[index]?.answer.message
                    }
                  />
                </GridItem>
            
                  <GridContainer
                  spacing={3}
                  justify="end"
                  alignItems="center"
                  className={"textAlignEnd"}
                >
                  <GridItem xs={6} sm={6} md={6} style={{marginLeft:"18px"}}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={"custom-btn"}
                      onClick={() => remove(index)}
                    >
                      Delete
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridContainer>
            );
          })}

          <GridContainer
            spacing={3}
            justify="end"
            alignItems="center"
            className={"textAlignEnd"}
          >
            <GridItem xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                className={"custom-btn"}
                onClick={() => {
                  append({
                    question: "",
                    answer: "",
                  });
                }}
              >
                Add more
              </Button>
            </GridItem>
          </GridContainer>
        </AccordionDetails>
        <AccordionActions>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            {/* {permission === false ? (
              
            ) : (
              ""
            )} */}
            <Button color="primary" className={"custom-btn"} type="submit">
              Update
            </Button>
          </Box>
        </AccordionActions>
      </ControlledAccordion>
    </form>
  );
};

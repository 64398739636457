import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "../httpservice";

export function getMediaDetail(body: any = null) {
  return httpservice.get("/newsdetail", {
    params: body,
  });
}
export function createMediaDetail(body: any) {
  return httpservice.post("/newsdetail", body);
}
export function updateMediaDetail(body: any) {
  return httpservice.put("/newsdetail", body);
}

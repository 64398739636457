import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import { userPermissions } from "redux/action/permission";
import { permissionsx } from "json/permissions";
import Admin from "./layouts/Admin";
import LoginLayout from "./containers/Login/LoginLayout";
import SecretLayout from "./containers/Secret/SecretLayout";
import forgotPasswordLayout from "./containers/ForgotPassword/ForgotPasswordLayout";
import { getRoles } from "services/userservices";
import { signOut } from "services/cognitouser";
import { ToastContainer, toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { congitoConfig, isvalid, getRoleId } from "services/cognitouser";

export const App = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  let unlistn = history.listen(({ action, location }) => {
    checkRoles();
  });

  const checkRoles = () => {
    let roleId = localStorage.getItem("roleId");
    if (roleId) {
      getRoles({ uuid: roleId }).then((res: any) => {
        if (res.data.result[0].status) {
          dispatch(userPermissions(res.data.result[0]["permissions"]));
          // dispatch(userPermissions(permissions));
        } else {
          signOut();
          toast.error("Your role is disabled! Contact Admin");

          window.location.href = "/";
        }
      });
    }
  };

  useEffect(() => {
    Auth.configure(congitoConfig);
    if (!isvalid()) {
      try {
        signOut();
      } catch (e) {}
      if (
        window.location.pathname != "/" &&
        window.location.pathname != "/secret"
      ) {
        window.location.href = "/";
      }
      console.log("window", window.location.pathname);
    }

    checkRoles();
    //GET FROM localStorage

    return () => {
      toast.error("Your role is disabled! Contact Admin");
    };
  }, []);

  return (
    <Switch>
      <Route path="/admin" component={Admin} />
      <Route path="/" exact component={LoginLayout} />
      <Route path="/secret" exact component={SecretLayout} />
      <Route path="/vendor/secret" exact component={SecretLayout} />
      <Route path="/forgot" exact component={forgotPasswordLayout} />

      {/* <Redirect from="/" to="/admin/dashboard" /> */}
    </Switch>
  );
};

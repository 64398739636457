import * as yup from "yup";
export const BusinessValidation: any = yup.object().shape({
  //Business banner validation
  // business_banner_title: yup
  //   .string()
  //   .required("please enter Banner Title")
  //   .nullable(),
  name: yup.string().required("please enter Name").nullable(),
  slug: yup.string().matches(/^[a-z\-\d]+$/,"do not use space and special character").required("please enter Slug").nullable(),

  //fieldArray validation business banner
  business_array: yup.array().of(
    yup.object().shape({
      sub_title: yup.string().required("Please enter Sub Title").nullable(),
    })
  ),

  //Icon section validation
  icon_section_title: yup.string().required("please enter Title").nullable(),
  icon_section_description: yup
    .string()
    .required("please enter Description")
    .nullable(),
  icon_section_playstore_url: yup
    .string()
    .required("please enter Playstore Url")
    .matches(/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,"please enter correct Url")
    .nullable(),
  icon_section_appstore_url: yup
    .string()
    .required("please enter Appstore Url")
    .matches(/^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,"please enter correct Url")
    .nullable(),

  //icon_section_array validation Icon section
  icon_section_array: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      sub_title: yup.string().required("Please enter Sub Title").nullable(),
    })
  ),
  //for_business_array validation
  for_business_array: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      description: yup.string().required("Please enter Description").nullable(),
      // business_card: yup.array().of(
      //   yup.object().shape({
      //     sub_title: yup.string().required("Please enter Sub Title").nullable(),
      //   })
      // ),
    })
  ),

  //icon_section_array validation Icon section
  repeater_array: yup.array().of(
    yup.object().shape({
      title: yup.string().required("Please enter Title").nullable(),
      description: yup.string().required("Please enter Description").nullable(),
    })
  ),
});

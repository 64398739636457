import ht from "date-fns/esm/locale/ht/index.js";
import httpservice from "./httpservice";

 
export function getServiceCategory(body: any = null) {
    return httpservice.get('/services/category', { params: body })
  }
  export function createServiceCategory(body: any) {
    return httpservice.post('/services/category', body)
  }
  export function updateServiceCategory(body: any) {
    return httpservice.put('/services/category', body)
  }
  export function getServiceTypes(body: any = null) {
    return httpservice.get('/services/types', body)
  }
  export function getServices(body: any = null) {
    return httpservice.get('/services', { params: body })
  }
  export function createService(body: any) {
    return httpservice.post('/services', body)
  }
  export function updateService(body: any) {
    return httpservice.put('/services', body)
  }
  
  export function createServiceConfig(body: any) {
    return httpservice.post('/services/configs', body) 
  }
  export function updateServiceConfig(body: any) {
    return httpservice.put('/services/configs', body)
  }
  export function getServiceConfig(body: any = null) {
    return httpservice.get('/services/configs', { params: body })
  }
  export function getServiceConfigListing(body: any = null) {
    return httpservice.get('/services/configs/listing', { params: body })
  }

  export const  serviceFileUpload= (files:any)=> {
    return httpservice.post('/upload/services', files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  export const  serviceCategoryLogo= (files:any)=> {
    return httpservice.post('/upload/others', files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useFieldArray, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import Avatar from "@material-ui/core/Avatar";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { vehicleBrandLogo } from "services/vehiclesServices";
// import { HeaderValidation } from "./validations/header-validation";
import { updateHeaderSection } from "services/website Services/themeSettingsServices";
import { spinLoaderShow } from "redux/action/spinLoader";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUCCESS_MSG } from "json/constants";
import GridContainer from "components/Grid/GridContainer";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const AwardWinningAccordion = (props: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { apiData, setValue, getValues, errors, collapse, control } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: `award_winning.award_cards`,
  });

  useEffect(() => {
    if (apiData) {
      console.log(apiData?.page_content?.award_winning?.award_cards);
      setValue(
        "award_winning.award_cards",
        apiData?.page_content?.award_winning?.award_cards
      );
    }
  }, [apiData]);

  useEffect(() => {
    if (apiData) {
      setValue(
        "award_winning.title",
        apiData?.page_content?.award_winning?.title
      );
      setValue(
        "award_winning.description",
        apiData?.page_content?.award_winning?.description
      );

      setValue(
        "award_winning.image",
        apiData?.page_content?.award_winning?.image
      );
      setUrl(apiData?.page_content?.award_winning?.image);
    }
  }, [apiData]);

  // useEffect(()=>{
  //   console.log("getValues",getValues())
  // })

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("award_winning.image", data.result[0]);
    setUrl(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  useEffect(() => {
    console.log("error", errors);
  }, [errors]);

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Award Winning Detail</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridItem xs={6} md={6} sm={6} style={{ marginTop: "07px" }}>
          <Box
            style={{ display: "flex", alignItems: "center" }}
            alignItems="center"
          >
            <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />
            <Button
              variant="outlined"
              color="primary"
              disabled={disabled}
              className={classes.uploadButton}
              onClick={() => setDropOpen(true)}
            >
              Award Winning Image
            </Button>
            <FileUploadDialog
              open={dropOpen}
              setOpen={setDropOpen}
              handleSave={handleSave}
              message={
                "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
              }
            />
          </Box>
        </GridItem>
        <GridItem xs={6} md={6} sm={6} alignItems="center">
          <InputField
            errors={!!errors?.award_winning?.title}
            fieldName="award_winning.title"
            type="text"
            autoComplete="false"
            label="* Title "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={
              errors?.award_winning?.title &&
              errors?.award_winning?.title?.message
            }
          />
        </GridItem>
        <GridItem xs={6} md={6} sm={6} alignItems="center">
          <InputField
            errors={!!errors?.award_winning?.description}
            fieldName="award_winning.description"
            type="text"
            autoComplete="false"
            label="* Description "
            control={control}
            isLoading={true}
            valueGot={""}
            setValue={setValue}
            helperText={
              errors?.award_winning?.description &&
              errors?.award_winning?.description?.message
            }
          />
        </GridItem>
      </AccordionDetails>
      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index: any) => {
          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.award_winning?.award_cards?.[index]?.date}
                  fieldName={`award_winning.award_cards[${index}].date`}
                  type="text"
                  label="* Date/Main Title "
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.award_winning?.award_cards?.[index]?.date &&
                    errors?.award_winning?.award_cards?.[index]?.date.message
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
                <InputField
                  errors={!!errors?.award_winning?.award_cards?.[index]?.title}
                  fieldName={`award_winning.award_cards[${index}].title`}
                  type="text"
                  label="* Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.award_winning?.award_cards?.[index]?.title &&
                    errors?.award_winning?.award_cards?.[index]?.title.message
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }}>
                <InputField
                  errors={
                    !!errors?.award_winning?.award_cards?.[index]?.description
                  }
                  fieldName={`award_winning.award_cards[${index}].description`}
                  type="text"
                  label="* Description"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.award_winning?.award_cards?.[index]?.description &&
                    errors?.award_winning?.award_cards?.[index]?.description
                      .message
                  }
                />
              </GridItem>

              <GridItem
                xs={6}
                sm={6}
                md={6}
                style={{ marginBottom: "25px", marginLeft: "5px" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={"custom-btn"}
                  onClick={() => remove(index)}
                >
                  Delete
                </Button>
              </GridItem>
            </GridContainer>
          );
        })}
        <Button
          variant="contained"
          color="primary"
          className={"custom-btn"}
          onClick={() => append({ date: "", description: "", title: "" })}
        >
          add more
        </Button>
      </AccordionDetails>
    </ControlledAccordion>
  );
};

import httpservice from "./httpservice";
  // bulk enable / disable users
export const bulkEnable= async(body:any) => {
    return httpservice.put("/admin/user/bulk/enable", body)
  }
  
  export const bulkDisable= async(body:any) => {
    return httpservice.put("/admin/user/bulk/disable", body)
  }
  //Country
export const bulkCountryEnable= async(body:any) => {
    return httpservice.post("/country/bulk/enable", body)
  }
  
  export const bulkCountryDisable= async(body:any) => {
    return httpservice.post("/country/bulk/disable", body)
  }
 //City
export const bulkCityEnable= async(body:any) => {
    return httpservice.post("/city/bulk/enable", body)
  }
  
  export const bulkCityDisable= async(body:any) => {
    return httpservice.post("/city/bulk/disable", body)
  } 
//Branch
export const bulkBranchEnable= async(body:any) => {
    return httpservice.post("/branch/bulk/enable", body)
  }
  
  export const bulkBranchDisable= async(body:any) => {
    return httpservice.post("/branch/bulk/disable", body)
  }
 //Zone
export const bulkZoneEnable= async(body:any) => {
  return httpservice.post("/zone/bulk/enable", body)
}

export const bulkZoneDisable= async(body:any) => {
  return httpservice.post("/zone/bulk/disable", body)
}
// schedule 
export const bulkScheduleEnable= async(body:any) => {
  return httpservice.post("/schedule/bulk/enable", body)
}
export const bulkScheduleDisable= async(body:any) => {
  return httpservice.post("/schedule/bulk/disable", body)
}
//Vehicle Make
export const bulkVehicleMakeEnable= async(body:any) => {
  return httpservice.post("/vehicle/brand/bulk/enable", body)
}

export const bulkVehicleMakeDisable= async(body:any) => {
  return httpservice.post("/vehicle/brand/bulk/disable", body)
}
//Vehicle Type
export const bulkVehicleTypeEnable= async(body:any) => {
  return httpservice.post("/vehicle/type/bulk/enable", body)
}

export const bulkVehicleTypeDisable= async(body:any) => {
  return httpservice.post("/vehicle/type/bulk/disable", body)
}
//Vehicle Model
export const bulkVehicleModelEnable= async(body:any) => {
  return httpservice.post("/vehicle/model/bulk/enable", body)
}

export const bulkVehicleModelDisable= async(body:any) => {
  return httpservice.post("/vehicle/model/bulk/disable", body)
} 
// service 
export const bulkServiceEnable= async(body:any) => {
  return httpservice.post("/services/bulk/enable", body)
}
export const bulkServiceDisable= async(body:any) => {
  return httpservice.post("/services/bulk/disable", body)
}
// service config
export const bulkServiceConfigEnable= async(body:any) => {
  return httpservice.post("/services/config/bulk/enable", body)
}
export const bulkServiceConfigDisable= async(body:any) => {
  return httpservice.post("/services/config/bulk/disable", body)
}
// packages
export const bulkPackagesEnable= async(body:any) => {
  return httpservice.post("/packages/bulk/enable", body)
}
export const bulkPackagesDisable= async(body:any) => {
  return httpservice.post("/packages/bulk/disable", body)
}
// vendor
export const bulkVendorEnable= async(body:any) => {
  return httpservice.post("/vendor/bulk/enable", body)
}
export const bulkVendorDisable= async(body:any) => {
  return httpservice.post("/vendor/bulk/disable", body)
}
export const bulkCustomerEnable= async(body:any) => {
  return httpservice.post("/customer/bulk/enable", body)
}
// customers
export const bulkCustomerDisable= async(body:any) => {
  return httpservice.post("/customer/bulk/disable", body)
}
 // customer Vehicles
export const bulkCustomerVehicleEnable= async(body:any) => {
  return httpservice.post("/customer/vehicle/bulk/enable", body)
}
export const bulkCustomerVehicleDisable= async(body:any) => {
  return httpservice.post("/customer/vehicle/bulk/disable", body)
}
// customer address
export const bulkCustomerAddressEnable= async(body:any) => {
  return httpservice.post("/customer/address/bulk/enable", body)
}
export const bulkCustomerAddressDisable= async(body:any) => {
  return httpservice.post("/customer/address/bulk/disable", body)
}
// customer packages
export const bulkCustomerPackageEnable= async(body:any) => {
  return httpservice.post("/customer/packages/bulk/enable", body)
}
export const bulkCustomerPackageDisable= async(body:any) => {
  return httpservice.post("/customer/packages/bulk/disable", body)
}
// driver enable
export const bulkDriverEnable= async(body:any) => {
  return httpservice.post("/driver/bulk/enable", body)
}
export const bulkDriverDisable= async(body:any) => {
  return httpservice.post("/driver/bulk/disable", body)
}
 // driver config
export const bulkDriverConfigsEnable= async(body:any) => {
  return httpservice.post("driver/config/bulk/enable", body)
}
export const bulkDriverConfigsDisable= async(body:any) => {
  return httpservice.post("driver/config/bulk/disable", body)
}
// promotions
export const bulkPromotionEnable= async(body:any) => {
  return httpservice.post("/promotions/bulk/enable", body)
}
export const bulkPromotionDisable= async(body:any) => {
  return httpservice.post("/promotions/bulk/disable", body)
}
import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from 'components/PageHeader/PageHeader'
import _ from 'lodash';
import { Box } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useForm } from "react-hook-form";
import { AutoCompleteField, DateField, InputField, LoadingButton, SwitchFieldDefault,ColorPickerField } from "components/Forms/Formfield.component";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCustomerVehicleValidation } from "./Validations/customerVehicleValidation";
import { City } from 'country-state-city';
import { useDispatch } from "react-redux";
import { spinLoaderShow } from 'redux/action/spinLoader'
import { createCustomerVehicle, getCustomers, getCustomersVehicle, updateCustomerVehicle } from 'services/customerServices'
import { getVehicleModel } from 'services/vehiclesServices'
import { getCountry, getCity } from 'services/locationservices'
import { CREATE_SUCCESS_MSG } from 'json/constants'
import { UPDATE_SUCCESS_MSG } from 'json/constants'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import Button from "components/CustomButtons/Button";
import Avatar from '@material-ui/core/Avatar';

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  dot: {
    height: "70px",
    width: "70px",
    borderRadius:" 50%",
    display:" inline-block"
  }
};
const defaultFormValues = {
  customer_id: '',
  vehicle_model_id: '',
  color: '',
  year: '',
  car_registration: '',
  plate_no: '',
  nick_name: '',
  country_name: '',
  city_name: '',
  battery_life: '',
  status: true

}


const useStyles = makeStyles(styles);
declare var google: any;


export default function AddCustomerVehicle(props: any) {
  const classes = useStyles();
  const [countries, setCountries] = useState<any>([])
  const [cities, setCities] = useState<any>([])
  const [city, setCity] = useState<any>({})
  const [customers, setCustomers] = useState<any>([])
  const [vehicleModel, setVehicleModel] = useState<any>([])
  const [editData, setEditData] = useState<any>([])
  const [headerTitle, setHeaderTitle] = useState<any>('Add')
  const [disabled, setDisabled] = useState<any>(false)
  const [color ,setColor]=useState<any>("")
  const [carRegistration, setCarRegistration] = useState<any>(null)
  const [insuranceExpiry ,setInsuranceExpiry] = useState<any>(null)
  const [tireExpirationFright , setTireExpirationFright] = useState<any>(null)
  const [tireExpirationFleft , setTireExpirationFleft] = useState<any>(null)
  const [tireExpirationRright , setTireExpirationRright] = useState<any>(null)
  const [tireExpirationRleft , setTireExpirationRleft] = useState<any>(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");



  const { control, register, handleSubmit, clearErrors, setError, formState: { errors }, setValue, reset, getValues } = useForm<any>({

    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
    resolver: yupResolver(createCustomerVehicleValidation)
  });

  const routes = [
    {
      'name': "Admin",
      'link': '/admin'
    },
    {
      'name': "Customer Vehicle",
      'link': '/admin/customer-vehicle'
    },
    {
      'name': headerTitle,
      'link': ''
    }
  ];

  useEffect(() => {

    getCountry({ status: true }).then((e) => {
      setCountries(e.data.result)
    }).catch((e) => {

    })
    getCustomers().then((res) => {
      setCustomers(res?.data?.result)
    }).catch((err) => {
    })
    getVehicleModel().then((res) => {
      setVehicleModel(res?.data?.result)
    }).catch((err) => {
    })
  }, [])

  const getCustomerVehicles = () => {
    getCustomersVehicle({ 'uuid': uuid }).then((res) => {
      let data = res?.data?.result?.[0]
      setValue("year", data.year)
      setValue("plate_no", data.plate_no)
      console.log("data",data)
      // setValue("nick_name", data.nick_name)
      setValue("status", data.status)
      setValue("battery_life", data?.battery_life)
      setValue("tire_pressure_front_right", data?.tyre_pressure?.find((x: any) => x.tyre == 'Front-Right')?.pressure)
      setValue("tire_pressure_front_left", data?.tyre_pressure?.find((x: any) => x.tyre == 'Front-Left')?.pressure)
      setValue("tire_pressure_rear_right", data?.tyre_pressure?.find((x: any) => x.tyre == 'Rear-Right')?.pressure)
      setValue("tire_pressure_rear_left", data?.tyre_pressure?.find((x: any) => x.tyre == 'Rear-Left')?.pressure)
      setValue("tyre_expiration_front_right", data?.tyre_expiration?.find((x: any) => x.tyre == 'Front-Right')?.date)
      setValue("tyre_expiration_front_left", data?.tyre_expiration?.find((x: any) => x.tyre == 'Front-Left')?.date)
      setValue("tyre_expiration_rear_right", data?.tyre_expiration?.find((x: any) => x.tyre == 'Rear-Right')?.date)
      setValue("tyre_expiration_rear_left", data?.tyre_expiration?.find((x: any) => x.tyre == 'Rear-Left')?.date)
      setValue("car_registration", data?.car_registration)
      setValue("country_name", data?.country_name)
      setValue("city_name", data?.city_name)
      setCarRegistration(data?.car_registration ?  data?.car_registration :null)
      setInsuranceExpiry(data?.insurance ? data?.insurance : null)
      setTireExpirationFright(data?.tyre_expiration ?   data?.tyre_expiration?.find((x: any) => x.tyre == 'Front-Right')?.date :null)
      setTireExpirationFleft(data?.tyre_expiration ?  data?.tyre_expiration?.find((x: any) => x.tyre == 'Front-Left')?.date :null)
      setTireExpirationRright(data?.tyre_expiration ? data?.tyre_expiration?.find((x: any) => x.tyre == 'Rear-Right')?.date :null)
      setTireExpirationRleft(data?.tyre_expiration ? data?.tyre_expiration?.find((x: any) => x.tyre == 'Rear-Left')?.date :null)
      setValue("insurance", data?.insurance)
      setValue("color", data?.color)
      setColor(data?.color)
      setEditData(data)
      // getCitiesByCountry({ uuid: data?.city.country.uuid })
      setCity(data?.city)
      let temp: any = new URLSearchParams(search).get("type");
      temp ? setHeaderTitle('View') : setHeaderTitle("Edit")
      temp == 'view' ? setDisabled(true) : setDisabled(false)
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
      dispatch(spinLoaderShow(false))

    })
  }


  useEffect(() => {
    dispatch(spinLoaderShow(true))
    if (uuid) {
      getCustomerVehicles()
    }
    else {
      reset(defaultFormValues)
      setHeaderTitle("Add")
      dispatch(spinLoaderShow(false))
    }
  }, [uuid])

  // const getCitiesByCountry = (data: any) => {
  //   setCities([])
  //   setCity({})
  //   if (data?.uuid) {
  //     getCity({ country_id: data?.uuid, status: true })
  //       .then((e) => {
  //         setCities(e?.data?.result)
  //       })
  //   }
  // }

  const onChangeColor = (data:any)=>{
    setColor(data)
  }

  const onSubmit = async (data: any) => {

    let format = 'YYYY'
    data.year = moment(data.year).format(format)
    console.log("color",data?.color)

    dispatch(spinLoaderShow(true))
    let modifyData: any = {
      customer_id: data.customer_id.uuid,
      vehicle_model_id: data.vehicle_model_id.uuid,
      color: data.color,
      year: data.year,
      plate_no: data.plate_no,
      // nick_name: data.nick_name,
      city_name: data.city_name,
      country_name: data.country_name,
      battery_life: data?.battery_life,
      car_registration: moment(data?.car_registration).format('YYYY-MM-DD'),
      status: data.status,
      insurance: moment(data?.insurance).format('YYYY-MM-DD'),
      tyre_pressure: [
        {
          tyre: "Front-Right",
          pressure: data?.tire_pressure_front_right
        },
        {
          tyre: "Front-Left",
          pressure: data?.tire_pressure_front_left
        },
        {
          tyre: "Rear-Right",
          pressure: data?.tire_pressure_rear_right
        },
        {
          tyre: "Rear-Left",
          pressure: data?.tire_pressure_rear_left
        }
      ],
      tyre_expiration: [
        {
          tyre: "Front-Right",
          pressure: moment(data?.tyre_expiration_front_right).format('YYYY-MM-DD')
        },
        {
          tyre: "Front-Left",
          pressure: moment(data?.tyre_expiration_front_left).format('YYYY-MM-DD')
        },
        {
          tyre: "Rear-Right",
          pressure: moment(data?.tyre_expiration_rear_right).format('YYYY-MM-DD')
        },
        {
          tyre: "Rear-Left",
          pressure: moment(data?.tyre_expiration_rear_left).format('YYYY-MM-DD')
        }
      ]
    }
    if (uuid) {
      modifyData.uuid = uuid
      updateOnSubmit(modifyData)
    }
    else {
      createOnSubmit(modifyData)
    }
  };

  const createOnSubmit = async (data: any) => {



    createCustomerVehicle(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues)
        history.push("/admin/customer-vehicle");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };
  const updateOnSubmit = (data: any) => {
    updateCustomerVehicle(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        setEditData(null)
        toast.success(UPDATE_SUCCESS_MSG);
        reset(defaultFormValues)
        history.push("/admin/customer-vehicle");

      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });

  };

  const onCarRegistrationChange=(data:any)=>{
    setCarRegistration(data)
  }
  const onInsuranceExpiryChange = (data:any) =>{
    setInsuranceExpiry(data)
  }
  const onTireExpiryFrontRight = (data:any) =>{
    setTireExpirationFright(data)
  }
  const onTireExpiryFrontLeft = (data:any) =>{
    setTireExpirationFleft(data)
  }
  const onTireExpiryRearRight = (data:any) =>{
    setTireExpirationRright(data)
  }
  const onTireExpiryRearLeft = (data:any) =>{
    setTireExpirationRleft(data)
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Customer Vehicle"
              routes={routes}
              newItem={[{ text: 'Back', link: "/admin/customer-vehicle" }]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
              <Box component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}>
                    <h4 className={classes.cardTitleWhite}> {headerTitle} Customer Vehicle</h4>
                  {uuid ? <h4 className={classes.cardTitleWhite}>Modified At: { moment(editData?.updatedAt).format('llll') }</h4> : null } 
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={3}>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.customer_id}
                      fieldName="customer_id"
                      autoComplete="off"
                      label="* Select Customer Id"
                      control={control}
                      setValue={setValue}
                      options={customers}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="first_name"
                      returnObject={true}
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.customer_id && errors?.customer_id?.message
                      }
                      valueGot={editData?.customer_id && customers?.find(({ uuid }: any) => { return uuid == editData?.customer_id })}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <AutoCompleteField
                      errors={!!errors?.vehicle_model_id}
                      fieldName="vehicle_model_id"
                      autoComplete="off"
                      label="* Select Vehicle Model"
                      control={control}
                      setValue={setValue}
                      returnObject={true}
                      options={vehicleModel}
                      isLoading={true}
                      optionKey="uuid"
                      optionValue="name"
                      iProps={{
                        disabled: disabled
                      }}
                      helperText={
                        errors?.vehicle_model_id && errors?.vehicle_model_id?.message
                      }
                      valueGot={editData?.vehicle_model_id && vehicleModel.find(({ uuid }: any) => { return uuid == editData?.vehicle_model_id })}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.color}
                      fieldName="color"
                      type="text"
                      autoComplete='off'
                      label="* Color"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.color && errors?.color?.message}

                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.year}
                      fieldName="year"
                      autoComplete='off'
                      label="* Year"
                      iProps={{
                        openTo: "year",
                        views: ['year'],
                        disabled: disabled
                      }}
                      valueGot={''}
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      helperText={errors?.year && errors?.year?.message}

                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.plate_no}
                      fieldName="plate_no"
                      type="text"
                      autoComplete='off'
                      label="* Plate Number"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.plate_no && errors?.plate_no?.message}

                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.nick_name}
                      fieldName="nick_name"
                      type="text"
                      autoComplete='off'
                      label="* Nick Name"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.nick_name && errors?.nick_name?.message}

                    />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.car_registration}
                      fieldName="car_registration"
                      autoComplete='off'
                      label="Car Registration"
                      setValue={setValue}
                      control={control}
                      iProps={{
                        disabled: disabled,
                        format: 'dd-MM-yyyy',
                        onChange:onCarRegistrationChange
                      }}
                      inputVariant="outlined"
                      helperText={errors?.car_registration && errors?.car_registration?.message}
                      valueGot={carRegistration}
                      
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.insurance}
                      fieldName="insurance"
                      autoComplete='off'
                      label="Insurance Expiry"
                      setValue={setValue}
                      control={control}
                      iProps={{
                        disabled: disabled,
                        format: 'dd-MM-yyyy',
                        onChange:onInsuranceExpiryChange
                      }}
                      inputVariant="outlined"
                      helperText={errors?.insurance && errors?.insurance?.message}
                      valueGot={insuranceExpiry}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.battery_life}
                      fieldName="battery_life"
                      type="text"
                      autoComplete='off'
                      label="Battery life (In Percentage)"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.battery_life && errors?.battery_life?.message}

                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.tire_pressure_front_right}
                      fieldName="tire_pressure_front_right"
                      type="text"
                      autoComplete='off'
                      label="Tire Pressure (Front Right) PSI"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.tire_pressure_front_right && errors?.tire_pressure_front?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.tire_pressure_front_left}
                      fieldName="tire_pressure_front_left"
                      type="text"
                      autoComplete='off'
                      label="Tire Pressure (Front Left) PSI"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.tire_pressure_front_left && errors?.tire_pressure_front_left?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.tire_pressure_rear_right}
                      fieldName="tire_pressure_rear_right"
                      type="text"
                      autoComplete='off'
                      label="Tire Pressure (Rear Right) PSI"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.tire_pressure_rear_right && errors?.tire_pressure_rear_right?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputField
                      errors={!!errors?.tire_pressure_rear_left}
                      fieldName="tire_pressure_rear_left"
                      type="text"
                      autoComplete='off'
                      label="Tire Pressure (Rear left) PSI"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.tire_pressure_rear_left && errors?.tire_pressure_rear_left?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.tyre_expiration_front_right}
                      fieldName="tyre_expiration_front_right"
                      autoComplete='off'
                      label="Tire Expiration (Front Right)"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disabled: disabled,
                        format: 'dd-MM-yyyy',
                        onChange:onTireExpiryFrontRight
                      }}
                      helperText={errors?.tyre_expiration_front_right && errors?.tyre_expiration_front_right?.message}
                      valueGot={tireExpirationFright}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.tyre_expiration_front_left}
                      fieldName="tyre_expiration_front_left"
                      autoComplete='off'
                      label="Tire Expiration (Front Left)"
                      setValue={setValue}
                      control={control}
                      iProps={{
                        disabled: disabled,
                        format: 'dd-MM-yyyy',
                        onChange: onTireExpiryFrontLeft
                      }}
                      inputVariant="outlined"
                      helperText={errors?.tyre_expiration_front_left && errors?.tyre_expiration_front_left?.message}
                      valueGot={tireExpirationFleft}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.tyre_expiration_rear_right}
                      fieldName="tyre_expiration_rear_right"
                      autoComplete='off'
                      label="Tire Expiration (Rear Right)"
                      setValue={setValue}
                      control={control}
                      iProps={{
                        disabled: disabled,
                        format: 'dd-MM-yyyy',
                        onChange:onTireExpiryRearRight
                      }}
                      inputVariant="outlined"
                      helperText={errors?.tyre_expiration_rear_right && errors?.tyre_expiration_rear_right?.message}
                      valueGot={tireExpirationRright}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <DateField
                      errors={!!errors?.tyre_expiration_rear_left}
                      fieldName="tyre_expiration_rear_left"
                      autoComplete='off'
                      label="Tire Expiration (Rear Left)"
                      setValue={setValue}
                      control={control}
                      iProps={{
                        disabled: disabled,
                        format: 'dd-MM-yyyy',
                        onChange:onTireExpiryRearLeft
                        
                      }}
                      inputVariant="outlined"
                      helperText={errors?.tyre_expiration_rear_left && errors?.tyre_expiration_rear_left?.message}
                      valueGot={tireExpirationRleft}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                  <InputField
                      errors={!!errors?.country_name}
                      fieldName="country_name"
                      type="text"
                      autoComplete='off'
                      label="Country"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.country_name && errors?.country_name?.message}

                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                  <InputField
                      errors={!!errors?.city_name}
                      fieldName="city_name"
                      type="text"
                      autoComplete='off'
                      label="City"
                      control={control}
                      valueGot={''}
                      iProps={{
                        disabled: disabled
                      }}
                      setValue={setValue}
                      helperText={errors?.city_name && errors?.city_name?.message}

                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "15px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="Status"
                      control={control}
                      isLoading={true}
                      setValue={setValue}
                      disabled={disabled}
                      helperText={
                        errors?.status &&
                        errors?.status?.message
                      }
                    />
                  </GridItem>
                  {/* <GridItem xs={2} sm={2} md={2}>
                  
                  </GridItem> */}
                  <GridItem xs={10} sm={10} md={10}>
                  <span className={classes.dot} style={ {backgroundColor: color} }></span>
                  <ColorPickerField
                    errors={!!errors?.color}
                    fieldName="color"
                    control={control}
                    isLoading={true}
                    setValue={setValue}
                    helperText={errors?.color && errors?.color?.message}
                    valueGot={editData && editData?.color}
                    iProps={{
                      onChange:onChangeColor
                    }}
                  />

                </GridItem>
                </GridContainer>

              </CardBody>
              <CardFooter>
                <Box display="flex" justifyContent="flex-end" style={{ width: '100%' }}>
                  <Button color="primary" disabled={disabled} type="submit" >{uuid ? 'Update Customer Vehicle' : "Create Customer Vehicle"}</Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}


import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import PageHeader from "components/PageHeader/PageHeader";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  DateField,
  TextEditorField,
} from "components/Forms/Formfield.component";
import {
  AutoCompleteField,
  InputField,
  LoadingButton,
  PhoneNoField,
  SwitchField,
  SwitchFieldDefault,
} from "components/Forms/Formfield.component";
import { useEffect } from "react";
import Button from "components/CustomButtons/Button";
import {
  Avatar,
  Divider,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { spinLoaderShow } from "redux/action/spinLoader";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { UPDATE_SUCCESS_MSG } from "json/constants";
import { CREATE_SUCCESS_MSG } from "json/constants";
import moment from "moment";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";
import {
  createPartnership,
  getPartnership,
  updatePartnership,
} from "services/website Services/partnershipServices";
import { vehicleBrandLogo } from "services/vehiclesServices";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { getCategoryData } from "services/website Services/categoryServices";
import { setPropTypes } from "recompose";
import {
  createMediaDetail,
  getMediaDetail,
  updateMediaDetail,
} from "services/website Services/media_detailServices";
import {
  createEventDetail,
  getEventDetail,
  updateEventDetail,
} from "services/website Services/event_detailServices";
import { EventDetailValidation } from "./validations/event-detail-validation";

const styles: any = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const defaultFormValues: any = {
  status: true,
};

const useStyles = makeStyles(styles);
declare var google: any;

export default function AddEventData(props: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [headerTitle, setHeaderTitle] = React.useState<string>("Add");
  const [editData, setEditData] = useState<any>(null);
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [dropOpenDetail, setDropOpenDetail] = useState<any>(false);
  const [url, setUrl] = useState<any>("");
  const [urlDetails, setUrlDetail] = useState<any>("");
  const [disabled, setDisabled] = useState<any>(false);
  const [types, setPropTypes] = useState([]);
  const [publishDate, setPublishDate] = useState<any>(null);
  const [UpcomingDate, setUpcomingEvent] = useState<any>(null);
  const [desData, setDesData] = useState<any>(null);

  const typeOptions = types;
  const temp = typeOptions.filter((e: any) => e.type == "Events");
  // console.log("temp", temp);

  const [scheduleType, setScheduleType] = useState<any>(false);

  // const [edit,setEdit] = useState<any>([]);
  // const typeOption = [{ id: 1, name: 'Activity' }, { id: 2, name: 'Facility' }]
  let history = useHistory();

  const { search } = useLocation();
  let uuid = new URLSearchParams(search).get("uuid");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(EventDetailValidation),
  });

  const routes = [
    {
      name: "Admin",
      link: "/admin",
    },
    {
      name: "Event Details",
      link: "/admin/event",
    },

    {
      name: headerTitle,
      link: "",
    },
    // {
    //   name: uuid,
    //   link: "",
    //   uuid: uuid ? true : false,
    // },
  ];

  //get category data
  useEffect(() => {
    getCategoryData().then((res: any) => {
      let data = res?.data?.result?.filter((e: any) => e.type === "Events");
      // console.log("caonnsolevalue", data);
      // console.log("dataaaa", res?.data?.result);
      setPropTypes(data);
    });
  }, []);

  const getEventDetailData = () => {
    getEventDetail({ uuid: uuid })
      .then((res) => {
        console.log("getEventDetailData", res.data.result);
        let data = res.data.result?.[0];
        setValue("name", data?.name);
        setValue("slug", data?.slug);
        setValue("status", data?.status);
        setValue("title", data?.detail_content?.title);
        setValue("event_name", data?.event_name);
        setValue("event_location", data?.event_location);
        setDesData(data?.detail_content?.description);
        setUrl(data?.logo);
        setUrlDetail(data?.image_detail);
        setValue(
          "publish_date",
          moment(data.publish_date).format("YYYY-MM-DD")
        );
        setValue(
          "upcoming_date",
          moment(data.upcoming_date).format("YYYY-MM-DD")
        );
        setPublishDate(moment(data.publish_date).format("YYYY-MM-DD"));
        setUpcomingEvent(moment(data.upcoming_date).format("YYYY-MM-DD"));
        setEditData(data);
        // setEditData(data);
        // let temp: any = new URLSearchParams(search).get("type");
        // temp ? setHeaderTitle("View") : setHeaderTitle("Edit");
        // temp == "view" ? setDisabled(true) : setDisabled(false);
        dispatch(spinLoaderShow(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (uuid) {
      dispatch(spinLoaderShow(true));
      getEventDetailData();
    } else {
      setHeaderTitle("Add");
      reset(defaultFormValues);
      setEditData([]);
    }
  }, [uuid]);

  const handleSave = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpen(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("logo", data.result[0]);
    setUrl(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  const handleSaveDetails = async (files: any) => {
    dispatch(spinLoaderShow(true));
    setDropOpenDetail(false);
    const formData = new FormData();
    formData.append("images", files[0]);
    const { data } = await vehicleBrandLogo(formData);
    setValue("image_detail", data.result[0]);
    setUrlDetail(data.result[0]);
    dispatch(spinLoaderShow(false));
  };

  const typeHandleChange = (data: any) => {
    if (data !== null) {
      if (data.uuid == 1) {
        setScheduleType(true);
      } else {
        setScheduleType(false);
      }
    }
  };

  const onSubmit = async (data: any) => {
    // console.log("dataaaaa1000", data);

    data.detail_content = {
      title: data.title,
      description: data.description,
    };
    data.publish_date = data.publish_date;
    data.upcoming_date = data.upcoming_date;
    data.category_id = data.type;
    delete data.title;
    delete data.description;
    delete data.category;
    dispatch(spinLoaderShow(false));
    if (uuid) {
      data.uuid = uuid;
      updateOnSubmit(data);
    } else {
      createOnSubmit(data);
    }
    console.log("data", data);
  };

  const createOnSubmit = (data: any) => {
    console.log("data", data);

    dispatch(spinLoaderShow(true));
    createEventDetail(data)
      .then((e: any) => {
        dispatch(spinLoaderShow(false));
        toast.success(CREATE_SUCCESS_MSG);
        reset(defaultFormValues);
        history.push("/admin/event");
      })
      .catch((e: any) => {
        dispatch(spinLoaderShow(false));
      });
  };

  const updateOnSubmit = (data: any) => {
    console.log("onsubmit", data);
    updateEventDetail(data)
      .then((res) => {
        console.log("post-event", res);
        toast.success(UPDATE_SUCCESS_MSG);
        history.push("/admin/event");

        // reset(defaultFormValues)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onStartChange = (data: any) => {
    setPublishDate(data);
  };
  const UpcomingEventDate = (data: any) => {
    setUpcomingEvent(data);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <PageHeader
              title="Event Detail "
              routes={routes}
              newItem={[
                //{ text: 'Add', link: "/admin/branch-manager/add", 'uuid': uuid ? false : true },
                { text: "Back", link: "/admin" },
              ]}
              backIcon
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Box
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  style={{ width: "100%" }}
                >
                  <h4 className={classes.cardTitleWhite}>
                    {" "}
                    {headerTitle} Event Detail
                  </h4>
                  {uuid ? (
                    <h4 className={classes.cardTitleWhite}>
                      Modified At: {moment(editData?.updatedAt).format("llll")}
                    </h4>
                  ) : null}
                </Box>
              </CardHeader>
              <CardBody>
                <GridContainer spacing={5}>
                  <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
                    <Box
                      style={{ display: "flex", alignItems: "center" }}
                      alignItems="center"
                    >
                      <Avatar
                        style={{ height: "100px", width: "100px" }}
                        alt="Remy Sharp"
                        src={url}
                        className={classes.avatar}
                      />
                      <Button
                        style={{ marginLeft: "20px" }}
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                        className={classes.uploadButton}
                        onClick={() => setDropOpen(true)}
                      >
                        Event Image
                      </Button>
                      <FileUploadDialog
                        open={dropOpen}
                        setOpen={setDropOpen}
                        handleSave={handleSave}
                        message={
                          "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                        }
                      />
                    </Box>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} style={{ marginTop: "50px" }}>
                    <SwitchFieldDefault
                      errors={!!errors?.status}
                      fieldName="status"
                      autoComplete="off"
                      label="status"
                      defaultChecked="true"
                      control={control}
                      optionKey="uuid"
                      optionValue="status"
                      disabled={disabled}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.status && errors?.status?.message}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
                    <InputField
                      errors={!!errors?.slug}
                      fieldName="slug"
                      type="text"
                      autoComplete="off"
                      label="* Slug"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      // valueGot={editData.find( ({ name })})
                      setValue={setValue}
                      helperText={errors?.slug && errors?.slug?.message}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
                    <InputField
                      errors={!!errors?.name}
                      fieldName="name"
                      type="text"
                      autoComplete="off"
                      label="* Name"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      // valueGot={editData.find( ({ name })})
                      setValue={setValue}
                      helperText={errors?.name && errors?.name?.message}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
                    <InputField
                      errors={!!errors?.title}
                      fieldName="title"
                      type="text"
                      autoComplete="off"
                      label="* Title"
                      control={control}
                      iProps={{
                        disabled: disabled,
                      }}
                      // valueGot={editData.find( ({ name })})
                      setValue={setValue}
                      helperText={errors?.title && errors?.title?.message}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} className={"mt-15"}>
                    <AutoCompleteField
                      errors={!!errors?.type}
                      fieldName="type"
                      autoComplete="off"
                      label="* Select Category."
                      control={control}
                      setValue={setValue}
                      options={types}
                      isLoading={true}
                      iProps={{
                        onChange: typeHandleChange,
                        // disabled: disabled || disabledType,
                      }}
                      returnObject={false}
                      optionKey="uuid"
                      optionValue="name"
                      helperText={errors?.type && errors?.type?.message}
                      valueGot={
                        editData &&
                        types?.find((e: any) => e.uuid == editData?.category_id)
                      }
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6} >
                    <Box
                      style={{ display: "flex", alignItems: "center" }}
                      alignItems="center"
                    >
                      <Avatar
                        style={{ height: "100px", width: "100px" }}
                        alt="Remy Sharp"
                        src={urlDetails}
                        className={classes.avatar}
                      />
                      <Button
                        style={{ marginLeft: "20px" }}
                        variant="outlined"
                        color="primary"
                        disabled={disabled}
                        className={classes.uploadButton}
                        onClick={() => setDropOpenDetail(true)}
                      >
                        Detail Image
                      </Button>
                      <FileUploadDialog
                        open={dropOpenDetail}
                        setOpen={setDropOpenDetail}
                        handleSave={handleSaveDetails}
                        message={
                          "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                        }
                      />
                    </Box>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <DateField
                      errors={!!errors?.publish_date}
                      fieldName="publish_date"
                      autoComplete="off"
                      label="* Publish Date"
                      setValue={setValue}
                      control={control}
                      inputVariant="outlined"
                      iProps={{
                        disablePast: true,
                        disabled: disabled,
                        format: "dd-MM-yyyy",
                        onChange: onStartChange,
                      }}
                      helperText={
                        errors?.publish_date && errors?.publish_date?.message
                      }
                      valueGot={publishDate}
                    />
                  </GridItem>
                  <GridItem xs={12} className={"mt-15"}>
                    <Typography> Description: </Typography>
                    <TextEditorField
                      className={"mt-15"}
                      errors={!!errors?.description}
                      textareaName="description"
                      fieldName="description"
                      type="text"
                      autoComplete="false"
                      label="Description"
                      control={control}
                      isLoading={true}
                      disabled={disabled}
                      valueGot={desData}
                      setValue={setValue}
                      helperText={
                        errors?.description && errors?.description?.message
                      }
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    disabled={disabled}
                    className={"custom-btn"}
                    type="submit"
                  >
                    {uuid ? "Update event Detail" : "Create event Detail "}
                  </Button>
                </Box>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  Typography,
  Avatar,
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InputField } from "components/Forms/Formfield.component";
// import {createPhoneNumberValidation} from './Validations/phone_num.validation'
import { Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import ControlledAccordion from "components/common/controlledAccordion.component";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core/styles";
import { FileUploadDialog } from "components/FileUpload/fileuploadDialog.component";
import { FileUploadFieldArray } from "components/FileUpload/fileupload-fieldarray.component";
import { getVariableValues } from "graphql/execution/values";

const styles: any = {
  uploadButton: {
    backgroundColor: "#043cae",
    color: "#fff",
    marginLeft: "1em",
  },
  avatar: {
    height: "60px",
    width: "60px",
  },
};
const useStyles = makeStyles(styles);

export const HowToUseJinoAccordion = (props: any) => {
  const classes = useStyles();
  const { collapse, apiData, control, errors, setValue, getValues } = props;
  const [dropOpen, setDropOpen] = useState<any>(false);
  const [disabled, setDisabled] = useState<any>(false);

  const defaultFormValues: any = {};

  const { fields, remove, append } = useFieldArray({
    control,
    name: `how_to_use_jino_array`,
  });

  useEffect(() => {
    [1, 2, 3].forEach(() => {
      append({
        logo: "",
        title: "",
        sub_title: "",
      });
    });
  }, []);

  useEffect(() => {
    if (apiData) {
      setValue(
        "how_to_use_jino_array",
        apiData?.page_content?.how_to_use_jino?.how_to_use_jino_array
      );
    }
  }, [apiData]);

  return (
    <ControlledAccordion collapse={collapse}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>How To Use Jino </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer spacing={3} style={{ flexGrow: 1 }}>
          <GridItem xs={6} md={6} sm={6} >
            <InputField
              errors={!!errors?.use_jino_title}
              fieldName="use_jino_title"
              type="text"
              autoComplete="false"
              label="* Title "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.use_jino_title && errors?.use_jino_title?.message
              }
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.use_jino_sub_title}
              fieldName="use_jino_sub_title"
              type="text"
              autoComplete="false"
              label="* Sub Title "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.use_jino_sub_title &&
                errors?.use_jino_sub_title?.message
              }
            />
          </GridItem>

          <GridItem xs={6} md={6} sm={6}>
            <InputField
              errors={!!errors?.use_jino_description}
              fieldName="use_jino_description"
              type="text"
              autoComplete="false"
              label="* Description "
              control={props?.control}
              isLoading={true}
              valueGot={""}
              setValue={props?.setValue}
              helperText={
                errors?.use_jino_description &&
                errors?.use_jino_description?.message
              }
            />
          </GridItem>
          <GridItem xs={6} md={6} sm={6}></GridItem>
        </GridContainer>
      </AccordionDetails>

      <AccordionDetails style={{ display: "block" }}>
        {fields.map((item: any, index) => {
          // console.log("item", item);

          return (
            <GridContainer key={item.id} spacing={3}>
              <GridItem xs={4} md={4} sm={4} style={{ marginTop: "7px" }}>
                <Box
                  style={{ display: "flex", alignItems: "center" }}
                  alignItems="center"
                >
                  <FileUploadFieldArray
                    fieldName={`how_to_use_jino_array[${index}].logo`}
                    keyss={`how_to_use_jino_array[${index}.logo]`}
                    open={dropOpen}
                    index={index}
                    lable={"Use Jino Image"}
                    setOpen={setDropOpen}
                    setValue={props?.setValue}
                    getValuesX={getValues}
                    control={props?.control}
                    disabled={disabled}
                    message={
                      "Drag and drop a file here or Click. Note: Only jpeg, png will be allowed. The maximum size is 10mb."
                    }
                  />
                </Box>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{marginTop:"30px"}}>
                <InputField
                  errors={!!errors?.how_to_use_jino_array?.[index]?.title}
                  fieldName={`how_to_use_jino_array[${index}].title`}
                  type="text"
                  label="* Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.how_to_use_jino_array?.[index]?.title &&
                    errors?.how_to_use_jino_array?.[index]?.title.message
                  }
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4} style={{marginTop:"30px"}}>
                <InputField
                  errors={!!errors?.how_to_use_jino_array?.[index]?.sub_title}
                  fieldName={`how_to_use_jino_array[${index}].sub_title`}
                  type="text"
                  label="* Sub Title"
                  autoComplete="false"
                  control={props?.control}
                  setValue={props?.setValue}
                  valueGot={""}
                  helperText={
                    errors?.how_to_use_jino_array?.[index]?.sub_title &&
                    errors?.how_to_use_jino_array?.[index]?.sub_title.message
                  }
                />
              </GridItem>
            </GridContainer>
          );
        })}
      </AccordionDetails>
      {/* <Button
        variant="contained"
        color="primary"
        className={"custom-btn"}
        onClick={() => {
          append({ logo: "", title: "", sub_title: "" });
        }}
      >
        Add more
      </Button> */}
      <AccordionActions></AccordionActions>
    </ControlledAccordion>
  );
};
